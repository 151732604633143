import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { GlobalService } from '../services/global/global.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private globalService: GlobalService,
        private router: Router
    ) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        if ( !this.globalService.getConnectedUser() ) {
            console.log('Not connected')
            this.router.navigate(["/login"]);
           return false;
        }
        return true;

    }
    
}