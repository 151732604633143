import { BrowserModule } from '@angular/platform-browser';
import { NgModule , APP_INITIALIZER } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';
registerLocaleData(localeFr, 'fr-FR', localeFrExtra);
import { LOCALE_ID } from '@angular/core';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
// import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { HttpClientModule , HTTP_INTERCEPTORS } from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import * as $ from 'jquery';
import { MaterialAngModule } from './matmodule/materialang.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr'; 

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { DashboardModule } from './dashboard/dashboard.module'
import { SharedModule } from './shared/shared.module';
import { PagesModule } from './pages/pages.module';
import { HeaderModule } from './header/header.module';
import { FooterModule } from './footer/footer.module';
import { LoginModule } from './login/login.module';
import { ModalModule } from './modal/modal.module';

import { JwtInterceptor } from './auth-helper/jwt.interceptor';
import { ErrorInterceptor } from './auth-helper/error.interceptor';
import { BackendService } from './services/backend/backend.service';
import { UtilsService } from './services/utils/utils.service';
import { NgxImageCompressService } from 'ngx-image-compress';
import { LoaderService } from './services/loader/loader.service';


export function loadConfigurations(backService: BackendService) {
  return () => backService.load();
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,

    ModalModule,
    DashboardModule,
    FooterModule,
    HeaderModule,
    PagesModule,
    SharedModule,
    LoginModule,

    ToastrModule.forRoot({
      timeOut: 4000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      closeButton: true,
    })

  ],
  providers: [
    BackendService, 
    UtilsService,
    LoaderService,
    { provide: APP_INITIALIZER, useFactory: loadConfigurations, deps: [BackendService], multi: true },
    { provide: LOCALE_ID, useValue: 'fr-FR' } ,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    NgxImageCompressService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
