import { Injectable , EventEmitter, Output } from '@angular/core';
import { environment } from 'src/environments/environment';

import { AngularEditorConfig } from '@kolkov/angular-editor';
import {NgxImageCompressService} from 'ngx-image-compress';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  public urlImage = environment.urlImage;
  editorConfig: AngularEditorConfig = {
      editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '100',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Le contenu ...',
      defaultParagraphSeparator: 'br',
      defaultFontName: 'Times New Roman',
      defaultFontSize: '2',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'Text normal',
        class: 'text-12',
      },
      {
        name: 'Sous sous titre',
        class: 'textSousTitre'
      },
      {
        name: 'Sous titre',
        class: 'textTitreH4'
      },
      {
        name: 'Titre',
        class: 'textTitreH3'
      }
    ],
    uploadUrl: `${environment.backendUrl}/parametrage/upload`,
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      
      ['heading',  'color'],
      ['paragraph', 'blockquote', 'removeBlockquote', 'horizontalLine', 'orderedList', 'unorderedList'],
      ['cut', 'copy', 'delete'],
    ]
  };

  search = {
    trajet_de: "",
    trajet_a: ""
  }

  pourcentageMS = "20"
  pourcentageGarantie = 0.15;
  sansGarantie = 0.5;

  annoneEnModification = null
  userNumber: any = environment.msahilishoFrontUserId;
  @Output() maskNumTelChanged = new EventEmitter();


  infosProducts = [
    {id: 1, nomproduct: "Téléphone portable", image: "smartphone.svg", prixproduct: "15", partype: "", poids: "0"},
    {id: 2, nomproduct: "Tablette", image: "tablet.svg", prixproduct: "15", partype: "", poids: "0"},
    {id: 3, nomproduct: "Montre", image: "wristwatch.svg", prixproduct: "10", partype: "", poids: "0"},
    {id: 4, nomproduct: "Usb + SD Disk", image: "sdcard.svg", prixproduct: "5", partype: "", poids: "0"},
    {id: 5, nomproduct: "Appareil photo", image: "camera.svg", prixproduct: "18", partype: "", poids: "0"},
    {id: 6, nomproduct: "Ordinateur portable", image: "laptop.svg", prixproduct: "30", partype: "", poids: "0"},
    {id: 7, nomproduct: "Batterie (Externe/Ordi)", image: "battery.svg", prixproduct: "15", partype: "", poids: "0"},
    {id: 8, nomproduct: "Chargeur", image: "charging.svg", prixproduct: "5", partype: "", poids: "0"},
    {id: 9, nomproduct: "Écouteurs", image: "airpods.svg", prixproduct: "5", partype: "", poids: "0"},
    {id: 10, nomproduct: "Disque dur", image: "disk.svg", prixproduct: "10", partype: "", poids: "0"},
    {id: 11, nomproduct: "Parfum (et/ou Coffret)", image: "perfume.svg", prixproduct: "15", partype: "", poids: "0"},
    {id: 12, nomproduct: "Lunette", image: "sunglasses.svg", prixproduct: "10", partype: "", poids: "0"},
    {id: 13, nomproduct: "Or", image: "gold.svg", prixproduct: "2", partype: "", poids: "0"},
    {id: 14, nomproduct: "Argent", image: "money.svg", prixproduct: "2", partype: "100€", poids: "0"},
    {id: 15, nomproduct: "Passeport", image: "passport.svg", prixproduct: "15", partype: "", poids: "0"},
    {id: 16, nomproduct: "Petit format", image: "envelopebig.svg", prixproduct: "10", partype: "", poids: "0"},
    {id: 17, nomproduct: "Grand format", image: "envelope.svg", prixproduct: "15", partype: "", poids: "0"},
    {id: 18, nomproduct: "Kofia", image: "kofia.jpg", prixproduct: "10", partype: "", poids: "0"},
    {id: 19, nomproduct: "Nkandou", image: "nkandou.png", prixproduct: "10", partype: "", poids: "0" },
    {id: 20, nomproduct: "Sandale artisanale", image: "sandales.jpg", prixproduct: "10", partype: "", poids: "0"},
    {id: "autre", nomproduct: "Autre", image: "other.svg", prixproduct: "", partype: "", poids: "0" }
  ];

  constructor(
    private imageCompress: NgxImageCompressService,
   // private eventService: EventsService 
  ) { }

// La liste des trajet est à date - 4 jours
  getStatutTrajet() {
    return [
      {id: 1, statut: 'En cours'},
      {id: 2, statut: 'En attente de validation'},
      {id: 3, statut: 'Terminé'},
      {id: 4, statut: 'Tous'}
    ]
  }

  getStatutSendbox() {
    return [
      {id: 1, statut: 'En cours'},
      {id: 2, statut: 'En attente de validation'},
      {id: 3, statut: 'Validée par le transporteur'},
      {id: 4, statut: 'Réfusée par le transporteur'},
      {id: 5, statut: 'Terminée et notée'},
      {id: 6, statut: 'Terminée'},
      {id: 7, statut: 'Annulée'},
      {id: 8, statut: 'Toutes'}
    ]
  }

  getStatutVoyage() {
    return [
      {id: 1, statut: 'En cours'},
      {id: 2, statut: 'En attente de validation'},
      {id: 3, statut: 'Validée'},
      {id: 4, statut: 'Terminée'},
      {id: 5, statut: 'Annulée'},
      {id: 6, statut: 'Toutes'}
    ]
  }

  downloadFile(base64String, filename) {
    const source = `${base64String}`;
    const link = document.createElement("a");
    link.href = source;
    link.download = filename
    link.click();
  }

  downloadPdf(base64String, fileName) {
    const source = `data:application/pdf;base64,${base64String}`;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${fileName}.pdf`
    link.click();
  }

  downloadPdfPromise(base64String, fileName) {
    return new Promise((res, err) => {
      const source = `data:application/pdf;base64,${base64String}`;
      const link = document.createElement("a");
      link.href = source;
      link.download = `${fileName}.pdf`
      link.click();
      res( true )
    });
  }

  

  getExtentionBase64( base64file ) {
    const arrayMimeType = base64file.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/);

    console.log( ' arrayMimeType ', arrayMimeType)
    if ( !!arrayMimeType ) {
      const mimeType = arrayMimeType[0];
      const extensionFile = mimeType.split('/')
      console.log( 'extensionFile ' , extensionFile )
      return extensionFile[1]
    } else {
      return 'png';
    }
    
  }

  getUserMsahilishoID() {
    return this.userNumber
  }

  getURLApp() {
    return environment.urlApp
  }

  getUrlFront() {
    return environment.urlFrontApp
  }


  getConnectedUser(){
    return localStorage.getItem('msahilisho_curUserBack') ? JSON.parse(localStorage.getItem('msahilisho_curUserBack')) : null
  }


  setConnectedUser( user ){
    return localStorage.setItem('msahilisho_curUserBack', JSON.stringify( user ) );
  }

  setSeSouvenir( user ){
    user ? localStorage.setItem('msahilisho_seSouvenirUserBack', JSON.stringify( user ) ) : localStorage.removeItem('msahilisho_seSouvenirUserBack');
  }

  getSeSouvenir(){
    return localStorage.getItem('msahilisho_seSouvenirUserBack') ? JSON.parse(localStorage.getItem('msahilisho_seSouvenirUserBack')) : null
  }


  logOut( ) {
    
    localStorage.removeItem('msahilisho_curUserBack')
    localStorage.removeItem('msahilisho_annonceEncoursBack')
  }
  

  setAnnoncdSelected( annone ) {
    localStorage.setItem('msahilisho_annonceEncoursBack', JSON.stringify( annone ))
  }

  getAnnoncdSelected() {
    return localStorage.getItem('msahilisho_annonceEncoursBack') ? JSON.parse(localStorage.getItem('msahilisho_annonceEncoursBack')) : null
  }

  setSearch( srch ){
    this.search = srch
  }

  getSearch(){
    return this.search
  }


  setPourcentage( pourcentage ) {
    this.pourcentageMS = pourcentage
  }

  getPourcentage() {
    return this.pourcentageMS
  }


  setAnnoneEnModification(annone) {
    this.annoneEnModification = annone
  }

  getAnnoneEnModification(){
    return this.annoneEnModification
  }

  setCookiesBrowserMs(){
    let cookies_ = {
      productSub: navigator.productSub,
      userAgent: navigator.userAgent,
      platform: navigator.platform,
      appVersion: navigator.appVersion,
      appName: navigator.appName
    }
    localStorage.setItem('msahilisho_cookiesAcceptUserBack', JSON.stringify( cookies_ ))
  }

  getCookiesBrowserMs(){
    return localStorage.getItem('msahilisho_cookiesAcceptUserBack') ? JSON.parse(localStorage.getItem('msahilisho_cookiesAcceptUserBack')) : null
  }


  dateDiff(date1, date2){
      var diff = {sec: null, min: null, hour: null, day: null}                           // Initialisation du retour
      var tmp = date2 - date1;
  
      tmp = Math.floor(tmp/1000);             // Nombre de secondes entre les 2 dates
      diff.sec = tmp % 60;                    // Extraction du nombre de secondes
  
      tmp = Math.floor((tmp-diff.sec)/60);    // Nombre de minutes (partie entière)
      diff.min = tmp % 60;                    // Extraction du nombre de minutes
  
      tmp = Math.floor((tmp-diff.min)/60);    // Nombre d'heures (entières)
      diff.hour = tmp % 24;                   // Extraction du nombre d'heures
      
      tmp = Math.floor((tmp-diff.hour)/24);   // Nombre de jours restants
      diff.day = tmp;
      
      return diff;
  }


  setRemisecentPoints( remise ) {
    localStorage.setItem('mshlish_remisecentPointsBack', remise)
  }

  getRemisecentPoints() {
    return localStorage.getItem('mshlish_remisecentPointsBack') ? parseFloat(localStorage.getItem('mshlish_remisecentPointsBack')): 0;
  }



  setVisitor( visitor ){
    if( visitor !== undefined && visitor !== null) {
      console.log('Visitor :: ', visitor )
      localStorage.setItem('msahilisho_visitoXztrPBack', JSON.stringify( visitor ))
    }
      
  }

  getVisitor(){
    return localStorage.getItem('msahilisho_visitoXztrPBack') && localStorage.getItem('msahilisho_visitoXztrPBack') !== "undefined" ? JSON.parse(localStorage.getItem('msahilisho_visitoXztrPBack')) :  null 
  }


  randomString(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcde-fghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  makeid(length) {
    var result           = '';
    var characters       = '123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }


  getErrorCode(){
    return [
      0, 401, 403, 404, 422, 500, 501, 503, 301, 302 
    ];
  }


  modelValueToUpload = null; 
  getBase64(event) {
    this.modelValueToUpload = null;
    let me = this;
    let file = event.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      me.modelValueToUpload = reader.result;
    };
    reader.onerror = function (error) {
    };
  }


  getBase64Promise ( event ) {
    return new Promise((res, err) => {
      this.modelValueToUpload = null;
      let me = this;
      let file = event.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        me.modelValueToUpload = reader.result;
        res( reader.result )
      };
      reader.onerror = function (error) {
          err(error)
      };
      
    });
  } 


  compressImage(src) {
    return new Promise((res, rej) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        const elem = document.createElement('canvas');
        const MAX_WHIDTH = 2000;
        const MAX_HEIGHT = 2000;
        console.log( 'image ise de image ' , img.width , img.height)

        let newX = img.width;
        let newY = img.height;

        console.log( "Befro New height and width " , newY, newX)

        if ( newX > newY ) {
          if ( newX > MAX_WHIDTH ) {
            newY *= MAX_WHIDTH / newX;
            newX = MAX_WHIDTH;
          }
        } else {
          if ( newY > MAX_HEIGHT ) {
            newX *= MAX_HEIGHT / newY;
            newY = MAX_HEIGHT;
          }
        }

        console.log( "New height and width " , newY, newX)
        elem.width = newX;
        elem.height = newY;
        const ctx = elem.getContext('2d');
        ctx.drawImage(img, 0, 0, newX, newY);
        const data = ctx.canvas.toDataURL();
        res(data);
      }
      img.onerror = error => rej(error);
    })
  }


  uploadImageFile() {
    return new Promise((res, err) => {
      return this.imageCompress.uploadFile().then(({image, orientation}) => {

        console.log( 'Image avant compress ::: ', image )
        console.warn('Size in bytes was:', this.imageCompress.byteCount(image));
        
        return this.imageCompress.compressFile(image, orientation, 50, 50).then(
          result => {
            this.modelValueToUpload = result;
            console.warn('Size in bytes is now:', this.imageCompress.byteCount(result));
            console.log( 'Image apres compress ::: ', result )
            res( result )
          }
        );
        
      });
    });
  }


  b64toBlob(dataURI) {

    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpeg' });
}




  getModelValueToUpload() {
    return this.modelValueToUpload;
  }

  setModelValueToUpload( value ) {
    this.modelValueToUpload = value;
  }

  dataURLtoFile(dataurl, filename) {
 
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, {type:mime});
  }


  getEditorConfig() {
    return this.editorConfig;
  }


  renameKey ( obj, oldKey, newKey ) {

    obj = obj.forEach(element => {
      element[newKey] = element[oldKey];
      delete element[oldKey];
    });

    return obj;
  }

  removeKey ( obj, key ) {
    return obj.forEach(function(v){ delete v[key] });
  }

  initList( arrayBoject ){
    for (var i = 0; i < arrayBoject.length; i++) {
      // arrayBoject[i].listItem = arrayBoject[i].listItem === "" ? [] : JSON.parse( arrayBoject[i].listItem )

      if ( arrayBoject[i].listItem ) {
        arrayBoject[i].listItem = arrayBoject[i].listItem === "" ? [] : JSON.parse( arrayBoject[i].listItem )
      }

      if ( arrayBoject[i].images ) {
        arrayBoject[i].images = arrayBoject[i].images === "" ? [] : JSON.parse( arrayBoject[i].images )
      }
      if ( arrayBoject[i].telephone_fr ) {
        arrayBoject[i].telephone_fr = arrayBoject[i].telephone_fr === "" ? [] : JSON.parse( arrayBoject[i].telephone_fr )
      }
      if ( arrayBoject[i].telephone_km ) {
        arrayBoject[i].telephone_km = arrayBoject[i].telephone_km === "" ? [] : JSON.parse( arrayBoject[i].telephone_km )
      }
      if ( arrayBoject[i].reseaux ) {
        arrayBoject[i].reseaux = arrayBoject[i].reseaux === "" ? [] : JSON.parse( arrayBoject[i].reseaux )
      }
    }
    return arrayBoject
  }

/**
 *
 *
 * - Super Admin : Gestion totale sur le site Web <br />  4
  - Administrateur : Ajoute, Modifie et Consulte <br /> 3
  - Manager : Ajoute et Consulte <br /> 2
  - Consultant : Consulte uniquement. Ne peut apporter aucune modification sur le site 1
 */
  canAdd() {
    const userConnected = this.getConnectedUser();
    if (!userConnected) {
      return false;
    }
    return parseInt(userConnected.niveau) >= 2 ? true : false;
  }

  canEdit() {
    const userConnected = this.getConnectedUser();
    if (!userConnected) {
      return false;
    }
    return parseInt(userConnected.niveau) >= 3 ? true : false;
  }

  canDelete() {
    const userConnected = this.getConnectedUser();
    if (!userConnected) {
      return false;
    }
    return parseInt(userConnected.niveau) === 4 ? true : false;
  }

  toastrwaring(message){  
   //  this.eventService.toastrwaring(message);  
  }


  checkInterdi(value : string) {
    return ["msahilisho", "admin", "administrateur", "agent", "voyage", "msahil"].includes( value.toLowerCase() )
  }

  checkIsMsahilisho( value ) {
    return value === "annonce@msahilishogroup.com"
  }


  getAeroports(){
    return [
      {name: "CDG Paris", pays: "France", drapeau: "france_.jpg"},
      {name: "ORY Paris", pays: "France", drapeau: "france_.jpg"},
      {name: "MRS Marseille", pays: "France", drapeau: "france_.jpg"},
      {name: "NCE Nice", pays: "France", drapeau: "france_.jpg"},
      {name: "BES Brest-Bretagne", pays: "France", drapeau: "france_.jpg"},
      {name: "RNS Rennes-Bretagne", pays: "France", drapeau: "france_.jpg"},
      {name: "RUN Saint-Denis", pays: "La Réunion", drapeau: "lareunion.png"},
      {name: "DZA Mamoudzou", pays: "Mayotte", drapeau: "mayotte.png"},
      {name: "HAH Moroni", pays: "Comores", drapeau: "comores_.png"},
      {name: "DSS Dakar", pays: "Sénégal", drapeau: "senegal_.png"},
      {name: "IST Istanbul", pays: "Turquie", drapeau: "turquie_.png"},
      {name: "SAW Istanbul", pays: "Turquie", drapeau: "turquie_.png"},
      {name: "ESB Ankara", pays: "Turquie", drapeau: "turquie_.png"},
      {name: "AYT Antalya", pays: "Turquie", drapeau: "turquie_.png"},
      {name: "TUN Tunis", pays: "Tunisie", drapeau: "tunisie_.png"},
      {name: "CMN Nouaceur", pays: "Maroc", drapeau: "maroc_.png"},
      {name: "RAK Marrakech", pays: "Maroc", drapeau: "maroc_.png"},
      {name: "AGA Agadir", pays: "Maroc", drapeau: "maroc_.png"},
      {name: "FEZ Fès", pays: "Maroc", drapeau: "maroc_.png"},
      {name: "TNG Tanger", pays: "Maroc", drapeau: "maroc_.png"}
    ];
  }

  getFrequenceVoyage(){
    return [
      {name: "Occasionnellement", value: "occasionnellement"},
      {name: "Toutes les semaines", value: "toutes_les_semaines"},
      {name: "Tous les mois", value: "tous_les_mois"}
    ]
  }

  getMoyenPaiement(){
    return [
      {name: "Virement", value: "virement"},
      {name: "Espèce", value: "espece"}
    ]
  }

  getTypeObjet(){
    return [
      {name: "Enveloppe", value: "enveloppe"},
      {name: "Colis", value: "colis"}
    ]
  }

  getInfoPlaceholder(){
    return {
      txtEnv: {
        taille: "La taille de votre enveloppe. Grand ou petit format",
        poids: "Le poids approximatif de votre enveloppe. Attention ! Sauf indication, le prix est le même qu'en KG.",
        infos: "Informations sur le l'enveloppe. Décrivez le contenu exact de votre enveloppe. Attention ! L'agent Msahilisho vérifiera son contenu avant de le récupérer."
      },
      txtColi: {
        taille: "La taille de l'objet envoyé en longueur et en largeur: Exemple 25cmX15cm. Si vous ne savez pas la taille, indiquez (Petit colis ou Grand colis)",
        poids: "Poids en KG de votre objet.",
        infos: "Informations sur le colis. Décrivez le contenu exact de votre colis, la valeur de son contenu et la quantité. Attention ! L'agent Msahilisho vérifiera son contenu avant de le récupérer."
      }
    }
  }

  getTypeEnveloppe(){
    return [
      {name: "Enveloppe grand format", value: "Enveloppe grand format"},
      {name: "Enveloppe standard", value: "Enveloppe standard"},
    ]
  }

  getInfosProducts() {
    return this.infosProducts;
  }

  maskSaisi( tel ) {
    if( tel.substring(0, 1) !== "+" ) {
      tel = '+' + tel;
    }
    console.log( ' telteltel ==== ', tel)
    let maskTel = {mask: "(+00) 0 00 00 00 00", value: "france_.jpg"};
    if( tel.substring(0, 3) === "+33" ) {
      //France
      maskTel.mask = "(+00) 0 00 00 00 00"
      maskTel.value = "france_.jpg"
    } else if( tel.substring(0, 4) === "+269" ) {
      //Comores
      maskTel.mask = "(+000) 000 00 00"
      maskTel.value = "comores_.png"
    } else if( tel.substring(0, 4) === "+221" ){
      //Sénégal
      maskTel.mask = "(+000) 00 000 00 00"
      maskTel.value = "senegal_.png"
    } else if( tel.substring(0, 4) === "+262" ){
      //Mayotte - La réunion
      maskTel.mask = "(+000) 0 00 00 00 00"
      maskTel.value = "france_.jpg"
    } else if( tel.substring(0, 3) === "+90" ){
      // Turquie
      maskTel.mask = "(+00) 00 00 00 00"
      maskTel.value = "turquie_.png"
    } else if( tel.substring(0, 4) === "+212" ){
      // Maroc
      maskTel.mask = "(+000) 00 00 00 00 00"
      maskTel.value = "maroc_.png"
    } else if( tel.substring(0, 4) === "+216" ){
      // Tunisie
      maskTel.mask = "(+000) 00 000 00"
      maskTel.value = "tunisie_.png"
    } else {
      maskTel.mask = "(+00) 0 00 00 00 00"
      maskTel.value = "france_.jpg"
    }

    this.maskNumTelChanged.emit( maskTel );
    return maskTel;

  }


  getAdresse( utilisateur ) {
    let adresse = '';
    adresse += utilisateur.address ? utilisateur.address + ' ' : '';
    adresse += utilisateur.zipecode ? utilisateur.zipecode + ' ' : '';
    adresse += utilisateur.city ? utilisateur.city + ' ' : '';
    return adresse;
  }

  getDateStringToDate( dateSend ) {
    const dateTrajet = new Date( dateSend );
    dateTrajet.setHours(0,0,0);
    return dateTrajet;
  }

  getListeIndicatifTelephone() {
    return  [
      { pays: 'fr', text: '(+33)', image: 'france_.jpg', mask: "(+00) 0 00 00 00 00" },
      { pays: 'km', text: '(+269)', image: 'comores_.png', mask: "(+000) 000 00 00" },
      { pays: 're', text: '(+262)', image: 'france_.jpg', mask: "(+000) 0 00 00 00 00" },
      { pays: 'sn', text: '(+221)', image: 'senegal_.png', mask: "(+000) 00 000 00 00" },
      { pays: 'ma', text: '(+212)', image: 'maroc_.png', mask: "(+000) 00 00 00 00 00" },
      { pays: 'tr', text: '(+90)', image: 'turquie_.png', mask: "(+00) 00 00 00 00" },
      { pays: 'tn', text: '(+216)', image: 'tunisie_.png', mask: "(+000) 00 000 00" }
    ];
  }

  getIndicatifTelephoneByMask( mask ) {
    const list = this.getListeIndicatifTelephone();
    return list.find(elt => elt.mask === mask);
  }

  unFormateDate( la_date ){
    if (!!la_date) {
      let la_date_ = la_date.split("-")
      return la_date_[2] + la_date_[1] + la_date_[0]
    } else {
      return '';
    }
  }

  unFormateDateContrat( la_date ){
    if (!!la_date) {
      let la_date_ = la_date.split("-")
      return `${la_date_[2]}/${la_date_[1]}/${la_date_[0]}`
    } else {
      return '';
    }
  }

  getListEmplacement() {
    return [
      { id: 1, libelle: 'Chez l\'expéditeur' },
      { id: 2, libelle: 'Remis à Msahilisho'},
      { id: 3, libelle: 'En cours d\'acheminement'},
      { id: 4, libelle: 'Retourner à l\'expéditeur'},
      { id: 5, libelle: 'En point de retrait'},
      { id: 6, libelle: 'Livré'},
    ]
  }




}
