export const environment = {
  production: true,
  environment: 'production',
  backendUrl: "https://msahilishogroup.com/backend/api/api/v1",
  urlImage: "https://msahilishogroup.com/backend/",
  urlApp: "https://adminpanel.msahilishogroup.com/",
  backendApi: "",
  urlFrontApp: "https://msahilishogroup.com",
  publish: true,
  msahilishoFrontUserId: 1,
  listeDiffusionGlobale: 6,
  campagneIdNouveauTrajet: 8,
  campagneIdKilosdispo: 23
};