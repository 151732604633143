import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditSendboxComponent } from './edit-sendbox.component';
@Injectable({
  providedIn: 'root'
})
export class EditSendboxService {

  constructor(private modalService: NgbModal) { }

  public confirmer(
    element,
    btnOkText: string = 'Yes',
    btnCancelText: string = 'No',
    dialogSize: 'sm'|'lg' = 'lg'): Promise<boolean> {
    const modalRef = this.modalService.open(EditSendboxComponent, { size: dialogSize });
    modalRef.componentInstance.element = element;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;
    return modalRef.result;
  }
}
