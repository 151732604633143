import { Component, OnInit , ViewChild , Input, Output , EventEmitter } from '@angular/core';

import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

import { ConfirmService } from '../../modal/confirm/confirm.service';
import { UtilsService } from '../../services/utils/utils.service';
import { GlobalService } from '../../services/global/global.service';
import { EventsService } from '../../services/events/events.service';
import { PrintSrvService } from 'src/app/services/print-srv/print-srv.service';
@Component({
  selector: 'app-arraytable',
  templateUrl: './arraytable.component.html',
  styleUrls: ['./arraytable.component.scss']
})
export class ArraytableComponent implements OnInit {

  
  dataSource: MatTableDataSource<any>;
  @Input() columnsToDisplay: string[] = [];
  @Input() elementsDisplay: string[] = [];
  @Output() tableEmitter = new EventEmitter();
  @Input() canDelete = false;
  @Input() canEdit = false;
  @Input() canList = false;
  @Input() canConfirm = false;
  @Input() canPrint = false;
  @Input() canReinit = false;

  @Input() typeTable: string = '';
  @Input() titrePrint: string = '';

  @Input() set arrayList(value: any) {
    console.log('Array of element ' , value)
    this.dataSource = new MatTableDataSource(value);
    console.log( ' his.dataSource ', this.dataSource)
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  maxItemList = Array(3).fill(3)

  afficherIF = [
    'action',
    'datecreate',
    'image',
    'listItem',
    'images',
    'niveau',
    'activate',
    'photo',
    'user_suggest',
    'trajet_suggest',
    'user_sender',
    'numtrans',
    'nom_prenom',
    'datedepart',
    'datearrivee',
    'email',
    'actif',
    'user_sender_avis',
    'note',
    'nbrstar',
    'photo_sender',
    'justificatif',
    'description',
    'agent_msahilisho',
    'comment'
  ]

  constructor(
    private confirmService: ConfirmService,
    private utilsService: UtilsService,
    private globalService: GlobalService,
    private eventsService: EventsService,
    private printSrvService: PrintSrvService
  ) { }

  ngOnInit(): void {
    
  }

  getNbrEtoile( nbr ) {
    console.log( ' Nbr ', nbr)
    return Array(nbr).fill(nbr)
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  isDansListe( column ) {
    return this.afficherIF.includes( column )
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }

    console.log( ' his.dataSource ', this.dataSource)
  }

  getNiveauByID( niveau ) {
    
    const leNiveau = this.utilsService.getNiveauByNiveau( niveau );
    console.log('le niveau niveau est ', niveau, leNiveau)

    return this.utilsService.getNiveauByNiveau( niveau );
  }


  
  

  btnEncoursAction(event, element, action) {
    if ( action === 'edit' ) {
      this.editElement( element );
    } else if ( action === 'delete' ) {
      this.deleteElement( element );
    } else if ( action === 'list' ) {
      this.showElement( element, action );
    } else if ( action === 'confirm' ) {
      this.showElement( element , action );
    } else if ( action === 'reinit' ) {
      this.refreshElement( element )
    }
  }


  deleteElement( elmt ) {

    if ( !this.globalService.canDelete() ) {
      this.eventsService.toastrwaring("Vous ne disposez pas des autorisations requises pour cette action.")
      return;
    }

    const titre = elmt.titre ? elmt.titre : elmt.libelle ? elmt.libelle : 'données';
    this.confirmService.confirm(`Suppression > ${titre}`, 'Êtes-vous sûr de vouloir supprimer cet élément ?', 'Oui', 'Non' , '' )
    .then( (confirmed : any) => {   

      if( confirmed.etat == "accept" ) {
        this.tableEmitter.emit( {element: elmt, method: 'delete'} );
      }

    })
    .catch( () => {

    })
  }


  editElement( elmt ) {

    if ( !this.globalService.canEdit() ) {
      this.eventsService.toastrwaring("Vous ne disposez pas des autorisations requises pour cette action.")
      return;
    }

    const titre = elmt.titre ? elmt.titre : elmt.libelle ? elmt.libelle : 'données';
    this.confirmService.confirm(`Modification > ${titre}`, 'Êtes-vous sûr de vouloir modifier cet élément ?', 'Oui', 'Non' , '' )
    .then( (confirmed : any) => {   

      if( confirmed.etat == "accept" ) {
        this.tableEmitter.emit( {element: elmt, method: 'update'} );
      }

    })
    .catch( () => {

    })
  }


  showElement( elmt , action ) {

    if ( !this.globalService.canAdd() ) {
      this.eventsService.toastrwaring("Vous ne disposez pas des autorisations requises pour cette action.")
      return;
    }
    this.tableEmitter.emit( {element: elmt, method: action} );
  }

  

  downloadFile( element, colomn ) {
    const extensionFile = this.globalService.getExtentionBase64( element[colomn] );
    const ladate = new Date();

    this.globalService.downloadFile(
      `${element[colomn]}` ,
      `${colomn}-${this.globalService.makeid(10)}.${extensionFile}`
    )
  }


  printArray() {
    if ( this.typeTable === 'sendbox' ) {
      this.printSrvService.printArraySendbox( this.dataSource.filteredData, this.titrePrint )
    } else if ( this.typeTable === 'voyageur' ) {
      this.printSrvService.printArrayVoyageurs( this.dataSource.filteredData, this.titrePrint )
    } else if ( this.typeTable === 'utilisateurs' ) {
      this.printSrvService.printArrayUtilisateurs( this.dataSource.filteredData)
    }
  }


  refreshElement( elmt ) {
    if ( !this.globalService.canEdit() ) {
      this.eventsService.toastrwaring("Vous ne disposez pas des autorisations requises pour cette action.")
      return;
    }

    console.log(elmt)

    this.confirmService.confirm(`Réinitialisation du mot de passe du compte de  ${elmt.username}`, 'Êtes-vous sûr de vouloir réinitialiser le mot de passe ?', 'Oui', 'Non' , '' )
    .then( (confirmed : any) => {   
      if( confirmed.etat == "accept" ) {
        this.tableEmitter.emit( {element: elmt, method: 'reinit'} );
      }
    })
    .catch( () => {
    })
  }


}
