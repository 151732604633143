import { Component, OnInit , HostListener ,  ElementRef, ViewChild , AfterViewInit } from '@angular/core';

import {Location} from '@angular/common'
import { Router } from '@angular/router';
import { MatDrawer } from '@angular/material';
import { EventsService } from 'src/app/services/events/events.service'
import { GlobalService } from 'src/app/services/global/global.service'
import { ValidatorrService } from 'src/app/services/validatorr/validatorr.service'
import { BackendService } from 'src/app/services/backend/backend.service';
import { PrintSrvService } from 'src/app/services/print-srv/print-srv.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmService } from 'src/app/modal/confirm/confirm.service';

@Component({
  selector: 'app-lister-utilisateurs',
  templateUrl: './lister-utilisateurs.component.html',
  styleUrls: ['./lister-utilisateurs.component.scss']
})
export class ListerUtilisateursComponent implements OnInit {

  userConnected
  urlImage
  opened = true
  txtBadge = "Afficher le menu"
  @ViewChild("drawer", {static: false}) drawer: MatDrawer;

  loadFinished = false

  columnsToDisplay = ['id', 'lastname', 'firstname', 'email', 'telephone', 'address', 'zipecode', 'city', 'photo', 'actif', 'action'];
  elementsDisplay = ['#', 'Nom', 'Prénom', 'E-mail', 'Téléphone', 'Adresse', 'BP', 'Ville', '#', 'État', 'Action'];
  lesUtilisateursArray = [];
  retourMessage

  constructor(
    private location: Location ,
    private router: Router,
    private eventsService: EventsService,
    private backendService: BackendService ,
    private globalService: GlobalService,
    private _formBuilder: FormBuilder,
    private printSrvService: PrintSrvService,
    private confirmService: ConfirmService
  ) { 
  }

  ngOnInit(): void {
    this.isConnectedUser()
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.mediaQuery()
      this.displayPage()
    }, 1000);
  }

  isConnectedUser() {
    this.userConnected = this.globalService.getConnectedUser();
  }
  /** *********************  Sidenav **********************  */
  mediaQuery() {
    this.drawer.opened  = window.innerWidth > 900 ? true : false
    this.txtBadge = window.innerWidth > 900 ? "Cacher le menu" : "Afficher le menu"
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.mediaQuery()
  }
  eventToggle( drawer_ ){
    this.txtBadge = drawer_._opened ?  "Cacher le menu" : "Afficher le menu"
  }

  displayPage() {
    this.loadFinished = false;
    this.backendService.get(`/users/users` )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.lesUtilisateursArray = [];
      } else {
        console.log( resultat )
        this.lesUtilisateursArray = resultat;
      }
      this.loadFinished = true
    })
    .catch( error => {
      this.lesUtilisateursArray = [];
      this.loadFinished = true
      this.retourMessage = {ko: "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."}
    })
  }


  onTableAction( event) {
    console.log('onTableAction Table action ', event)
    const element = event.element
    /*if (event.method === 'confirm') {
      this.activerUtilisateur( element )
    }*/
    if (event.method === 'list') {
      this.router.navigate([`/les-utilisateurs/msahilisho/lister-les-utilisateurs/5-1/${element.id}`])
      window.scroll(0,0);
    }

  }

}
