import { Component, OnInit , HostListener ,  ElementRef, ViewChild , AfterViewInit , Input } from '@angular/core';

import {Location} from '@angular/common'
import { Router , ActivatedRoute } from '@angular/router';
import { MatDrawer } from '@angular/material';
import {FormControl, FormGroup, Validators, FormBuilder} from '@angular/forms';

import { EventsService } from '../../services/events/events.service'
import { GlobalService } from '../../services/global/global.service'
import { ValidatorrService } from '../../services/validatorr/validatorr.service'
import { BackendService } from '../../services/backend/backend.service';
import { UtilsService } from '../../services/utils/utils.service';

@Component({
  selector: 'app-content-param',
  templateUrl: './content-param.component.html',
  styleUrls: ['./content-param.component.scss']
})
export class ContentParamComponent implements OnInit {

  userConnected
  showFormulaire = true;
  formulaireGroup: FormGroup;
  submitted = false;
  opened = true
  txtBadge = "Afficher le menu"
  @ViewChild("drawer", {static: false}) drawer: MatDrawer ;

  elementPage;
  retourMessage = { ok: null, ko: null };
  contentParam
  editorConfig

  @Input() set pageElement(value: any) {
    console.log('Array of element ' , value)
    this.elementPage = value;
    this.displayPage( value.selectedID )
  }

  constructor(
    private location: Location ,
    private router: Router,
    private eventsService: EventsService,
    private backendService: BackendService ,
    private globalService: GlobalService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private utilsService: UtilsService
  ) { 
    this.formulaireGroup = this.formBuilder.group({
      titreCtrl: [ '', Validators.required],
      contenuCtrl: [ '', Validators.required]
    });
  }

  ngOnInit(): void {
    this.editorConfig = this.globalService.getEditorConfig();
  }

  showOrHideFormulaure() {
    this.showFormulaire = !this.showFormulaire;
  }

  get f() { 
    return this.formulaireGroup.controls; 
  }

  displayPage( identifiant ) {
    this.backendService.get( `/cguteams/${identifiant}` )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.retourMessage.ko = resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
      } else {
         console.log( 'resultat ', resultat );
         this.contentParam = resultat;
         this.initFormulaire();
      }
     })
     .catch( error => {
      this.retourMessage.ko = "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
    })
  }

  initFormulaire() {
    this.formulaireGroup.patchValue({
      titreCtrl: this.elementPage.libelle,
      contenuCtrl: this.contentParam[0].contenu
    });
  }

  resetFormulaire() {
    this.retourMessage.ok = null;
    this.retourMessage.ko = null;
    this.submitted = false;
  }

  onSubmit() {
    this.submitted = true;
    this.retourMessage.ok = null;
    this.retourMessage.ko = null;

    if (this.formulaireGroup.invalid) {
      this.retourMessage.ko = "Une erreur a été détectée sur le formulaire. Certains champs sont obligatoires !";
      return;
    }

    const formSend = {
      titre: this.f.titreCtrl.value,
      contenu: this.f.contenuCtrl.value,
      agent: this.globalService.getConnectedUser().id
    }

    this.editContentParam( formSend );
  }

  editContentParam( formSend ) {
    if ( !this.globalService.canEdit() ) {
      this.eventsService.toastrwaring("Vous ne disposez pas des autorisations requises pour cette action.")
      return;
    }
    this.backendService.post( `/cguteams/update/${this.elementPage.selectedID}`, formSend )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.retourMessage.ko = resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
      } else {
        this.retourMessage.ok = resultat.text;
        setTimeout(() => {
          this.resetFormulaire();
          this.displayPage( this.elementPage.selectedID );
        }, 2000);
      }
     })
     .catch( error => {
      this.retourMessage.ko = "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
    })
  }

}
