import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { MaterialAngModule } from '../matmodule/materialang.module'


import { EventsService } from '../services/events/events.service'
import { GlobalService } from '../services/global/global.service'
import { ValidatorrService } from '../services/validatorr/validatorr.service'
import { BackendService } from '../services/backend/backend.service'
@NgModule({
  declarations: [
    HeaderComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    MaterialAngModule
  ],
  exports: [
    HeaderComponent
  ],
  providers: [
    EventsService,
    GlobalService,
    ValidatorrService,
    BackendService
  ]
})
export class HeaderModule { }
