<div class="row">
    <div class="col-md-12 text-h4 colorBleu">
        {{ elementPage?.libelle}}

        <span class="cursor displayRight text-12" (click)="showOrHideFormulaure()">
            {{ showFormulaire ? 'Cacher' : 'Afficher' }}
            <i class="material-icons text-h3 ">
                {{ showFormulaire ? 'expand_less' : 'expand_more' }}
            </i>
        </span>
    </div>
</div>

<form *ngIf="showFormulaire" class="contactForm" [formGroup]="formulaireGroup" (ngSubmit)="onSubmit()" >

    <div class="form-group" >
        <label for="titreCtrl">Titre  <span>(*)</span></label>
        <legend>Le titre de l'élément</legend> <!--   readonly="readonly"  -->
        <input id="titreCtrl" type="text" class="form-control" formControlName="titreCtrl" placeholder="Veuillez renseigner un titre" data-rule="minlen:10" data-msg="Veuillez saisir au moins 10 caractères" [ngClass]="{ 'is-invalid': submitted && f.titreCtrl.errors }">
        <div class="validation"></div>
    </div>

    <div class="form-group" >
        <label for="contenuCtrl">Contenu  <span>(*)</span></label>
        <legend> Contenu de la page.</legend>
    <!--
        <textarea class="form-control" id="contenuCtrl" rows="3" formControlName="contenuCtrl" 
        placeholder="Contenu" data-rule="minlen:10" data-msg="Veuillez saisir au moins 10 caractères" 
        [ngClass]="{ 'is-invalid': submitted && f.contenuCtrl.errors }"></textarea>
    -->

        <angular-editor formControlName="contenuCtrl" id="contenuCtrl" [config]="editorConfig" [ngClass]="{ 'is-invalid': submitted && f.contenuCtrl.errors }" ></angular-editor>

    </div>

    <div class="row" *ngIf="retourMessage" >

        <div class="col-md-12 div-error" *ngIf="retourMessage.ko" >
            {{retourMessage.ko}}
        </div>

        <div class="col-md-12 div-success" *ngIf="retourMessage.ok" >
            {{retourMessage.ok}}
        </div>

    </div>


    <div class="row">
        <div class="col">
            <div class="text-center">
                <button  class="btn-valider" type="submit" title="Enregistrer"> Enregistrer </button>
            </div>
        </div>
    </div>


    <div class="row" *ngIf="contentParam">
        <div class="col">
            <div class="text-left modification">
                <span>Dernière personne à avoir effectué des modifications : </span> {{contentParam[0].agent_msahilisho.username}}
            </div>
        </div>
    </div>

</form>