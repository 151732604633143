import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef, NgZone, OnDestroy } from '@angular/core';
import { GlobalService } from 'src/app/services/global/global.service';

@Component({
  selector: 'app-gest-tel',
  templateUrl: './gest-tel.component.html',
  styleUrls: ['./gest-tel.component.scss']
})
export class GestTelComponent implements OnInit, OnDestroy {

  maskTel = {mask: "(+00) 0 00 00 00 00" , value: "france_.jpg"}
  @Input() telphoneInput: any;
  @Input() placeholderInput: any;
  listTelephone = [];
  @Input() numTelCtrl;
  @Input() idTelephone;
  lastNum
  @Input() errors;
  @Output() numTelChanged = new EventEmitter();

  constructor(
    private globalService: GlobalService,
    private cdRef:ChangeDetectorRef,
    private zone: NgZone
  ) { 
    this.numTelCtrl = '';
    this.globalService.maskNumTelChanged.subscribe((mask: any) => {
      //this.maskTel = mask;
      console.log('mask num changed' , this.idTelephone, mask, this.maskTel );
      if ( !!this.maskTel && mask.mask != this.maskTel.mask ) {
        this.maskTel = mask;
      }
      this.cdRef.detectChanges();
    })
  }
  

  ngOnInit(): void {
    this.listTelephone = this.globalService.getListeIndicatifTelephone();
    if (!!this.numTelCtrl) {
      this.maskTel = this.globalService.maskSaisi( this.numTelCtrl );
    }
    
    console.log(' this.maskTelthis.maskTel ', this.maskTel)
  }

  ngOnDestroy() {
    console.log('On destroy ************************ > ', this.idTelephone , this.maskTel)
    this.idTelephone = null;
    this.maskTel = null;
    this.numTelCtrl = null;
  }


  numTelChange( newObject ) {
    if(!this.lastNum) {
      this.lastNum = newObject;
    }
    console.log('nre object ', newObject, this.numTelCtrl )
    if ( !!this.idTelephone && !!this.lastNum && this.lastNum !== this.numTelCtrl ) {
      this.lastNum = this.numTelCtrl;
      this.numTelChanged.emit( {tel: this.numTelCtrl, mask: this.maskTel, id: this.idTelephone} );
    }
  }

  /**
   *
   *
   * @param {*} pays
   * @memberof GestTelComponent
   */
  telFormat( ntl ) {
    this.maskTel.mask = ntl.mask;
    this.maskTel.value = ntl.image;
    this.numTelCtrl = ntl.text;
    this.cdRef.detectChanges();
    this.numTelChanged.emit( {tel: ntl.text, mask: this.maskTel, id: this.idTelephone} );
  }


  checkMask() {

  }

}
