import { Component, OnInit , HostListener ,  ElementRef, ViewChild , AfterViewInit } from '@angular/core';

import {Location} from '@angular/common'
import { Router } from '@angular/router';
import { MatDrawer, MatGridTileHeaderCssMatStyler } from '@angular/material';
import { EventsService } from 'src/app/services/events/events.service'
import { GlobalService } from 'src/app/services/global/global.service'
import { ValidatorrService } from 'src/app/services/validatorr/validatorr.service'
import { BackendService } from 'src/app/services/backend/backend.service'
import { ConfirmService } from 'src/app/modal/confirm/confirm.service';

@Component({
  selector: 'app-valider-photos',
  templateUrl: './valider-photos.component.html',
  styleUrls: ['./valider-photos.component.scss']
})
export class ValiderPhotosComponent implements OnInit {

  userConnected
  urlImage
  opened = true
  txtBadge = "Afficher le menu"
  @ViewChild("drawer", {static: false}) drawer: MatDrawer ;
  listePhotos = []
  retourMessage
  columnsToDisplay = ['id', 'photo',  'action'];
  elementsDisplay = ['#', 'Photo à valider', 'Valider ou refuser'];

  constructor(
    private location: Location ,
    private router: Router,
    private eventsService: EventsService,
    private backendService: BackendService ,
    private globalService: GlobalService,
    private confirmService: ConfirmService
  ) { }

  ngOnInit(): void {
    this.isConnectedUser()
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.mediaQuery()
      this.displayPage();
    }, 1000);
  }
  isConnectedUser() {
    this.userConnected = this.globalService.getConnectedUser();
  }
  /** *********************  Sidenav **********************  */
  mediaQuery() {
    this.drawer.opened  = window.innerWidth > 900 ? true : false
    this.txtBadge = window.innerWidth > 900 ? "Cacher le menu" : "Afficher le menu"
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.mediaQuery()
  }
  eventToggle( drawer_ ){
    this.txtBadge = drawer_._opened ?  "Cacher le menu" : "Afficher le menu"
  }
  /** *********************  Sidenav **********************  */


  displayPage(){
    this.retourMessage = null;
    this.backendService.get(`/users/get-list-photo-avalider` )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.listePhotos = [];
      } else {
        this.listePhotos = resultat;
      }
    })
    .catch( error => {
        this.listePhotos = [];
      this.retourMessage = {ko: "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."}
    })
  }

  onTableAction( event ) {
    console.log('onTableAction Table action ', event)
    const element = event.element
    if (event.method === 'confirm') {
      this.vailiderPhoto( element )
    }
  }



  vailiderPhoto( element ) {
    if ( !this.globalService.canEdit() ) {
      this.eventsService.toastrwaring("Vous ne disposez pas des autorisations requises pour cette action.")
      return;
    }
    
    this.confirmService.confirm(`Validation photo de profil N° [${element.id}]`, 'Êtes-vous sûr de vouloir valider cette photo ?', 'Valider', 'Anuler' , 'Refuser' )
    .then( (confirmed : any) => {
      console.log( confirmed )
      if( confirmed.etat !== "cancel" ) {

        let photo = {
          etat: confirmed.etat === "accept" ? 1 : 0,
          iduser: element.id_user,
          agent: this.globalService.getConnectedUser().id
        }

        console.log( 'photo' , photo)
        this.backendService.post(`/users/valider-photo/${element.id}`, photo)
        .then( resultat => {
          console.log('Result ', resultat)
          if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
            this.eventsService.errorsmsg( resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement." )
          } else {
            this.eventsService.successmsg( resultat.text )
            setTimeout( () => {
              this.displayPage();
            }, 2000)
          }
        })
        .catch( error => {
          this.eventsService.errorsmsg( "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement!" )
        })
      }
      
    })
    .catch( () => {
    })
  }

}
