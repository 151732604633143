<div *ngIf="title != ''" class="modal-header">
    <span class="modal-title text-h6 colorBleu"> {{title}} </span>
</div>
<div *ngIf="message != ''" class="modal-body text-12">
    <div class="row">
        <div class="download col-12" (click)="downloadFile()" > 
            <span class="material-icons">save_alt</span>
            Télécharger le fichier justificatif
        </div>
    </div>
</div>
<div class="modal-footer">

    <button type="button" class="btn btn-annuler" (click)="decline()"> Annuler</button>

    <button *ngIf="element.encours" type="button" class="btn btn-vert" (click)="modifier()"> Modifier </button>

    <button *ngIf="element.terminer === '0' " type="button" class="btn btn-bleu" (click)="terminer()"> Terminer le trajet </button>

    <button *ngIf="element.etat === '0'" type="button" class="btn btn-valider" (click)="accept()"> Valider ou refuser </button>
</div>