import { Injectable } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EtablirContratComponent } from './etablir-contrat.component';

@Injectable({
  providedIn: 'root'
})
export class EtablirContratVoyageService {

  constructor(private modalService: NgbModal) { }

  public confirm(
    voyageurSelected,
    suiviVoyage,
    btnOkText: string = 'Yes',
    btnCancelText: string = 'No',
    dialogSize: 'sm'|'lg' = 'lg'): Promise<boolean> {
    const modalRef = this.modalService.open(EtablirContratComponent, { size: dialogSize });
    modalRef.componentInstance.voyageurSelected = voyageurSelected;
    modalRef.componentInstance.suiviVoyage = suiviVoyage;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;
    return modalRef.result;
  }

}
