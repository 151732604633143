import { Component, OnInit , HostListener ,  ElementRef, ViewChild , AfterViewInit } from '@angular/core';

import {Location} from '@angular/common'
import { Router , ActivatedRoute } from '@angular/router';
import { MatDrawer } from '@angular/material';
import {FormControl, FormGroup, Validators, FormBuilder} from '@angular/forms';

import { EventsService } from '../../services/events/events.service'
import { GlobalService } from '../../services/global/global.service'
import { ValidatorrService } from '../../services/validatorr/validatorr.service'
import { BackendService } from '../../services/backend/backend.service';
import { UtilsService } from '../../services/utils/utils.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-parametrage',
  templateUrl: './parametrage.component.html',
  styleUrls: ['./parametrage.component.scss']
})
export class ParametrageComponent implements OnInit {

  userConnected
  showFormulaire = true;

  formulaireGroup: FormGroup;
  submitted = false;
  opened = true
  txtBadge = "Afficher le menu"
  @ViewChild("drawer", {static: false}) drawer: MatDrawer ;

  elementPage;
  retourMessage: any;

  isCGU = false;
  envoieEncours = false;

  constructor(
    private location: Location ,
    private router: Router,
    private eventsService: EventsService,
    private backendService: BackendService ,
    private globalService: GlobalService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private utilsService: UtilsService
  ) { 
    this.formulaireGroup = this.formBuilder.group({
      dateEffetCtrl: [ '', Validators.required]
    });
  }

  ngOnInit(): void {

    this.route.params.subscribe(routeParams => {
      console.log('Param router Parametter:: ' , routeParams)
      console.log('Param router Parametter routeParams.id  :: ' , routeParams.id  )

      console.log('Param router Parametter:: this.router.url' , this.router.url)

      if (['/nos-conditions-generales-d-utilisation', '/nos-politique-de-confidentialite', '/nos-mentions-legales'].includes( this.router.url )) {
        this.elementPage = this.utilsService.getMenuByPage( this.router.url )[0];
        if ( this.router.url === '/nos-conditions-generales-d-utilisation' ) {
           this.isCGU = true;
        }
      } else {
        this.elementPage = this.utilsService.getSousMenuTrajetsByID( routeParams.id )[0];
      }
      
    });

    this.isConnectedUser()
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.mediaQuery()
    }, 1000);
  }

  isConnectedUser() {
    this.userConnected = this.globalService.getConnectedUser();
  }

  /** *********************  Sidenav **********************  */
  mediaQuery() {
    this.drawer.opened  = window.innerWidth > 900 ? true : false
    this.txtBadge = window.innerWidth > 900 ? "Cacher le menu" : "Afficher le menu"
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.mediaQuery()
  }

  eventToggle( drawer_ ){
    this.txtBadge = drawer_._opened ?  "Cacher le menu" : "Afficher le menu"
  }



  get f() {
     return this.formulaireGroup.controls;
  }
    
    onSubmit() {
      this.submitted = true;
      this.retourMessage = null;
  
      if (this.formulaireGroup.invalid) {
        this.retourMessage = {ko: 'Une erreur a été détectée sur le formulaire. Tous les champs sont obligatoires !'};
        return;
      }
  
      const {dateEffetCtrl: dateffet } = this.formulaireGroup.value;
      const campagne = {
        campaignId: 26,
        params: {
          DATE_EFFET: this.formaterDate( dateffet ),
          URL: `${environment.urlFrontApp}/#/conditions-generales-utilisations`
        },
        listIds: [
          environment.listeDiffusionGlobale
        ],
        titre: 'Mise à jour des conditions d\'utilisation',
        message: 'En savoir plus sur la nouvelle version de nos conditions générales d\'utilisation de Msahilisho Group',
        deepLink: '/tabs/menu'
      };

      this.envoieEncours = true;
      console.log( 'campagne ', campagne)
  
      this.backendService.post( `/emails/send-campaign` , campagne )
      .then( resultat => {
        if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
          this.retourMessage = {ko: resultat.status != 0 ? resultat.error.error : 'Une erreur inattendue s\'est produite. veuillez réessayer ultérieurement.'}
        } else {
          this.retourMessage = {ok: 'La campagne est bien activée et est en cours d\'exécution.'};
          this.eventsService.successmsg( 'La campagne est bien activée et est en cours d\'exécution.' );
        }
        this.envoieEncours = false;
      })
      .catch( error => {
        this.envoieEncours = false;
        this.retourMessage = {ko: 'Une erreur inattendue s\'est produite. veuillez réessayer ultérieurement.'};
      });
  
    }
  
    formaterDate( la_date: any ){
      const dateRetour = `${la_date.substring(4, 8)}-${la_date.substring(2, 4)}-${la_date.substring(0, 2)}`;
      const d = new Date(dateRetour);
      const df = new Date(d).toLocaleDateString('fr-fr', {  year:"numeric", month:"long", day:"numeric"}) 
      return df
    }

}
