import { Component, OnInit , HostListener ,  ElementRef, ViewChild , AfterViewInit } from '@angular/core';

import {Location} from '@angular/common'
import { Router , ActivatedRoute } from '@angular/router';
import { MatDrawer } from '@angular/material';
import {FormControl, FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';

import { EventsService } from '../../services/events/events.service'
import { GlobalService } from '../../services/global/global.service'
import { ValidatorrService } from '../../services/validatorr/validatorr.service'
import { BackendService } from '../../services/backend/backend.service';
import { UtilsService } from '../../services/utils/utils.service';

@Component({
  selector: 'app-comptes-user',
  templateUrl: './comptes-user.component.html',
  styleUrls: ['./comptes-user.component.scss']
})
export class ComptesUserComponent implements OnInit {

  userConnected;
  opened = true;
  txtBadge = "Afficher le menu";

  @ViewChild("drawer", {static: false}) drawer: MatDrawer ;

  formulaireGroup: FormGroup;
  submitted = false;
  lesAdminsArray = [];
  niveauList = []

  retourMessage = { ok: null, ko: null };
  editMode = false;
  editElementSelected
  showFormulaire = true;

  columnsToDisplay = ['id', 'username', 'email', 'niveau', 'agent_msahilisho', 'action'];
  elementsDisplay = ['#', 'Nom et prénom', 'Email', 'Niveau', 'Éditer par', 'Action'];


  constructor(
    private location: Location ,
    private router: Router,
    private eventsService: EventsService,
    private backendService: BackendService ,
    private globalService: GlobalService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private utilsService: UtilsService
  ) { }

  ngOnInit(): void {
    
    this.displayPage()

    this.formulaireGroup = this.formBuilder.group({
      usernameCtrl: [ '', [Validators.required, Validators.maxLength(50)]],
      emailCtrl: [ '', [Validators.required, ValidatorrService.emailValidator] ],
      niveauCtrl: [ '' , Validators.required ]
    });

    this.isConnectedUser()


  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.mediaQuery()
    }, 1000);
  }

  isConnectedUser() {
    this.userConnected = this.globalService.getConnectedUser();

    if ( this.userConnected ) {
      console.log(' this.userConnected this.userConnected ', this.userConnected)
      this.niveauList = this.utilsService.getNiveauList( this.userConnected.niveau )
    }
    
  }

  initFormulaire() {
    this.formulaireGroup.patchValue({
      usernameCtrl: this.userConnected.username,
      emailCtrl: this.userConnected.email,
      niveauCtrl: parseInt( this.userConnected.niveau )
    });

    this.editElementSelected = this.userConnected;
    this.editMode = true;
    this.showFormulaire = true;
  }

  displayPage() {

    this.backendService.get( `/users/admins` )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
      } else {
        this.lesAdminsArray = resultat;
      }
     })
     .catch( error => {
    })
  }

  get f() { 
    return this.formulaireGroup.controls; 
  }


  showOrHideFormulaure() {
    this.showFormulaire = !this.showFormulaire;
  }


  onTableAction( event ) {
    console.log('onTableAction Table action ', event)

    this.editElementSelected = event.element;

    if (event.method === 'update' ) {
      this.editMode = true;
      const elementOfUpdate = event.element;
      this.showFormulaire = true;

      this.formulaireGroup.patchValue({
        usernameCtrl: elementOfUpdate.username,
        photoCtrl: '',
        emailCtrl: elementOfUpdate.email,
        niveauCtrl: parseInt( elementOfUpdate.niveau )
      })
      
      window.scroll(0,0);

    } else if (event.method === 'delete' ) {

      if ( this.lesAdminsArray.length > 1 ) {
        this.confirmDelete();
      }
      
    } else if (event.method === 'reinit' ) {
      this.reinitPassword() 
    }

  }


  cancelEditMode() {
    this.editMode = false;
    this.globalService.setModelValueToUpload( null );
    this.resetFormulaire();
  }


  resetFormulaire() {
    this.retourMessage.ok = null;
    this.retourMessage.ko = null;
    this.submitted = false;
    this.editMode = false;
    this.formulaireGroup.patchValue({
      usernameCtrl: '',
      emailCtrl: '',
      niveauCtrl: ''
    });
  }


  onSubmit() {

    this.submitted = true;
    this.retourMessage.ok = null;
    this.retourMessage.ko = null;

    if (this.formulaireGroup.invalid) {
      this.retourMessage.ko = "Une erreur a été détectée sur le formulaire. Certains champs sont obligatoires !";
      return;
    }

    const formSend = {
      username: this.f.usernameCtrl.value,
      email: this.f.emailCtrl.value,
      niveau: this.f.niveauCtrl.value,
      agent: this.globalService.getConnectedUser().id
    }

    console.log('Form Before Send ', formSend)

    if (!this.editMode) {
      this.addCompte( formSend );
    } else {
      this.editCompte( formSend );
    }

  }


  editCompte( formSend ) {

    if ( !this.globalService.canEdit() ) {
      this.eventsService.toastrwaring("Vous ne disposez pas des autorisations requises pour cette action.")
      return;
    }

    this.backendService.post( `/users/update-admin/${this.editElementSelected.id}`, formSend )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.retourMessage.ko = resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
      } else {
        this.retourMessage.ok = resultat.text;
        setTimeout(() => {
          this.displayPage();
          if ( this.editMode &&  (parseInt(this.editElementSelected.id) === parseInt(this.userConnected.id)) ) {
            const userEdited = {
              id: this.userConnected.id,
              username: formSend.username,
              email: formSend.email,
              niveau: formSend.niveau,
            }
            this.globalService.setConnectedUser( userEdited )
            this.eventsService.onUserConnected( userEdited )
          }
          this.resetFormulaire();
        }, 2000);
      }
     })
     .catch( error => {
      this.retourMessage.ko = "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
    })
  
  }

  addCompte( formSend ) {

    if ( !this.globalService.canAdd() ) {
      this.eventsService.toastrwaring("Vous ne disposez pas des autorisations requises pour cette action.")
      return;
    }

    this.backendService.post( `/users/new-admin`, formSend )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.retourMessage.ko = resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
      } else {
        this.retourMessage.ok = resultat.text;
        this.resetFormulaire();
        setTimeout(() => {
          this.displayPage();
        }, 8000);
      }
     })
     .catch( error => {
      this.retourMessage.ko = "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
    })
  
  }


  confirmDelete(){

    this.retourMessage.ok = null;
    this.retourMessage.ko = null;
    this.backendService.get(`/users/delete-admin/${this.editElementSelected.id}`, )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.eventsService.errorsmsg( resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement." )
      } else {
        this.displayPage();
        this.retourMessage.ok = resultat.text;
        setTimeout( () => {
          this.retourMessage = null;
        }, 2000)
      }
    })
    .catch( error => {
      this.retourMessage.ko = "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
    })

  }


  reinitPassword() {

    if ( !this.globalService.canEdit() ) {
      this.eventsService.toastrwaring("Vous ne disposez pas des autorisations requises pour cette action.")
      return;
    }

    const formSend = {
      username: this.editElementSelected.username,
      email: this.editElementSelected.email,
      agent: this.globalService.getConnectedUser().id
    }

    this.backendService.post( `/users/reinit-pass-admin/${this.editElementSelected.id}`, formSend )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.retourMessage.ko = resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
      } else {
        this.retourMessage.ok = resultat.text;
        this.eventsService.successmsg("Le nouveau mot de passe du compte administrateur a été envoyé avec succès !");
      }
     })
     .catch( error => {
      this.retourMessage.ko = "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
    })

  }


  /** *********************  Sidenav **********************  */
  mediaQuery() {
    this.drawer.opened  = window.innerWidth > 900 ? true : false
    this.txtBadge = window.innerWidth > 900 ? "Cacher le menu" : "Afficher le menu"
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.mediaQuery()
  }

  eventToggle( drawer_ ){
    this.txtBadge = drawer_._opened ?  "Cacher le menu" : "Afficher le menu"
  }

}
