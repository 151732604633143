
<div class="full-with">

    <app-header></app-header>


    <mat-drawer-container class="example-container" autosize>
        <mat-drawer #drawer class="example-sidenav" mode="side" [opened]="opened" >
            <app-sidebar></app-sidebar>
        </mat-drawer>

        <div class="example-sidenav-content">

            <button type="button" mat-button (click)="drawer.toggle() && eventToggle(drawer)" class="btn-menu-dash" >
                <i class="material-icons">menu</i> <span>{{txtBadge}}</span>
            </button> <br />


            <div class="content-in-div">
                
                <div class="form partie-formulaire">
                    <app-content-param
                    *ngIf="elementPage"
                    [pageElement]="elementPage"
                    ></app-content-param>
                </div>



                <div class="partie-notification" *ngIf="isCGU" >
                    <div class="row">
                        <div class="col-md-12 notif-user">
                            Notifier les utilisateurs des nouvelles modifications des C.G.U
                        </div>
                    </div>
                    <form class="contactForm" [formGroup]="formulaireGroup" (ngSubmit)="onSubmit()" >
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group" >
                              <div class="input-group">
                                  <label for="dateEffetCtrl">Date d'effet  <span>(*)</span></label>
                                  <legend>
                                    Date à partir de laquelle les nouvelles conditions générales d'utilisation prennent effet.
                                  </legend>
                                  <input [showMaskTyped]="true" mask="00/00/0000" id="dateEffetCtrl" type="text" class="form-control" formControlName="dateEffetCtrl" placeholder="dd/mm/yyyy"  [ngClass]="{ 'is-invalid': submitted && f.dateEffetCtrl.errors }"  >
                              </div>
  
                          </div>
                        </div>
                      </div>
  
                      <div class="row" *ngIf="retourMessage" >
                        <div class="col-md-12 div-error" *ngIf="retourMessage.ko" >
                            {{retourMessage.ko}}
                        </div>
                        <div class="col-md-12 div-success" *ngIf="retourMessage.ok" >
                            {{retourMessage.ok}}
                        </div>
                      </div>
  
                      <div class="row">
                        <div class="col">
                            <div class="text-center">
                                <button  class="btn-valider" type="submit" title="Envoyer la campagne de notification"> Envoyer la campagne de notification Mise à jour des C.G.U </button>
                            </div>
                        </div>
                      </div>
  
                    </form>
                </div>
  

                
            </div>

            <app-footer></app-footer>

        </div>
    </mat-drawer-container>


</div>
