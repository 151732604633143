
<div class="full-with">

    <app-header></app-header>


    <mat-drawer-container class="example-container" autosize>
        <mat-drawer #drawer class="example-sidenav" mode="side" [opened]="opened" >
            <app-sidebar></app-sidebar>
        </mat-drawer>

        <div class="example-sidenav-content">

            <button type="button" mat-button (click)="drawer.toggle() && eventToggle(drawer)" class="btn-menu-dash" >
                <i class="material-icons">menu</i> <span>{{txtBadge}}</span>
            </button> <br />


            <div class="content-in-div">
                

                <div class="serach">
                    <form [formGroup]="searchFormGroup" (ngSubmit)="envoyerCampagne()" >
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group" >
                                    <label for="trajetCtrl">Trajet <span>(*)</span></label>
                                    <mat-select id="trajetCtrl" formControlName="trajetCtrl" class="form-control" placeholder="Sélectionner un trajet" [ngClass]="{ 'is-invalid': submitted && f.trajetCtrl.errors }"  >
                                        <mat-option *ngFor="let trajet of listeDesTrajet" [value]="trajet" class="optionList">
                                            Trajet : {{trajet.aerodep}} -> {{trajet.aeroarr}} du {{trajet.datedepot | date: 'EE dd MMMM yyyy'}} ({{trajet.kgdispo}}kg dispo.) [{{trajet.encours ? 'EN COURS' : 'TERMINÉ'}}] 
                                        </mat-option>
                                    </mat-select>
                                </div>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group" >
                                    <label for="typeEnvoiCtrl">Type de mail trajet  <span>(*)</span></label>
                                    <mat-button-toggle-group #group="matButtonToggleGroup" formControlName="typeEnvoiCtrl" [ngClass]="{ 'is-invalid': submitted && f.typeEnvoiCtrl.errors }" >
                                        <mat-button-toggle value="nouveau" aria-label="Nouveau trajet">
                                            Nouveau trajet
                                          </mat-button-toggle>
                                        <mat-button-toggle value="kgdispo" aria-label="Rappel kg disponibles">
                                          Rappel kg disponibles
                                        </mat-button-toggle>
                                    </mat-button-toggle-group>
                                </div>
                            </div>
                        </div>


                        <div class="row" *ngIf="retourMessage" >
                            <div class="col-md-12 div-error" *ngIf="retourMessage.ko" >
                                {{retourMessage.ko}}
                            </div>
                            <div class="col-md-12 div-success" *ngIf="retourMessage.ok" >
                                {{retourMessage.ok}}
                            </div>
                        </div>

                        <div *ngIf="!envoieEncours">
                            <br />
                            <button class="btn btn-valider" type="submit" >Envoyer la campagne de mail </button>
                        </div>

                    </form>
                </div>

            </div>

            

        </div>
    </mat-drawer-container>
    <app-footer></app-footer>

</div>
