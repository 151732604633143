
<div class="full-with">

    <app-header></app-header>


    <mat-drawer-container class="example-container" autosize>
        <mat-drawer #drawer class="example-sidenav" mode="side" [opened]="opened" >
            <app-sidebar></app-sidebar>
        </mat-drawer>

        <div class="example-sidenav-content">

            <button type="button" mat-button (click)="drawer.toggle() && eventToggle(drawer)" class="btn-menu-dash" >
                <i class="material-icons">menu</i> <span>{{txtBadge}}</span>
            </button> <br />

            <div class="content-in-div">
                <div class="titre-page">
                    Liste des utilisateurs Msahilisho Import & Export
                </div>


                <app-arraytable *ngIf="lesUtilisateursArray.length > 0"
                    [arrayList]="lesUtilisateursArray"
                    [columnsToDisplay]="columnsToDisplay"
                    [elementsDisplay]="elementsDisplay"
                    [canList]="'true'"
                    (tableEmitter)="onTableAction($event)"
                    [typeTable]="'utilisateurs'"
                    [canPrint]="'true'"
                    [titrePrint]=""
                ></app-arraytable>

                <div class="no-elemnts" *ngIf="lesUtilisateursArray.length <= 0">
                    Aucun utilisateur trouvé
                </div>

                <br /><br />

            </div>

        </div>
    </mat-drawer-container>
    <app-footer></app-footer>

</div>
