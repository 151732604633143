import { Component, OnInit , Input  } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BackendService } from '../../services/backend/backend.service'
import { GlobalService } from '../../services/global/global.service'
import { EventsService } from '../../services/events/events.service'

@Component({
  selector: 'app-edit-etat',
  templateUrl: './edit-etat.component.html',
  styleUrls: ['./edit-etat.component.scss']
})
export class EditEtatComponent implements OnInit {

  @Input() element
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  title : string = "Modifier l'état de l'expédition"

  etatExpedition = []
  messageRetour
  etatSelected

  constructor(
    private activeModal: NgbActiveModal ,
    private backendService: BackendService ,
    private globalService: GlobalService,
    private eventsService: EventsService,
  ) { }

  ngOnInit() {

  }


  ngAfterContentInit() {

    this.title = `Modifier l'état de l'expédition n° ${this.element.numtrans}`
    this.etatSelected = this.element.etattrans

    if( this.element.etattrans === "En cours" ){
      this.etatExpedition.push({name: "En cours"}, {name: "Terminée"}, {name: "Annulée"})
    } else if( this.element.etattrans === "Terminée" ){
      this.etatExpedition.push({name: "Terminée"})
    } else if( this.element.etattrans === "En attente de validation" ){
      this.etatExpedition.push({name: "En attente de validation"}, {name: "Validée par le transporteur"}, {name: "Réfusée par le transporteur"}, {name: "En cours"}, {name: "Annulée"})
    } else if( this.element.etattrans === "Validée par le transporteur" ){
      this.etatExpedition.push( {name: "Validée par le transporteur"}, {name: "En cours"}, {name: "Terminée"}, {name: "Annulée"} )
    } else if( this.element.etattrans === "Réfusée par le transporteur" ){
      this.etatExpedition.push({name: "Réfusée par le transporteur"})
    } else if( this.element.etattrans === "Annulée" ){
      this.etatExpedition.push({name: "Annulée"})
    }
    
  }


  public decline() {
    this.activeModal.close({status: false});
  }

  public valider() {

    let dateAct = new Date()
    let note = {
      note : `Mise à jour de l'état de l'expédition : "${this.etatSelected}"`,
      create_at: `${dateAct.getDate()}/${dateAct.getMonth() + 1}/${dateAct.getFullYear()}`
    }
    let lesNotes = !!this.element.notestrans ? JSON.parse(this.element.notestrans) : [];
    lesNotes.push( note )

    let box = {
      iduserexp: !!this.element.user_sender.id ? this.element.user_sender.id : 0,
      numtrans: this.element.numtrans,
      etattrans: this.etatSelected,
      notestrans: JSON.stringify( lesNotes ),
      localisation: this.element.localisation === "Chez l'expéditeur" ? "Remis à Msahilisho" : this.element.localisation,
      user_direct: !!this.element.user_sender.user_direct,
      email_user: !!this.element.user_sender.email ? this.element.user_sender.email : null,
      agent: this.globalService.getConnectedUser().id
    }

    
    if( this.etatSelected == "Terminée" ) {
      box.localisation = `Livré`
    }
    if( this.etatSelected == "Réfusée par le transporteur" ) {
      box.localisation = `Chez l'expéditeur`
    }
    if( this.etatSelected == "Annulée" ) {
      box.localisation = `Chez l'expéditeur`
    }

    console.log( box )
    this.backendService.post(`/senbox/etat/${this.element.id}`, box )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.messageRetour = {ko: resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement." }
        this.eventsService.errorsmsg( this.messageRetour.ko )
      } else {
        this.messageRetour = {ok: resultat.text}
        this.eventsService.successmsg( this.messageRetour.ok )
        setTimeout( () => {
          this.activeModal.close({status: true});
        }, 1000)
      }
    })
    .catch( error => {
      this.messageRetour = {ko: "Une erreur lors de l'enregistrement des données"}
      this.eventsService.errorsmsg( this.messageRetour.ko )
    })

  }


  public dismiss() {
    this.decline();
  }


}
