
<div class="full-with">

    <app-header></app-header>


    <mat-drawer-container class="example-container" autosize>
        <mat-drawer #drawer class="example-sidenav" mode="side" [opened]="opened" >
            <app-sidebar></app-sidebar>
        </mat-drawer>

        <div class="example-sidenav-content">

            <button type="button" mat-button (click)="drawer.toggle() && eventToggle(drawer)" class="btn-menu-dash" >
                <i class="material-icons">menu</i> <span>{{txtBadge}}</span>
            </button> <br />


            <div class="content-in-div">

                <div class="titre-page">
                    Nouvelle expédition directe
                </div>

                <mat-horizontal-stepper [linear]="isLinear" #stepper class="stepPage">

                    <mat-step [stepControl]="userFormGroup" errorMessage="(*) obligatoires." >
                        <form [formGroup]="userFormGroup">
                            <ng-template matStepLabel>Sélectionner un expéditeur </ng-template>
                            <label>Sélectionner un expéditeur dans le tableau ou cliquer suivant pour en créer</label>

                            <app-arraytable *ngIf="lesUsersArray.length > 0"
                                [arrayList]="lesUsersArray"
                                [columnsToDisplay]="columnsToDisplay"
                                [elementsDisplay]="elementsDisplay"
                                [canList]="'true'"
                                (tableEmitter)="onTableAction($event)"
                            ></app-arraytable>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group" >
                                        <label for="nameFirstCtrl">Nom et prénom  <span>(*)</span></label>
                                        <legend>Si l'utilisateur n'est pas connu, saisissez un nom et un prénom </legend>
                                        <input id="nameFirstCtrl" type="text" class="form-control" formControlName="nameFirstCtrl" 
                                        (keyup)="onChangeInput($event)"
                                        placeholder="Veuillez renseigner un nom" [ngClass]="{ 'is-invalid': submitted && f1.nameFirstCtrl.errors }">
                                    </div>
                                </div>
                            </div>

                            <div>
                                <button (click)="changeBTN('user-primo')" class="nextLevel" mat-button matStepperNext>Étape suivante</button>
                                <br/><br /><span class="champObligatoire">Attention ! Les champs avec (*) sont obligatoires </span>
                            </div>
                        </form>
                    </mat-step>

                    <mat-step [stepControl]="userInfoFormGroup" errorMessage="(*) obligatoires."  >
                        <form [formGroup]="userInfoFormGroup">
                            <ng-template matStepLabel>Infos expéditeur </ng-template>

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group" >
                                        <label for="nomPrenomExpediteurCtrl">Expéditeur  <span>(*)</span></label>
                                        <legend>Le nom et prénom tel que écrit sur la pièce d'identité</legend>
                                        <input id="nomPrenomExpediteurCtrl" type="text" class="form-control" formControlName="nomPrenomExpediteurCtrl" placeholder="Le nom et prénom" required [ngClass]="{ 'is-invalid': submitted && f.nomPrenomExpediteurCtrl.errors }" >
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group" >
                                        <label for="telExpediteurCtrl">Téléphone expéditeur  <span>(*)</span></label>
                                        <legend>Votre numéro de téléphone tel que défini dans votre compte</legend>
                                        <div class="input-group mb-3">

                                            <div class="input-group-prepend">
                                                <button class="btn btn-outline-secondary dropdown-toggle" type="button"  
                                                    aria-haspopup="true" aria-expanded="false" [matMenuTriggerFor]="menuExp" > 
                                                    <img src="assets/imgs/{{maskTelExp.value}}" width="20" >
                                                </button>
                                                <mat-menu #menuExp="matMenu" class="img-select-menu" >
                                                  <button *ngFor="let ntl of listTelephone" mat-menu-item (click)="telFormat( ntl , 'exp' )" >
                                                      <img src="assets/imgs/{{ntl.image}}" width="30" > &nbsp; {{ntl.text}}
                                                  </button>
                                                </mat-menu>
                                            </div>

                                            <input [validation]="true" [showMaskTyped]="true" mask="{{maskTelExp.mask}}" id="telExpediteurCtrl" type="text" 
                                            class="form-control" formControlName="telExpediteurCtrl" placeholder="Téléphone expéditeur" 
                                            required [ngClass]="{ 'is-invalid': submitted && f.telExpediteurCtrl.errors }" >
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group" >
                                        <label for="adresseExpediteurCtrl">Adresse de résidence de l'expéditeur  <span>(*)</span></label>
                                        <legend>Adresse précise de l'expéditeur. </legend>
                                        <input id="adresseExpediteurCtrl" type="text" class="form-control" formControlName="adresseExpediteurCtrl" placeholder="Adresse de l'expéditeur" required [ngClass]="{ 'is-invalid': submitted && f.adresseExpediteurCtrl.errors }" >
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group" >
                                        <label for="emailExpediteurCtrl">E-mail de l'expéditeur  <span>(*)</span></label>
                                        <legend>E-mail de l'expéditeur. </legend>
                                        <input id="emailExpediteurCtrl" type="text" class="form-control" formControlName="emailExpediteurCtrl" placeholder="E-mail de l'expéditeur" required [ngClass]="{ 'is-invalid': submitted && f.emailExpediteurCtrl.errors }" >
                                    </div>
                                </div>
                            </div>


                            <div>
                                <button class="prevLevel" mat-button matStepperPrevious>Étape précédente</button>
                                <button (click)="changeBTN('utilisateur')" class="nextLevel" mat-button matStepperNext>Étape suivante</button>
                            </div>
                        </form>
                    </mat-step>

                    <mat-step [stepControl]="destInfoFormGroup" errorMessage="(*) obligatoires."  >
                        <form [formGroup]="destInfoFormGroup">
                            <ng-template matStepLabel>Infos destinataire </ng-template>

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group" >
                                        <label for="trajetCtrl">Trajet <span>(*)</span></label>
                                        <legend>Choisissez un trajet pour cette expédition</legend>
                                        <mat-select id="trajetCtrl" formControlName="trajetCtrl" class="form-control" placeholder="Sélectionner un trajet" [ngClass]="{ 'is-invalid': submitted && f2.trajetCtrl.errors }"  >
                                            <mat-option *ngFor="let trajet of listeDesTrajet" [value]="trajet" class="optionList">
                                                Trajet : {{trajet.aerodep}} -> {{trajet.aeroarr}} du {{trajet.datedepot | date: 'EE dd MMMM yyyy'}} ({{trajet.kgdispo}}kg dispo.) 
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group" >
                                        <label for="nomPrenomDestinataireCtrl">Nom et prénom du destinataire  <span>(*)</span></label>
                                        <legend>Attention! Nos agents vérifieront les noms et prénoms avant de remettre le colis</legend>
                                        <input id="nomPrenomDestinataireCtrl" type="text" class="form-control" formControlName="nomPrenomDestinataireCtrl" placeholder="Nom et prénom du destinataire" required [ngClass]="{ 'is-invalid': submitted && f2.nomPrenomDestinataireCtrl.errors }"  >
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group" >
                                        <label for="adresseLivraisonCtrl">Adresse de résidence du destinataire  <span>(*)</span></label>
                                        <legend>Adresse précise du destinataire. Ces informations seront utiles aux agents Msahilisho pour des fins de vérification. </legend>
                                        <input id="adresseLivraisonCtrl" type="text" class="form-control" formControlName="adresseLivraisonCtrl" placeholder="Adresse du destinataire" required [ngClass]="{ 'is-invalid': submitted && f2.adresseLivraisonCtrl.errors }" >
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group" >
                                        <label for="telephoneDestinataireCtrl">Téléphone destinataire <span>(*)</span></label>
                                        <legend>Numéro de téléphone sur lequel le destinataire est joignable. Au format {{masktelArr.mask}}</legend>
                                        <div class="input-group mb-3">
                                            <div class="input-group-prepend">
                                                <button class="btn btn-outline-secondary dropdown-toggle" type="button"  
                                                    aria-haspopup="true" aria-expanded="false" [matMenuTriggerFor]="menuDest" > 
                                                    <img src="assets/imgs/{{masktelArr.value}}" width="20" >
                                                </button>
                                                <mat-menu #menuDest="matMenu" class="img-select-menu" >
                                                  <button *ngFor="let ntl of listTelephone" mat-menu-item (click)="telFormat( ntl , 'dest' )" >
                                                      <img src="assets/imgs/{{ntl.image}}" width="30" > &nbsp; {{ntl.text}}
                                                  </button>
                                                </mat-menu>
                                            </div>
                                            <input [validation]="true" [showMaskTyped]="true" mask="{{masktelArr.mask}}" id="telephoneDestinataireCtrl" 
                                            type="text" class="form-control" formControlName="telephoneDestinataireCtrl" 
                                            placeholder="Veuillez renseigner le numéro de téléphone du destinataire" 
                                            required [ngClass]="{ 'is-invalid': submitted && f2.telephoneDestinataireCtrl.errors }" >
                                        </div>
        
                                    </div>
                                </div>
                            </div>

                            <div>
                                <button class="prevLevel" mat-button matStepperPrevious>Étape précédente</button>
                                <button (click)="changeBTN('destinataire')" class="nextLevel" mat-button matStepperNext>Étape suivante</button>
                            </div>
                        </form>
                    </mat-step>

                    <mat-step [stepControl]="colisInfoFormGroup" errorMessage="(*) obligatoires."  >
                        <form [formGroup]="colisInfoFormGroup">
                            <ng-template matStepLabel>Infos Colis </ng-template>

                            <div class="row">
                                <div class="col-md-12"> 
                                    <div class="form-group" >
                                        <label for="envoiConcerne">Votre envoi concerne <span>(*)</span></label>
                                    </div>
                                </div>
                                <div class="col-md-12 div-info-produit-non-pesable">
                                    <i class="material-icons">warning</i> Le coût d'expédition en tenant compte de facteur tel que la valeur du colis est estimé à 15% de la valeur déclarée. Prix = (15% v.).
                                </div>
                            </div>

                            <div class="row msahilisho-div">
                                <div *ngFor="let pdt of infosProducts" class="col-6 col-md-3 col-lg-3 col-sm-12 col-div" [ngClass]= "produiSelect && produiSelect.id === pdt.id ? 'select-div': ''"   (click)="chooseEnvoieMsahilisho(pdt)">
                                    <div class="nom-produit">{{pdt.nomproduct}}</div>
                                    <div class="icon-produit"> <img src="assets/imgs/icon-produit/{{pdt.image}}" ></div>
                                    <div class="prix-produit" *ngIf="pdt.prixproduct != ''" > 
                                        {{pdt.nomproduct === 'Argent' ? pdt.prixproduct + '€' : '20% v.'}}
                                        <!-- {{pdt.prixproduct}} € --> 
                                        <span *ngIf="pdt.partype != ''" class="parType" >/{{pdt.partype}} </span>
                                    </div>
                                </div>
                            </div>

                            <div class="row" *ngIf="produiSelect && produiSelect.id === 'autre'" >
                                <div class="col-md-6">
                                    <div class="form-group" >
                                        <label for="poidsCtrl">Poids  <span>(*)</span></label>
                                        <legend>Poids en KG de votre objet. </legend>
                                        <input id="poidsCtrl" step="0.01" type="number" class="form-control" formControlName="poidsCtrl" placeholder="Poid de votre objet" required [ngClass]="{ 'is-invalid': submitted && f3.poidsCtrl.errors }" >
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group" >
                                        <label for="telephoneDestinatairetrl">Type de colis<span>(*)</span></label>
                                        <legend>Le type de colis que vous voulez expédier: Enveloppe ou Colis ? </legend>
                                        <mat-select  formControlName="typeObjetCtrl" required class="form-control" placeholder="Type d'objet " (selectionChange)="onTypeSelection($event)">
                                            <mat-option *ngFor="let tpe of typeObjet" [value]="tpe.value">
                                            {{tpe.name}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group" >
                                        <label for="valeurCtrl">Valeur du colis  <span>(*)</span></label>
                                        <legend>Estimer à peu près le prix de votre colis. Cette information est utile aux agents Msahilisho pour des fins de vérification et en vue de faire jouer la garantie Msahilisho Group. Dans certains cas, des factures ou justificatifs peuvent vous être demandés.</legend>
                                        <input id="valeurCtrl" type="number" class="form-control" formControlName="valeurCtrl" placeholder="Valeur du colis" required [ngClass]="{ 'is-invalid': submitted && f3.valeurCtrl.errors }" >
                                        <div class="error-maximum" *ngIf="f3.valeurCtrl.errors && f3.valeurCtrl.errors.max" > Le montant maximum autorisé est de 3000€ par expédition. </div>
                                    </div>
                                </div>
        
                                <div class="col-md-6">
                                    <div class="form-group" >
                                        <label for="garantieColisCtrl">Garantie  <span>(*)</span></label>
                                        <legend>Choisissez la garantie optionelle et Msahilisho Group s'engage à vous rembourser la totalité de la valeur de votre colis en cas de perte. Dans le cas contraire, vous serez rembourser à hauteur de 50%.</legend>
                                        <mat-button-toggle-group #group="matButtonToggleGroup" formControlName="garantieColisCtrl">
                                            <mat-button-toggle value="non" aria-label="Je ne veux pas la garantie">
                                                Je ne veux pas la garantie
                                              </mat-button-toggle>
                                            <mat-button-toggle value="oui" aria-label="Je veux la garantie">
                                              Je veux la garantie
                                            </mat-button-toggle>
                                        </mat-button-toggle-group>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group" >
                                        <label for="infoObjetCtrl">Informations sur le colis  <span>(*)</span></label>
                                        <legend>{{typeSelected == 'colis' ? infoPlaceholder.txtColi.infos : infoPlaceholder.txtEnv.infos }}</legend>
                                        <textarea class="form-control" id="infoObjetCtrl" rows="3" formControlName="infoObjetCtrl" placeholder="Information sur le colis" [ngClass]="{ 'is-invalid': submitted && f3.infoObjetCtrl.errors }"></textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="condition">
                                <div>
                                    <mat-checkbox formControlName="confirmationCtrl" [ngClass]="{ 'is-invalid': submitted && f3.confirmationCtrl.errors }"  > </mat-checkbox>
                                    <span>En cliquant sur le bouton Valider, vous indiquez avoir pris connaissance et accepté</span>
                                    <a class="cursor" (click)="openCGU()">les Conditions Générales d'Utilisation.</a>
                                </div>
                            </div>


                            <div *ngIf="!envoiEncours">
                                <br />
                                <button class="btn selectionner" mat-button (click)="onSubmit()" >Valider votre demande </button>
                            </div>
        
                            <div class="row" *ngIf="messageRetour" >
                                <div class="col-md-12 div-error" *ngIf="messageRetour.ko" >
                                    {{messageRetour.ko}}
                                </div>
                                <div class="col-md-12 div-success" *ngIf="messageRetour.ok" >
                                    {{messageRetour.ok}}
                                </div>
                                <br />
                            </div>

                        </form>
                    </mat-step>

                </mat-horizontal-stepper>

            </div>

        </div>
    </mat-drawer-container>
    <app-footer></app-footer>

</div>
