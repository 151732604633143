
<div class="full-with">

    <app-header></app-header>


    <mat-drawer-container class="example-container" autosize>
        <mat-drawer #drawer class="example-sidenav" mode="side" [opened]="opened" >
            <app-sidebar></app-sidebar>
        </mat-drawer>

        <div class="example-sidenav-content">

            <button type="button" mat-button (click)="drawer.toggle() && eventToggle(drawer)" class="btn-menu-dash" >
                <i class="material-icons">menu</i> <span>{{txtBadge}}</span>
            </button> <br />


            <div class="content-in-div">
                

                <div class="form partie-formulaire">

                    <div class="row">
                        <div class="col-md-12 text-h4 colorBleu">
                            Paramétrage

                            <span class="cursor" (click)="showOrHideFormulaure()">
                                <i class="material-icons text-h3 displayRight">
                                    {{ showFormulaire ? 'expand_less' : 'expand_more' }}
                                </i>
                            </span>
                        </div>
                    </div>

                    <form *ngIf="showFormulaire" class="contactForm" [formGroup]="formulaireGroup" (ngSubmit)="onSubmit()" >

                        <div class="row" *ngIf="leReglageArray && leReglageArray[0]" >
                            <div class="col-md-12 text-center">
                                <img [src]="leReglageArray[0].image64  | safeHtml  " width="250" />
                            </div>
                        </div>

                        <div class="form-group" >
                            <label for="nomCtrl">Nom de la société  <span>(*)</span></label>
                            <legend>Le nom de votre société</legend>
                            <input id="nomCtrl" type="text" class="form-control" formControlName="nomCtrl" placeholder="Ce champ est obligatoire" [ngClass]="{ 'is-invalid': submitted && f.nomCtrl.errors }">
                            <div class="validation"></div>
                        </div>

                        <div class="form-group" >
                            <label for="emailCtrl">Email de la société  <span>(*)</span></label>
                            <legend>L'adresse email de votre société</legend>
                            <input id="emailCtrl" type="text" class="form-control" formControlName="emailCtrl" placeholder="Ce champ est obligatoire" [ngClass]="{ 'is-invalid': submitted && f.emailCtrl.errors }">
                            <div class="validation"></div>
                        </div>

                        <div class="form-group" >
                            <label for="adresseFrCtrl">Adresse en France  <span>(*)</span></label>
                            <legend> Adresse de la société en france</legend>
                        
                            <textarea class="form-control" id="adresseFrCtrl" rows="3" formControlName="adresseFrCtrl" 
                            placeholder="Adresse de la société en france"  
                            [ngClass]="{ 'is-invalid': submitted && f.adresseFrCtrl.errors }"></textarea>
                        </div>

                        <div class="form-group" >
                            <label for="adresseKmCtrl">Adresse aux Comores  <span>(*)</span></label>
                            <legend> Adresse de la société aux Comores</legend>
                        
                            <textarea class="form-control" id="adresseKmCtrl" rows="3" formControlName="adresseKmCtrl" 
                            placeholder="Adresse de la société aux Comores"  
                            [ngClass]="{ 'is-invalid': submitted && f.adresseKmCtrl.errors }"></textarea>
                        </div>

                        <div class="form-group" >
                            <label for="descriptionsCtrl">Brève présentation  <span>(*)</span></label>
                            <legend> Brève présentation de la société  </legend>
                        
                            <angular-editor formControlName="descriptionsCtrl" id="descriptionsCtrl" [config]="editorConfig" 
                            placeholder="Brève présentation de la société"
                            [ngClass]="{ 'is-invalid': submitted && f.descriptionsCtrl.errors }" ></angular-editor>

                        </div>

                        <div>
                            <div class="form-group" >
                                <label>N° de téléphone en France  <span>(*)</span></label>
                                <legend> Vos numéros de téléphone en France </legend>
                                <input id="telephoneFrCtrl" type="text" class="form-control" formControlName="telephoneFrCtrl" placeholder="Ce champ est obligatoire" [ngClass]="{ 'is-invalid': submitted && f.telephoneFrCtrl.errors }">
                                <div class="validation"></div>
                            </div>

                        </div>

                        <div>
                            <div class="form-group" >
                                <label>N° de téléphone aux Comores  <span>(*)</span></label>
                                <legend> Vos numéros de téléphone aux Comores </legend>
                                <input id="telephoneKmCtrl" type="text" class="form-control" formControlName="telephoneKmCtrl" placeholder="Ce champ est obligatoire" [ngClass]="{ 'is-invalid': submitted && f.telephoneKmCtrl.errors }">
                                <div class="validation"></div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12 points-fidelite">
                                Pourcentage remise des points de fidélités
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group" >
                                    <label>Points ouverture compte  <span>(*)</span></label>
                                    <legend> Ouverture de compte client Msahilisho Group </legend>
                                    <input id="ouverturecompteCtrl" type="number" class="form-control" formControlName="ouverturecompteCtrl" placeholder="Ce champ est obligatoire" [ngClass]="{ 'is-invalid': submitted && f.ouverturecompteCtrl.errors }">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group" >
                                    <label>Points 1 colis envoyé  <span>(*)</span></label>
                                    <legend> A chaque envoi de colis </legend>
                                    <input id="colisenvoyeCtrl" type="number" class="form-control" formControlName="colisenvoyeCtrl" placeholder="Ce champ est obligatoire" [ngClass]="{ 'is-invalid': submitted && f.colisenvoyeCtrl.errors }">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group" >
                                    <label>Points recommandation  <span>(*)</span></label>
                                    <legend> Recommandation des services Msahilisho Group </legend>
                                    <input id="recommandationCtrl" type="number" class="form-control" formControlName="recommandationCtrl" placeholder="Ce champ est obligatoire" [ngClass]="{ 'is-invalid': submitted && f.recommandationCtrl.errors }">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group" >
                                    <label>Point ajout avis envoi  <span>(*)</span></label>
                                    <legend> Ajouter un avis à chaque expédition </legend>
                                    <input id="ajouavisCtrl" type="number" class="form-control" formControlName="ajouavisCtrl" placeholder="Ce champ est obligatoire" [ngClass]="{ 'is-invalid': submitted && f.ajouavisCtrl.errors }">
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="form-group" >
                                <label>Remise sur 100 points de fidélité  <span>(*)</span></label>
                                <legend> 100 points de fidélité acquis </legend>
                                <input id="remisecentpointsCtrl" type="number" class="form-control" formControlName="remisecentpointsCtrl" placeholder="Ce champ est obligatoire" [ngClass]="{ 'is-invalid': submitted && f.remisecentpointsCtrl.errors }">
                            </div>
                        </div>

                        <div class="row" *ngIf="retourMessage" >

                            <div class="col-md-12 div-error" *ngIf="retourMessage.ko" >
                                {{retourMessage.ko}}
                            </div>
    
                            <div class="col-md-12 div-success" *ngIf="retourMessage.ok" >
                                {{retourMessage.ok}}
                            </div>
    
                        </div>
                
                
                        <div class="row">
                            <div class="col">
                                <div class="text-center">
                                    <button  class="btn-valider" type="submit" title="Enregistrer"> Enregistrer </button>
                                </div>
                            </div>
                        </div>


                        <br />
                        <div class="row" *ngIf="leReglageArray && leReglageArray[0]">
                            <div class="col">
                                <div class="text-left modification">
                                    <span>Dernière personne à avoir effectué des modifications : </span> {{leReglageArray[0]?.agent_msahilisho.username}}
                                </div>
                            </div>
                        </div>
                        <br /><br />

                    </form>

                </div>


            </div>

            <app-footer></app-footer>

        </div>
    </mat-drawer-container>


</div>
