import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar/sidebar.component';


import { MaterialAngModule } from '../matmodule/materialang.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';

import { SafeHtml } from '../pipes/safeHtml.pipe'
import { TruncatePipe } from '../pipes/truncatString.pipe';
import { ModalModule } from '../modal/modal.module';

import { LoaderService } from '../services/loader/loader.service';
import { UtilsService } from '../services/utils/utils.service';
import { EventsService } from '../services/events/events.service'
import { GlobalService } from '../services/global/global.service'
import { ValidatorrService } from '../services/validatorr/validatorr.service'
import { BackendService } from '../services/backend/backend.service';
import { UploadimageComponent } from './uploadimage/uploadimage.component';
import { ArraytableComponent } from './arraytable/arraytable.component';
import { ContentParamComponent } from './content-param/content-param.component'
import { NgxMaskModule } from 'ngx-mask';

import { DialogComponent } from './dialog/dialog.component';
import { GestTelComponent } from './gest-tel/gest-tel.component';
import { LoaderComponent } from './loader/loader.component';

@NgModule({
  declarations: [
    SidebarComponent,
    UploadimageComponent,
    ArraytableComponent,

    SafeHtml,
    TruncatePipe,
    ContentParamComponent,
    GestTelComponent,
    DialogComponent,
    LoaderComponent
  ],
  imports: [
    CommonModule,
    MaterialAngModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    //ModalModule,
    AngularEditorModule,
    NgxMaskModule.forRoot()
  ],
  exports: [
    SidebarComponent,
    UploadimageComponent,
    ArraytableComponent,
    ContentParamComponent,

    SafeHtml,
    GestTelComponent,
    DialogComponent,
    LoaderComponent
  ],
  providers: [
    UtilsService,
    EventsService,
    GlobalService,
    ValidatorrService,
    BackendService,
    LoaderService,

    SafeHtml
  ]
})
export class SharedModule { }
