import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  pageSelected = null;
  dropDownSelected = null;

  private niveauList = [
    {
      id: 1,
      libelle: "Consultant",
      niveau: 1
    },
    {
      id: 2,
      libelle: "Manager",
      niveau: 2
    },
    {
      id: 3,
      libelle: "Administrateur",
      niveau: 3
    },
    {
      id: 4,
      libelle: "Super Admin",
      niveau: 4
    }
  ]

  private sousMenuTrajets = [
    {
      id: 3,
      libelle: "Proposer un trajet",
      page: '/trajets/nouveau-trajet/ajout/3-1',
      selectedID: '3-1'
    },
    {
      id: 3,
      libelle: "Lister les trajets",
      page: '/trajets/lister-les-trajets/liste/3-2',
      selectedID: '3-2'
    },
    {
      id: 3,
      libelle: "Notifier les utilisateurs",
      page: '/trajets/notifier-utilisateurs-trajet/notification-emails/3-3',
      selectedID: '3-3'
    },
  ];

  private sousMenusExpeditions = [
    {
      id: 2,
      libelle: 'Expédier un colis',
      collaps: false,
      page: '/les-expeditions/msahilisho/nouvelle-expedition/2-1',
      endItem: false,
      selectedID: '2-1'
    },
    {
      id: 2,
      libelle: 'Lister les expéditions',
      collaps: false,
      page: '/les-expeditions/msahilisho/lister-expeditions/2-2',
      endItem: false,
      selectedID: '2-2'
    },
  ];

  private sousMenusVoyageur = [
    {
      id: 4,
      libelle: 'Nouvelle demande',
      collaps: false,
      page: '/les-voyageurs/msahilisho/nouvelle-demande/4-1',
      endItem: false,
      selectedID: '4-1'
    },
    {
      id: 4,
      libelle: 'Suivi voyageurs',
      collaps: false,
      page: '/les-voyageurs/msahilisho/lister-les-demandes-voyageur/4-2',
      endItem: false,
      selectedID: '4-2'
    },
  ];

  private sousMenusUtilisateurs = [
    {
      id: 5,
      libelle: 'Valider photo de profil',
      collaps: false,
      page: '/les-utilisateurs/msahilisho/valider-les-photos/5-1',
      endItem: false,
      selectedID: '5-1'
    },
    {
      id: 5,
      libelle: 'Lister les utilisateurs',
      collaps: false,
      page: '/les-utilisateurs/msahilisho/lister-les-utilisateurs/5-1',
      endItem: false,
      selectedID: '5-2'
    },
  ];

  private menuList = [
    {
      id: 1,
      libelle: 'Tableau de bord',
      collaps: false,
      collapsed: [],
      page: '/dashboard',
      divider: false,
      icone: 'dashboard'
    },
    {
      id: -1,
      libelle: 'Gérer les trajets',
      divider: true,
      collaps: false,
      icone: ''
    },
    {
      id: 3,
      libelle: 'Trajets',
      collaps: true,
      collapsed: this.sousMenuTrajets,
      page: '/trajets',
      divider: false,
      icone: 'roundabout_right'
    },
    {
      id: -1,
      libelle: 'Gérer les expéditions',
      divider: true,
      collaps: false,
      icone: ''
    },
    {
      id: 2,
      libelle: 'Expéditions',
      collaps: true,
      collapsed: this.sousMenusExpeditions,
      page: '/les-expeditions',
      divider: false,
      icone: 'flight_land'
    },
    {
      id: -1,
      libelle: 'Gérer les voyageurs',
      divider: true,
      collaps: false,
      icone: ''
    },
    {
      id: 4,
      libelle: 'Voyageurs',
      collaps: true,
      collapsed: this.sousMenusVoyageur,
      page: '/les-voyageurs',
      divider: false,
      icone: 'luggage'
    },
    {
      id: -1,
      libelle: 'Gérer les utilisateurs',
      divider: true,
      collaps: false,
      icone: ''
    },
    {
      id: 5,
      libelle: 'Utilisateurs',
      collaps: true,
      collapsed: this.sousMenusUtilisateurs,
      page: '/les-utilisateurs',
      divider: false,
      icone: 'people'
    },
    {
      id: 6,
      libelle: 'Avis utilisateurs',
      collaps: false,
      collapsed: [],
      page: '/les-avis',
      divider: false,
      icone: 'star_rate'
    },

    {
      id: -1,
      libelle: 'Général',
      divider: true,
      collaps: false,
      icone: ''
    },

    {
      id: 7,
      libelle: 'Flash info',
      collaps: false,
      collapsed: [],
      page: '/les-flashinfos',
      divider: false,
      icone: 'campaign'
    },

    {
      id: -1,
      libelle: 'Réglages',
      divider: true,
      collaps: false,
      icone: 'person'
    },

    {
      id: 8,
      libelle: 'Équipe',
      collaps: false,
      collapsed: [],
      page: '/notre-equipe',
      divider: false,
      icone: 'groups'
    },

    {
      id: 9,
      libelle: 'Gérer les comptes',
      collaps: false,
      collapsed: [],
      page: '/gerer-les-comptes',
      divider: false,
      icone: 'person'
    },

    {
      id: 10,
      libelle: 'Gérer les impayés',
      collaps: false,
      collapsed: [],
      page: '/gerer-les-imapyes',
      divider: false,
      icone: 'euro_symbol'
    },

    {
      id: 11,
      libelle: 'Gérer les frais',
      collaps: false,
      collapsed: [],
      page: '/gerer-les-notes-de-frais',
      divider: false,
      icone: 'add_shopping_cart'
    },

    {
      id: 12,
      libelle: 'Paramétrage',
      collaps: false,
      collapsed: [],
      page: '/nos-reglages',
      divider: false,
      icone: 'settings'
    },

    {
      id: 13,
      libelle: 'Envoyer un mail',
      collaps: false,
      collapsed: [],
      page: '/envoyer-un-email',
      divider: false,
      icone: 'mail'
    },

    {
      id: 14,
      libelle: 'Conditions Générales d\'Utilisation',
      collaps: false,
      collapsed: [],
      page: '/nos-conditions-generales-d-utilisation',
      divider: false,
      icone: 'admin_panel_settings',
      selectedID: 'cgu/2'
    },

    {
      id: 15,
      libelle: 'Politique de confidentialité',
      collaps: false,
      collapsed: [],
      page: '/nos-politique-de-confidentialite',
      divider: false,
      icone: 'verified_user',
      selectedID: 'cgu/1'
    }
/*
    
    {
      id: 12,
      libelle: 'Mentions légales',
      collaps: false,
      collapsed: [],
      page: '/nos-mentions-legales',
      divider: false,
      icone: 'admin_panel_settings',
      selectedID: 'cgu/2'
    },*/

  ];


  constructor() { }

  getMenuByID( id ) {
    return this.menuList.filter(elmt => elmt.id === id );
  }

  getMenuByPage( page ) {
    return this.menuList.filter(elmt => elmt.page === page );
  }

  getSousMenuTrajetsByID( id ) {
    return this.sousMenuTrajets.filter(elmt => elmt.selectedID === id );
  }

  getMenuList() {
    return this.menuList;
  }

  formatStringForURL( stringtxt ) {
    stringtxt = stringtxt.replace(/\s/g, '-');
    stringtxt = stringtxt.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    stringtxt = stringtxt.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '-');
    stringtxt = stringtxt.replace('---', '-')
    stringtxt = stringtxt.replace('--', '-')
    stringtxt = stringtxt.replace('----', '-')
    return stringtxt.toLowerCase();
  }

  getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  getNiveauList( niveau ) {
    niveau = parseInt(niveau);
    if ( niveau === 4 ) {
      return this.niveauList;
    } else if ( niveau === 3 ) {
      return this.niveauList.filter(nvl => nvl.niveau !== 4);
    } else if ( niveau === 2 ) {
      return this.niveauList.filter(nvl => ( nvl.niveau !== 3 && nvl.niveau !== 4 ) );
    } else {
      return this.niveauList.filter(nvl => nvl.niveau === 1);
    }
  }

  getNiveauByNiveau( niveau ) {
    niveau = parseInt(niveau);
    return this.niveauList.filter(nvl => nvl.niveau === niveau)[0];
  }

  getFonctionsList() {
    return [
      { id: 1, libelle: 'Président' },
      { id: 2, libelle: 'Directeur Technique' },
      { id: 3, libelle: 'DRH - Directeur des ressources humaines' },
      { id: 4, libelle: 'Directeur comptable et financier' },
      { id: 5, libelle: 'Directeur Commercial' },
      { id: 6, libelle: 'Directeur Général' },
      { id: 7, libelle: 'Agent – Préparateur de commande' },
      { id: 8, libelle: 'Agent – Transport logistique' }
    ];
  }
  
}
