
<div class="full-with">

    <app-header></app-header>


    <mat-drawer-container class="example-container" autosize>
        <mat-drawer #drawer class="example-sidenav" mode="side" [opened]="opened" >
            <app-sidebar></app-sidebar>
        </mat-drawer>

        <div class="example-sidenav-content">

            <button type="button" mat-button (click)="drawer.toggle() && eventToggle(drawer)" class="btn-menu-dash" >
                <i class="material-icons">menu</i> <span>{{txtBadge}}</span>
            </button> <br />


            <div class="content-in-div">
                <div class="titre-page">
                    Liste des trajets
                </div>

                <div class="container-app">
                    <div class="information">
                        <i class="material-icons">warning</i> <i class="material-icons">warning</i> <i class="material-icons">warning</i>
                        La liste des trajet en cours sont à date - 4 jours 
                    </div>

                    <div class="serach">
                        <form [formGroup]="searchFormGroup" >
                            <div class="form-group" >
                                <label for="statutCtrl">Statut des trajets  <span>(*)</span></label>
                                <mat-select id="statutCtrl" formControlName="statutCtrl" class="form-control" placeholder="Statut des trajets" (selectionChange)="onStatutSelection($event)" >
                                    <mat-option *ngFor="let stt of statutTrajet" [value]="stt.id" class="optionList">
                                    {{stt.statut}}
                                    </mat-option>
                                </mat-select>
                            </div>
                        </form>
                    </div>

                    <div class="col-md-12 div-error" *ngIf="retourMessage" >
                        {{retourMessage}}
                    </div>



                    <section id="services" >
                        <div class="">
                  
                          <div class="row">
                
                
                            <div *ngFor="let ntravl of listTrajets; let i = index" [ngClass]="i%2 == 0 ? 'offset-lg-1' : ''" class="col-md-6 col-lg-5 wow bounceInUp cursor" data-wow-duration="1.4s"  (click)="traitemntTrajet(ntravl)">
                                <div class="box ">
                                  <div class="icon"><i> <img class="avatar" src="{{urlImage}}{{ntravl.user_suggest.photo}}" /> </i></div>
                
                                  <div class="row" *ngIf="ntravl.encours || (!ntravl.encours && ntravl.etat === '0')" >
                                      <div class="col-12 text-center btn-edit-annonce">
                                        <i class="material-icons"> {{ntravl.etat === '0' ? 'fact_check' : 'edit'}} </i>
                                      </div>
                                  </div>
                                  
                                  <div class="row">
                                      <div class="col-md-8">
                                          <h4 class="title">
                                              <a >{{ntravl.user_suggest.username}} !</a>
                                          </h4>
                                      </div>
                                      <div class="col-md-4 duree-voyage">
                                          Durée : {{ntravl.nombre_jours}} jour(s)
                                      </div>
                                  </div>
                  
                                  <div class="detail-user">
                                      <div class="row">
                                          <div class="col-12 col-lg-6 col-md-6 col-sm-12 date-dep">
                                              <i class="fa fa-calendar" aria-hidden="true"></i> <span>{{ ntravl.datedepot | date: 'EE dd MMMM yyyy'}}</span>
                                          </div>
                                          <div class="col-6 col-lg-3 col-md-3 col-sm-6 icon-transport">
                                              <img src="assets/imgs/plane.svg" class="travel-img" />
                                          </div>
                                          <!-- [ngClass]="ntravl.etat == 0 ? 'note-user-nonvalider' : 'note-user-valider'" -->
                                          <div class="col-6 col-lg-3 col-md-3 col-sm-6 text-1Opx" >
                                              <div *ngIf="ntravl.encours" >
                                                {{ntravl.etat == '0' ? 'En attente de validation' : ntravl.etat == '1' ? 'En cours' : 'Réfusée par Msahilisho Group' }}
                                              </div>
                                              <div *ngIf="!ntravl.encours" >
                                                {{ntravl.etat == '0' ? 'En attente de validation' : ntravl.etat == '1' ? 'Terminé' : 'Réfusée par Msahilisho Group' }}
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                  
                                  <div class="colis-user">
                  
                                      <!-- Airport -->
                                      <div class="row">
                                          <div class="col-md-6">
                                              <span class="text-colis">Départ ({{ ntravl.datedepot | date: 'EE dd MMMM yyyy'}})</span><br />
                                              <img src="assets/imgs/airport.svg" class="airport-img" />
                                              <span class="airport-text">{{ntravl.aerodep}}</span>
                                          </div>
                                          <div class="col-md-6">
                                              <span class="text-colis">Arrivé ({{ ntravl.daterecup | date: 'EE dd MMMM yyyy'}})</span><br />
                                              <img src="assets/imgs/airport.svg" class="airport-img" />
                                              <span class="airport-text">{{ntravl.aeroarr}}</span>
                                          </div>
                                      </div>
                  
                                      <!-- Colis -->
                                      <div class="row prix-aukilo">
                                          <div class="col-6 col-md-6 ">
                                              <span class="text-colis">Prix /kg</span><br />
                                              <img src="assets/imgs/euro.svg" class="airport-img" />
                                              <span class="airport-text">{{ntravl.prixkg}} €</span>
                                          </div>
                                          <div class="col-6 col-md-6">
                                              <span class="text-colis">Kilos disponibles</span><br />
                                              <img src="assets/imgs/box.svg" class="airport-img" />
                                              <span class="airport-text">{{ntravl.kgdispo}} KG</span>
                                          </div>
                                      </div>
                  
                                  </div>
                  
                  
                                  <!-- <p class="description">Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident</p>-->
                  
                  
                                  <div class="info-user">
                                      <div class="row">
                                          <div class="col-6 col-md-4 " [ngClass]="ntravl.user_suggest.email != '' ? 'check-icon' : 'uncheck-icon'" >
                                              <i class="material-icons">check_circle</i><span>Email</span> 
                                          </div>
                                          <div class="col-6 col-md-4" [ngClass]="ntravl.user_suggest.telephone != '' ? 'check-icon' : 'uncheck-icon'">
                                              <i class="material-icons">check_circle</i><span>Téléphone</span> 
                                          </div>
                                          <div class="col-6 col-md-4" [ngClass]="ntravl.user_suggest.address != '' ? 'check-icon' : 'uncheck-icon'">
                                              <i class="material-icons">check_circle</i><span>Adresse</span> 
                                          </div>
                                          <!--<div class="col-6 col-md-3" [ngClass]="ntravl.user_suggest.email != '' ? 'check-icon' : 'uncheck-icon'">
                                              <i class="material-icons">check_circle</i><span>P. identité</span> 
                                          </div>-->
                                      </div>

                                      
                                  </div>
                  
                  
                                </div>

                                
                            </div>
                
                            <div *ngIf="loadFinished && listTrajets.length <= 0" class="row ">
                                <div class="col-12 alert alert-info">
                                    Aucun résultat ne correspond à votre recherche.
                                </div>
                            </div>
                
                          </div>
                
                
                            <div class="text-center" *ngIf="listTrajets.length > 0 && !notOther " >
                                <a (click)="voirPlus()" class="btn-showannonces cursor"> <img src="assets/imgs/search.svg" class="airport-img" /> Voir plus de trajets (+4)</a>
                            </div>
                  
                        </div>
                    </section>

                </div>
            </div>


        </div>
    </mat-drawer-container>
    <app-footer></app-footer>

</div>
