import { Component, OnInit , HostListener ,  ElementRef, ViewChild , AfterViewInit } from '@angular/core';

import {Location} from '@angular/common'
import { Router , ActivatedRoute } from '@angular/router';
import { MatDrawer } from '@angular/material';
import { EventsService } from 'src/app/services/events/events.service'
import { GlobalService } from 'src/app/services/global/global.service'
import { ValidatorrService } from 'src/app/services/validatorr/validatorr.service'
import { BackendService } from 'src/app/services/backend/backend.service'

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EditEtatVoyageService } from 'src/app/modal/edit-etat-voyage/edit-etat-voyage.service';
import { EditFileVoyageService } from 'src/app/modal/edit-file-voyage/edit-file-voyage.service';
import { AddDepensesService } from 'src/app/modal/add-depenses/add-depenses.service';
import { EtablirContratVoyageService } from '../etablir-contrat/etablir-contrat.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
@Component({
  selector: 'app-consulter-voyageur',
  templateUrl: './consulter-voyageur.component.html',
  styleUrls: ['./consulter-voyageur.component.scss']
})
export class ConsulterVoyageurComponent implements OnInit {

  userConnected
  urlImage
  opened = true
  txtBadge = "Afficher le menu"
  @ViewChild("drawer", {static: false}) drawer: MatDrawer;

  retourMessage;
  loadFinished = false;
  VoyageurSelected
  maskNumTel
  paramsPage
  suiviVoyage
  lesDepensesVoyage: any = [];

  editDate = false;
  editMontant = false;
  editDateNaiss = false;
  dateFormGroup: FormGroup;
  montantFormGroup: FormGroup;
  depenseFormGroup: FormGroup;
  naissanceFormGroup: FormGroup;
  submitted = false;

  columnsToDisplay = ['id', 'montant', 'comment', 'justificatif', 'agent_msahilisho', 'action'];
  elementsDisplay = ['#', 'Montant', 'Détail', 'Justificatif', 'Agent', 'Action'];

  textTelecharger = 'Télécharger';

  constructor(
    private location: Location ,
    private router: Router,
    private eventsService: EventsService,
    private backendService: BackendService ,
    private globalService: GlobalService,
    private _formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private editEtatVoyageService: EditEtatVoyageService,
    private editFileVoyageService: EditFileVoyageService,
    private validService: ValidatorrService,
    private addDepensesService: AddDepensesService,
    private etablirContratVoyageService: EtablirContratVoyageService,
    private loaderService: LoaderService
  ) { 
    this.route.params.subscribe(routeParams => {
      console.log('Param router ::----- ' , routeParams)
      this.paramsPage = routeParams.voyage
      this.displayPage(this.paramsPage)
    });
    this.dateFormGroup = this._formBuilder.group({
      dateDepartCtrl: ['', Validators.required],
      dateArriveeCtrl: ['', Validators.required]
    }, {validator: this.validService.dateLessThan('dateDepartCtrl', 'dateArriveeCtrl')} );

    this.montantFormGroup = this._formBuilder.group({
      prixtotalbilletCtrl: ['', Validators.required],
      montantareglerCtrl: ['', Validators.required],
      montantregleCtrl: [''],
      rectrajetallerCtrl: [''],
      rectrajetretourCtrl: ['']
    });
    this.naissanceFormGroup = this._formBuilder.group({
      dateNaissCtrl: ['', Validators.required],
      adresseCtrl: ['', Validators.required]
    });
    
  }

  ngOnInit(): void {
    this.isConnectedUser();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.mediaQuery()
    }, 1000);
  }

  isConnectedUser() {
    this.userConnected = this.globalService.getConnectedUser();
  }

  /** *********************  Sidenav **********************  */
  mediaQuery() {
    this.drawer.opened  = window.innerWidth > 900 ? true : false
    this.txtBadge = window.innerWidth > 900 ? "Cacher le menu" : "Afficher le menu"
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.mediaQuery()
  }

  eventToggle( drawer_ ){
    this.txtBadge = drawer_._opened ?  "Cacher le menu" : "Afficher le menu"
  }
  get f1() { 
    return this.dateFormGroup.controls; 
  }
  get f2() { 
    return this.montantFormGroup.controls; 
  }
  get f3() { 
    return this.naissanceFormGroup.controls; 
  }


  displayPage(id) {

    this.loadFinished = false;
    this.backendService.get( `/voyageur/list/${id}` )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.retourMessage = {ko: resultat.status != 0 ? resultat.error.error : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement. 1"}
      } else {
        console.log( '----+++-----', resultat )
        this.VoyageurSelected = resultat.demande[0];
        this.lesDepensesVoyage = resultat.depenses;
        this.suiviVoyage = resultat.suivi ? resultat.suivi[0] : null;
        this.maskNumTel = this.globalService.maskSaisi( this.VoyageurSelected.numTel );
        this.initForm();
      }
      this.loadFinished = true
    })
    .catch( error => {
      this.loadFinished = true
      this.retourMessage = {ko: "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement. 2"}
    })
  }

  getBack(){
    this.router.navigate(['/les-voyageurs/msahilisho/lister-les-demandes-voyageur/4-2'])
  }

  initForm() {
    if ( !!this.suiviVoyage ) {
      this.dateFormGroup.patchValue({
        dateDepartCtrl: !!this.suiviVoyage.datedepart ? this.globalService.unFormateDate( this.suiviVoyage.datedepart ) : '',
        dateArriveeCtrl: !!this.suiviVoyage.dateretour ? this.globalService.unFormateDate( this.suiviVoyage.dateretour ) : '',
      })
      this.montantFormGroup.patchValue({
        prixtotalbilletCtrl: !!this.suiviVoyage.prixtotalbillet ? this.suiviVoyage.prixtotalbillet : '',
        montantareglerCtrl: !!this.suiviVoyage.montantaregler ? this.suiviVoyage.montantaregler : '',
        montantregleCtrl: !!this.suiviVoyage.montantregle ? this.suiviVoyage.montantregle : '',
        rectrajetallerCtrl: !!this.suiviVoyage.rectrajetaller ? this.suiviVoyage.rectrajetaller : '',
        rectrajetretourCtrl: !!this.suiviVoyage.rectrajetretour ? this.suiviVoyage.rectrajetretour : '',
      })
    }
  
    this.naissanceFormGroup.patchValue({
      dateNaissCtrl: !!this.VoyageurSelected.datenaiss ? this.VoyageurSelected.datenaiss : '',
      adresseCtrl: !!this.VoyageurSelected.adresseresid ? this.VoyageurSelected.adresseresid : ''
    });
  }

  

  editEtat(){
    if ( !this.globalService.canEdit() ) {
      this.eventsService.toastrwaring("Vous ne disposez pas des autorisations requises pour cette action.")
      return;
    }
    this.editEtatVoyageService.confirm(this.VoyageurSelected, 'Valider', 'Anuler')
    .then( (confirmed : any ) => {
      if( confirmed.status ) {
        this.eventsService.successmsg( "Votre demande a été bien prise en compte" )
        setTimeout( () => {
          this.displayPage(this.paramsPage);
        }, 2000)
      }
    })
    .catch( () => {
    })
  }

  editFile( type ) {
    if ( !this.globalService.canEdit() ) {
      this.eventsService.toastrwaring("Vous ne disposez pas des autorisations requises pour cette action.")
      return;
    }
    this.editFileVoyageService.confirm(this.VoyageurSelected, type, 'Valider', 'Anuler')
    .then( (confirmed : any ) => {
      if( confirmed.status ) {
        this.eventsService.successmsg( "Votre demande a été bien prise en compte" )
        setTimeout( () => {
          this.displayPage(this.paramsPage);
        }, 2000)
      }
    })
    .catch( () => {
    })
  }

  downloadContrat() {
    this.textTelecharger = 'Téléchargement en cours'
    const typeMime = this.suiviVoyage.contrat.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)
    if (!!typeMime) {
      const mimeType = typeMime[0];
      const extensionFile = mimeType.split('/')
      this.globalService.downloadFile(
        `${this.suiviVoyage.contrat}` ,
        `contrat-voyageur-${this.VoyageurSelected.nom}-${this.VoyageurSelected.prenom}-${this.VoyageurSelected.datedepart}.${extensionFile[1]}`
      )
      this.textTelecharger = 'Télécharger';
    } else {
      
      this.globalService.downloadPdfPromise(
        `${this.suiviVoyage.contrat}` ,
        `contrat-voyageur-${this.VoyageurSelected.nom}-${this.VoyageurSelected.prenom}-${this.VoyageurSelected.datedepart}`
      ).then(result => {
        console.log('Hollaaaaaaa ')
        this.textTelecharger = 'Télécharger';
      })
    }
  }

  downloadBilletAller() {
    const mimeType = this.suiviVoyage.billetaller.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
    const extensionFile = mimeType.split('/')
    this.globalService.downloadFile(
      `${this.suiviVoyage.billetaller}` ,
      `billet-aller-${this.VoyageurSelected.nom}-${this.VoyageurSelected.prenom}-${this.VoyageurSelected.datedepart}.${extensionFile[1]}`
    )
  }
  downloadBilletRetour() {
    const mimeType = this.suiviVoyage.billetretour.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
    const extensionFile = mimeType.split('/')
    this.globalService.downloadFile(
      `${this.suiviVoyage.billetretour}` ,
      `billet-retour-${this.VoyageurSelected.nom}-${this.VoyageurSelected.prenom}-${this.VoyageurSelected.datedepart}.${extensionFile[1]}`
    )
  }

  downloadPiceIdentite() {
    const extensionFile = this.VoyageurSelected.justificatif.split('.')
    this.globalService.downloadFile(
      `${this.VoyageurSelected.image64}` ,
      `piece-identite-${this.VoyageurSelected.nom}-${this.VoyageurSelected.prenom}-${this.VoyageurSelected.datedepart}.${extensionFile[1]}`
    )
  }


  saveDate() {
    this.submitted = true
    this.retourMessage = null;
    console.log(this.dateFormGroup)
    if (this.dateFormGroup.invalid) {
      if (!this.dateFormGroup.errors)
        this.retourMessage = {ko: "Tous les champs sont obligatoires."}
      return;
    }

    let voyage = {
      datedepart: this.formaterDate(this.f1.dateDepartCtrl.value),
      dateretour: this.formaterDate(this.f1.dateArriveeCtrl.value),
      agent: this.globalService.getConnectedUser().id
    }

    console.log( 'saveDate ', voyage)
    this.backendService.post(`/voyageur/edit-suivi/${this.suiviVoyage.id}`, voyage)
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.eventsService.errorsmsg( resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement." )
        this.retourMessage = {ko: "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."}
      } else {
        this.eventsService.successmsg( resultat.text )
        setTimeout( () => {
          this.editDate = false;
          this.displayPage(this.paramsPage)
          this.submitted = false
        }, 2000)
      }
    })
    .catch( error => {
      this.retourMessage = {ko: "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."}
    })
  }

  formaterDate( la_date ){
    return `${la_date.substring(0, 2)}-${la_date.substring(2, 4)}-${la_date.substring(4, 8)}`
  }

  saveDateNaiss() {
    this.submitted = true
    this.retourMessage = null;
    console.log(this.naissanceFormGroup)
    if (this.naissanceFormGroup.invalid) {
      if (!this.naissanceFormGroup.errors)
        this.retourMessage = {ko: "Tous les champs sont obligatoires."}
      return;
    }

    let saveDateNaiss = {
      adresseresid: this.f3.adresseCtrl.value,
      datenaiss: this.formaterDate(this.f3.dateNaissCtrl.value),
      agent: this.globalService.getConnectedUser().id
    }

    console.log( 'saveDate ', saveDateNaiss)
    this.backendService.post(`/voyageur/edit-date-nais-voyage/${this.VoyageurSelected.idvoyageur}`, saveDateNaiss)
    .then( resultat => {
      console.log('Result edit date naiss : ', resultat)
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.eventsService.errorsmsg( resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement." )
        this.retourMessage = {ko: "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."}
      } else {
        this.eventsService.successmsg( resultat.text )
        setTimeout( () => {
          this.editDateNaiss = false;
          this.displayPage(this.paramsPage)
          this.submitted = false
        }, 2000)
      }
    })
    .catch( error => {
      this.retourMessage = {ko: "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."}
    })
  }


  saveMontant() {
    this.submitted = true
    this.retourMessage = null;
    console.log(this.montantFormGroup)
    if (this.montantFormGroup.invalid) {
      this.retourMessage = {ko: "Tous les champs sont obligatoires."}
      return;
    }

    // depensetrajet: this.f2.depensetrajetCtrl.value,
    let voyage = {
      prixtotalbillet: this.f2.prixtotalbilletCtrl.value,
      montantaregler: this.f2.montantareglerCtrl.value,
      montantregle: this.f2.montantregleCtrl.value,
      agent: this.globalService.getConnectedUser().id,
      rectrajetaller: this.f2.rectrajetallerCtrl.value,
      rectrajetretour: this.f2.rectrajetretourCtrl.value,
    }

    console.log( 'saveDate ', voyage)
    this.backendService.post(`/voyageur/edit-suivi/${this.suiviVoyage.id}`, voyage)
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.eventsService.errorsmsg( resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement. 6" )
        this.retourMessage = {ko: "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement. 7"}
      } else {
        this.eventsService.successmsg( resultat.text )
        setTimeout( () => {
          this.editMontant = false;
          this.displayPage(this.paramsPage)
          this.submitted = false
        }, 2000)
      }
    })
    .catch( error => {
      this.retourMessage = {ko: "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement. 8"}
    })
  }

  onTableAction( event) {
    console.log('onTableAction Table action ', event)
    const element = event.element
    if (event.method === 'delete') {
      this.confirmDelete( element )
    }
  }

  confirmDelete( element ){

    this.retourMessage = null;
    this.backendService.get(`/voyageur/delete-depenses-voyage/${element.id}`, )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.eventsService.errorsmsg( resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement. 9" )
      } else {
        this.retourMessage = {ok: resultat.text};
        setTimeout( () => {
          this.displayPage(this.paramsPage);
        }, 2000)
      }
    })
    .catch( error => {
      this.retourMessage = {ko: "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement. 10"}
    })

  }


  addDepenses() {
    if ( !this.globalService.canAdd() ) {
      this.eventsService.toastrwaring("Vous ne disposez pas des autorisations requises pour cette action.")
      return;
    }
    this.addDepensesService.confirm(this.VoyageurSelected, 'Valider', 'Anuler')
    .then( (confirmed : any ) => {
      if( confirmed.status ) {
        this.eventsService.successmsg( "Votre demande a été bien prise en compte" )
        setTimeout( () => {
          this.displayPage(this.paramsPage);
        }, 2000)
      }
    })
    .catch( () => {
    })
  }


  etablirContrat() {
    if ( !this.globalService.canEdit() ) {
      this.eventsService.toastrwaring("Vous ne disposez pas des autorisations requises pour cette action.")
      return;
    }

    if ( !this.VoyageurSelected.adresseresid || !this.VoyageurSelected.datenaiss ) {
      this.eventsService.toastrwaring("Veuillez compléter les informations du voyageur ( adresse et date de naissance ) avant d'envoyer le contrat.")
      return;
    }

    if (this.VoyageurSelected.statut === "En cours" || this.VoyageurSelected.statut === "Validée") {

      if ( !!this.suiviVoyage && !!this.suiviVoyage.montantaregler ) {

        this.etablirContratVoyageService.confirm(this.VoyageurSelected, this.suiviVoyage, 'Envoyer', 'Anuler')
        .then( (confirmed : any ) => {
          if( confirmed.status ) {
            this.eventsService.successmsg( "Votre demande a été bien prise en compte" )
            setTimeout( () => {
              this.displayPage(this.paramsPage);
            }, 2000)
          }
        })
        .catch( () => {
        })

      } else {
        this.eventsService.toastrwaring("Vous devez renseigner le suivi du voyage ( date aller-retour, mont du billet à payer ) avant de rédiger le contrat.")
        return;
      }

    } else {
      this.eventsService.toastrwaring("L'état de la demande doit où être validé ou être en cours avant d'envoyer le contrat.")
      return;
    }

  }


}
