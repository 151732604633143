import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { EventsService } from '../services/events/events.service'
import { GlobalService } from '../services/global/global.service'
import { ValidatorrService } from '../services/validatorr/validatorr.service'
import { BackendService } from '../services/backend/backend.service'
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  isCollapsed = false
  userConnected = null
  urlImage;

  constructor(
    private router: Router,
    private eventsService: EventsService,
    private backendService: BackendService ,
    private globalService: GlobalService,
  ) { 
    this.eventsService.userConnectEmitter.subscribe( user => {
      this.isConnectedUser()
    })
  }

  ngOnInit(): void {
    this.isConnectedUser()
  }

  isConnectedUser() {
    this.userConnected = this.globalService.getConnectedUser();
    console.log('this.userConnected ', this.userConnected)
    // Ligne à supprimer apres
    //this.userConnected.photo = 'assets/imgs/avatar.png'
  }

  openPage(page) {
    this.router.navigate([page]);
    window.scroll(0,0);
  }


  logOut(){
    this.globalService.logOut()
    this.eventsService.setUserLoggedIn(false);
    this.eventsService.onUserDeconnected()
    this.router.navigate(['/login']);
    window.scroll(0,0);
  }

  openWebMail( type ){

    if ( type === 'email' ) {
      window.open('https://californie.o2switch.net:2096', '_blanc')
    } else if ( type === 'sendblue' ) {
      window.open('https://my.sendinblue.com/dashboard/', '_blanc')
    } else if ( type === 'o2switch' ) {
      window.open('https://californie.o2switch.net:2083/cpsess9229747505/frontend/paper_lantern/index.html', '_blanc')
    } 
    
  }

}
