
<div class="full-with">

    <app-header></app-header>


    <mat-drawer-container class="example-container" autosize>
        <mat-drawer #drawer class="example-sidenav" mode="side" [opened]="opened" >
            <app-sidebar></app-sidebar>
        </mat-drawer>

        <div class="example-sidenav-content">

            <button type="button" mat-button (click)="drawer.toggle() && eventToggle(drawer)" class="btn-menu-dash" >
                <i class="material-icons">menu</i> <span>{{txtBadge}}</span>
            </button> <br />


            <div class="content-in-div">
                <div class="titre-page">
                    Envoyer un mail
                </div>

                <form class="contactForm" [formGroup]="formulaireGroup" class="contactForm" (ngSubmit)="onSubmit()" >

                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group" >
                                <label for="emailCtrl">E-mail  <span>(*)</span></label>
                                <legend>Adresse mail du destinataire.</legend>
                                <input id="emailCtrl" type="text" class="form-control" formControlName="emailCtrl" placeholder="Veuillez renseigner une adresse mail" [ngClass]="{ 'is-invalid': submitted && f.emailCtrl.errors }">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group" >
                                <label for="titreCtrl"> Objet du mail  <span>(*)</span></label>
                                <legend>Titre ou objet du mail à envoyer</legend>
                                <input id="titreCtrl" type="text" class="form-control" formControlName="titreCtrl" placeholder="Veuillez renseigner l'objet du mail" [ngClass]="{ 'is-invalid': submitted && f.titreCtrl.errors }">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group" >
                                <label for="importFile" #labelImport class="cursor" > <i class="material-icons">  search </i> {{urlSelectJustif.msg}}  <span>Joindre une pièce jointe</span></label>
                                <legend> Ajouter une pièce jointe au mail (Fichier moins de 1Mo) Attention : Renommer bien le fichier avant de l'envoyer. le nom du fichier est celui envoyé au client.</legend>
                                 <input type="file" class="custom-file-input" formControlName="importFile" id="importFile" (change)="onFileChange($event)" [ngClass]="{ 'is-invalid': submitted && f.importFile.errors }">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group" >
                                <label for="contenuCtrl">Contenu du mail <span>(*)</span></label>
                                <legend>Écrire le contenu du mail à envoyer. ATTENTION ! Utiliser la taille 4 pour le message, si non il apparaitra très petit.</legend>
                                <angular-editor formControlName="contenuCtrl" id="contenuCtrl" [config]="editorConfig" [ngClass]="{ 'is-invalid': submitted && f.contenuCtrl.errors }" ></angular-editor>
                            </div>
                        </div>
                    </div>
                    
                    <div class="row" *ngIf="retourMessage" >
                        <div class="col-md-12 div-error" *ngIf="retourMessage.ko" >
                            {{retourMessage.ko}}
                        </div>
                        <div class="col-md-12 div-success" *ngIf="retourMessage.ok" >
                            {{retourMessage.ok}}
                        </div>
                    </div>
                
                    <div class="row">
                        <div class="col">
                            <div class="text-center">
                                <button  class="btn-valider" type="submit" title="Envoyer un mail"> Envoyer un mail </button>
                            </div>
                        </div>
                    </div>
                
                </form>


            </div>

        </div>
    </mat-drawer-container>
    <app-footer></app-footer>

</div>
