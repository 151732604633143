import { Component, OnInit , Input  } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BackendService } from '../../services/backend/backend.service'
import { GlobalService } from '../../services/global/global.service'
import { EventsService } from '../../services/events/events.service'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-depenses',
  templateUrl: './add-depenses.component.html',
  styleUrls: ['./add-depenses.component.scss']
})
export class AddDepensesComponent implements OnInit {

  @Input() element
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  title : string = "Ajouter une dépense "

  etatExpedition = []
  etatSelected

  depenseFormGroup: FormGroup;
  urlImageSelect = { url: null, msg: 'Choisir un justificatif', error: null };
  submitted = false;
  retourMessage

  constructor(
    private activeModal: NgbActiveModal ,
    private backendService: BackendService ,
    private globalService: GlobalService,
    private eventsService: EventsService,
    private _formBuilder: FormBuilder,
  ) { 
    this.depenseFormGroup = this._formBuilder.group({
      montantCtrl: ['', Validators.required],
      commentCtrl: ['', Validators.required],
      justificatifCtrl: ['']
    });
  }

  ngOnInit() {
    console.log( this.element )
  }

  openAndUploadFile() {
    this.globalService.uploadImageFile()
    .then( resulta => {
      console.log( 'Le resultat attendu est :::: ', resulta );
      this.urlImageSelect.error = null;
      this.urlImageSelect.url = resulta;
      this.urlImageSelect.msg = `${this.globalService.randomString(10)}`;
    } )
    .catch( error => {
      this.urlImageSelect.error = "Erreur lors du chargement de l'image"
    })
  }


  public decline() {
    this.activeModal.close({status: false});
  }

  get f() { 
    return this.depenseFormGroup.controls; 
  }

  public onSubmit() {
    this.submitted = true;
    this.retourMessage = null;
    this.urlImageSelect.error = null;

    if (this.depenseFormGroup.invalid) {
      this.retourMessage = {ko: "Une erreur a été détectée sur le formulaire. Certains champs sont obligatoires !"};
      return;
    }


    if ( !!this.urlImageSelect.url ) {

      const contenuSend = {
        idvoyageur: this.element.idvoyageur,
        justificatif: this.urlImageSelect.url,
        name_image: this.urlImageSelect.msg,
        montant: this.f.montantCtrl.value,
        comment: this.f.commentCtrl.value,
        agent: this.globalService.getConnectedUser().id
      };

      console.log( contenuSend )

      this.backendService.post(`/voyageur/add-depenses-voyage`, contenuSend )
      .then( resultat => {
        if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
          this.retourMessage = {ko: resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."}
          this.eventsService.errorsmsg( this.retourMessage.ko )
        } else {
          this.eventsService.successmsg( resultat.text )
          this.retourMessage = {ok: resultat.text}
          setTimeout( () => {
            this.activeModal.close({status: true});
          }, 1000)
        }
      })
      .catch( error => {
        this.retourMessage = {ko: "Une erreur lors de l'enregistrement des données"}
      })


    } else {
      this.retourMessage = {ko: "Une erreur a été détectée sur le formulaire. Le justificatif est obligatoire !"};
      this.urlImageSelect.error = "No image selected"
      console.log( this.urlImageSelect )
      return;
    }
    

  }

}
