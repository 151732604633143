import { Component, OnInit , HostListener ,  ElementRef, ViewChild , AfterViewInit } from '@angular/core';


import { Router , ActivatedRoute } from '@angular/router';
import { MatDrawer } from '@angular/material';
import { GlobalService } from '../../../services/global/global.service'
import { BackendService } from '../../../services/backend/backend.service'

@Component({
  selector: 'app-edit-trajet',
  templateUrl: './edit-trajet.component.html',
  styleUrls: ['./edit-trajet.component.scss']
})
export class EditTrajetComponent implements OnInit {

  userConnected
  urlImage
  opened = true
  txtBadge = "Afficher le menu"
  @ViewChild("drawer", {static: false}) drawer: MatDrawer ;
  idTrajetSelected;
  suggestSelect
  loadFinished = false;
  retourMessage

  constructor(
    private router: Router,
    private backendService: BackendService ,
    private globalService: GlobalService,
    private route: ActivatedRoute
  ) { 
    
  }

  ngOnInit(): void {
    console.log('zep')
    this.route.params.subscribe(routeParams => {
      console.log('Param router :: ' , routeParams)
      console.log('Param router Parametter:: this.router.url' , this.router.url)
      this.getSuggestRoute(routeParams.idtrajet)
    });
    this.isConnectedUser()
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.mediaQuery()
    }, 1000);
  }

  isConnectedUser() {
    this.userConnected = this.globalService.getConnectedUser();
  }

  /** *********************  Sidenav **********************  */
  mediaQuery() {
    this.drawer.opened  = window.innerWidth > 900 ? true : false
    this.txtBadge = window.innerWidth > 900 ? "Cacher le menu" : "Afficher le menu"
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.mediaQuery()
  }

  eventToggle( drawer_ ){
    this.txtBadge = drawer_._opened ?  "Cacher le menu" : "Afficher le menu"
  }


  getSuggestRoute(id){
    
    this.backendService.get(`/suggest/route-by-id/${id}` )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.retourMessage = resultat.status != 0 ? resultat.error.error : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
      } else {
        // yes 
        this.suggestSelect = resultat[0];
      }
      this.loadFinished = true
      console.log('sugest route get ', resultat)
    })
    .catch( error => {
      this.loadFinished = true
      this.retourMessage = "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
    })
  }

  getBack(){
    this.router.navigate(['/trajets/lister-les-trajets/liste/3-2'])
  }

}
