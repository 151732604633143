import { Component, OnInit , EventEmitter , Output } from '@angular/core';

import { Router , NavigationStart , NavigationEnd } from '@angular/router';

import { UtilsService } from '../../services/utils/utils.service';
import { EventsService } from '../../services/events/events.service'
import { GlobalService } from '../../services/global/global.service'
import { ValidatorrService } from '../../services/validatorr/validatorr.service'
import { BackendService } from '../../services/backend/backend.service'


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  selectedPage;
  menuList = [];
  dropDownSelected

  userConnected;

  constructor(
    private eventsService: EventsService,
    private utilsService: UtilsService,
    private router: Router,
    private backendService: BackendService,
    private globalService: GlobalService,
  ) { 
    this.eventsService.userConnectEmitter.subscribe( user => {
      this.isConnectedUser()
    })
  }

  ngOnInit(): void {
    this.menuList = this.utilsService.getMenuList();
    this.selectedPage = this.utilsService.pageSelected ? this.utilsService.pageSelected : this.menuList[0];
    
    console.log( 'this.menuList this.menuList ------------------<<<<<<< ', this.menuList )
    this.getSelectedUrl();
    this.isConnectedUser();

    this.router.events.subscribe( (val) => { 
      if (val instanceof NavigationEnd) {
        console.log('Router change-----------------> ', val)
        this.getSelectedUrl()
      }
    })
  }

  isConnectedUser() {
    this.userConnected = this.globalService.getConnectedUser();
   
    // Ligne à supprimer apres
    this.userConnected.photo = this.userConnected.photo === '' ? 'assets/imgs/avatar.png' : this.userConnected.photo;
  }

  selectOption( pageSelect ){
    this.selectedPage = pageSelect

    console.log('Selected page pageSelect - : ' , pageSelect )

    this.router.navigate([pageSelect.page]);
    window.scroll(0,0);
    
  }

  selectSousOption( page , ssousPage ){
    console.log('Selected page : ', ssousPage , page)
    this.selectedPage = page
    this.dropDownSelected = ssousPage;

    this.utilsService.pageSelected = page;
    this.utilsService.dropDownSelected = ssousPage;

    this.router.navigate([ssousPage.page]);
    window.scroll(0,0);
  }


  getSelectedUrl() {
    const url = this.router.url;

    console.log('url ', this.router);

    const arraySplit = url.split("/");
    arraySplit.shift()
    console.log('Array split ', arraySplit);
    const page = this.menuList.filter( elt => elt.page === `/${arraySplit[0]}` );
    console.log(this.router.url)
    console.log(page)
    this.selectedPage = page[0];

    this.dropDownSelected = arraySplit.length >= 4 ? this.selectedPage.collapsed.filter( elt => elt.selectedID === `${arraySplit[3]}` )[0] : null;

    console.log('this.dropDownSelected ', this.dropDownSelected);


  }

}
