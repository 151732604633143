
<div class="full-with">

    <app-header></app-header>


    <mat-drawer-container class="example-container" autosize>
        <mat-drawer #drawer class="example-sidenav" mode="side" [opened]="opened" >
            <app-sidebar></app-sidebar>
        </mat-drawer>

        <div class="example-sidenav-content">

            <button type="button" mat-button (click)="drawer.toggle() && eventToggle(drawer)" class="btn-menu-dash" >
                <i class="material-icons">menu</i> <span>{{txtBadge}}</span>
            </button> <br />


            <div class="content-in-div">

                <div class="form partie-formulaire">
                    <div class="row">
                        <div class="col-md-12 text-h4 colorBleu">
                            Gérer les impayés
                            <span class="cursor" (click)="showOrHideFormulaure()">
                                <i class="material-icons text-h3 displayRight">
                                    {{ showFormulaire ? 'expand_less' : 'expand_more' }}
                                </i>
                            </span>
                        </div>
                    </div>

                    <form *ngIf="showFormulaire" class="contactForm" [formGroup]="impayesFormGroup" (ngSubmit)="onSubmit()" >

                        <div class="form-group" >
                            <label for="nom_prenomCtrl">Nom et prénom  <span>(*)</span></label>
                            <legend>Le nom et le prénom du débiteur </legend>
                            <input id="nom_prenomCtrl" type="text" class="form-control" formControlName="nom_prenomCtrl" placeholder="Le nom et le prénom du débiteur" data-rule="minlen:10" data-msg="Veuillez saisir au moins 10 caractères" [ngClass]="{ 'is-invalid': submitted && f.nom_prenomCtrl.errors }">
                            <div class="validation"></div>
                        </div>

                        <div class="form-group" >
                            <label for="total_apayerCtrl">Total à payer  <span>(*)</span></label>
                            <legend>Le montant total à payer </legend>
                            <input id="total_apayerCtrl" type="number" step="0.01" class="form-control" formControlName="total_apayerCtrl" placeholder="Le montant total à payer" [ngClass]="{ 'is-invalid': submitted && f.total_apayerCtrl.errors }">
                            <div class="validation"></div>
                        </div>

                        <div class="form-group" >
                            <label for="deja_payeCtrl">Déjà payé  <span>(*)</span></label>
                            <legend>Le montant déjà payé</legend>
                            <input id="deja_payeCtrl" type="number" step="0.01" class="form-control" formControlName="deja_payeCtrl" placeholder="Le montant déjà payé" [ngClass]="{ 'is-invalid': submitted && f.deja_payeCtrl.errors }">
                            <div class="validation"></div>
                        </div>

                        <div class="form-group" >
                            <label for="telephoneCtrl">Numéro de téléphone du débiteur  <span>(*)</span></label>
                            <legend>Numéro de téléphone sur lequel le débiteur est joignable. Au format {{maskTel.mask}} </legend>
                            <div class="input-group mb-3">

                                <div class="input-group-prepend">
                                    <button class="btn btn-outline-secondary dropdown-toggle" type="button"  
                                        aria-haspopup="true" aria-expanded="false" [matMenuTriggerFor]="menuImp" > 
                                        <img src="assets/imgs/{{maskTel.value}}" width="20" >
                                    </button>
                                    <mat-menu #menuImp="matMenu" class="img-select-menu" >
                                      <button *ngFor="let ntl of listTelephone" mat-menu-item (click)="telFormat( ntl )" >
                                          <img src="assets/imgs/{{ntl.image}}" width="30" > &nbsp; {{ntl.text}}
                                      </button>
                                    </mat-menu>
                                </div>

                                <input [validation]="true" [showMaskTyped]="true" mask="{{maskTel.mask}}" id="telephoneCtrl" 
                                type="text" class="form-control" formControlName="telephoneCtrl" placeholder="Le numéro de téléphone du débiteur" 
                                [ngClass]="{ 'is-invalid': submitted && f.telephoneCtrl.errors }" >
                            </div>

                        </div>

                        <div class="form-group" >
                            <label for="descriptionCtrl">Descripton  <span>(*)</span></label>
                            <legend> Description de l'impayé ( voyage, colis, autres détails ) .</legend>
                            <angular-editor formControlName="descriptionCtrl" id="descriptionCtrl" [config]="editorConfig" [ngClass]="{ 'is-invalid': submitted && f.descriptionCtrl.errors }" ></angular-editor>
                        </div>

                        <div class="row" *ngIf="retourMessage" >
                            <div class="col-md-12 div-error" *ngIf="retourMessage.ko" >
                                {{retourMessage.ko}}
                            </div>
                            <div class="col-md-12 div-success" *ngIf="retourMessage.ok" >
                                {{retourMessage.ok}}
                            </div>
                        </div>
                        <br /><br />
                
                        <div class="row">
                            <div class="col">
                                <div class="text-center">
                                    <button  class="btn-valider" type="submit" title="Enregistrer"> Enregistrer </button>
                                </div>
                            </div>
                            <div class="col" *ngIf="editMode">
                                <div class="text-center">
                                    <button class="btn-annuler" title="Annuler" (click)="cancelEditMode()" > Annuler </button>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>

                <div class="partie-tableau margin-top-40px">
                    <div class="row">
                        <div class="col-md-12 text-h4 colorBleu">
                            Liste des impayés en cours et terminés
                        </div>
                    </div>
                    <app-arraytable *ngIf="listeImpayes.length > 0"
                        [arrayList]="listeImpayes"
                        [columnsToDisplay]="columnsToDisplay"
                        [elementsDisplay]="elementsDisplay"
                        [canEdit]="'true'"
                        [canDelete]="'true'"
                        (tableEmitter)="onTableAction($event)"
                    ></app-arraytable>
                </div>

            </div>

            

        </div>
    </mat-drawer-container>
    <app-footer></app-footer>

</div>
