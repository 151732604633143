import { Component, OnInit , Input  } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {FormControl, FormGroup, Validators, FormBuilder, ValidationErrors } from '@angular/forms';
import { BackendService } from '../../services/backend/backend.service'
import { GlobalService } from '../../services/global/global.service'
import { EventsService } from '../../services/events/events.service'

@Component({
  selector: 'app-edit-location',
  templateUrl: './edit-location.component.html',
  styleUrls: ['./edit-location.component.scss']
})
export class EditLocationComponent implements OnInit {

  @Input() element
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() title : string = "Modifier la localisation de l'expédition";
  @Input() typeOuverture: string = 'location';


  messageRetour
  infosFormGroup: FormGroup;
  submitted = false
  listeLocalisation: any = [];

  constructor(
    private activeModal: NgbActiveModal ,
    private backendService: BackendService ,
    private globalService: GlobalService,
    private eventsService: EventsService,
    private _formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.listeLocalisation = this.globalService.getListEmplacement();
    this.infosFormGroup = this._formBuilder.group({
      locationCtrl: ['', Validators.required ],
      noteCtrl: ['', Validators.required ]
    });

  }

  ngAfterContentInit() {
    this.title += ` n° ${this.element.numtrans}`
  }

  get f() { 
    return this.infosFormGroup.controls; 
  }


  public decline() {
    this.activeModal.close({status: false});
  }

  public valider() {

    this.submitted = true;
    console.log(this.infosFormGroup)
    if (this.typeOuverture === 'location') {
      this.infosFormGroup.patchValue({
        noteCtrl: `Localisation de votre expédition : "${this.f.locationCtrl.value}"`,
      })
    } else {
      this.infosFormGroup.patchValue({
        locationCtrl: `${this.f.noteCtrl.value}`,
      })
    }
    // stop here if form is invalid
    if (this.infosFormGroup.invalid) {
      return;
    }

    let dateAct = new Date()
    let note = {
      note : `${this.f.noteCtrl.value}`,
      create_at: `${dateAct.getDate()}/${dateAct.getMonth() + 1}/${dateAct.getFullYear()}`
    }

    let lesNotes = !!this.element.notestrans ? JSON.parse(this.element.notestrans) : [];
    lesNotes.push( note )

    let box = {
      iduserexp: !!this.element.user_sender.id ? this.element.user_sender.id : 0,
      numtrans: this.element.numtrans,
      notestrans: JSON.stringify( lesNotes ),
      localisation: this.f.locationCtrl.value,
      user_direct: !!this.element.user_sender.user_direct,
      email_user: !!this.element.user_sender.email ? this.element.user_sender.email : null
    }
    console.log('la box ', box)

    this.backendService.post(`/senbox/${this.typeOuverture}/${this.element.id}`, box )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.messageRetour = {ko: resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."}
        this.eventsService.errorsmsg( this.messageRetour.ko )
      } else {
        this.eventsService.successmsg( resultat.text )
        this.messageRetour = {ok: resultat.text}
        setTimeout( () => {
          this.activeModal.close({status: true});
        }, 1000)
      }
    })
    .catch( error => {
      this.messageRetour = {ko: "Une erreur lors de l'enregistrement des données"}
    })

  }


  public dismiss() {
    this.decline();
  }

}
