


  <div class="side-bar-left">
    <mat-accordion class="example-headers-align" multi>

      <div class="partie-avatar">
  
        <div class="row">
          <div class="col-12 profile-user">
            <span class="profile  cursor"  >
              <img [src]="userConnected.photo" class="profile"  /> <br />
              <span>Salut, {{userConnected.username}} </span>
            </span>
          </div>
        </div>
        
      </div>
  
  
      <div *ngFor="let men of menuList" >
  
          <!-- -->
          <mat-expansion-panel *ngIf="men.collaps && !men.divider" >
              <mat-expansion-panel-header [ngClass]="selectedPage.id == men.id ? 'menu-selected' : 'menu'"  >
                <mat-panel-title class="text-12">
                  <span class="material-icons icon-left-menu">{{men.icone}}</span> {{men.libelle}}
                </mat-panel-title>
              </mat-expansion-panel-header>
  
              <mat-list role="list" >
                  <mat-list-item *ngFor="let ssmen of men.collapsed" role="listitem" class="cursor text-12" [ngClass]="dropDownSelected && dropDownSelected.selectedID == ssmen.selectedID ? 'menu-selected' : 'menu'" (click)="selectSousOption(men, ssmen)" >
                      {{ssmen.libelle}}
                  </mat-list-item>
              </mat-list>
  
          </mat-expansion-panel>
  
          <mat-list role="list" *ngIf="!men.collaps && men.divider" >
              <mat-divider></mat-divider>
              <h3 matSubheader> {{men.libelle}} </h3>
          </mat-list>
  
          <mat-list role="list" *ngIf="!men.collaps && !men.divider" >
              <mat-list-item role="listitem" class="cursor menu text-12" [ngClass]="selectedPage.id == men.id ? 'menu-selected' : 'menu'" (click)="selectOption(men)" >
                  <span class="material-icons icon-left">{{men.icone}}</span> {{men.libelle}}
              </mat-list-item>
          </mat-list>
  
      </div>
  
      <div class="margin-top-40px">&nbsp;</div>
  
  
    
  
  </mat-accordion>
  </div>