<div class="modal-header">
    <h4 class="modal-title"> {{title}} </h4>
    <button type="button" class="close" aria-label="Close" (click)="decline()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <form [formGroup]="depenseFormGroup" >
        <div class="example-container-">

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group" >
                        <label for="titreCtrl">Montant  <span>(*)</span></label>
                        <legend>Le montant de la dépense</legend>
                        <input step="0.01" id="montantCtrl" type="number" class="form-control" formControlName="montantCtrl" placeholder="Montant de la dépense"  [ngClass]="{ 'is-invalid': submitted && f.montantCtrl.errors }" >
                        <div class="validation"></div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group" >
                        <label for="commentCtrl">Commentaire  <span>(*)</span></label>
                        <legend>Détail de la dépense : Achat bagage supplémentaire, Transport, Frais Agent indépendant (frais préparation bagages, frais accompagnement aéroport), autres dépenses (Achat sacs, fourniture)) </legend>
                        <textarea class="form-control" id="commentCtrl" rows="3" formControlName="commentCtrl" placeholder="Détail de la dépense : Achat bagage supplémentaire, Transport, Frais Agent indépendant ..." [ngClass]="{ 'is-invalid': submitted && f.commentCtrl.errors }"></textarea>
                        <div class="validation"></div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group" >
                        <label (click)="openAndUploadFile()" #labelImport class="cursor" [ngClass]="{ 'colorError': submitted && urlImageSelect.error }" > 
                            <i class="material-icons">  search </i> {{urlImageSelect.msg}}  
                            <span>Sélectionnez un justificatif (*)</span>
                            <br />
                            <span *ngIf="urlImageSelect.url && urlImageSelect.url !== ''" >
                                <img [src]="urlImageSelect.url  | safeHtml  " width="100"  />
                            </span>
                        </label>
                        <legend> Sélectionner un justificatif </legend>
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="retourMessage" >
                <div class="col-md-12 div-error" *ngIf="retourMessage.ko" >
                    {{retourMessage.ko}}
                </div>
                <div class="col-md-12 div-success" *ngIf="retourMessage.ok" >
                    {{retourMessage.ok}}
                </div>
            </div>
        </div>
    </form>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="decline()">{{ btnCancelText }}</button>
    <button type="button" class="btn btn_default" (click)="onSubmit()">{{ btnOkText }}</button>
</div>