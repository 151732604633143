import { Component, OnInit , HostListener ,  ElementRef, ViewChild , AfterViewInit } from '@angular/core';

import {Location} from '@angular/common'
import { Router } from '@angular/router';
import { MatDrawer } from '@angular/material';
import { EventsService } from 'src/app/services/events/events.service'
import { GlobalService } from 'src/app/services/global/global.service'
import { ValidatorrService } from 'src/app/services/validatorr/validatorr.service'
import { BackendService } from 'src/app/services/backend/backend.service'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmService } from 'src/app/modal/confirm/confirm.service';

@Component({
  selector: 'app-notefrais',
  templateUrl: './notefrais.component.html',
  styleUrls: ['./notefrais.component.scss']
})
export class NotefraisComponent implements OnInit {

  userConnected
  urlImage
  opened = true
  txtBadge = "Afficher le menu"
  @ViewChild("drawer", {static: false}) drawer: MatDrawer ;
  loadFinished = false;
  listeFrais = []
  retourMessage;
  columnsToDisplay = ['nomprenom', 'montant', 'comment', 'justificatif', 'statut', 'agent_msahilisho', 'action'];
  elementsDisplay = ['Nom et prénom', 'Montant', 'Détail', 'Justificatif', 'Statut', 'Agent', 'Action'];

  fraisFormGroup: FormGroup;
  showFormulaire = true;
  editorConfig;
  submitted = false;
  editMode = false;
  editElementSelected;
  urlImageSelect = { url: null, msg: 'Choisir un justificatif', error: null };
  listeStatut = []

  constructor(
    private _formBuilder: FormBuilder,
    private location: Location ,
    private router: Router,
    private eventsService: EventsService,
    private backendService: BackendService,
    private globalService: GlobalService,
    private confirmService: ConfirmService
  ) { 
    this.fraisFormGroup = this._formBuilder.group({
      nomprenomCtrl: [ '', [Validators.required, Validators.maxLength(140)]],
      montantCtrl: ['', Validators.required],
      commentCtrl: ['', Validators.required],
      justificatifCtrl: [''],
      statutCtrl: ['', Validators.required]
    });
    this.listeStatut = [
      {id: 1, statut: "Non payé"},
      {id: 1, statut: "Payé"}
    ]
  }

  ngOnInit(): void {
    this.editorConfig = this.globalService.getEditorConfig();
    this.isConnectedUser()
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.mediaQuery()
      this.displayPage()
    }, 1000);
  }
  isConnectedUser() {
    this.userConnected = this.globalService.getConnectedUser();
  }
  /** *********************  Sidenav **********************  */
  mediaQuery() {
    this.drawer.opened  = window.innerWidth > 900 ? true : false
    this.txtBadge = window.innerWidth > 900 ? "Cacher le menu" : "Afficher le menu"
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.mediaQuery()
  }
  eventToggle( drawer_ ){
    this.txtBadge = drawer_._opened ?  "Cacher le menu" : "Afficher le menu"
  }
  /** *********************  Sidenav **********************  */

  get f() { 
    return this.fraisFormGroup.controls; 
  }

  showOrHideFormulaure() {
    this.showFormulaire = !this.showFormulaire;
  }

  displayPage() {
    this.loadFinished = false;
    this.backendService.get(`/impayes/list-frais` )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.listeFrais = [];
        this.retourMessage = {ko: "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."}
      } else {
        console.log( resultat )
        this.listeFrais = resultat;
      }
      this.loadFinished = true
    })
    .catch( error => {
      this.listeFrais = [];
      this.loadFinished = true
      this.retourMessage = {ko: "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."}
    })
  }


  onSubmit() {
    this.submitted = true
    this.retourMessage = null;
    if (this.fraisFormGroup.invalid) {
      this.retourMessage = {ko: "Une erreur a été détectée"}
      return;
    }

    if ( !!this.urlImageSelect.url ) {

      const formSend = {
        nomprenom: this.f.nomprenomCtrl.value,
        montant: this.f.montantCtrl.value,
        comment: this.f.commentCtrl.value,
        justificatif: this.urlImageSelect.url,
        statut: this.f.statutCtrl.value,
        agent: this.globalService.getConnectedUser().id
      }
      console.log('Form Before Send ', formSend);
  
      if (!this.editMode) {
        this.addFrais( formSend );
      } else {
        this.editFrais( formSend );
      }

    } else {
      this.retourMessage = {ko: "Une erreur a été détectée sur le formulaire. Le justificatif est obligatoire !"};
      this.urlImageSelect.error = "No image selected"
      console.log( this.urlImageSelect )
      return;
    }
    
  }

  cancelEditMode() {
    this.editMode = false;
    this.resetFormulaire();
  }

  resetFormulaire() {
    this.retourMessage = null;
    this.submitted = false;
    this.editMode = false;
    this.fraisFormGroup.patchValue({
      nomprenomCtrl: '',
      montantCtrl: '',
      commentCtrl: '',
      justificatifCtrl: '',
      statutCtrl: ''
    });
    this.urlImageSelect.error = null;
    this.urlImageSelect.url = null;
    this.urlImageSelect.msg = 'Choisir un justificatif';
  }

  onTableAction( event) {
    console.log('onTableAction Table action ', event)
    const element = event.element
    this.editElementSelected = event.element;

    if (event.method === 'delete') {
      this.confirmDelete(element)
    } else if (event.method === 'update' ) {
      this.editMode = true;
      const elementOfUpdate = event.element;
      this.showFormulaire = true;
      console.log(  ' elementOfUpdate ', elementOfUpdate )

      this.fraisFormGroup.patchValue({
        nomprenomCtrl: elementOfUpdate.nomprenom,
        montantCtrl: elementOfUpdate.montant,
        commentCtrl: elementOfUpdate.comment,
        justificatifCtrl: elementOfUpdate.justificatif,
        statutCtrl: elementOfUpdate.statut
      });
      this.urlImageSelect.url = elementOfUpdate.justificatif;
      console.log( this.fraisFormGroup )
      window.scroll(0,0);
    }
  }

  addFrais( formSend ) {
    if ( !this.globalService.canAdd() ) {
      this.eventsService.toastrwaring("Vous ne disposez pas des autorisations requises pour cette action.")
      return;
    }
    this.backendService.post( `/impayes/add-frais`, formSend )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.retourMessage = {ko: resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."}
      } else {
        this.retourMessage = {ok: resultat.text};
        setTimeout(() => {
          this.resetFormulaire();
          this.displayPage();
        }, 2000);
      }
    })
    .catch( error => {
      this.retourMessage = {ko: "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."}
    })
  }

  editFrais( formSend ) {

    if ( !this.globalService.canEdit() ) {
      this.eventsService.toastrwaring("Vous ne disposez pas des autorisations requises pour cette action.")
      return;
    }
    this.backendService.post( `/impayes/update-frais/${this.editElementSelected.id}`, formSend )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.retourMessage = {ko: resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."}
      } else {
        this.retourMessage = {ok: resultat.text};
        setTimeout(() => {
          this.resetFormulaire();
          this.displayPage();
        }, 2000);
      }
    })
    .catch( error => {
      this.retourMessage = {ko: "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."}
    })
  }

  confirmDelete(element) {
    this.retourMessage = null;
    this.backendService.get(`/impayes/delete-frais/${element.id}`, )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.eventsService.errorsmsg( resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement." )
      } else {
        this.retourMessage = {ok: resultat.text};
        this.eventsService.successmsg( resultat.text );
        setTimeout( () => {
          this.displayPage();
        }, 2000)
      }
    })
    .catch( error => {
      this.retourMessage = {ko: "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."}
      this.eventsService.errorsmsg( "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement." )
    })
  }


  openAndUploadFile() {
    this.globalService.uploadImageFile()
    .then( resulta => {
      console.log( 'Le resultat attendu est :::: ', resulta );
      this.urlImageSelect.error = null;
      this.urlImageSelect.url = resulta;
      this.urlImageSelect.msg = `${this.globalService.randomString(10)}`;
    } )
    .catch( error => {
      this.urlImageSelect.error = "Erreur lors du chargement de l'image"
    })
  }

}
