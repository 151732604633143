import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders , HttpEventType  } from '@angular/common/http';
import { Observable , throwError } from 'rxjs';

import { LogsService } from '../logs/logs.service'
import { map, catchError } from  'rxjs/operators';
import { environment } from 'src/environments/environment';

import { GlobalService } from '../global/global.service'

const optionRequete = {
  headers: new HttpHeaders({ 
    'Access-Control-Allow-Origin':'*',
    'Content-Type': 'application/json; charset=utf-8'
  })
};

@Injectable({
  providedIn: 'root'
})
export class BackendService {

  backendUrl = environment.backendUrl
  backendApi = environment.backendApi

  constructor( 
    private http: HttpClient ,
    private logService: LogsService ,
    private globalService: GlobalService,
  ) {

  }
  

  /**
   * 
   * @param url 
   */
  get( url ) {

    const headers = new HttpHeaders()
    .set('Content-Type', 'application/json; charset=utf-8')

    this.logService.log( `GET ::: ${url}` )

    return this.http
    .get( `${this.backendUrl}${url}` , {headers} )
    .toPromise()
    .then( response => response )
    .catch(error => error )

  }

  /**
   * 
   * @param url 
   * @param param 
   */
  put(url, param_){

    if( param_ == null ){
      return throwError("Null parameter").toPromise()
    }

    const headers = new HttpHeaders()
    .set('Content-Type', 'application/json; charset=utf-8')

    this.logService.log( `PUT ::: ${url} - ${JSON.stringify(param_)}` )

    return this.http
    .put( `${this.backendUrl}${url}`, param_ , {headers} )
    .toPromise()
    .then(response => response)
    .catch(error => error )

  }


  
  /**
   * 
   * @param url 
   * @param param 
   */
  post(url, param_){

    if( param_ == null ){
      return throwError("Null parameter").toPromise()
    }

    const headers = new HttpHeaders()
    .set('Content-Type', 'application/json; charset=utf-8')

    //this.logService.log( `POST ::: ${url} - ${JSON.stringify(param_)}` )

    return this.http
    .post( `${this.backendUrl}${url}`, param_ , {headers} )
    .toPromise()
    .then(response => response)
    .catch(error => error )

  }



  /**
   * 
   * @param url 
   */
  delete(url) {

    const headers = new HttpHeaders()
    .set('Content-Type', 'application/json; charset=utf-8')

    this.logService.log( `DELETE ::: ${url}` )

    return this.http
    .delete(`${this.backendUrl}${url}`, {headers} )
    .toPromise()
    .then(response => response)
    .catch(error => error)

  }


  getCountries() {
		return this.http.get(`assets/json/countries.json`);
  }
  

  /**
   * Upload file 
   * @param data 
   * @param url 
   */
  upload(data, url ) {

    this.logService.log( `UPLOAD ::: ${url} - ${JSON.stringify(data)}` )

    const headers = new HttpHeaders()
    .set('Content-Type', 'application/json; charset=utf-8')

    return this.http.post<any>(`${this.backendUrl}${url}`, data ,{
      reportProgress: true,
      observe: 'events',

    }).pipe(map((event) => {

      switch (event.type) {

        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);
          return { status: 'progress', message: progress };

        case HttpEventType.Response:
          return event.body;
        default:
          return { status: 'noprogress', message: `Unhandled event: ${event.type}` } ;
      }
    })
    );

  }




  load(){
    
    const headers = new HttpHeaders()
    .set('Content-Type', 'application/json; charset=utf-8')

    this.logService.log( `GET ::: /token/init` )

    return this.http
    .get( `${this.backendUrl}/token/init` , {headers} )
    .toPromise()
    .then( response => {
      
      this.logService.log( JSON.stringify(response) )
      this.globalService.setVisitor( response )
    } )
    .catch(error => {
      
      
    } )

  }


  
  onConnexion(){

    const headers = new HttpHeaders()
    .set('Content-Type', 'application/json; charset=utf-8')

    let param_ = {
      ip: 'ip'
    }

    this.http
    .post( `${this.backendUrl}/contact/visite`, param_ , {headers})
    .toPromise()
    .then((response) => {
      console.log('Response log ',response)
    })
    .catch((error) => {
      console.log('error lof ', error)
    } )

  }


  download(url){
    
    const headers = new HttpHeaders()
    .set('Content-Type', 'application/json; charset=utf-8')
    this.logService.log( `GET ::: /download` )
    return this.http
    .get(`${url}`, {headers} )
    .toPromise()
    .then(response => response)
    .catch(error => error)

  }


}
