import { Component, OnInit , Input, Output , EventEmitter } from '@angular/core';

import {FormControl, FormGroup, Validators, FormBuilder} from '@angular/forms';
import { GlobalService } from '../../services/global/global.service'
import { EventsService } from '../../services/events/events.service'

@Component({
  selector: 'app-uploadimage',
  templateUrl: './uploadimage.component.html',
  styleUrls: ['./uploadimage.component.scss']
})
export class UploadimageComponent implements OnInit {

  formulaireGroup: FormGroup;
  submitted = false;
  urlImageSelect = { url: '', msg: 'Image slide' }

  @Output() imageEmitter = new EventEmitter();
  @Input() imageSelect;
  @Input() textImageLegend;

  constructor(
    private _formBuilder: FormBuilder,
    private globalService: GlobalService,
    private eventsService: EventsService
  ) { }

  ngOnInit(): void {
    this.globalService.setModelValueToUpload( null );

    this.formulaireGroup = this._formBuilder.group({
      imageCtrl: [ '', Validators.required]
    });

    this.eventsService.editImage.subscribe( image => {
      this.pachValueForm( image )
    })
  }

  get f() { 
    return this.formulaireGroup.controls; 
  }

  pachValueForm( image ) {
    this.formulaireGroup.patchValue({
      imageCtrl: this.globalService.dataURLtoFile(image, 'slider_edit')
    });
    this.globalService.setModelValueToUpload( image );
  }

  onFileChange( event ) {  //files: FileList   :::: $event.target.files 
    
    if (event.target.files && event.target.files[0]) {
       var reader = new FileReader();
       this.urlImageSelect.url = event.target.files[0]
       reader.readAsDataURL(event.target.files[0]);
       this.urlImageSelect.msg = event.target.files[0].name
       // Image to Base 64
       this.globalService.getBase64(event);

       this.imageEmitter.emit( this.urlImageSelect )
     }
     
  }

}
