import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialAngModule } from '../matmodule/materialang.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgxMaskModule } from 'ngx-mask'

import { EventsService } from '../services/events/events.service'
import { GlobalService } from '../services/global/global.service'
import { ValidatorrService } from '../services/validatorr/validatorr.service'
import { BackendService } from '../services/backend/backend.service';
import { UtilsService } from '../services/utils/utils.service';

import { SqueletteComponent } from './squelette/squelette.component';
import { ParametrageComponent } from './parametrage/parametrage.component';
import { ReglagesComponent } from './reglages/reglages.component';
import { ComptesUserComponent } from './comptes-user/comptes-user.component';
import { NosEquipesComponent } from './nos-equipes/nos-equipes.component';

import { HeaderModule} from '../header/header.module';
import { SharedModule } from '../shared/shared.module';
import { FooterModule } from '../footer/footer.module';
import { TrajetsModule } from './trajets/trajets.module';
import { SendboxModule } from './sendbox/sendbox.module';
import { VoyageursModule } from './voyageurs/voyageurs.module';
import { UtilisateursModule } from './utilisateurs/utilisateurs.module';
import { AvisModule } from './avis/avis.module';
import { FlashInfoModule } from './flash-info/flash-info.module';
import { ImpayesModule } from './impayes/impayes.module';
import { NotefraisModule } from './notefrais/notefrais.module';
import { SendMailComponent } from './send-mail/send-mail.component';

@NgModule({
  declarations: [
    SqueletteComponent,
    ParametrageComponent,
    ReglagesComponent,
    ComptesUserComponent,
    NosEquipesComponent,
    SendMailComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    MaterialAngModule,
    FormsModule,
    ReactiveFormsModule,
    AngularEditorModule,
    NgxMaskModule.forRoot(),

    HeaderModule,
    SharedModule,
    FooterModule,
    TrajetsModule,
    SendboxModule,
    VoyageursModule,
    UtilisateursModule,
    AvisModule,
    FlashInfoModule,
    ImpayesModule,
    NotefraisModule
  ],
  providers: [
    EventsService,
    GlobalService,
    ValidatorrService,
    BackendService,
    UtilsService
  ]
})
export class PagesModule { }
