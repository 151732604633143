
   <div class="form-group" >
    <label for="imageCtrl" #labelImport class="cursor" [ngClass]="{ 'colorError': submitted && f.imageCtrl.errors }" > 
        <i class="material-icons">  search </i> {{urlImageSelect.msg}}  
        <span>Sélectionnez une image (*)</span>
    </label>
    <legend> {{ textImageLegend }} </legend>
    <input type="file" accept="image/png, image/jpeg" class="custom-file-input colorGris text-12" formControlName="imageCtrl" id="imageCtrl" (change)="onFileChange($event)" [ngClass]="{ 'is-invalid': submitted && f.imageCtrl.errors }">
    <div class="validation"></div>
</div>
