import { Component, OnInit , HostListener ,  ElementRef, ViewChild , AfterViewInit } from '@angular/core';

import {Location} from '@angular/common'
import { Router } from '@angular/router';
import { MatDrawer } from '@angular/material';
import { EventsService } from 'src/app/services/events/events.service'
import { GlobalService } from 'src/app/services/global/global.service'
import { ValidatorrService } from 'src/app/services/validatorr/validatorr.service'
import { BackendService } from 'src/app/services/backend/backend.service'
import { DateformatService } from 'src/app/matmodule/dateformat.service'

import {
  NgbDateParserFormatter
} from '@ng-bootstrap/ng-bootstrap';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';



@Component({
  selector: 'app-nouvelle-demande',
  templateUrl: './nouvelle-demande.component.html',
  styleUrls: ['./nouvelle-demande.component.scss'],
  providers: [
    {provide: NgbDateParserFormatter, useClass: DateformatService},
    {
      provide: STEPPER_GLOBAL_OPTIONS, useValue: {showError: true}
    }
  ]
})
export class NouvelleDemandeComponent implements OnInit {

  userConnected
  urlImage
  opened = true
  txtBadge = "Afficher le menu"
  @ViewChild("drawer", {static: false}) drawer: MatDrawer ;


  isLinear = true;
  submitted = false
  infosPersoFormGroup: FormGroup;
  infosVoyageFormGroup: FormGroup;
  confiramtionFormGroup: FormGroup;
  aeroportList = [];
  @ViewChild('labelImport')
  labelImport: ElementRef;
  fileToUpload: File = null;
  urlSelectJustif = { url: '', msg: 'Justificatif d\'identité' };
  progressMessage = {progress: null, ok: null, ko: null};
  maskTel = {mask: "(+00) 0 00 00 00 00" , value: "france_.jpg"};
  listTelephone = []

  constructor(
    private location: Location ,
    private router: Router,
    private eventsService: EventsService,
    private backendService: BackendService ,
    private globalService: GlobalService,
    private validService: ValidatorrService,
    private _formBuilder: FormBuilder
  ) { 
    this.initFormGroups();
  }

  ngOnInit(): void {
    this.listTelephone = this.globalService.getListeIndicatifTelephone();
    this.isConnectedUser()
    this.aeroportList = this.globalService.getAeroports();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.mediaQuery()
    }, 1000);
  }

  isConnectedUser() {
    this.userConnected = this.globalService.getConnectedUser();
  }

  /** *********************  Sidenav **********************  */
  mediaQuery() {
    this.drawer.opened  = window.innerWidth > 900 ? true : false
    this.txtBadge = window.innerWidth > 900 ? "Cacher le menu" : "Afficher le menu"
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.mediaQuery()
  }

  eventToggle( drawer_ ){
    this.txtBadge = drawer_._opened ?  "Cacher le menu" : "Afficher le menu"
  }


  initFormGroups() {
    this.infosPersoFormGroup = this._formBuilder.group({
      nomCtrl: ['', Validators.required],
      prenomCtrl: ['', Validators.required],
      numTelCtrl: ['', Validators.required],
      emailCtrl: ['', [Validators.required, ValidatorrService.emailValidator]],
      importFile: ['']
    });

    this.infosVoyageFormGroup = this._formBuilder.group({
      aeroportDepCtrl: ['', Validators.required],
      aeroportArrCtrl: ['', Validators.required],
      dateDepartCtrl: ['', Validators.required],
      dateArriveeCtrl: ['', Validators.required]
    }, {validator: this.validService.dateLessThan('dateDepartCtrl', 'dateArriveeCtrl')} );

    this.confiramtionFormGroup = this._formBuilder.group({
      confirmationCtrl: ['', Validators.required],
      commentCtrl: ['']
    });

    this.infosPersoFormGroup.patchValue( {
      numTelCtrl: "33 "
    } )
  }

  isIE(){
    return /Trident\/|MSIE/.test(window.navigator.userAgent);
  }

  get f1() { 
    return this.infosPersoFormGroup.controls; 
  }
  get f2() { 
    return this.infosVoyageFormGroup.controls; 
  }
  get f3() { 
    return this.confiramtionFormGroup.controls; 
  }

  onFileChange( event ) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      this.urlSelectJustif.url = event.target.files[0]
      reader.readAsDataURL(event.target.files[0]);
      this.urlSelectJustif.msg = event.target.files[0].name
    }
  }

  publier(){
    this.submitted = true
    this.progressMessage.ko = null;
    this.progressMessage.ok = null;
    this.progressMessage.progress = null;

    if (this.infosPersoFormGroup.invalid) {
      this.progressMessage.ko = "Une erreur a été détectée dans l'onglet [Informations personnelles] "
      return;
    }
    if (this.infosVoyageFormGroup.invalid) {
      this.progressMessage.ko = "Une erreur a été détectée dans l'onglet [Informations du voyage] "
      return;
    }
    if (this.confiramtionFormGroup.invalid) {
      this.progressMessage.ko = "Une erreur a été détectée dans l'onglet [Confirmation et validation des CGU]. Le champs CGU est obligatoire."
      return;
    }

    const formData = new FormData();
    // Info voyage
    const aerortDepart = this.infosVoyageFormGroup.controls['aeroportDepCtrl'].value ;
    const aerortArrivee = this.infosVoyageFormGroup.controls['aeroportArrCtrl'].value ;
    formData.append('datedepart', this.formaterDate( this.infosVoyageFormGroup.controls['dateDepartCtrl'].value));
    formData.append('datearrivee', this.formaterDate(this.infosVoyageFormGroup.controls['dateArriveeCtrl'].value));
    formData.append('aerodep', aerortDepart.name);
    formData.append('aeroarr', aerortArrivee.name);
    formData.append('paysdep', aerortDepart.pays);
    formData.append('paysarr', aerortArrivee.pays);
    // Info personnelle
    formData.append('nom', this.infosPersoFormGroup.controls['nomCtrl'].value);
    formData.append('prenom', this.infosPersoFormGroup.controls['prenomCtrl'].value);
    formData.append('numTel', this.infosPersoFormGroup.controls['numTelCtrl'].value);
    formData.append('email', this.infosPersoFormGroup.controls['emailCtrl'].value);
    formData.append('justificatif', this.urlSelectJustif.url);
    // Confirmation
    formData.append('comment', this.confiramtionFormGroup.controls['commentCtrl'].value);

    console.log( formData );

    this.backendService.upload( formData, `/voyageur/add`)
    .subscribe (
      (res) => {
        let uploadResponse = res;
        if( uploadResponse.status === 'error' ) {
          this.eventsService.errorsmsg( uploadResponse.message )
        } else {
          if( uploadResponse.status == 'progress' ) {
            this.progressMessage.progress = uploadResponse.message;
          }
          if( uploadResponse.text ) {
            this.progressMessage.ok = "Félicitations ! Votre demande a été enregistrée avec succès!";
            setTimeout(() => {
              this.router.navigate(['/dashboard']);
              window.scroll(0,0);
            }, 6000);
          }
        }
      }, (err) => {
        console.log(err)
        if( typeof err === "string")
          this.eventsService.errorsmsg( err )
          this.progressMessage.ko = "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
      }
    );

  }

  formaterDate( la_date ){
    return `${la_date.substring(0, 2)}-${la_date.substring(2, 4)}-${la_date.substring(4, 8)}`
  }

  openCGU(){
    window.open( `${this.globalService.getURLApp()}#/conditions-generales-utilisations`  , "_blank")
  }


  changeBTN( action ) {
    this.submitted = false
    if( action == "infosPerso" ) {
      if (this.infosPersoFormGroup.invalid) {
        this.submitted = true
        return;
      }
    }
    if( action == "infosVoyage" ) {
      if (this.infosVoyageFormGroup.invalid) {
        this.submitted = true
        return;
      }
    }
    if( action == "cgu" ) {
      if (this.confiramtionFormGroup.invalid) {
        this.submitted = true
        this.progressMessage.ko = null
        return;
      }
    }
  }


  telFormat( ntl ) {
    console.log('ntel ' , ntl)
    this.infosPersoFormGroup.patchValue( {
      numTelCtrl: ntl.text
    } );
    this.maskTel.value = ntl.image;
    this.maskTel.mask = ntl.mask;
  }

}
