import { Component, OnInit , Input  } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {FormControl, FormGroup, Validators, FormBuilder, ValidationErrors } from '@angular/forms';
import { BackendService } from '../../services/backend/backend.service'
import { GlobalService } from '../../services/global/global.service'
import { EventsService } from '../../services/events/events.service'

@Component({
  selector: 'app-edit-sendbox',
  templateUrl: './edit-sendbox.component.html',
  styleUrls: ['./edit-sendbox.component.scss']
})
export class EditSendboxComponent implements OnInit {

  @Input() element
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  messageRetour
  infosFormGroup: FormGroup;
  submitted = false
  title
  envoiEncours = false

  masktelArr = {mask: "(+00) 0 00 00 00 00" , value: "france.png"}
  maskTelExp = {mask: "(+00) 0 00 00 00 00" , value: "france.png"}
  listTelephone = []

  constructor(
    private activeModal: NgbActiveModal ,
    private backendService: BackendService ,
    private globalService: GlobalService,
    private eventsService: EventsService,
    private _formBuilder: FormBuilder,
  ) { 
    this.infosFormGroup = this._formBuilder.group({
      nomPrenomExpediteurCtrl: ['', Validators.required],
      telExpediteurCtrl: ['', Validators.required],
      adresseExpediteurCtrl: ['', Validators.required],
      emailExpediteurCtrl: [''],

      nomPrenomDestinataireCtrl: ['', Validators.required],
      telephoneDestinataireCtrl: ['33', Validators.required],
      infoObjetCtrl: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.listTelephone = this.globalService.getListeIndicatifTelephone();
  }

  ngAfterContentInit() {

    this.title = `Modifier l'expédition n° ${this.element.numtrans}`;
    this.masktelArr = this.globalService.maskSaisi( this.element.teldest )
    this.maskTelExp = this.globalService.maskSaisi( this.element.user_sender.telephone )
    this.infosFormGroup.patchValue({
      nomPrenomExpediteurCtrl: this.element.user_sender.username,
      telExpediteurCtrl: this.element.user_sender.telephone,
      adresseExpediteurCtrl: this.element.user_sender.adresse,
      emailExpediteurCtrl: this.element.user_sender.email,
      
      nomPrenomDestinataireCtrl: this.element.nomdest,
      telephoneDestinataireCtrl: this.element.teldest,
      infoObjetCtrl: this.element.infosobj
    })
    console.log( this.masktelArr )
  }

  get f() { 
    return this.infosFormGroup.controls; 
  }

  public decline() {
    this.activeModal.close({status: false});
  }

  public valider() {
    this.submitted = true;
    if (this.infosFormGroup.invalid) {
      return;
    }
    console.log(this.infosFormGroup.getRawValue())

    let dateAct = new Date()
    let note = {
      note : `Modification de l'expédion par ${this.globalService.getConnectedUser().username}`,
      create_at: `${dateAct.getDate()}/${dateAct.getMonth() + 1}/${dateAct.getFullYear()}`
    }

    let lesNotes = !!this.element.notestrans ? JSON.parse(this.element.notestrans) : [];
    lesNotes.push( note )

    let box = {
      iduserexp: !!this.element.user_sender.id ? this.element.user_sender.id : 0,
      nameerexp: this.f.nomPrenomExpediteurCtrl.value,
      telrecup: this.f.telExpediteurCtrl.value,
      addrexp: this.f.adresseExpediteurCtrl.value,
      mailexp: this.f.emailExpediteurCtrl.value,

      nomdest: this.f.nomPrenomDestinataireCtrl.value,
      teldest: `${this.f.telephoneDestinataireCtrl.value}`,
      infosobj: this.f.infoObjetCtrl.value,
      
      numtrans: this.element.numtrans,
      notestrans: JSON.stringify( lesNotes ),

      user_direct: !!this.element.user_sender.user_direct
    }
    console.log('la box ', box)

    this.backendService.post(`/senbox/update-via-admin/${this.element.id}`, box )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.messageRetour = {ko: resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."}
        this.eventsService.errorsmsg( this.messageRetour.ko )
      } else {
        this.eventsService.successmsg( resultat.text )
        this.messageRetour = {ok: resultat.text}
        setTimeout( () => {
          this.activeModal.close({status: true});
        }, 1000)
      }
    })
    .catch( error => {
      this.messageRetour = {ko: "Une erreur lors de l'enregistrement des données"}
    })


  
  }

  public dismiss() {
    this.decline();
  }

  telFormat( ntl , type ) {
    console.log('ntel ' , ntl)
    if (type === 'exp') {
      this.infosFormGroup.patchValue( {
        telExpediteurCtrl: ntl.text
      } );
      this.maskTelExp.value = ntl.image;
      this.maskTelExp.mask = ntl.mask;
    } else {
      this.infosFormGroup.patchValue( {
        telephoneDestinataireCtrl: ntl.text
      } );
      this.masktelArr.value = ntl.image;
      this.masktelArr.mask = ntl.mask;
    }
  }


}
