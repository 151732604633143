
<div class="full-with">

    <app-header></app-header>


    <mat-drawer-container class="example-container" autosize>
        <mat-drawer #drawer class="example-sidenav" mode="side" [opened]="opened" >
            <app-sidebar></app-sidebar>
        </mat-drawer>

        <div class="example-sidenav-content">

            <button type="button" mat-button (click)="drawer.toggle() && eventToggle(drawer)" class="btn-menu-dash" >
                <i class="material-icons">menu</i> <span>{{txtBadge}}</span>
            </button> <br />


            <div class="content-in-div">
                <button type="button" mat-button (click)="getBack()" class="btn-menu-dash" >
                    <i class="material-icons">navigate_before</i> <span> Retourner à la liste</span>
                </button> <br />
                
                <div class="titre-page">
                    Consulter un utilisateur
                </div>

                <div *ngIf="utilisateurSelected">
                    <div class="row">
                        <div class="col-md-12">
                            <label>Utilisateur</label>
                        </div>
                    </div>
    
                    <div class="row">
                        <div class="col-md-2">
                            <img [src]="utilisateurSelected.image64  | safeHtml " class="image-table" />
                        </div>
                        <div class="col-md-10 text-right inter-div">
                            <span> Staut </span><br />
                            <span *ngIf="utilisateurSelected.actif == 1" class="active"> Actif </span>
                            <span *ngIf="utilisateurSelected.actif == 0" class="desactive"> Désactivé </span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6 inter-div">
                            <span> Nom et prénom </span><br />
                            {{utilisateurSelected.civilite}} {{utilisateurSelected.lastname}} {{utilisateurSelected.firstname}}
                        </div>
                        <div class="col-md-6 inter-div">
                            <span> Téléphone </span><br />
                            {{ utilisateurSelected.telephone | mask: maskNumTel.mask }}
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6 inter-div">
                            <span> E-mail</span><br />
                            {{ utilisateurSelected.email ? utilisateurSelected.email : '-' }}
                        </div>
                        <div class="col-md-6 inter-div">
                            <span> Adresse </span><br />
                            {{ utilisateurSelected.address }} {{ utilisateurSelected.zipecode }} {{ utilisateurSelected.city }}
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6 inter-div">
                            <span> Date de naissance</span><br />
                            {{ !!utilisateurSelected.datenais ? utilisateurSelected.datenais : '-' }}
                        </div>
                        <div class="col-md-6 inter-div">
                            <span> Adresse </span><br />
                            {{ utilisateurSelected.address }} {{ utilisateurSelected.zipecode }} {{ utilisateurSelected.city }}
                        </div>
                    </div>

                    <br /><br />
                    <div class="row">
                        <div class="col-md-6">
                            <button class="btn btn-valider" mat-button (click)="activerUtilisateur()" >Activer ou Désactivé l'utilisateur</button>
                        </div>
                    </div>

                    <br /><br />
                    <div class="row">
                        <div class="col-md-12">
                            <label>Points</label>
                        </div>
                    </div>

                    <form [formGroup]="utilisateurFormGroup" (ngSubmit)="savePoints()" >

                        <br />
                        <div class="row form-div ">
                            <div class="col-md-6">
                                <div class="form-group" >
                                    <label for="expeditionCtrl">Points expéditions  <span>(*)</span></label>
                                    <legend>Le nombre total des points d'expéditions </legend>
                                    <input id="expeditionCtrl" type="number" class="form-control" formControlName="expeditionCtrl" placeholder="Point expéditions" [ngClass]="{ 'is-invalid': submitted && f1.expeditionCtrl.errors }">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group" >
                                    <label for="recommandationCtrl">Points recommandations  <span>(*)</span></label>
                                    <legend>Le nombre total des points de recommandations </legend>
                                    <input id="recommandationCtrl" type="number" class="form-control" formControlName="recommandationCtrl" placeholder="Point recommandations" [ngClass]="{ 'is-invalid': submitted && f1.recommandationCtrl.errors }">
                                </div>
                            </div>
                        </div>

                        <div class="row" *ngIf="retourMessage" >
                            <div class="col-md-12 div-error" *ngIf="retourMessage.ko" >
                                {{retourMessage.ko}}
                            </div>
                            <div class="col-md-12 div-success" *ngIf="retourMessage.ok" >
                                {{retourMessage.ok}}
                            </div>
                            <br />
                        </div>

                        <div class="row">
                            <div class="col">
                                <div class="text-center">
                                    <button  class="btn-valider" type="submit" title="Enregistrer"> Enregistrer </button>
                                </div>
                            </div>
                        </div>
           
                        
                    </form>

                    <br />
                    <div class="row" *ngIf="utilisateurSelected && utilisateurSelected.pointclient">
                        <div class="col">
                            <div class="text-left modification">
                                <span>Dernière personne à avoir ajouter des points pour cet utilisateur : </span> {{utilisateurSelected?.pointclient[0].agent_msahilisho.username}}
                            </div>
                        </div>
                    </div>
                    <br /><br />

                </div>

            </div>

            

        </div>
    </mat-drawer-container>
    <app-footer></app-footer>

</div>
