import { Component, OnInit , Input  } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { GlobalService } from '../../services/global/global.service'

@Component({
  selector: 'app-confirm-multiple',
  templateUrl: './confirm-multiple.component.html',
  styleUrls: ['./confirm-multiple.component.scss']
})
export class ConfirmMultipleComponent implements OnInit {

  @Input() element: any;
  @Input() title: string;
  @Input() message: string;
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() btnRefuserText: string;


  constructor(
    private activeModal: NgbActiveModal,
    private globalService: GlobalService
  ) { }

  ngOnInit() {
    console.log( this.element )
  }

  public decline() {
    this.activeModal.close({etat: 'cancel'});
  }

  public accept() {
    this.activeModal.close({etat: 'accept'});
  }

  public modifier() {
    this.activeModal.close({etat: 'modifier'});
  }

  public terminer() {
    this.activeModal.close({etat: 'terminer'});
  }

  public dismiss() {
    this.decline();
  }

  public refuser() {
    this.activeModal.close({etat: 'refuse'});
  }

  downloadFile() {
    console.log(  'this.element.image64 ' , this.element.image64 )
    const name = "justificatif"
    const extensionFile = this.globalService.getExtentionBase64( this.element.image64 );
    this.globalService.downloadFile(
      `${this.element.image64}` ,
      `${name}-${this.globalService.makeid(10)}.${extensionFile}`
    )
  }

}
