import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialAngModule } from '../../matmodule/materialang.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EventsService } from '../../services/events/events.service'
import { GlobalService } from '../../services/global/global.service'
import { ValidatorrService } from '../../services/validatorr/validatorr.service'
import { BackendService } from '../../services/backend/backend.service';
import { UtilsService } from '../../services/utils/utils.service';
import { HeaderModule} from '../../header/header.module';
import { SharedModule } from '../../shared/shared.module';
import { FooterModule } from '../../footer/footer.module';
import { NgxMaskModule } from 'ngx-mask';
import { PrintSrvService } from 'src/app/services/print-srv/print-srv.service';
import { FlashInfoComponent } from './flash-info.component';
import { AngularEditorModule } from '@kolkov/angular-editor';


@NgModule({
  declarations: [
    FlashInfoComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    MaterialAngModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),

    HeaderModule,
    SharedModule,
    FooterModule,
    AngularEditorModule,
  ],
  providers: [
    EventsService,
    GlobalService,
    ValidatorrService,
    BackendService,
    UtilsService,
    PrintSrvService
  ]
})
export class FlashInfoModule { }
