

<div *ngIf="totalStatistique" >

    <div class="row">
     
        <div class="col-md-3 partie-sous-div">
            <div class="inner-div">
                <span class="titre"> <span class="material-icons">roundabout_right</span> Trajets </span>
                <div class="total"> {{totalStatistique.trajets}} </div>
            </div>
        </div>
    
        <div class="col-md-3 partie-sous-div">
            <div class="inner-div">
                <span class="titre"> <span class="material-icons">flight_land</span> Expéd' user </span>
                <div class="total"> {{totalStatistique.expeditions}} </div>
            </div>
        </div>
    
        <div class="col-md-3 partie-sous-div">
            <div class="inner-div">
                <span class="titre"> <span class="material-icons">flight_land</span> Expéd' admin</span>
                <div class="total"> {{totalStatistique.expednouser}} </div>
            </div>
        </div>
    
        <div class="col-md-3 partie-sous-div">
            <div class="inner-div">
                <span class="titre"> <span class="material-icons">luggage</span> Demandes Voyageurs </span>
                <div class="total"> {{totalStatistique.voyageurs}} </div>
            </div>
        </div>
    
         
     </div>
    
     <div class="row">
        <div class="col-md-3 partie-sous-div">
            <div class="inner-div">
                <span class="titre"> <span class="material-icons">people</span> Utilisateurs </span>
                <div class="total"> {{totalStatistique.utilisateurs}} </div>
            </div>
         </div>
    
        <div class="col-md-3 partie-sous-div">
            <div class="inner-div">
                <span class="titre"> <span class="material-icons">star_rate</span> Avis </span>
                <div class="total"> {{totalStatistique.avis}} </div>
            </div>
         </div>
    
         <div class="col-md-3 partie-sous-div">
            <div class="inner-div">
                <span class="titre"> <span class="material-icons">visibility</span> Visiteurs </span>
                <div class="total"> {{totalStatistique.visiteurs}} </div>
            </div>
         </div>
    
         <div class="col-md-3 partie-sous-div">
            <div class="inner-div">
                <span class="titre"> <span class="material-icons">phone_iphone</span> Installation app' </span>
                <div class="total"> {{totalStatistique.appli}} </div>
            </div>
         </div>
     </div>

</div>
