import { Component, OnInit , HostListener ,  ElementRef, ViewChild , AfterViewInit } from '@angular/core';

import {Location} from '@angular/common'
import { Router , ActivatedRoute } from '@angular/router';
import { MatDrawer } from '@angular/material';
import { EventsService } from 'src/app/services/events/events.service'
import { GlobalService } from 'src/app/services/global/global.service'
import { ValidatorrService } from 'src/app/services/validatorr/validatorr.service'
import { BackendService } from 'src/app/services/backend/backend.service'
import { ConfirmService } from 'src/app/modal/confirm/confirm.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-suivi-points',
  templateUrl: './suivi-points.component.html',
  styleUrls: ['./suivi-points.component.scss']
})
export class SuiviPointsComponent implements OnInit {

  userConnected
  urlImage
  opened = true
  txtBadge = "Afficher le menu"
  @ViewChild("drawer", {static: false}) drawer: MatDrawer ;

  retourMessage;
  loadFinished = false;
  utilisateurSelected;
  paramsPage;
  utilisateurFormGroup: FormGroup;
  submitted = false
  maskNumTel

  constructor(
    private router: Router,
    private eventsService: EventsService,
    private backendService: BackendService ,
    private globalService: GlobalService,
    private confirmService: ConfirmService,
    private route: ActivatedRoute,
    private _formBuilder: FormBuilder
  ) { 
    this.route.params.subscribe(routeParams => {
      console.log('Param router ::----- ' , routeParams)
      this.paramsPage = routeParams.user
      this.displayPage()
    });
    this.utilisateurFormGroup = this._formBuilder.group({
      expeditionCtrl: ['', Validators.required],
      recommandationCtrl: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.isConnectedUser()
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.mediaQuery()
    }, 1000);
  }
  isConnectedUser() {
    this.userConnected = this.globalService.getConnectedUser();
  }
  /** *********************  Sidenav **********************  */
  mediaQuery() {
    this.drawer.opened  = window.innerWidth > 900 ? true : false
    this.txtBadge = window.innerWidth > 900 ? "Cacher le menu" : "Afficher le menu"
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.mediaQuery()
  }
  eventToggle( drawer_ ){
    this.txtBadge = drawer_._opened ?  "Cacher le menu" : "Afficher le menu"
  }
  /** *********************  Sidenav **********************  */

  get f1() { 
    return this.utilisateurFormGroup.controls; 
  }

  displayPage() {
    this.loadFinished = false;
    this.backendService.get( `/users/user/${this.paramsPage}` )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.retourMessage = {ko: resultat.status != 0 ? resultat.error.error : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."}
      } else {
        console.log( resultat )
        this.utilisateurSelected = resultat[0];
        this.maskNumTel = this.globalService.maskSaisi( this.utilisateurSelected.telephone);
        this.initForm();
      }
      this.loadFinished = true
    })
    .catch( error => {
      this.loadFinished = true
      this.retourMessage = {ko: "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."}
    })
  }

  initForm() {
    if (this.utilisateurSelected.pointclient && this.utilisateurSelected.pointclient.length > 0 ) {
      this.utilisateurFormGroup.patchValue({
        expeditionCtrl: !!this.utilisateurSelected.pointclient[0].expedition ? this.utilisateurSelected.pointclient[0].expedition : '',
        recommandationCtrl: !!this.utilisateurSelected.pointclient[0].recommandation ? this.utilisateurSelected.pointclient[0].recommandation : ''
      })
    } else {
      this.utilisateurFormGroup.patchValue({
        expeditionCtrl: '',
        recommandationCtrl: ''
      })
    }
  }

  savePoints() {
    this.submitted = true
    this.retourMessage = null;
    console.log(this.utilisateurFormGroup)
    if (this.utilisateurFormGroup.invalid) {
      this.retourMessage = {ko: "Tous les champs sont obligatoires."}
      return;
    }

    let uuser = {
      expedition: this.f1.expeditionCtrl.value,
      recommandation: this.f1.recommandationCtrl.value,
      agent: this.globalService.getConnectedUser().id
    }

    console.log( 'saveDate ', uuser)
    this.backendService.post(`/users/edit-points-user/${this.paramsPage}`, uuser)
    .then( resultat => {
      console.log( 'resultatresultat::: ', resultat)
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.eventsService.errorsmsg( resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement." )
        this.retourMessage = {ko: "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."}
      } else {
        this.eventsService.successmsg( resultat.text )
        setTimeout( () => {
          this.displayPage()
          this.submitted = false
        }, 2000)
      }
    })
    .catch( error => {
      this.retourMessage = {ko: "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."}
    })
  }


  activerUtilisateur() {
    if ( !this.globalService.canEdit() ) {
      this.eventsService.toastrwaring("Vous ne disposez pas des autorisations requises pour cette action.")
      return;
    }
    
    this.confirmService.confirm(`Activer ou désactiver l'utilisateur N° [${this.utilisateurSelected.id}] ${this.utilisateurSelected.lastname} ${this.utilisateurSelected.firstname}`, 'Êtes-vous sûr de vouloir valider cette action ?', 'Activer', 'Anuler' , 'Désactiver' )
    .then( (confirmed : any) => {
      console.log( confirmed )
      if( confirmed.etat !== "cancel" ) {

        let user = {
          actif: confirmed.etat === "accept" ? 1 : 0,
          email: this.utilisateurSelected.email,
          agent: this.globalService.getConnectedUser().id,
          username: `${this.utilisateurSelected.lastname} ${this.utilisateurSelected.firstname}`
        }

        console.log( 'user' , user)
        this.backendService.post(`/users/activer-user`, user)
        .then( resultat => {
          console.log('Result ', resultat)
          if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
            this.eventsService.errorsmsg( resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement." )
          } else {
            this.eventsService.successmsg( resultat.text )
            setTimeout( () => {
              this.displayPage();
            }, 2000)
          }
        })
        .catch( error => {
          this.eventsService.errorsmsg( "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement!" )
        })
      }
      
    })
    .catch( () => {
    })
  }

  getBack(){
    this.router.navigate(['/les-utilisateurs/msahilisho/lister-les-utilisateurs/5-1'])
  }

}
