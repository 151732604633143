import { Component, OnInit , HostListener ,  ElementRef, ViewChild , AfterViewInit } from '@angular/core';

import {Location} from '@angular/common'
import { Router } from '@angular/router';
import { MatDrawer } from '@angular/material';
import { EventsService } from 'src/app/services/events/events.service'
import { GlobalService } from 'src/app/services/global/global.service'
import { ValidatorrService } from 'src/app/services/validatorr/validatorr.service'
import { BackendService } from 'src/app/services/backend/backend.service'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmService } from 'src/app/modal/confirm/confirm.service';

@Component({
  selector: 'app-impayes',
  templateUrl: './impayes.component.html',
  styleUrls: ['./impayes.component.scss']
})
export class ImpayesComponent implements OnInit {

  userConnected
  urlImage
  opened = true
  txtBadge = "Afficher le menu"
  @ViewChild("drawer", {static: false}) drawer: MatDrawer ;
  loadFinished = false;
  listeImpayes = []
  retourMessage;
  columnsToDisplay = ['nom_prenom', 'total_apayer', 'deja_paye', 'telephone', 'description', 'agent_msahilisho', 'action'];
  elementsDisplay = ['Nom et prénom', 'Total', 'Payé', 'Téléphone', 'Détail', 'Agent', 'Action'];

  impayesFormGroup: FormGroup;
  showFormulaire = true;
  editorConfig;
  submitted = false;
  editMode = false;
  editElementSelected;
  maskTel = {mask: "(+00) 0 00 00 00 00" , value: "france_.jpg"};
  listTelephone = [];

  constructor(
    private _formBuilder: FormBuilder,
    private location: Location ,
    private router: Router,
    private eventsService: EventsService,
    private backendService: BackendService,
    private globalService: GlobalService,
    private confirmService: ConfirmService
  ) { 
    this.impayesFormGroup = this._formBuilder.group({
      nom_prenomCtrl: [ '', [Validators.required, Validators.maxLength(140)]],
      total_apayerCtrl: ['', Validators.required],
      deja_payeCtrl: ['', Validators.required],
      telephoneCtrl: ['', Validators.required],
      descriptionCtrl: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.listTelephone = this.globalService.getListeIndicatifTelephone();
    this.editorConfig = this.globalService.getEditorConfig();
    this.isConnectedUser()
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.mediaQuery()
      this.displayPage()
      this.impayesFormGroup.patchValue( {
        telephoneCtrl: "33 "
      })
    }, 1000);
  }
  isConnectedUser() {
    this.userConnected = this.globalService.getConnectedUser();
  }
  /** *********************  Sidenav **********************  */
  mediaQuery() {
    this.drawer.opened  = window.innerWidth > 900 ? true : false
    this.txtBadge = window.innerWidth > 900 ? "Cacher le menu" : "Afficher le menu"
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.mediaQuery()
  }
  eventToggle( drawer_ ){
    this.txtBadge = drawer_._opened ?  "Cacher le menu" : "Afficher le menu"
  }
  /** *********************  Sidenav **********************  */

  get f() { 
    return this.impayesFormGroup.controls; 
  }
  

  telFormat( ntl ) {
    console.log('ntel ' , ntl)
    this.impayesFormGroup.patchValue( {
      telephoneCtrl: ntl.text
    } );
    this.maskTel.value = ntl.image;
    this.maskTel.mask = ntl.mask;
  }

  showOrHideFormulaure() {
    this.showFormulaire = !this.showFormulaire;
  }

  displayPage() {
    this.loadFinished = false;
    this.backendService.get(`/impayes/list` )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.listeImpayes = [];
        this.retourMessage = {ko: "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."}
      } else {
        console.log( resultat )
        this.listeImpayes = resultat;
      }
      this.loadFinished = true
    })
    .catch( error => {
      this.listeImpayes = [];
      this.loadFinished = true
      this.retourMessage = {ko: "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."}
    })
  }

  onSubmit() {
    this.submitted = true
    this.retourMessage = null;
    if (this.impayesFormGroup.invalid) {
      this.retourMessage = {ko: "Une erreur a été détectée"}
      return;
    }

    const formSend = {
      nom_prenom: this.f.nom_prenomCtrl.value,
      total_apayer: this.f.total_apayerCtrl.value,
      deja_paye: this.f.deja_payeCtrl.value,
      telephone: this.f.telephoneCtrl.value,
      description: this.f.descriptionCtrl.value,
      agent: this.globalService.getConnectedUser().id
    }
    console.log('Form Before Send ', formSend);

    if (!this.editMode) {
      this.addImpaye( formSend );
    } else {
      this.editImpaye( formSend );
    }
  }

  cancelEditMode() {
    this.editMode = false;
    this.resetFormulaire();
  }

  resetFormulaire() {
    this.retourMessage = null;
    this.submitted = false;
    this.editMode = false;
    this.impayesFormGroup.patchValue({
      nom_prenomCtrl: '',
      total_apayerCtrl: '',
      deja_payeCtrl: '',
      telephoneCtrl: '33',
      descriptionCtrl: ''
    });
    this.maskTel = {mask: "(+00) 0 00 00 00 00" , value: "france_.jpg"};
  }

  onTableAction( event) {
    console.log('onTableAction Table action ', event)
    const element = event.element
    this.editElementSelected = event.element;

    if (event.method === 'delete') {
      this.confirmDelete(element)
    } else if (event.method === 'update' ) {
      this.editMode = true;
      const elementOfUpdate = event.element;
      this.showFormulaire = true;
      console.log(  ' elementOfUpdate ', elementOfUpdate )
      this.maskTel = this.globalService.maskSaisi( elementOfUpdate.telephone )
      this.impayesFormGroup.patchValue({
        nom_prenomCtrl: elementOfUpdate.nom_prenom,
        total_apayerCtrl: elementOfUpdate.total_apayer,
        deja_payeCtrl: elementOfUpdate.deja_paye,
        telephoneCtrl: elementOfUpdate.telephone,
        descriptionCtrl: elementOfUpdate.description
      });
      console.log( this.impayesFormGroup )
      window.scroll(0,0);
    }
  }

  addImpaye( formSend ) {
    if ( !this.globalService.canAdd() ) {
      this.eventsService.toastrwaring("Vous ne disposez pas des autorisations requises pour cette action.")
      return;
    }
    this.backendService.post( `/impayes/add`, formSend )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.retourMessage = {ko: resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."}
      } else {
        this.retourMessage = {ok: resultat.text};
        setTimeout(() => {
          this.resetFormulaire();
          this.displayPage();
        }, 2000);
      }
    })
    .catch( error => {
      this.retourMessage = {ko: "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."}
    })
  }


  editImpaye( formSend ) {

    if ( !this.globalService.canEdit() ) {
      this.eventsService.toastrwaring("Vous ne disposez pas des autorisations requises pour cette action.")
      return;
    }
    this.backendService.post( `/impayes/update/${this.editElementSelected.id}`, formSend )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.retourMessage = {ko: resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."}
      } else {
        this.retourMessage = {ok: resultat.text};
        setTimeout(() => {
          this.resetFormulaire();
          this.displayPage();
        }, 2000);
      }
    })
    .catch( error => {
      this.retourMessage = {ko: "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."}
    })
  }

  confirmDelete(element) {
    this.retourMessage = null;
    this.backendService.get(`/impayes/delete/${element.id}`, )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.eventsService.errorsmsg( resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement." )
      } else {
        this.retourMessage = {ok: resultat.text};
        this.eventsService.successmsg( resultat.text );
        setTimeout( () => {
          this.displayPage();
        }, 2000)
      }
    })
    .catch( error => {
      this.retourMessage = {ko: "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."}
      this.eventsService.errorsmsg( "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement." )
    })
  }




}
