<div class="modal-header">
    <h4 class="modal-title"> {{title}} </h4>
    <button type="button" class="close" aria-label="Close" (click)="decline()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<form [formGroup]="fileFormGroup">

<div class="modal-body">

        <div class="example-container-">
            

                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group" >
                            <label for="importFile" #labelImport class="cursor" > <i class="material-icons">  search </i> {{urlSelectJustif.msg}}  <span>Téléchargez un fichier</span></label>
                            <legend>Il permet le suivi de la demande par notre équipe commerciale.</legend>
                             <input type="file" class="custom-file-input" formControlName="importFile" id="importFile" (change)="onFileChange($event)" [ngClass]="{ 'is-invalid': submitted && f.importFile.errors }">
                        </div>
                    </div>
                </div>
    
                <div class="row" *ngIf="progressMessage" >
                    <div class="col-md-12 div-error" *ngIf="progressMessage.ko" >
                        {{progressMessage.ko}}
                    </div>
                    <div class="col-md-12 div-success" *ngIf="progressMessage.ok" >
                        {{progressMessage.ok}}
                    </div>
                </div>

           

        </div>
  
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="decline()">{{ btnCancelText }}</button>
    <button type="button" class="btn btn-valider" (click)="valider()">{{ btnOkText }}</button>
</div>

</form>