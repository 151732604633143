import { Component, OnInit , HostListener ,  ElementRef, ViewChild , AfterViewInit } from '@angular/core';

import {Location} from '@angular/common'
import { Router } from '@angular/router';
import { MatDrawer } from '@angular/material';

import { EventsService } from '../services/events/events.service'
import { GlobalService } from '../services/global/global.service'
import { ValidatorrService } from '../services/validatorr/validatorr.service'
import { BackendService } from '../services/backend/backend.service'
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  userConnected
  urlImage

  opened = true
  txtBadge = "Afficher le menu"

  @ViewChild("drawer", {static: false}) drawer: MatDrawer ;

  messageretour;
  retourMessage: any;

  constructor(
    private location: Location ,
    private router: Router,
    private eventsService: EventsService,
    private backendService: BackendService ,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.isConnectedUser();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.mediaQuery()
      this.paramsFidelite();
    }, 1000);
  }

  isConnectedUser() {
    this.userConnected = this.globalService.getConnectedUser();
    console.log( ' userConnected ', this.userConnected)
  }

  mediaQuery() {
    this.drawer.opened  = window.innerWidth > 900 ? true : false
    this.txtBadge = window.innerWidth > 900 ? "Cacher le menu" : "Afficher le menu"
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.mediaQuery()
  }

  eventToggle( drawer_ ){
    this.txtBadge = drawer_._opened ?  "Cacher le menu" : "Afficher le menu"
  }


  synchoBase( url ) {

    let syncho = {
      agent: this.globalService.getConnectedUser().id,
      environment: environment.environment
    }
    this.backendService.post( `/majbdd/${url}`, syncho )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.messageretour = 'Aïe Karamba !'
      } else {
        console.log( 'resultat statistique ', resultat );
        this.messageretour = resultat.text;
        setTimeout(() => {
          this.messageretour = null;
        }, 3000);
      }
     })
     .catch( error => {
      this.messageretour = 'Aïe Karamba ! Y a quoi  !'
    })
  }


  paramsFidelite() {
    this.backendService.get( `/contact/footer` )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
      } else {
        this.globalService.setRemisecentPoints( resultat[0].remisecentpoints );
      }
     })
     .catch( error => {
    })
  }


  sendMailForAll() {
    const dateDuJour = new Date()
    const campagne = {
      campaignId: 34,
      params: {
        ANNEE_ENCOURS: dateDuJour.getFullYear()
      },
      listIds: [
        environment.listeDiffusionGlobale
      ]
    };
    console.log(campagne)

    this.backendService.post( `/emails/send-campaign-sans-notif` , campagne )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.retourMessage = {ko: resultat.status != 0 ? resultat.error.error : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."}
      } else {
        this.retourMessage = {ok: "La campagne est bien activée et est en cours d'exécution."};
        this.eventsService.successmsg( "La campagne est bien activée et est en cours d'exécution." )
      }
    })
    .catch( error => {
      this.retourMessage = {ko: "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."}
    })
  }


}
