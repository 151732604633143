import { Component, OnInit , HostListener ,  ElementRef, ViewChild , AfterViewInit } from '@angular/core';

import {Location} from '@angular/common'
import { Router } from '@angular/router';
import { MatDrawer } from '@angular/material';
import { EventsService } from 'src/app/services/events/events.service'
import { GlobalService } from 'src/app/services/global/global.service'
import { ValidatorrService } from 'src/app/services/validatorr/validatorr.service'
import { BackendService } from 'src/app/services/backend/backend.service'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmService } from 'src/app/modal/confirm/confirm.service';

@Component({
  selector: 'app-flash-info',
  templateUrl: './flash-info.component.html',
  styleUrls: ['./flash-info.component.scss']
})
export class FlashInfoComponent implements OnInit {

  userConnected
  urlImage
  opened = true
  txtBadge = "Afficher le menu"
  @ViewChild("drawer", {static: false}) drawer: MatDrawer ;
  loadFinished = false;
  listeFlashinfos = []
  retourMessage;
  columnsToDisplay = ['titre', 'text', 'afficher', 'image', 'action'];
  elementsDisplay = ['Titre', 'Contenu', 'Afficher ?', '', 'Action'];

  flashInfoFormGroup: FormGroup;
  statusList = [{statut: 'oui'}, {statut: 'non'}];

  urlSelectJustif = { url: '', msg: 'Image' };
  progressMessage = {progress: null, ok: null, ko: null};

  showFormulaire = true;
  editorConfig;
  submitted = false;
  editMode = false;

  constructor(
    private _formBuilder: FormBuilder,
    private location: Location ,
    private router: Router,
    private eventsService: EventsService,
    private backendService: BackendService,
    private globalService: GlobalService,
    private confirmService: ConfirmService
  ) { 
    this.flashInfoFormGroup = this._formBuilder.group({
      titreCtrl: [ '', [Validators.required, Validators.maxLength(140)]],
      textCtrl: ['', Validators.required],
      afficherCtrl: ['', Validators.required],
      importFile: ['']
    });
  }

  ngOnInit(): void {
    this.editorConfig = this.globalService.getEditorConfig();
    this.isConnectedUser()
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.mediaQuery()
      this.displayPage()
    }, 1000);
  }
  isConnectedUser() {
    this.userConnected = this.globalService.getConnectedUser();
  }
  /** *********************  Sidenav **********************  */
  mediaQuery() {
    this.drawer.opened  = window.innerWidth > 900 ? true : false
    this.txtBadge = window.innerWidth > 900 ? "Cacher le menu" : "Afficher le menu"
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.mediaQuery()
  }
  eventToggle( drawer_ ){
    this.txtBadge = drawer_._opened ?  "Cacher le menu" : "Afficher le menu"
  }
  /** *********************  Sidenav **********************  */

  get f() { 
    return this.flashInfoFormGroup.controls; 
  }

  onFileChange( event ) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      this.urlSelectJustif.url = event.target.files[0]
      reader.readAsDataURL(event.target.files[0]);
      this.urlSelectJustif.msg = event.target.files[0].name
    }
  }

  showOrHideFormulaure() {
    this.showFormulaire = !this.showFormulaire;
  }

  displayPage() {
    this.loadFinished = false;
    this.backendService.get(`/contact/list-flashinfo` )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.listeFlashinfos = [];
        this.retourMessage = {ko: "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."}
      } else {
        console.log( resultat )
        this.listeFlashinfos = resultat;
      }
      this.loadFinished = true
    })
    .catch( error => {
      this.listeFlashinfos = [];
      this.loadFinished = true
      this.retourMessage = {ko: "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."}
    })
  
  }
  onSubmit() {

    this.submitted = true
    this.progressMessage.ko = null;
    this.progressMessage.ok = null;
    this.progressMessage.progress = null;

    if (this.flashInfoFormGroup.invalid) {
      this.progressMessage.ko = "Une erreur a été détectée"
      return;
    }

    if ( this.urlSelectJustif.url === '' ) {
      this.progressMessage.ko = "La photo de présentation est obligatoire."
      return;
    }

    const formData = new FormData();
    formData.append('image', this.urlSelectJustif.url);
    formData.append('afficher', this.f.afficherCtrl.value);
    formData.append('text', this.f.textCtrl.value);
    formData.append('titre', this.f.titreCtrl.value);

    this.backendService.upload( formData, `/contact/new-flash`)
    .subscribe (
      (res) => {
        let uploadResponse = res;
        if( uploadResponse.status === 'error' ) {
          this.eventsService.errorsmsg( uploadResponse.message )
        } else {
          if( uploadResponse.status == 'progress' ) {
            this.progressMessage.progress = uploadResponse.message;
          }
          if( uploadResponse.text ) {
            this.progressMessage.ok = "Félicitations ! Votre flashinfo a été enregistrée avec succès!";
            setTimeout(() => {
              this.resetFormulaire()
              this.displayPage()
              window.scroll(0,0);
            }, 2000);
          }
        }
      }, (err) => {
        if( typeof err === "string")
          this.eventsService.errorsmsg( err )
          this.progressMessage.ko = "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
      }
    );
  
  }

  cancelEditMode() {
    this.editMode = false;
    this.resetFormulaire();
  }

  resetFormulaire() {
    this.retourMessage = null;
    this.progressMessage.ko = null;
    this.progressMessage.ok = null;
    this.progressMessage.progress = null;
    this.submitted = false;
    this.editMode = false;
    this.flashInfoFormGroup.patchValue({
      titreCtrl: '',
      textCtrl: '',
      afficherCtrl: '',
      importFile: ''
    });
  }

  onTableAction( event) {
    console.log('onTableAction Table action ', event)
    const element = event.element
    if (event.method === 'delete') {
      this.confirmDelete(element)
    } else if (event.method === 'confirm') {
      this.confirmerAfichage( element );
    }
  }

  confirmDelete(element) {
    this.retourMessage = null;
    this.backendService.get(`/contact/delete-flashinfo/${element.id}`, )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.eventsService.errorsmsg( resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement." )
      } else {
        this.retourMessage = {ok: resultat.text};
        this.eventsService.successmsg( resultat.text );
        setTimeout( () => {
          this.displayPage();
        }, 2000)
      }
    })
    .catch( error => {
      this.retourMessage = {ko: "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."}
      this.eventsService.errorsmsg( "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement." )
    })
  }


  confirmerAfichage( element ) {
    if ( !this.globalService.canEdit() ) {
      this.eventsService.toastrwaring("Vous ne disposez pas des autorisations requises pour cette action.")
      return;
    }

    let message = '';
    if( element.afficher === 'non' ) {
      message = `Afficher le flash info : ${element.titre}`
    } else {
      message = `Ne plus afficher le flash info : ${element.titre}`
    }
    
    this.confirmService.confirm(`${message}`, 'Êtes-vous sûr de vouloir valider cette action ?', 'Oui', 'Anuler' , 'Non' )
    .then( (confirmed : any) => {
      console.log( confirmed )
      if( confirmed.etat !== "cancel" ) {

        let etat = 'oui'
        if( element.afficher === 'non' && confirmed.etat === "accept" ) {
          etat = 'oui';
        } else if( element.afficher === 'non' && confirmed.etat !== "accept" ) {
          etat = 'non';
        } else if( element.afficher === 'oui' && confirmed.etat === "accept" ) {
          etat = 'non';
        } else if( element.afficher === 'oui' && confirmed.etat !== "accept" ) {
          etat = 'oui';
        }

        let statut = {
          afficher: etat,
        }

        console.log( 'statut afficge ' , statut)
        this.backendService.post(`/contact/edit-etatflash/${element.id}`, statut)
        .then( resultat => {
          console.log('Result ', resultat)
          if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
            this.eventsService.errorsmsg( resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement." )
          } else {
            this.eventsService.successmsg( resultat.text )
            setTimeout( () => {
              this.displayPage();
            }, 2000)
          }
        })
        .catch( error => {
          this.eventsService.errorsmsg( "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement!" )
        })
      }
      
    })
    .catch( () => {
    })
  }

}
