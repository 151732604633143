import { Component, OnInit , HostListener , ViewChild } from '@angular/core';

import {Location} from '@angular/common'
import { Router , ActivatedRoute } from '@angular/router';
import { MatDrawer } from '@angular/material';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';

import { EventsService } from '../../services/events/events.service'
import { GlobalService } from '../../services/global/global.service'
import { BackendService } from '../../services/backend/backend.service';
import { UtilsService } from '../../services/utils/utils.service';
import { ValidatorrService } from 'src/app/services/validatorr/validatorr.service';

@Component({
  selector: 'app-nos-equipes',
  templateUrl: './nos-equipes.component.html',
  styleUrls: ['./nos-equipes.component.scss']
})
export class NosEquipesComponent implements OnInit {

  userConnected

  opened = true
  txtBadge = "Afficher le menu"

  formulaireGroup: FormGroup;
  submitted = false;
  editMode = false;
  retourMessage = { ok: null, ko: null };
  lesEquipesArray = [];
  fonctionsList = []

  showFormulaire = true;
  urlImageSelect = { url: null, msg: 'Choisir une image', error: null }
  
  columnsToDisplay = ['id', 'nom_prenom', 'email', 'bio', 'photo', 'agent_msahilisho', 'action'];
  elementsDisplay = ['#', 'Nom et prénom', 'Email', 'Présentation', 'Photo', 'Éditer par', 'Action'];

  @ViewChild("drawer", {static: false}) drawer: MatDrawer ;

  editorConfig
  editElementSelected

  constructor(
    private location: Location ,
    private router: Router,
    private eventsService: EventsService,
    private backendService: BackendService ,
    private globalService: GlobalService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private utilsService: UtilsService
  ) { }

  ngOnInit(): void {

    this.editorConfig = this.globalService.getEditorConfig();
    this.fonctionsList = this.utilsService.getFonctionsList()

    this.displayPage();

    this.formulaireGroup = this.formBuilder.group({
      nomPrenomCtrl: [ '', [Validators.required, Validators.maxLength(160)]],
      emailCtrl: [ '', Validators.required],
      presentationCtrl: [ '', Validators.required],
      photoCtrl: [ ''],
      fonctionCtrl: [ '', Validators.required],
    });

    this.isConnectedUser()
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.mediaQuery()
    }, 1000);
  }

  isConnectedUser() {
    this.userConnected = this.globalService.getConnectedUser();
  }


  displayPage() {

    this.backendService.get( `/cguteams/teams` )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.retourMessage.ko = resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement.!"
      } else {
        console.log( 'resultat ', resultat );
        this.lesEquipesArray = resultat;
      }
     })
     .catch( error => {
      this.retourMessage.ko = "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement !"
    })
  }

  get f() { 
    return this.formulaireGroup.controls; 
  }

  cancelEditMode() {
    this.editMode = false;
    this.globalService.setModelValueToUpload( null );
    this.resetFormulaire();
  }

  resetFormulaire() {
    this.retourMessage.ok = null;
    this.retourMessage.ko = null;
    this.submitted = false;
    this.editMode = false;
    this.formulaireGroup.patchValue({
      nomPrenomCtrl: '',
      emailCtrl: '',
      presentationCtrl: '',
      photoCtrl: '',
      fonctionCtrl: ''
    });
    this.globalService.setModelValueToUpload(null);
    this.urlImageSelect = { url: '', msg: 'Choisir une image', error: null }
  }

  showOrHideFormulaure() {
    this.showFormulaire = !this.showFormulaire;
  }

  onFileChange( event ) {  //files: FileList   :::: $event.target.files 
    if (event.target.files && event.target.files[0]) {
       var reader = new FileReader();
       reader.readAsDataURL(event.target.files[0]);
       this.urlImageSelect.msg = event.target.files[0].name
       // Image to Base 64
       this.globalService.getBase64(event);
       setTimeout(() => {
        this.urlImageSelect.url = this.globalService.getModelValueToUpload();
       }, 1000);
    }
  }


  openAndUploadFile() {
    this.globalService.uploadImageFile()
    .then( resulta => {
      console.log( 'Le resultat attendu est :::: ', resulta );
      this.urlImageSelect.url = resulta
      this.urlImageSelect.msg = `${this.globalService.randomString(10)}.png`;
    } )
    .catch( error => {
      this.urlImageSelect.error = "Erreur lors du chargement de l'image"
    })
  }


  onTableAction( event ) {
    console.log('onTableAction Table action ', event)
    this.editElementSelected = event.element;

    if (event.method === 'update' ) {
      this.editMode = true;
      const elementOfUpdate = event.element;
      this.showFormulaire = true;
      console.log(  ' elementOfUpdate ', elementOfUpdate )

      this.formulaireGroup.patchValue({
        nomPrenomCtrl: elementOfUpdate.nom_prenom,
        emailCtrl: elementOfUpdate.email,
        presentationCtrl: elementOfUpdate.bio,
        photoCtrl: '',
        fonctionCtrl: parseInt(elementOfUpdate.titreNum),
      });
      console.log( this.formulaireGroup )

      if ( elementOfUpdate.photo !== '' ) {
        this.urlImageSelect.msg = `${this.utilsService.formatStringForURL( elementOfUpdate.nom_prenom, )}_${elementOfUpdate.id}.png`;
        this.urlImageSelect.url = elementOfUpdate.photo;
        this.globalService.setModelValueToUpload( elementOfUpdate.photo );
      }
      window.scroll(0,0);

    } else if (event.method === 'delete' ) {
      this.confirmDelete();
    }

  }


  onSubmit() {

    this.submitted = true;
    this.retourMessage.ok = null;
    this.retourMessage.ko = null;

    if (this.formulaireGroup.invalid) {
      this.retourMessage.ko = "Une erreur a été détectée sur le formulaire. Certains champs sont obligatoires !";
      return;
    }

    if ( this.globalService.getModelValueToUpload() === null || this.globalService.getModelValueToUpload() === "" ) {
      this.retourMessage.ko = "Une erreur a été détectée sur le formulaire. Certains champs sont obligatoires !";
      this.urlImageSelect.error = "No image selected"
      console.log( this.urlImageSelect )
      return;
    }

    const formSend = {
      nom_prenom: this.f.nomPrenomCtrl.value,
      email: this.f.emailCtrl.value,
      bio: this.f.presentationCtrl.value,
      image64: this.globalService.getModelValueToUpload(),
      titreNum: this.f.fonctionCtrl.value,
      agent: this.globalService.getConnectedUser().id
    }

    console.log('Form Before Send ', formSend);

    if (!this.editMode) {
      this.addTeam( formSend );
    } else {
      this.editTeam( formSend );
    }

  }


  addTeam( formSend ) {

    if ( !this.globalService.canAdd() ) {
      this.eventsService.toastrwaring("Vous ne disposez pas des autorisations requises pour cette action.")
      return;
    }

    this.backendService.post( `/cguteams/add-teams`, formSend )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.retourMessage.ko = resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
      } else {
        this.retourMessage.ok = resultat.text;
        setTimeout(() => {
          this.resetFormulaire();
          this.displayPage();
        }, 2000);
      }
    })
    .catch( error => {
      this.retourMessage.ko = "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
    })

  }


  editTeam( formSend ) {

    if ( !this.globalService.canEdit() ) {
      this.eventsService.toastrwaring("Vous ne disposez pas des autorisations requises pour cette action.")
      return;
    }

    this.backendService.post( `/cguteams/update-teams/${this.editElementSelected.id}`, formSend )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.retourMessage.ko = resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
      } else {
        this.retourMessage.ok = resultat.text;
        setTimeout(() => {
          this.resetFormulaire();
          this.displayPage();
        }, 2000);
      }
    })
    .catch( error => {
      this.retourMessage.ko = "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
    })

  }


  confirmDelete(){

    this.backendService.get(`/cguteams/delete-teams/${this.editElementSelected.id}`, )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.eventsService.errorsmsg( resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement." )
      } else {
        this.retourMessage.ok = resultat.text;
        setTimeout( () => {
          this.displayPage();
        }, 2000)
      }
    })
    .catch( error => {
      this.retourMessage.ko = "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
    })

  }


  /** *********************  Sidenav **********************  */
  mediaQuery() {
    this.drawer.opened  = window.innerWidth > 900 ? true : false
    this.txtBadge = window.innerWidth > 900 ? "Cacher le menu" : "Afficher le menu"
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.mediaQuery()
  }

  eventToggle( drawer_ ){
    this.txtBadge = drawer_._opened ?  "Cacher le menu" : "Afficher le menu"
  }

}
