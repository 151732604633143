import { Component, OnInit , Input  } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventsService } from 'src/app/services/events/events.service';
import { GlobalService } from 'src/app/services/global/global.service';
import { BackendService } from 'src/app/services/backend/backend.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PrintSrvService } from 'src/app/services/print-srv/print-srv.service';
import { jsPDF } from "jspdf";
import { LoaderService } from 'src/app/services/loader/loader.service';

@Component({
  selector: 'app-etablir-contrat',
  templateUrl: './etablir-contrat.component.html',
  styleUrls: ['./etablir-contrat.component.scss']
})
export class EtablirContratComponent implements OnInit {


  @Input() voyageurSelected: any
  @Input() suiviVoyage: any;
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  title : string = "Envoyer un contrat de voyage"

  messageRetour
  editorConfig

  formulaireGroup: FormGroup;
  submitted = false;
  contratGenerer = true;
  leReglageArray : any
  textAImprimer: any

  constructor(
    private activeModal: NgbActiveModal ,
    private backendService: BackendService ,
    private globalService: GlobalService,
    private eventsService: EventsService,
    private formBuilder: FormBuilder,
    private printSrvService: PrintSrvService,
    private loaderService: LoaderService
  ) { 
    this.formulaireGroup = this.formBuilder.group({
      contenuCtrl: [ '', Validators.required],
      emailCtrl: [ '', Validators.required]
    });
  }

  get f() { 
    return this.formulaireGroup.controls; 
  }

  ngOnInit(): void {
    this.editorConfig = this.globalService.getEditorConfig();
    this.initialiseValue();
    this.getParametrage();
  }

  public decline() {
    this.activeModal.close({status: false});
  }

  public onSubmit() {
    this.checkSbmit();

    let doc: any = new jsPDF('p', 'pt', 'a4', true);
    const fileName = `Contrat-Voyageur-${this.voyageurSelected.nom}-${this.voyageurSelected.prenom}.pdf`
    document.getElementById('partie-a-imprimer').style.display = 'block';

    setTimeout(() => {
      doc.html(document.getElementById('partie-a-imprimer'), {
        callback: async (pdf: any) => {

          const pdfToSave = await btoa(pdf.output());
          document.getElementById('partie-a-imprimer').style.display = 'none';
          this.loaderService.isLoading.next(false);

          let voyage = {
            bonjourDemandeurVoyageur: `Bonjour ${this.voyageurSelected.nom} ${this.voyageurSelected.prenom}`,
            trajetAllerRetour: `${this.voyageurSelected.aerodep} (${this.voyageurSelected.paysdep}) <=> ${this.voyageurSelected.aeroarr} (${this.voyageurSelected.paysarr})`,
            attachment: pdfToSave,
            agent: this.globalService.getConnectedUser().id,
            fileName: fileName,
            email: this.f.emailCtrl.value,
            idvoyageur: this.suiviVoyage.idvoyageur,
            idsuivi: this.suiviVoyage.id
          }
          console.log( voyage )
          this.backendService.post(`/emails/contrat-voyageur`, voyage )
          .then( resultat => {
            console.log('resultatresultatresultat ', resultat)
            if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
              this.messageRetour = {ko: resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement." }
              this.eventsService.errorsmsg( this.messageRetour.ko )
            } else {
              this.messageRetour = {ok: resultat.text}
              this.eventsService.successmsg( this.messageRetour.ok )
              setTimeout( () => {
                this.activeModal.close({status: true});
              }, 5000)
            }
          })
          .catch( error => {
            this.messageRetour = {ko: "Une erreur lors de l'enregistrement des données"}
            this.eventsService.errorsmsg( this.messageRetour.ko )
          })

        },
        x: 0,
        y: 0,
        autoPaging: 'text',
        margin: [30, 0, 30, 0]
      })
    }, 100);

  }

  checkSbmit() {
    this.submitted = true;
    this.messageRetour = null;
    this.loaderService.isLoading.next(true);

    if (this.formulaireGroup.invalid) {
      this.messageRetour = {ko: "Une erreur a été détectée sur le formulaire. Certains champs sont obligatoires !"};
      return;
    }
    this.contratGenerer = false;
    this.textAImprimer = this.f.contenuCtrl.value;
    this.textAImprimer = this.textAImprimer.replaceAll('&#10;   &#10;      &#10;', '');
    this.textAImprimer = this.textAImprimer.replaceAll('<p dir="ltr"><span><br></span></p>', '');
    this.textAImprimer = this.textAImprimer.replaceAll('<p dir="ltr"><span><b><br></b></span></p>', '');
  }

  public genererPDF() {
    this.checkSbmit();
    let doc: any = new jsPDF('p', 'pt', 'a4', true);
    const fileName = `Contrat-Voyageur-${this.voyageurSelected.nom}-${this.voyageurSelected.prenom}.pdf`
    document.getElementById('partie-a-imprimer').style.display = 'block';

    setTimeout(() => {
      doc.html(document.getElementById('partie-a-imprimer'), {
        callback: (pdf: any) => {
          pdf.save(fileName);
          document.getElementById('partie-a-imprimer').style.display = 'none';
          this.loaderService.isLoading.next(false);
        },
        x: 0,
        y: 0,
        autoPaging: 'text',
        margin: [30, 0, 30, 0]
      })
    }, 100);
  }

  initialiseValue() {

   let textContrat: any = `     
    <p dir="ltr"><span class="textTitreH4">Contrat De Partenariat Commercial À Durée Déterminée</span><span><br><br></span></p>
    &#10;   &#10;      &#10;   
    <p dir="ltr">La soci&#233;t&#233; <b>Msahilisho GROUP Import/Export SARL</b> au capital de 600.000KMF immatricul&#233;e sous le num&#233;ro <b>N&#176;320/20</b> du registre chronologique <b>N&#176;9510-B-20</b> &#224; Moroni (COMORES) du 17 septembre 2020 propose &#224; ces fid&#232;le client sur la base d&#8217;un tirage au sort ou par le biais d&#8217;une recommandation client&#232;le un achat de billet d&#8217;avion en contre partie des services mentionn&#233;s ci-dessous.</p>
    &#10;   &#10;      &#10;   
    <p dir="ltr"><span>Ci-apr&#232;s &#171;&#160;Les partenaires&#160;&#187;</span></p>
    &#10;   
    <p dir="ltr"><span><br></span></p>
    &#10;   &#10;      &#10;   
    <p dir="ltr"><span>D&#8217;une part.</span></p>
    &#10;   &#10;      &#10;   
    <p dir="ltr">La soci&#233;t&#233; <b>Msahilisho GROUP Import/Export SARL</b>, d&#233;nomm&#233;e par la suite Msahilisho GROUP, au capitale de 600.000KMF immatricul&#233;e sous le num&#233;ro N&#176;320/20 du registre chronologique N&#176;9510-B-20 &#224; Moroni (COMORES) du 17 septembre 2020 dont le si&#232;ge social est &#224; Moroni Djivani (Comores) est repr&#233;sent&#233;e par son Directeur Commercial et Marketing&#160;</p>  
    <p dir="ltr">Mr <b>BADAOUI</b> Abdou<br>+33 7 60 07 78 94<br><a><span>abdou.badaoui@msahilishogroup.com</span></a></p>
  
    <p dir="ltr"><span>D&#8217;autre part&#160;:</span></p>
    &#10;   &#10;      &#10;   
    <p dir="ltr">Le client <b>{{NOMCLIENT}}</b> n&#233; le {{DATENAISSCLIENT}}, domicili&#233; et r&#233;sidant &#224; l&#8217;adresse : {{ADRESSECLIENT}} par la suite VMS (Voyageur Msahilisho Group)</p>
    &#10;   &#10;      &#10;   
    <p dir="ltr"><span> {{TELCLIENT}} </span><span><br></span><span> {{EMAILCLIENT}} </span></p>
    &#10;   &#10;      &#10;   
    <p dir="ltr"><span>Il a &#233;t&#233; convenu ce qui suit&#160;:</span></p>
    &#10;   
    <p dir="ltr"><span><br></span></p>
    &#10;   &#10;      &#10;   
    <p dir="ltr"><span><b>Article 1&#160;: Pr&#233;sentation du concept</b></span></p>  
    <p dir="ltr"><span>Le concept d&#8217;achat de billet d&#8217;avion aux particuliers est un moyen de fid&#233;liser encore plus nos clients.&#160;</span></p>
    &#10;   &#10;      &#10;   
    <p dir="ltr"><span>Trois modalit&#233;s sont envisag&#233;es&#160;:&#160;</span></p>
    &#10;   &#10;      &#10;   
    <ol>
      &#10;      &#10;         &#10;      
      <li dir="ltr" aria-level="1">
          &#10;         &#10;            &#10;         
          <p dir="ltr" role="presentation"><span>Chaque semaine, l&#8217;&#233;quipe commerciale en pr&#233;sence de son Directeur fait un tirage au sort sur les clients qui ont fait exp&#233;dier au moins une fois un colis ou paquet par Msahilisho GROUP.</span></p>
          &#10;         &#10;         &#10;      
      </li>
      &#10;      &#10;      &#10;   
    </ol>
    &#10;   &#10;      &#10;   
    <p dir="ltr"><span>Le client gagnant est appel&#233; pour &#234;tre inform&#233; des modalit&#233;s sur l&#8217;offre de promotion du prix du billet d&#8217;avion. Ce dernier peut l&#8217;utiliser ou l&#8217;offrir &#224; un proche avant sa date d&#8217;expiration ou tout simplement refuser l&#8217;offre.</span></p>
    &#10;   &#10;      &#10;   
    <ol start="2">
      &#10;      &#10;         &#10;      
      <li dir="ltr" aria-level="1">
          &#10;         &#10;            &#10;         
          <p dir="ltr" role="presentation"><span>Un client super fid&#232;le nous recommande un voyageur sur la base d&#8217;un lien familiale, apr&#232;s constat, nous proc&#233;dons &#224; la discussion avec ledit voyageur afin de lui proposer l&#8217;offre.</span></p>
          &#10;         &#10;         &#10;      
      </li>
      &#10;      &#10;         &#10;      
      <li dir="ltr" aria-level="1">
          &#10;         &#10;            &#10;         
          <p dir="ltr" role="presentation"><span>Un client Msahilisho GROUP, peut simplement faire une demande de voyage de son plein gr&#233; afin de b&#233;n&#233;ficier l&#8217;offre promotionnelle en acceptant les conditions &#233;tablies par Msahilisho GROUP import/export.</span></p>
          &#10;         &#10;         &#10;      
      </li>
      &#10;      &#10;      &#10;   
    </ol>
    &#10;   &#10;      &#10;   
    <p dir="ltr"><span><b>Article 2&#160;: Offre de promotion</b><br><br>L&#8217;offre en question, c&#8217;est de b&#233;n&#233;ficier ou faire b&#233;n&#233;ficier&#160; un billet d&#8217;avion sur le trajet ({{TRAJET_ALLER_RETOUR}}) SANS BAGAGES. </span> </p>
    <p dir="ltr"><span><br></span></p>
    &#10;   &#10;      &#10;   
    <p dir="ltr"><span>En claire, vous ne b&#233;n&#233;ficiez que de la roulette (bagage cabine) et (un sac &#224; dos ou sac &#224; main) en ALLER &#160;({{TRAJET_ALLER}}) et en RETOUR &#160;({{TRAJET_RETOUR}}).</span></p><p> <span>Msahilisho Group va donc b&#233;n&#233;ficier l&#8217;ensemble des bagages en soute en ALLER&#160; ({{TRAJET_ALLER}})&#160;et en RETOUR&#160; ({{TRAJET_RETOUR}}).</span></p>
    &#10;   &#10;      &#10;   
    <p dir="ltr"><span>Toutefois, si le VMS a besoin de colis/kilos en plus, elle/il doit </span><span>OBLIGATOIREMENT</span><span> acheter les kilos aupr&#232;s de Msahilisho GROUP.&#160;</span></p>
    &#10;   
    <p dir="ltr"><span><br></span></p>
    &#10;   &#10;      &#10;   
    <p dir="ltr"><span><b>Article 3&#160;: Conditions de l&#8217;offre promotionnelle sp&#233;cifique</b></span></p>
    &#10;   
    <p dir="ltr"><span><b><br></b></span></p>
    &#10;   &#10;      &#10;   
    <p dir="ltr"><span>Le VMS s&#8217;acquittera d&#8217;une somme de <b>{{MONTANTAPYER}}&#8364;</b> en faveur de Msahilisho GROUP avant la confirmation du billet afin de b&#233;n&#233;ficier de son offre. </span><br><br>Apr&#232;s r&#233;ception du mail du pr&#233;sent contrat et dans le but de mieux nous organiser sur la gestion de votre voyage, sans retour de votre part dans un d&#233;lai de 72H (hors demande de voyage express) avec l&#8217;ensemble des pi&#232;ces justificatifs qui vont servir &#224; acheter les billets d&#8217;avion, nous ne garantirons pas ni le prix act&#233; dans ce pr&#233;sent contrat ni la date de votre s&#233;jour.&#160;</p>
    &#10;  
    <p dir="ltr"><span>Une &#233;ventuelle r&#233;vision du contrat pourrait &#234;tre envisag&#233;e. Apr&#232;s confirmation de l&#8217;achat du billet aucun remboursement n&#8217;est possible.</span></p>
    &#10;   
    <p dir="ltr"><span><br></span></p>
    &#10;   &#10;      &#10;   
    <p dir="ltr"><span>En cas d&#8217;annulation du voyage par le client apr&#232;s confirmation du billet, msahilishogroup se r&#233;serve le droit de demander le remboursement de la somme totale du billet hors promotion.</span></p>
    &#10;   
    <p dir="ltr"><span><br></span></p>
    &#10;   &#10;      &#10;   
    <p dir="ltr"><span><b>Article 4&#160;: Caution</b></span></p>
    &#10;   
    <p dir="ltr"><span><b><br></b></span></p>
    &#10;   &#10;      &#10;   
    <p dir="ltr"><span>Le VMS doit s&#8217;acquitter d&#8217;une somme de garantie sous forme de caution qui sera calcul&#233; en fonction du prix du billet. Cette caution lui sera int&#233;gralement restitu&#233;e d&#232;s lors qu&#8217;elle/il effectue son trajet avec succ&#232;s.</span></p>
    &#10;   
    <p dir="ltr"><span><br></span></p>
    &#10;   &#10;      &#10;   
    <p dir="ltr"><span>En effet, le VMS doit </span><span><b>RECUPERER</b></span><span> &#224; son arriv&#233; l&#8217;ensemble des bagages qui lui ont &#233;t&#233; confi&#233;s (</span><span>des photos des bagages lors du d&#233;part sont recommand&#233;es</span><span>) &#224; l&#8217;a&#233;roport d&#8217;arriv&#233; afin de remettre &#224; notre correspondant qui lui attendra devant la porte de sortie avec la somme total de la garantie (caution).</span></p>
    &#10;   
    <p dir="ltr"><span><br></span></p>
    &#10;   &#10;      &#10;   
    <p dir="ltr"><span><b>Article 5&#160;: Responsabilit&#233; de Msahilisho GROUP</b></span></p>
    &#10;   
    <p dir="ltr"><span><b><br></b></span></p>
    &#10;   &#10;      &#10;   
    <p dir="ltr"><span>Msahilisho GROUP a la responsabilit&#233; de veiller aux bons d&#233;roulements administratifs et financiers suivants&#160;:</span></p>
    &#10;   &#10;      &#10;   
    <ul>
      &#10;      &#10;         &#10;      
      <li dir="ltr" aria-level="1">
          &#10;         &#10;            &#10;         
          <p dir="ltr" role="presentation"><span>Achat du billet au nom du </span><span>VMS.</span></p>
          &#10;         &#10;         &#10;      
      </li>
      &#10;      &#10;         &#10;      
      <li dir="ltr" aria-level="1">
          &#10;         &#10;            &#10;         
          <p dir="ltr" role="presentation"><span>Pr&#233;parer les colis et mettre dans des valises ou sacs.</span></p>
          &#10;         &#10;         &#10;      
      </li>
      &#10;      &#10;         &#10;      
      <li dir="ltr" aria-level="1">
          &#10;         &#10;            &#10;         
          <p dir="ltr" role="presentation"><span>Transporter les colis jusqu&#8217;&#224; l&#8217;a&#233;roport de d&#233;part.</span></p>
          &#10;         &#10;         &#10;      
      </li>
      &#10;      &#10;         &#10;      
      <li dir="ltr" aria-level="1">
          &#10;         &#10;            &#10;         
          <p dir="ltr" role="presentation"><span>Accompagner </span><span>VMS</span><span> sur les modalit&#233;s d&#8217;enregistrements &#224; l&#8217;a&#233;roport.</span></p>
          &#10;         &#10;         &#10;      
      </li>
      &#10;      &#10;         &#10;      
      <li dir="ltr" aria-level="1">
          &#10;         &#10;            &#10;         
          <p dir="ltr" role="presentation"><span>Pay&#233; tout exc&#232;dent et tout impr&#233;vu sur les bagages.</span></p>
          &#10;         &#10;         &#10;      
      </li>
      &#10;      &#10;         &#10;      
      <li dir="ltr" aria-level="1">
          &#10;         &#10;            &#10;         
          <p dir="ltr" role="presentation"><span>Donner la possibilit&#233; au VMS de venir assister &#224; la pr&#233;paration des colis afin de voir ce qui est mis dans les bagages pour plus de transparence et de confiance.</span></p>
          &#10;         &#10;         &#10;      
      </li>
      &#10;      &#10;         &#10;      
      <li dir="ltr" aria-level="1">
          &#10;         &#10;            &#10;         
          <p dir="ltr" role="presentation"><span>Offrir le bagage &#224; main (Roulette) au </span><span>VMS</span><span> et le sac &#224; dos/sac &#224; main (ALLER &#8211; RETOUR) sauf indication contraire.</span></p>
          &#10;         &#10;         &#10;      
      </li>
      &#10;      &#10;      &#10;   
    </ul>
    &#10;   &#10;      &#10;   
    <p dir="ltr"><span><b>Article 6&#160;: Responsabilit&#233; du voyageur (VMS)</b></span></p>
    &#10;   
    <p dir="ltr"><span><b><br></b></span></p>
    &#10;   &#10;      &#10;   
    <ul>
      &#10;      &#10;         &#10;      
      <li dir="ltr" aria-level="1">
          &#10;         &#10;            &#10;         
          <p dir="ltr" role="presentation"><span>Avoir l&#8217;ensemble des documents de voyage &#224; jour</span><span> (Test PCR, Passeport, Motif imp&#233;rieux &#8230;)</span></p>
          &#10;         &#10;         &#10;      
      </li>
      &#10;      &#10;         &#10;      
      <li dir="ltr" aria-level="1">
          &#10;         &#10;            &#10;         
          <p dir="ltr" role="presentation"><span>Arriver &#224; l&#8217;heure &#224; l&#8217;a&#233;roport le jour du d&#233;part&#160; pr&#233;vu</span><span> (sans cela toutes cons&#233;quences seront &#224; la charge du VMS &#8211; Remboursement de la totalit&#233; des d&#233;penses).</span></p>
          &#10;         &#10;         &#10;      
      </li>
      &#10;      &#10;         &#10;      
      <li dir="ltr" aria-level="1">
          &#10;         &#10;            &#10;         
          <p dir="ltr" role="presentation"><span>Veiller &#224; la bonne ex&#233;cution des consignes de la compagnie a&#233;rienne et la surveillance des bagages afin de pr&#233;server l&#8217;ensemble des colis.</span></p>
          &#10;         &#10;         &#10;      
      </li>
      &#10;      &#10;         &#10;      
      <li dir="ltr" aria-level="1">
          &#10;         &#10;            &#10;         
          <p dir="ltr" role="presentation"><span>Ne doit pas accepter des colis ou paquets suppl&#233;ments pour un tiers </span><span>(hors Msahilisho GROUP)</span></p>
          &#10;         &#10;         &#10;      
      </li>
      &#10;      &#10;         &#10;      
      <li dir="ltr" aria-level="1">
          &#10;         &#10;            &#10;         
          <p dir="ltr" role="presentation"><span>Ne peut pas acheter des kilos suppl&#233;ments hors Msahilisho GROUP.</span></p>
          &#10;         &#10;         &#10;      
      </li>
      &#10;      &#10;         &#10;      
      <li dir="ltr" aria-level="1">
          &#10;         &#10;            &#10;         
          <p dir="ltr" role="presentation"><span>Veiller &#224; la r&#233;cup&#233;ration des colis &#224; l&#8217;a&#233;roport (arriv&#233;e) et donner &#224; notre correspondant.</span></p>
          &#10;         &#10;         &#10;      
      </li>
      &#10;      &#10;         &#10;      
      <li dir="ltr" aria-level="1">
          &#10;         &#10;            &#10;         
          <p dir="ltr" role="presentation"><span>Dans le cas o&#249; les colis ne sont pas arriv&#233;s, effectu&#233;s les d&#233;marches administratives li&#233;es &#224; la d&#233;claration jusqu&#8217;&#224; la r&#233;cup&#233;ration des colis. Une preuve de d&#233;claration va faire foi sur les d&#233;marches effectu&#233;es.</span></p>
          &#10;         &#10;         &#10;      
      </li>
      &#10;      &#10;         &#10;      
      <li dir="ltr" aria-level="1">
          &#10;         &#10;            &#10;         
          <p dir="ltr" role="presentation"><span>Accepter dans la mesure du possible des objets tels que (Argent, T&#233;l&#233;phone, Ordinateur &#8230;)</span></p>
          &#10;         &#10;         &#10;      
      </li>
      &#10;      &#10;         &#10;      
      <li dir="ltr" aria-level="1">
          &#10;         &#10;            &#10;         
          <p dir="ltr" role="presentation"><span>D&#233;finir le choix du lieu de r&#233;cup&#233;ration des Colis retour ({{TRAJET_RETOUR}})</span></p>
          &#10;         &#10;         &#10;      
      </li>
      &#10;      &#10;      &#10;   
    </ul>
    &#10;   &#10;      <br>&#10;      &#10;   
    <p dir="ltr"><span><b>Article 7&#160;: Dur&#233;e l&#233;gal de r&#233;traction</b></span></p>
    &#10;   
    <p dir="ltr"><span><br></span></p>
    &#10;   &#10;      &#10;   
    <p dir="ltr"><span>Le client a le droit de renoncer &#224; l&#8217;offre. Le VMS dispose d&#8217;une dur&#233;e de 24 heures avant la confirmation et r&#233;ception de l&#8217;argent par Msahilisho GROUP. Passer ces d&#233;lais, c&#8217;est les dispositions de l&#8217;article 3 qui s&#8217;appliquent.</span></p>
    &#10;   
    <p dir="ltr"><span><br></span></p>
    &#10;   &#10;      &#10;   
    <p dir="ltr"><span><b>Article 8&#160;: Garantie, responsabilit&#233; et obligation&#160;</b></span></p>
    &#10;   
    <p dir="ltr"><span><br></span></p>
    &#10;   &#10;      &#10;   
    <p dir="ltr"><span>Msahilisho GROUP n&#8217;est pas une agence &#233;mettrice de billet d&#8217;avion, nous nous chargeons d&#8217;acheter un billet d&#8217;avion valide et valable sur le s&#233;jour demand&#233; au nom du VMS. La validit&#233; du billet est v&#233;rifiable directement sur le site internet de la compagnie a&#233;rienne ou &#224; l&#8217;agence le plus proche.</span></p>
    &#10;   
    <p dir="ltr"><span><br></span></p>
    &#10;   &#10;      &#10;   
    <p dir="ltr"><span>Msahilisho GROUP se d&#233;charge de toutes responsabilit&#233;s li&#233;es aux conditions d&#8217;entr&#233;es ou de sorties (conditions sanitaires, ou autres) dans les pays de destination et de provenance.</span></p>
    &#10;   
    <p dir="ltr"><span><br></span></p>
    &#10;   &#10;      &#10;   
    <p dir="ltr"><span>Le VMS peut modifier son billet &#224; sa guise et partir avant ou apr&#232;s la date mentionn&#233;e, tous frais suppl&#233;mentaires sont int&#233;gralement (100%) &#224; la charge du VMS ainsi le VMS &#224; l&#8217;obligation d&#8217;informer un agent Msahilisho GROUP par &#233;crit ou envoyer un mail &#224; </span><a><span><b><i>contact@msahilishogroup.com</i></b></span></a><span> 7 jours avant afin que nous puissions reprogrammer le trajet.</span></p>
    &#10;   
    <p dir="ltr"><span><br></span></p>
    &#10;   &#10;      &#10;   
    <p dir="ltr"><span><b>Article 9&#160;: Coronavirus et autres</b></span></p>
    &#10;   
    <p dir="ltr"><span><b><br></b></span></p>
    &#10;   &#10;      &#10;   
    <p dir="ltr"><span>Depuis quelques temps, les mesures de s&#233;curit&#233; prises par les gouvernements obligent les voyageurs vaccin&#233;s ou non vaccin&#233;s de se faire d&#233;pister (TEST PCR ANTI COVID-19 48h avant le d&#233;part). Les frais d&#233;bours&#233;s &#224; cet effet seront &#224; 100% &#224; la charge du VMS.</span></p>
    &#10;   &#10;      &#10;   
    <ul>
      &#10;      &#10;         &#10;      
      <li dir="ltr" aria-level="1">
          &#10;         &#10;            &#10;         
          <p dir="ltr" role="presentation"><span>Si r&#233;sultat PCR est NEGATIF, le VMS peut voyager</span></p>
          &#10;         &#10;         &#10;      
      </li>
      &#10;      &#10;         &#10;      
      <li dir="ltr" aria-level="1">
          &#10;         &#10;            &#10;         
          <p dir="ltr" role="presentation"><span>Sinon le VMS ne peut pas voyager</span></p>
          &#10;         &#10;         &#10;      
      </li>
      &#10;      &#10;      &#10;   
    </ul>
    &#10;   &#10;      &#10;   
    <p dir="ltr"><span>Dans le cas o&#249; le VMS est test&#233; POSITIF, le VMS doit faire lui-m&#234;me les proc&#233;dures afin de reporter son voyage aupr&#232;s de la compagnie a&#233;rienne (Ethiopien Airlines) ou (Kenya Airways) en se rendant directement &#224; l&#8217;agence de </span><span>PARIS / MARSEILLE / MORONI</span><span> avec son billet d&#8217;avion.</span><span><br></span><span><br></span><span>Tous les frais qui seront potentiellement demand&#233;s par la compagnie a&#233;rienne pour le report de son voyage seront &#224; int&#233;gralement (100%) &#224; la charge du VMS.</span><span><br></span><span><br></span><span>Tout autre frais qui engendre un report de voyage (avec un motif valable) en lien directe avec le VMS sera &#224; 100% &#224; la charge du VMS.</span></p>
    &#10;   
    <p dir="ltr"><span><br></span></p>
    &#10;   &#10;      &#10;   
    <p dir="ltr"><span>Msahilisho GROUP interviendra dans le seul cas o&#249; il y aurait un&#160;probl&#232;me sur le billet avec la compagnie a&#233;rienne et devra d&#233;bourser la totalit&#233; des frais pr&#233;vus afin de le r&#233;gulariser.</span></p>
    &#10;   
    <p dir="ltr"><span><br></span></p>
    &#10;   &#10;      &#10;   
    <p dir="ltr"><span><b>Article 10&#160;: Dur&#233;e du pr&#233;sent contrat (peu vari&#233;e en fonction des al&#233;as)</b></span></p>
    &#10;   
    <p dir="ltr"><span><br></span></p>
    &#10;   &#10;      &#10;   
    <p dir="ltr"><span>Ce contrat prend effet &#224; partir de sa date de signature pour une dur&#233;e &#233;gale correspondant &#224; la dur&#233;e du s&#233;jour {{DATESEJOUR}}</span><span>.</span></p>
    &#10;   
    <p dir="ltr"><span><br></span></p>
    &#10;   &#10;      &#10;   
    <p dir="ltr"><span><b>Article 11&#160;: Point de rendez-vous</b></span></p>
    &#10;   
    <p dir="ltr"><span><b><br></b></span></p>
    &#10;   &#10;      &#10;   
    <p dir="ltr"><span>Apr&#232;s confirmation, signature du contrat et validation du voyage, Msahilisho GROUP donne rendez-vous au VMS &#224; l&#8217;a&#233;roport de d&#233;part (pour un d&#233;part) 30min avant le d&#233;but de l&#8217;enregistrement soit 16h30 en hiver et 17h30 en &#233;t&#233; pour (MARSEILLE/PARIS) et &#224; 12h00 pour (MORONI) &#224; titre d'exemple.</span><span><br></span><span><br></span><span>Msahilisho GROUP se chargera d&#8217;apporter les colis (bagages en soute) jusqu&#8217;&#224; l&#8217;a&#233;roport ainsi le VMS quant &#224; lui apportera ses affaire personnel, dans la plus part des cas la roulette (10KG) et son sac &#224; dos ou sac &#224; main.</span></p>
    &#10;   
    <p dir="ltr"><span><br><br></span></p>
    &#10;   &#10;      &#10;   
    <p dir="ltr"><span><b>Article 12&#160;: Non-respect du contrat</b></span></p>
    &#10;   
    <p dir="ltr"><span><b><br></b></span></p>
    &#10;   &#10;      &#10;   
    <p dir="ltr"><span>D&#8217;une part, en cas du non-respect du contrat par le VMS, Msahilisho GROUP se r&#233;serve le droit de demander le remboursement total des d&#233;penses et recettes sur le trajet.</span></p>
    &#10;   
    <p dir="ltr"><span><br></span></p>
    &#10;   &#10;      &#10;   
    <p dir="ltr"><span>D&#8217;autre part en cas du non-respect de l&#8217;article 5 par Msahilisho group, le voyageur a le droit de demander un d&#233;dommagement pour l&#8217;ensemble des d&#233;penses engendr&#233;es sur le trajet.</span></p>
    &#10;   &#10;   &#10;   <br class="Apple-interchange-newline">`;

   console.log(this.voyageurSelected)
   console.log(this.suiviVoyage)
   
   textContrat = textContrat.replaceAll('{{NOMCLIENT}}', `${this.voyageurSelected.nom.toUpperCase()} ${this.voyageurSelected.prenom}`);
   textContrat = textContrat.replaceAll('{{ADRESSECLIENT}}', `<b>${this.voyageurSelected.adresseresid}</b>`);
   textContrat = textContrat.replaceAll('{{DATENAISSCLIENT}}', `<b>${this.globalService.unFormateDateContrat(this.voyageurSelected.datenaiss)}</b>`);
   textContrat = textContrat.replaceAll('{{TELCLIENT}}', `${this.voyageurSelected.numTel}`);
   textContrat = textContrat.replaceAll('{{EMAILCLIENT}}', `${this.voyageurSelected.email}`);
   textContrat = textContrat.replaceAll('{{TRAJET_ALLER}}', `<b>${this.voyageurSelected.aerodep} => ${this.voyageurSelected.aeroarr}</b>`);
   textContrat = textContrat.replaceAll('{{TRAJET_RETOUR}}', `<b>${this.voyageurSelected.aeroarr} => ${this.voyageurSelected.aerodep}</b>`);
   textContrat = textContrat.replaceAll('{{TRAJET_ALLER_RETOUR}}', `<b>${this.voyageurSelected.aerodep} (${this.voyageurSelected.paysdep}) <=> ${this.voyageurSelected.aeroarr} (${this.voyageurSelected.paysarr})</b>`);


   textContrat = textContrat.replaceAll('{{MONTANTAPYER}}', `${this.suiviVoyage.montantaregler}`);
   textContrat = textContrat.replaceAll('{{DATESEJOUR}}', `<b>du ${this.globalService.unFormateDateContrat(this.suiviVoyage.datedepart)} au ${this.globalService.unFormateDateContrat(this.suiviVoyage.dateretour)}</b>`);

    this.formulaireGroup.patchValue({
      contenuCtrl: textContrat,
      emailCtrl: this.voyageurSelected.email
    })
  }


  getParametrage() {
    this.backendService.get( `/contact/footer` )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
      } else {
        console.log( 'resultat ', resultat );
        this.leReglageArray = resultat[0];
        this.leReglageArray.telephone = this.leReglageArray.telephone.replaceAll('<br />', ' / ')
        this.leReglageArray.telcomores = this.leReglageArray.telcomores.replaceAll('<br />', ' / ')
      }
     })
     .catch( error => {
    })
  }

}
