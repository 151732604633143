

<div class="footerCopyright">
    <div class="row">
        <div class="col-md-12">
            
            <div class="copyright">
                &copy; Copyright <strong>Msahilisho Group v. {{versionApplication}}</strong>. Tous droits réservés.
              </div>
              <div class="credits">
                Une création de <a href=""> Msahilisho Group <img src="assets/imgs/msahilisho.png" width="100px" /> </a> et <a href="https://hachamco.fr/"> Hacham & Co <img src="assets/imgs/hachamco.png" width="120px" /> </a>
            </div>
    
        </div>
    </div>
</div>
