
<div class="full-with">

    <app-header></app-header>


    <mat-drawer-container class="example-container" autosize>
        <mat-drawer #drawer class="example-sidenav" mode="side" [opened]="opened" >
            <app-sidebar></app-sidebar>
        </mat-drawer>

        <div class="example-sidenav-content">

            <button type="button" mat-button (click)="drawer.toggle() && eventToggle(drawer)" class="btn-menu-dash" >
                <i class="material-icons">menu</i> <span>{{txtBadge}}</span>
            </button> <br />


            <div class="content-in-div">

                <div class="row">
                    <div class="col-12 text-center text-h4 colorBleu">
                        Bienvenue sur le nouvel espace d'administration Msahilisho Group
                    </div>
                </div>

                <app-statistique></app-statistique>

                <div class="info-div">
                    <span class="material-icons">info</span> : À chaque envoi de mail, une copie est envoyée à cette adresse : <b> copie.emails@msahilishogroup.com / Msahilisho!2021C </b> <br />
                    Je vous invite fortement à consulter ce mail, pour vérifier les échanges de mails Msahilisho <-> Utilisateurs.
                    <br />
                    Pour toute demande, réservation, envoi, récupurération ... etc avec un client sans adresse e-mail, veuillez utiliser celui-ci :
                    <b> reservation@msahilishogroup.com / Msahilisho!2021Reserv </b> <br /><br />
                </div>


                <div class="row">
                    <div class="col-12 image-dashboard">
                        <img src="assets/imgs/visualdata.svg" />
                    </div>
                </div>

                <div *ngIf="userConnected.niveau == 4">
                    <!--<button class="btn btn-valider" (click)="synchoBase('syncrouser')" > Initialisation des points utilisateurs </button>-->
                    <button class="btn btn-valider" (click)="synchoBase('synchocontact')" > Syncho contact dans Sendinblue</button>
                    <button class="btn btn-valider" (click)="sendMailForAll()" > Envoyer Mail Augmentation des Prix</button>
                    <!--<button class="btn btn-valider" (click)="synchoBase('majnumtel')" > Formattage n° tel </button>-->
                    <!--<button class="btn btn-valider" (click)="synchoBase('image64')" > Formattage image base 64 </button>-->
                    <button class="btn btn-valider" (click)="synchoBase('endscripts')" > Terminer trajet sendbox date + 1 j </button>
                </div>

                <!-- <button *ngIf="userConnected.email === 'info@hachamco.fr'" class="btn btn-valider" (click)="executerScritpBdd()" > Exécuter le cript bdd </button> -->
                <div class="col-md-12 div-success" *ngIf="messageretour" >
                    {{messageretour}}
                </div>

            </div>

        </div>
    </mat-drawer-container>

    <app-footer></app-footer>
</div>
