import { Component, OnInit , HostListener ,  ElementRef, ViewChild , AfterViewInit } from '@angular/core';

import {Location} from '@angular/common'
import { Router } from '@angular/router';
import { MatDrawer } from '@angular/material';
import { EventsService } from 'src/app/services/events/events.service'
import { GlobalService } from 'src/app/services/global/global.service'
import { ValidatorrService } from 'src/app/services/validatorr/validatorr.service'
import { BackendService } from 'src/app/services/backend/backend.service'

import {FormControl, FormGroup, Validators, FormBuilder} from '@angular/forms';

@Component({
  selector: 'app-send-mail',
  templateUrl: './send-mail.component.html',
  styleUrls: ['./send-mail.component.scss']
})
export class SendMailComponent implements OnInit {

  userConnected
  urlImage
  opened = true
  txtBadge = "Afficher le menu"
  @ViewChild("drawer", {static: false}) drawer: MatDrawer ;
  urlSelectJustif: any;

  retourMessage;
  formulaireGroup: FormGroup;
  editorConfig;
  submitted = false;

  constructor(
    private location: Location ,
    private router: Router,
    private eventsService: EventsService,
    private backendService: BackendService ,
    private globalService: GlobalService,
    private formBuilder: FormBuilder
  ) { 
    this.formulaireGroup = this.formBuilder.group({
      contenuCtrl: [ '', Validators.required],
      emailCtrl: [ '', Validators.required],
      titreCtrl: [ '', Validators.required],
      importFile: ['']
    });
    this.urlSelectJustif = { url: '', msg: 'Pièce jointe' };
  }

  ngOnInit(): void {
    this.editorConfig = this.globalService.getEditorConfig();
    this.isConnectedUser()
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.mediaQuery()
    }, 1000);
  }
  isConnectedUser() {
    this.userConnected = this.globalService.getConnectedUser();
  }
  /** *********************  Sidenav **********************  */
  mediaQuery() {
    this.drawer.opened  = window.innerWidth > 900 ? true : false
    this.txtBadge = window.innerWidth > 900 ? "Cacher le menu" : "Afficher le menu"
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.mediaQuery()
  }
  eventToggle( drawer_ ){
    this.txtBadge = drawer_._opened ?  "Cacher le menu" : "Afficher le menu"
  }
  /** *********************  Sidenav **********************  */

  get f() { 
    return this.formulaireGroup.controls; 
  }

  resetFormulaire() {
    this.retourMessage = null;
    this.submitted = false;
    this.formulaireGroup.patchValue({
      titreCtrl: '',
      contenuCtrl: '',
      emailCtrl: ''
    })
  }

  onSubmit() {

    if ( !this.globalService.canAdd() ) {
      this.eventsService.toastrwaring("Vous ne disposez pas des autorisations requises pour cette action.")
      return;
    }
    this.submitted = true;
    this.retourMessage = null;

    if (this.formulaireGroup.invalid) {
      this.retourMessage = {ko: "Une erreur a été détectée sur le formulaire. Tous les champs sont obligatoires !"};
      return;
    }

    const formSend = {
      titre: this.f.titreCtrl.value,
      contenu: this.f.contenuCtrl.value,
      email: this.f.emailCtrl.value,
      agent: this.globalService.getConnectedUser().id,
      piecejointe: !!this.urlSelectJustif.url ? this.urlSelectJustif.url : null,
      filename: !!this.urlSelectJustif.msg ? this.urlSelectJustif.msg : null
    }

    
    console.log('Form send ' , formSend )
    this.backendService.post( `/emails/send-email`, formSend )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.retourMessage = {ko: resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."}
      } else {
        this.retourMessage = {ok: resultat.text};
        setTimeout(() => {
          this.resetFormulaire();
        }, 2000);
      }
     })
     .catch( error => {
      this.retourMessage = {ko: "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."}
    })

  }


  onFileChange( event ) {
    if (event.target.files && event.target.files[0]) {
      this.globalService.getBase64Promise( event )
      .then( result => {
        console.log( 'Result : ', result );
        this.urlSelectJustif.msg = event.target.files[0].name;
        this.urlSelectJustif.url = result;
      })
      .catch( error => {
        console.log('Error event : ', error)
      })
    }
  }

}
