import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Equipe
import { NosEquipesComponent } from './pages/nos-equipes/nos-equipes.component'
// Mentions légales
import { ParametrageComponent } from './pages/parametrage/parametrage.component'
// Dashbord
import { DashboardComponent } from './dashboard/dashboard.component';
// Login
import { LoginComponent } from './login/login.component';
// Compte admin
import { ComptesUserComponent } from './pages/comptes-user/comptes-user.component'
// Parametrages
import { ReglagesComponent } from './pages/reglages/reglages.component'
// Suggest route
import { SuggestrouteComponent } from './pages/trajets/suggestroute/suggestroute.component';
import { ListeTrajetsComponent } from './pages/trajets/liste-trajets/liste-trajets.component';
import { EditTrajetComponent } from './pages/trajets/edit-trajet/edit-trajet.component';
import { NotifierUtilisateurComponent } from './pages/trajets/notifier-utilisateur/notifier-utilisateur.component';
// Send box
import { NouvelenvoiComponent } from './pages/sendbox/nouvelenvoi/nouvelenvoi.component';
import { TousLesSendboxComponent } from './pages/sendbox/tous-les-sendbox/tous-les-sendbox.component';
import { ConsulterSendboxComponent } from './pages/sendbox/consulter-sendbox/consulter-sendbox.component';
// Voyageur
import { NouvelleDemandeComponent } from './pages/voyageurs/nouvelle-demande/nouvelle-demande.component';
import { SuiviVoyageursComponent } from './pages/voyageurs/suivi-voyageurs/suivi-voyageurs.component';
import { ConsulterVoyageurComponent } from './pages/voyageurs/consulter-voyageur/consulter-voyageur.component';
//Utilisateurs
import { ValiderPhotosComponent } from './pages/utilisateurs/valider-photos/valider-photos.component';
import { ListerUtilisateursComponent } from './pages/utilisateurs/lister-utilisateurs/lister-utilisateurs.component';
import { SuiviPointsComponent } from './pages/utilisateurs/suivi-points/suivi-points.component';
//Avis
import { AvisComponent } from './pages/avis/avis.component';
//Flashinfo
import { FlashInfoComponent } from './pages/flash-info/flash-info.component';
// Impayés
import { ImpayesComponent } from './pages/impayes/impayes.component';
//Frais
import { NotefraisComponent } from './pages/notefrais/notefrais.component';
// Envoi e-mail
import { SendMailComponent } from './pages/send-mail/send-mail.component';

import { VisitorGuard } from './auth-helper/visitor.guard'
import { AuthGuard } from './auth-helper/auth.guard';

const routes: Routes = [

  /*
    { path: 'nos-politique-de-confidentialite',      component: ParametrageComponent , canActivate: [VisitorGuard, AuthGuard] },
  */
// Login
  { path: 'login',      component: LoginComponent , canActivate: [VisitorGuard]},

  // dashbord
  { path: 'dashboard',      component: DashboardComponent , canActivate: [VisitorGuard, AuthGuard]},

  // Mentions legales
  //{ path: 'nos-mentions-legales',      component: ParametrageComponent , canActivate: [VisitorGuard, AuthGuard] },

  // Conditions Générales d'Utilisation
  { path: 'nos-conditions-generales-d-utilisation',      component: ParametrageComponent , canActivate: [VisitorGuard, AuthGuard] },
  { path: 'nos-politique-de-confidentialite',      component: ParametrageComponent , canActivate: [VisitorGuard, AuthGuard] },

  // Parametrage
  { path: 'nos-reglages',      component: ReglagesComponent , canActivate: [VisitorGuard, AuthGuard]},

  // equipe
  { path: 'notre-equipe',      component: NosEquipesComponent , canActivate: [VisitorGuard, AuthGuard]},

  // Trajets
  { path: 'trajets/nouveau-trajet/ajout/:id',      component: SuggestrouteComponent , canActivate: [VisitorGuard, AuthGuard] },
  { path: 'trajets/lister-les-trajets/liste/:id',      component: ListeTrajetsComponent , canActivate: [VisitorGuard, AuthGuard] },
  { path: 'trajets/lister-les-trajets/liste/:id/:idtrajet',      component: EditTrajetComponent , canActivate: [VisitorGuard, AuthGuard] },
  { path: 'trajets/notifier-utilisateurs-trajet/notification-emails/:id',      component: NotifierUtilisateurComponent , canActivate: [VisitorGuard, AuthGuard] },

  // Expédition
  { path: 'les-expeditions/msahilisho/nouvelle-expedition/:id',      component: NouvelenvoiComponent , canActivate: [VisitorGuard, AuthGuard] },
  { path: 'les-expeditions/msahilisho/lister-expeditions/:id',      component: TousLesSendboxComponent , canActivate: [VisitorGuard, AuthGuard] },
  { path: 'les-expeditions/msahilisho/lister-expeditions/:id/:sendbox/:type',      component: ConsulterSendboxComponent , canActivate: [VisitorGuard, AuthGuard] },

  //Voyageurs
  { path: 'les-voyageurs/msahilisho/nouvelle-demande/:id',      component: NouvelleDemandeComponent , canActivate: [VisitorGuard, AuthGuard] },
  { path: 'les-voyageurs/msahilisho/lister-les-demandes-voyageur/:id',      component: SuiviVoyageursComponent , canActivate: [VisitorGuard, AuthGuard] },
  { path: 'les-voyageurs/msahilisho/lister-les-demandes-voyageur/:id/:voyage',      component: ConsulterVoyageurComponent , canActivate: [VisitorGuard, AuthGuard] },

  //Utilisateurs
  { path: 'les-utilisateurs/msahilisho/valider-les-photos/:id',      component: ValiderPhotosComponent , canActivate: [VisitorGuard, AuthGuard] },
  { path: 'les-utilisateurs/msahilisho/lister-les-utilisateurs/:id',      component: ListerUtilisateursComponent , canActivate: [VisitorGuard, AuthGuard] },
  { path: 'les-utilisateurs/msahilisho/lister-les-utilisateurs/:id/:user',      component: SuiviPointsComponent , canActivate: [VisitorGuard, AuthGuard] },

  //Avis
  { path: 'les-avis',      component: AvisComponent , canActivate: [VisitorGuard, AuthGuard]},

  //Flashinfo
  { path: 'les-flashinfos',      component: FlashInfoComponent , canActivate: [VisitorGuard, AuthGuard]},

  // Compte administrateurs
  { path: 'gerer-les-comptes',      component: ComptesUserComponent , canActivate: [VisitorGuard, AuthGuard]},

  // Impayés
  { path: 'gerer-les-imapyes',      component: ImpayesComponent , canActivate: [VisitorGuard, AuthGuard]},

  // Impayés
  { path: 'gerer-les-notes-de-frais',      component: NotefraisComponent , canActivate: [VisitorGuard, AuthGuard]},

  //Envoyer mail
  { path: 'envoyer-un-email',      component: SendMailComponent , canActivate: [VisitorGuard, AuthGuard]},

  { path: '',   redirectTo: 'login', pathMatch: 'full' },
  { path: '**', component: LoginComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes , { useHash: true } )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
