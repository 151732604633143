import { Component, OnInit , HostListener ,  ElementRef, ViewChild , AfterViewInit } from '@angular/core';

import {Location} from '@angular/common'
import { Router } from '@angular/router';
import { MatDrawer } from '@angular/material';
import { EventsService } from 'src/app/services/events/events.service'
import { GlobalService } from 'src/app/services/global/global.service'
import { ValidatorrService } from 'src/app/services/validatorr/validatorr.service'
import { BackendService } from 'src/app/services/backend/backend.service';
import { PrintSrvService } from 'src/app/services/print-srv/print-srv.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-tous-les-sendbox',
  templateUrl: './tous-les-sendbox.component.html',
  styleUrls: ['./tous-les-sendbox.component.scss']
})
export class TousLesSendboxComponent implements OnInit {

  userConnected
  urlImage
  opened = true
  txtBadge = "Afficher le menu"
  @ViewChild("drawer", {static: false}) drawer: MatDrawer;

  statutSendbox = [];
  searchFormGroup: FormGroup;
  loadFinished = false

  columnsToDisplay = ['id', 'numtrans', 'user_suggest', 'trajet_suggest', 'user_sender', 'poidsobj', 'prixtotal', 'action'];
  elementsDisplay = ['#', 'N° Tr', 'Voyageur', 'Trajet', 'Expéditeur', 'Nbr kg', 'Montant €', 'Action'];
  lesExpeditionsUserArray = [];
  lesExpeditionsAdminArray = [];
  retourMessage

  constructor(
    private location: Location ,
    private router: Router,
    private eventsService: EventsService,
    private backendService: BackendService ,
    private globalService: GlobalService,
    private _formBuilder: FormBuilder,
    private printSrvService: PrintSrvService
  ) { 
    this.searchFormGroup = this._formBuilder.group({
      statutCtrl: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.isConnectedUser()
    this.statutSendbox = this.globalService.getStatutSendbox();
  }

  get f(){
    return this.searchFormGroup.controls;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.mediaQuery()
      this.searchFormGroup.patchValue({
        statutCtrl: this.statutSendbox[0].statut
      })
      this.displayPage()
    }, 1000);
  }

  isConnectedUser() {
    this.userConnected = this.globalService.getConnectedUser();
  }

  /** *********************  Sidenav **********************  */
  mediaQuery() {
    this.drawer.opened  = window.innerWidth > 900 ? true : false
    this.txtBadge = window.innerWidth > 900 ? "Cacher le menu" : "Afficher le menu"
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.mediaQuery()
  }

  eventToggle( drawer_ ){
    this.txtBadge = drawer_._opened ?  "Cacher le menu" : "Afficher le menu"
  }


  onStatutSelection(event) {
    console.log(event)
    this.lesExpeditionsAdminArray = [];
    this.lesExpeditionsUserArray = [];
    this.displayPage();
  }

  displayPage() {

    let senbox = {
      etattrans: this.searchFormGroup.controls.statutCtrl.value
    }
    this.loadFinished = false;
    this.backendService.post(`/senbox/get-list-for-admin` , senbox )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.lesExpeditionsAdminArray = [];
        this.lesExpeditionsUserArray = [];
      } else {
        console.log( resultat )
        this.lesExpeditionsUserArray = resultat.array_sendbox_statut;
        this.lesExpeditionsAdminArray = resultat.array_sendbox_no_user_statut;
      }
      this.loadFinished = true
    })
    .catch( error => {
      this.lesExpeditionsAdminArray = [];
        this.lesExpeditionsUserArray = [];
      this.loadFinished = true
      this.retourMessage = {ko: "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."}
    })
  }


  onTableAction( event , type) {
    console.log('onTableAction Table action ', event)
    const element = event.element
    if (event.method === 'list') {
      this.router.navigate([`/les-expeditions/msahilisho/lister-expeditions/2-2/${element.id}/${type}`])
      window.scroll(0,0);
    }

  }

}
