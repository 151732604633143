import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuggestrouteComponent } from './suggestroute/suggestroute.component';


import { MaterialAngModule } from '../../matmodule/materialang.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { EventsService } from '../../services/events/events.service'
import { GlobalService } from '../../services/global/global.service'
import { ValidatorrService } from '../../services/validatorr/validatorr.service'
import { BackendService } from '../../services/backend/backend.service';
import { UtilsService } from '../../services/utils/utils.service';

import { HeaderModule} from '../../header/header.module';
import { SharedModule } from '../../shared/shared.module';
import { FooterModule } from '../../footer/footer.module';

import { NgxMaskModule } from 'ngx-mask';
import { ListeTrajetsComponent } from './liste-trajets/liste-trajets.component';
import { EditTrajetComponent } from './edit-trajet/edit-trajet.component';
import { FormTrajetComponent } from './components/form-trajet/form-trajet.component';
import { NotifierUtilisateurComponent } from './notifier-utilisateur/notifier-utilisateur.component';

@NgModule({
  declarations: [
    SuggestrouteComponent,
    ListeTrajetsComponent,
    EditTrajetComponent,
    FormTrajetComponent,
    NotifierUtilisateurComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    MaterialAngModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),

    HeaderModule,
    SharedModule,
    FooterModule,
    
  ],
  providers: [
    EventsService,
    GlobalService,
    ValidatorrService,
    BackendService,
    UtilsService
  ]
})
export class TrajetsModule { }
