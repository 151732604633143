
<div class="full-with">

    <app-header></app-header>


    <mat-drawer-container class="example-container" autosize>
        <mat-drawer #drawer class="example-sidenav" mode="side" [opened]="opened" >
            <app-sidebar></app-sidebar>
        </mat-drawer>

        <div class="example-sidenav-content">

            <button type="button" mat-button (click)="drawer.toggle() && eventToggle(drawer)" class="btn-menu-dash" >
                <i class="material-icons">menu</i> <span>{{txtBadge}}</span>
            </button> <br />


            <div class="content-in-div">

                <div class="form partie-formulaire">
                    <div class="row">
                        <div class="col-md-12 text-h4 colorBleu">
                            Flash Infos 
                            <span class="cursor" (click)="showOrHideFormulaure()">
                                <i class="material-icons text-h3 displayRight">
                                    {{ showFormulaire ? 'expand_less' : 'expand_more' }}
                                </i>
                            </span>
                        </div>
                    </div>

                    <form *ngIf="showFormulaire" class="contactForm" [formGroup]="flashInfoFormGroup" (ngSubmit)="onSubmit()" >

                        <div class="form-group" >
                            <label for="titreCtrl">Titre  <span>(*)</span></label>
                            <legend>Le titre du flash info </legend>
                            <input id="titreCtrl" type="text" class="form-control" formControlName="titreCtrl" placeholder="Veuillez renseigner un titre" data-rule="minlen:10" data-msg="Veuillez saisir au moins 10 caractères" [ngClass]="{ 'is-invalid': submitted && f.titreCtrl.errors }">
                            <div class="validation"></div>
                        </div>

                        <div class="form-group" >
                            <label for="textCtrl">Descripton  <span>(*)</span></label>
                            <legend> Description du flashinfo. De quoi parlons-nous ici ? .</legend>
                            <angular-editor formControlName="textCtrl" id="textCtrl" [config]="editorConfig" [ngClass]="{ 'is-invalid': submitted && f.textCtrl.errors }" ></angular-editor>
                        </div>

                        <div class="form-group" >
                            <label for="afficherCtrl">Afficher ?   <span>(*)</span></label>
                            <legend> Avant d'afficher une Flashinfo, assurez-vous de mettre à non celle qui est affiichée.</legend>
                            <mat-select id="afficherCtrl" formControlName="afficherCtrl" class="form-control" placeholder="Affichier ? " >
                                <mat-option *ngFor="let stt of statusList" [value]="stt.statut" class="optionList">
                                    {{stt.statut}}
                                </mat-option>
                            </mat-select>
                        </div>

                        <div class="form-group" >
                            <label for="importFile" #labelImport class="cursor" > <i class="material-icons">  search </i> {{urlSelectJustif.msg}}  <span>Téléchargez une image</span></label>
                            <legend>Il permet le suivi de la demande par notre équipe commerciale.</legend>
                             <input type="file" accept="image/png, image/jpeg, image/jpg" class="custom-file-input" formControlName="importFile" id="importFile" (change)="onFileChange($event)" [ngClass]="{ 'is-invalid': submitted && f.importFile.errors }">
                        </div>

                        <div class="row" *ngIf="retourMessage" >
                            <div class="col-md-12 div-error" *ngIf="retourMessage.ko" >
                                {{retourMessage.ko}}
                            </div>
                            <div class="col-md-12 div-success" *ngIf="retourMessage.ok" >
                                {{retourMessage.ok}}
                            </div>
                        </div>

                        <div class="col-md-12 div-success" *ngIf="progressMessage.ok" >
                            {{progressMessage.ok}}
                        </div>
                        <div class="col-md-12 div-error" *ngIf="progressMessage.ko" >
                            {{progressMessage.ko}}
                        </div>
                        <br /><br />
                
                
                        <div class="row">
                            <div class="col">
                                <div class="text-center">
                                    <button  class="btn-valider" type="submit" title="Enregistrer"> Enregistrer </button>
                                </div>
                            </div>
                            <div class="col" *ngIf="editMode">
                                <div class="text-center">
                                    <button class="btn-annuler" title="Annuler" (click)="cancelEditMode()" > Annuler </button>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>

                <div class="partie-tableau margin-top-40px">
                    <div class="row">
                        <div class="col-md-12 text-h4 colorBleu">
                            Les Flashinfos
                        </div>
                    </div>

                    <app-arraytable *ngIf="listeFlashinfos.length > 0"
                        [arrayList]="listeFlashinfos"
                        [columnsToDisplay]="columnsToDisplay"
                        [elementsDisplay]="elementsDisplay"
                        [canConfirm]="'true'"
                        [canDelete]="'true'"
                        (tableEmitter)="onTableAction($event)"
                    ></app-arraytable>
                </div>

            </div>

            

        </div>
    </mat-drawer-container>
    <app-footer></app-footer>

</div>
