import { Injectable } from '@angular/core';
import { jsPDF } from "jspdf";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PrintSrvService {

  constructor() { }

  unFormateDate( la_date ){
    if(!!la_date) {
      let la_date_ = la_date.split("-");
      return la_date_[2] + '/' + la_date_[1] + '/' + la_date_[0];
    } else {
      return '-';
    }
    
  }

  printArraySendbox( listTable , statut ) {

    let documentHTML = `<table style="width: 630px !important; margin-bottom: 5px;font-size: 10px;">`;
    documentHTML += `<tr style="border: 1px solid #ffc800; background-color: #f5df92; font-weight: bold; color: #0d344d;font-size: 10px; text-align: center; ">`;
    documentHTML += `<td style="padding-top: 10px; text-align: center; padding-bottom: 10px;font-size: 10px;"> Liste des expéditions <u>${statut}</u> </td>`;
    documentHTML += `</tr>`;
    documentHTML += `</table>`;

    documentHTML += `<table style="width: 630px !important; margin-bottom: 10px;font-size: 10px;">`;
    documentHTML += `<tr style="font-weight: bold; color: #0d344d;font-size: 10px;">`;
    documentHTML += `<td style="padding-top: 15px; text-align: center; padding-bottom: 15px;">`;
    documentHTML += `<img src="assets/imgs/msahilisho.png" width="150px" >`;
    documentHTML += `</td>`;
    documentHTML += `</tr>`;
    documentHTML += `</table>`;

    documentHTML += `<table style="width: 630px !important; font-size: 9px;">`;
    documentHTML += `<tr style="border: 1px solid #ffc800; background-color: #f5df92; font-weight: bold; color: #0d344d;font-size: 9px;">`;
    documentHTML += `<td style="padding-top: 5px; padding-bottom: 5px;"> N° Tr </td>`;
    documentHTML += `<td style="padding-top: 5px; padding-bottom: 5px;"> Expéditeur</td>`;
    documentHTML += `<td style="padding-top: 5px; padding-bottom: 5px;"> Transporteur </td>`;
    documentHTML += `<td style="padding-top: 5px; padding-bottom: 5px;"> Trajet </td>`;
    documentHTML += `<td style="padding-top: 5px; padding-bottom: 5px;"> Date </td>`;
    documentHTML += `<td style="padding-top: 5px; padding-bottom: 5px;"> Kg </td>`;
    documentHTML += `<td style="padding-top: 5px; padding-bottom: 5px;"> Prix </td>`;
    documentHTML += `<td style="padding-top: 5px; padding-bottom: 5px;"> Garantie </td>`;
    documentHTML += `</tr>`;

    listTable.forEach((element: any) => {
      documentHTML += `<tr style="color: #0d344d; font-size: 8px;">`;
      documentHTML += `<td style="padding-top: 5px;"> ${element.numtrans} </td>`;
      documentHTML += `<td style="padding-top: 5px;"> ${element.user_sender.username} : ${element.user_sender.telephone}</td>`;
      documentHTML += `<td style="padding-top: 5px;"> ${element.infos_suggest.user_suggest.username} </td>`;
      documentHTML += `<td style="padding-top: 5px;"> ${element.infos_suggest.aerodep} -> ${element.infos_suggest.aeroarr} </td>`;
      documentHTML += `<td style="padding-top: 5px;"> ${this.unFormateDate(element.infos_suggest.datedepot)} </td>`;
      documentHTML += `<td style="padding-top: 5px;"> ${element.poidsobj}kg </td>`;
      documentHTML += `<td style="padding-top: 5px;"> ${element.prixtotal}${element.infos_suggest.devise} </td>`;
      documentHTML += `<td style="padding-top: 5px;"> ${element.garantie === '' ? 'Non' : element.garantie} </td>`;
      documentHTML += `</tr>`;
    });
    documentHTML += `</table>`;
    const ladateActu = new Date();
    const fileName = `Liste-expedition-${statut}-${ladateActu.getDay()}-${ladateActu.getMonth() + 1}-${ladateActu.getFullYear()}`;
    this.printHTML( documentHTML, fileName );
  }

  printArrayVoyageurs( listTable , statut ) {

    let documentHTML = `<table style="width: 630px !important; margin-bottom: 5px;font-size: 10px;">`;
    documentHTML += `<tr style="border: 1px solid #ffc800; background-color: #f5df92; font-weight: bold; color: #0d344d;font-size: 10px; text-align: center; ">`;
    documentHTML += `<td style="padding-top: 10px; text-align: center; padding-bottom: 10px;font-size: 10px;"> Liste des demandes voyageurs <u>${statut}</u> </td>`;
    documentHTML += `</tr>`;
    documentHTML += `</table>`;

    documentHTML += `<table style="width: 630px !important; margin-bottom: 10px;font-size: 10px;">`;
    documentHTML += `<tr style="font-weight: bold; color: #0d344d;font-size: 10px;">`;
    documentHTML += `<td style="padding-top: 15px; text-align: center; padding-bottom: 15px;">`;
    documentHTML += `<img src="assets/imgs/msahilisho.png" width="150px" >`;
    documentHTML += `</td>`;
    documentHTML += `</tr>`;
    documentHTML += `</table>`;

    documentHTML += `<table style="width: 630px !important; font-size: 9px;">`;
    documentHTML += `<tr style="border: 1px solid #ffc800; background-color: #f5df92; font-weight: bold; color: #0d344d;font-size: 9px;">`;
    documentHTML += `<td style="padding-top: 5px; padding-bottom: 5px;"> N° </td>`;
    documentHTML += `<td style="padding-top: 5px; padding-bottom: 5px;"> Nom et prénom</td>`;
    documentHTML += `<td style="padding-top: 5px; padding-bottom: 5px;"> Téléphone </td>`;
    documentHTML += `<td style="padding-top: 5px; padding-bottom: 5px;"> E-mail </td>`;
    documentHTML += `<td style="padding-top: 5px; padding-bottom: 5px;"> Trajet </td>`;
    documentHTML += `<td style="padding-top: 5px; padding-bottom: 5px;"> Date dep. </td>`;
    documentHTML += `<td style="padding-top: 5px; padding-bottom: 5px;"> Date ret. </td>`;
    documentHTML += `</tr>`;

    listTable.forEach((element: any) => {
      documentHTML += `<tr style="color: #0d344d; font-size: 8px;">`;
      documentHTML += `<td style="padding-top: 5px;"> ${element.idvoyageur} </td>`;
      documentHTML += `<td style="padding-top: 5px;"> ${element.nom} ${element.prenom}</td>`;
      documentHTML += `<td style="padding-top: 5px;"> ${element.numTel} </td>`;
      documentHTML += `<td style="padding-top: 5px;"> ${element.email} </td>`;
      documentHTML += `<td style="padding-top: 5px;"> ${element.aerodep} <-> ${element.aeroarr} </td>`;
      documentHTML += `<td style="padding-top: 5px;"> ${this.unFormateDate(element.datedepart)} </td>`;
      documentHTML += `<td style="padding-top: 5px;"> ${this.unFormateDate(element.datearrivee)} </td>`;
      documentHTML += `</tr>`;
    });
    documentHTML += `</table>`;
    const ladateActu = new Date();
    const fileName = `Liste-demandes-voyageurs-${statut}-${ladateActu.getDay()}-${ladateActu.getMonth() + 1}-${ladateActu.getFullYear()}`;
    this.printHTML( documentHTML, fileName );
  }

  printArrayUtilisateurs( listTable ) {

    let documentHTML = `<table style="width: 630px !important; margin-bottom: 5px;font-size: 10px;">`;
    documentHTML += `<tr style="border: 1px solid #ffc800; background-color: #f5df92; font-weight: bold; color: #0d344d;font-size: 10px; text-align: center; ">`;
    documentHTML += `<td style="padding-top: 10px; text-align: center; padding-bottom: 10px;font-size: 10px;"> Liste des utilisateurs </td>`;
    documentHTML += `</tr>`;
    documentHTML += `</table>`;

    documentHTML += `<table style="width: 630px !important; margin-bottom: 10px;font-size: 10px;">`;
    documentHTML += `<tr style="font-weight: bold; color: #0d344d;font-size: 10px;">`;
    documentHTML += `<td style="padding-top: 15px; text-align: center; padding-bottom: 15px;">`;
    documentHTML += `<img src="assets/imgs/msahilisho.png" width="150px" >`;
    documentHTML += `</td>`;
    documentHTML += `</tr>`;
    documentHTML += `</table>`;

    documentHTML += `<table style="width: 630px !important; font-size: 9px;">`;
    documentHTML += `<tr style="border: 1px solid #ffc800; background-color: #f5df92; font-weight: bold; color: #0d344d;font-size: 9px;">`;
    documentHTML += `<td style="padding-top: 5px; padding-bottom: 5px;"> N° </td>`;
    documentHTML += `<td style="padding-top: 5px; padding-bottom: 5px;"> Nom et prénom</td>`;
    documentHTML += `<td style="padding-top: 5px; padding-bottom: 5px;"> Téléphone </td>`;
    documentHTML += `<td style="padding-top: 5px; padding-bottom: 5px;"> E-mail </td>`;
    documentHTML += `<td style="padding-top: 5px; padding-bottom: 5px;"> Adresse </td>`;
    documentHTML += `<td style="padding-top: 5px; padding-bottom: 5px;"> Date nais. </td>`;
    documentHTML += `<td style="padding-top: 5px; padding-bottom: 5px;"> État </td>`;
    documentHTML += `<td style="padding-top: 5px; padding-bottom: 5px;"> # </td>`;
    documentHTML += `</tr>`;

    listTable.forEach((element: any) => {
      documentHTML += `<tr style="color: #0d344d; font-size: 8px;">`;
      documentHTML += `<td style="padding-top: 5px;">${element.id} </td>`;
      documentHTML += `<td style="padding-top: 5px;">${element.civilite} ${element.lastname} ${element.firstname}</td>`;
      documentHTML += `<td style="padding-top: 5px;">${element.telephone}</td>`;
      documentHTML += `<td style="padding-top: 5px;">${element.email}</td>`;
      documentHTML += `<td style="padding-top: 5px;">${element.address} ${element.zipecode} ${element.city}</td>`;
      documentHTML += `<td style="padding-top: 5px;">${element.datenais}</td>`;
      documentHTML += `<td style="padding-top: 5px;">${element.actif == 1 ? 'Actif' : 'Désactivé'}</td>`;
      documentHTML += `<td style="padding-top: 5px;">${this.getPhoto(element)}</td>`;
      documentHTML += `</tr>`;
    });
    documentHTML += `</table>`;
    const ladateActu = new Date();
    const fileName = `Liste-des-utilisateurs-au-${ladateActu.getDay()}-${ladateActu.getMonth() + 1}-${ladateActu.getFullYear()}`;
    this.printHTML( documentHTML, fileName );
  }

  getPhoto(element) {
    if (!!element.image64) {
      return `<img src="${element.image64}" width="20px" />`
    } else {
      return `<img src="assets/imgs/avatar.png" width="20px" />`
    }
  }

  printHTML(documentHTML, fileName ) {
    let doc: any = new jsPDF('l', 'px', 'a4', true);
    setTimeout(() => {
      doc.html(documentHTML, {
        callback: (pdf: any) => {
          pdf.save(fileName);
        },
        x: 0,
        y: 0,
        autoPaging: 'text',
        margin: [10, 0, 10, 0]
      })
    }, 100);
  }




  printContrat( textHTML , fileName ) {
    
    let documentHTML = `<table style="width: 330px !important; margin-bottom: 5px;font-size: 10px;">`;
    documentHTML += `<tr style="border: 1px solid #ffc800; background-color: #f5df92; font-weight: bold; color: #0d344d;font-size: 10px; text-align: center; ">`;
    documentHTML += `<td style="padding-top: 10px; text-align: center; padding-bottom: 10px;font-size: 10px;"> Liste des expéditions <u>p</u> </td>`;
    documentHTML += `</tr>`;
    documentHTML += `</table>`;

    documentHTML += `<table style="width: 330px !important; margin-bottom: 10px;font-size: 10px;">`;
    documentHTML += `<tr style="font-weight: bold; color: #0d344d;font-size: 10px;">`;
    documentHTML += `<td style="padding-top: 15px; text-align: center; padding-bottom: 15px;">`;
    documentHTML += `<img src="assets/imgs/msahilisho.png" width="150px" >`;
    documentHTML += `</td>`;
    documentHTML += `</tr>`;
    documentHTML += `</table>`;


    let doc = new jsPDF('p', 'px', 'a4', true);
    setTimeout(() => {
      doc.html(documentHTML, {
        callback: (pdf: any) => {
          pdf.save(fileName);
        },
        x: 0,
        y: 0,
        autoPaging: 'text',
        margin: [20, 20, 20, 20]
      })
    }, 100);
  }
}
