
<div class="full-with">

    <app-header></app-header>


    <mat-drawer-container class="example-container" autosize>
        <mat-drawer #drawer class="example-sidenav" mode="side" [opened]="opened" >
            <app-sidebar></app-sidebar>
        </mat-drawer>

        <div class="example-sidenav-content">

            <button type="button" mat-button (click)="drawer.toggle() && eventToggle(drawer)" class="btn-menu-dash" >
                <i class="material-icons">menu</i> <span>{{txtBadge}}</span>
            </button> <br />


            <div class="content-in-div">
                <div class="titre-page">
                    Toutes les expéditions
                </div>

                <div class="serach">
                    <form [formGroup]="searchFormGroup" >
                        <div class="form-group" >
                            <label for="statutCtrl">Statut des expéditions  <span>(*)</span></label>
                            <mat-select id="statutCtrl" formControlName="statutCtrl" class="form-control" placeholder="Statut des trajets" (selectionChange)="onStatutSelection($event)" >
                                <mat-option *ngFor="let stt of statutSendbox" [value]="stt.statut" class="optionList">
                                {{stt.statut}}
                                </mat-option>
                            </mat-select>
                        </div>
                    </form>
                </div>

                <div class="col-md-12 div-error" *ngIf="retourMessage" >
                    {{retourMessage.ko}}
                </div>


                <div class="titre-page-rubrique">
                    Les expéditions utilisateurs
                </div>

                <app-arraytable *ngIf="lesExpeditionsUserArray.length > 0"
                    [arrayList]="lesExpeditionsUserArray"
                    [columnsToDisplay]="columnsToDisplay"
                    [elementsDisplay]="elementsDisplay"
                    [canList]="'true'"
                    (tableEmitter)="onTableAction($event, 'user')"
                    [typeTable]="'sendbox'"
                    [canPrint]="'true'"
                    [titrePrint]="searchFormGroup.controls.statutCtrl.value"
                ></app-arraytable>

                <div class="no-elemnts" *ngIf="lesExpeditionsUserArray.length <= 0">
                    Aucune expédition utilisateur ne corresponds à votre recherche
                </div>

                <div class="titre-page-rubrique">
                    Les expéditions via l'espace admin
                </div>
                
                <app-arraytable *ngIf="lesExpeditionsAdminArray.length > 0"
                    [arrayList]="lesExpeditionsAdminArray"
                    [columnsToDisplay]="columnsToDisplay"
                    [elementsDisplay]="elementsDisplay"
                    [canList]="'true'"
                    (tableEmitter)="onTableAction($event, 'admin')"
                    [typeTable]="'sendbox'"
                    [canPrint]="'true'"
                    [titrePrint]="searchFormGroup.controls.statutCtrl.value"
                ></app-arraytable>

                <div class="no-elemnts" *ngIf="lesExpeditionsAdminArray.length <= 0">
                    Aucune expédition via l'espace admin ne corresponds à votre recherche
                </div>

                <br /><br />

            </div>

            

        </div>
    </mat-drawer-container>
    <app-footer></app-footer>

</div>
