import { Component, OnInit , HostListener ,  ElementRef, ViewChild , AfterViewInit } from '@angular/core';

import {Location} from '@angular/common'
import { Router } from '@angular/router';
import { MatDrawer } from '@angular/material';
import { EventsService } from '../../../services/events/events.service'
import { GlobalService } from '../../../services/global/global.service'
import { ValidatorrService } from '../../../services/validatorr/validatorr.service'
import { BackendService } from '../../../services/backend/backend.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmService } from '../../../modal/confirm/confirm.service';
import { ConfirmMultipleService } from '../../../modal/confirm-multiple/confirm-multiple.service';

@Component({
  selector: 'app-liste-trajets',
  templateUrl: './liste-trajets.component.html',
  styleUrls: ['./liste-trajets.component.scss']
})
export class ListeTrajetsComponent implements OnInit {

  userConnected
  urlImage
  opened = true
  txtBadge = "Afficher le menu"
  @ViewChild("drawer", {static: false}) drawer: MatDrawer ;
  searchFormGroup: FormGroup;
  statutTrajet = [];

  listTrajets = []
  currentRate = 5
  limite = 0
  loadFinished = false
  notOther = false
  retourMessage: any

  constructor(
    private location: Location ,
    private router: Router,
    private eventsService: EventsService,
    private backendService: BackendService ,
    private globalService: GlobalService,
    private _formBuilder: FormBuilder,
    private confirmService: ConfirmService,
    private confirmMultipleService: ConfirmMultipleService
  ) { 
    this.searchFormGroup = this._formBuilder.group({
      statutCtrl: ['', Validators.required]
    });

  }

  ngOnInit(): void {
    this.statutTrajet = this.globalService.getStatutTrajet();
    this.isConnectedUser()
    this.urlImage = this.globalService.urlImage
  }

  ngAfterViewInit() {
    
    setTimeout(() => {
      this.searchFormGroup.patchValue({
        statutCtrl: this.statutTrajet[0].id
      })
      this.mediaQuery()
      this.displayPage()
    }, 1000);
  }

  isConnectedUser() {
    this.userConnected = this.globalService.getConnectedUser();
  }
  /** *********************  Sidenav **********************  */
  mediaQuery() {
    this.drawer.opened  = window.innerWidth > 900 ? true : false
    this.txtBadge = window.innerWidth > 900 ? "Cacher le menu" : "Afficher le menu"
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.mediaQuery()
  }

  eventToggle( drawer_ ){
    this.txtBadge = drawer_._opened ?  "Cacher le menu" : "Afficher le menu"
  }


  onStatutSelection(event) {
    console.log(event)
    this.listTrajets = [];
    this.displayPage();
  }


  displayPage() {

    let annonce = {
      limite: this.limite,
      statutRecherche: this.searchFormGroup.controls.statutCtrl.value
    }
    this.loadFinished = false;
    this.backendService.post(`/suggest/get-list-for-admin` , annonce )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.listTrajets = []
      } else {
        this.listTrajets = this.listTrajets.concat( resultat )
        this.notOther = resultat.length < 4 ? true : false
      }
      this.loadFinished = true
      console.log('this.listTrajets', this.listTrajets)
    })
    .catch( error => {
      this.listTrajets = []
      this.loadFinished = true
      this.retourMessage = "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
    })
  }

  voirPlus(){
    this.limite = this.listTrajets.length
    this.displayPage()
  }

  traitemntTrajet( element ) {
    console.log(element )

    if ( element.terminer == 0 ) {
      this.modalTraitement( element );
    } else {
      this.eventsService.errorsmsg("Trajet terminé. Vous ne pouvez plus le modifier.");
    }
    
  }

  editAnnonce(element) {

    console.log(element )
    const dateJour = new Date();
    const dateTrajet = new Date( element.daterecup );
    dateJour.setHours(0,0,0);
    dateTrajet.setHours(0,0,0);

    if ( !this.globalService.canEdit() ) {
      this.eventsService.toastrwaring("Vous ne disposez pas des autorisations requises pour cette action.")
      return;
    }

    if (dateJour.getTime() > dateTrajet.getTime()) {
      this.eventsService.errorsmsg("Trajet terminé. Vous ne pouvez plus le modifier.");
    } else {
      this.router.navigate([`/trajets/lister-les-trajets/liste/3-2/${element.id}`])
      window.scroll(0,0);
    }
    
  }


  validerTrajet(element) {
    if ( !this.globalService.canEdit() ) {
      this.eventsService.toastrwaring("Vous ne disposez pas des autorisations requises pour cette action.")
      return;
    }
    
    this.confirmService.confirm(`Validation trajet ${element.aerodep} -> ${element.aeroarr} `, 'Êtes-vous sûr de vouloir valider cette demande ?', 'Valider', 'Anuler' , 'Refuser' )
    .then( (confirmed : any) => {
      console.log( confirmed )
      if( confirmed.etat !== "cancel" ) {

        let route = {
          etat: confirmed.etat === "accept" ? 1 : -1,
          iduser: element.user_suggest.id,
          aerodep: element.aerodep,
          aeroarr: element.aeroarr,
          datedepot: element.datedepot,
          validerpar: this.userConnected.id
        }

        console.log( 'route' , route , element )
        this.backendService.post(`/suggest/etat/${element.id}`, route)
        .then( resultat => {
          console.log('Result ', resultat)
          if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
            this.eventsService.errorsmsg( resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement." )
          } else {
            this.eventsService.successmsg( resultat.text )
            setTimeout( () => {
              this.onStatutSelection(0);
            }, 2000)
          }
        })
        .catch( error => {
          this.eventsService.errorsmsg( "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement!" )
        })
      }
      
    })
    .catch( () => {
    })

  }



  modalTraitement( element ) {
    if ( !this.globalService.canEdit() ) {
      this.eventsService.toastrwaring("Vous ne disposez pas des autorisations requises pour cette action.")
      return;
    }
    
    this.confirmMultipleService.confirm(`Traitement du trajet ${element.aerodep} -> ${element.aeroarr} `, 'Que voulez-vous faire  ?', element, 'Valider', 'Anuler' , 'Refuser' )
    .then( (confirmed : any) => {
      console.log( confirmed )
      if( confirmed.etat !== "cancel" ) {

        if ( confirmed.etat === "accept" ) {
          this.validerTrajet(element);
        } else if ( confirmed.etat === "modifier" ) {
          const dateJour = new Date();
          const dateTrajet = new Date( element.daterecup );
          dateJour.setHours(0,0,0);
          dateTrajet.setHours(0,0,0);

          if( (dateJour.getTime() > dateTrajet.getTime()) ) {
            return;
          }
          this.editAnnonce(element);
        }  else if ( confirmed.etat === "terminer" ) {
          this.terminerLetrajet( element );
        }

      }
    })
    .catch( () => {
    })
  }


  terminerLetrajet( element ) {
    if ( !this.globalService.canEdit() ) {
      this.eventsService.toastrwaring("Vous ne disposez pas des autorisations requises pour cette action.")
      return;
    }
    
    this.confirmService.confirm(`Terminer le trajet ${element.aerodep} -> ${element.aeroarr} `, 'Êtes-vous sûr de vouloir valider cette demande ? Cette action est à exécuter une fois tous les colis récupérés. Elle va mettre toute les expéditions liées à ce trajet à l\'état terminé et cloturer toute les demandes. <br /><b>ACTION IRRÉVERSIBLE</b>', 'Valider', 'Anuler' )
    .then( (confirmed : any) => {
      console.log( confirmed )
      if( confirmed.etat !== "cancel" ) {

        let route = {
          iduser: element.user_suggest.id,
          validerpar: this.userConnected.id,
          idtrajet: element.id
        }

        console.log( 'route' , route , element )
        this.backendService.post(`/suggest/terminer-trajet/${element.id}`, route)
        .then( resultat => {
          console.log('Result ', resultat)
          if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
            this.eventsService.errorsmsg( resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement." )
          } else {
            this.eventsService.successmsg( resultat.text )
            setTimeout( () => {
              this.onStatutSelection(0);
            }, 2000)
          }
        })
        .catch( error => {
          this.eventsService.errorsmsg( "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement!" )
        })
      }
      
    })
    .catch( () => {
    })
  }

}
