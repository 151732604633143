

<div class="row" *ngIf="dataSource && canPrint">
    <div class="col-md-12 btn-print">
        <button class="btn btn-annuler" (click)="printArray()" > <span class="material-icons">print</span> Imprimer la liste</button>
    </div>
</div>

<mat-form-field>
    <mat-label>Filtrer </mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Filtrer le tableau ... " #input>
</mat-form-field>


<div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay; let i = index">
            <th mat-header-cell *matHeaderCellDef>  
                <span [innerHTML]="elementsDisplay[i]" > </span>
            </th>

            <td mat-cell *matCellDef="let element">  

                <span class="text-12" *ngIf="!isDansListe(column)" > 
                    <span [innerHTML]="element[column] ? (element[column] | truncate:[30, '...']) : ''" ></span>
                </span>

                <span class="text-12" *ngIf="column === 'description' || column === 'comment'" > 
                    <span [innerHTML]="element[column] ? (element[column] | truncate:[200, '...']) : ''" ></span>
                </span>

                <span class="text-12" *ngIf="column === 'datecreate'"> {{element[column] | date: 'dd MMMM yyyy' }}  </span>
                <span class="text-12" *ngIf="column === 'datedepart'"> {{element[column] | date: 'dd MMMM yyyy' }}  </span>
                <span class="text-12" *ngIf="column === 'datearrivee'"> {{element[column] | date: 'dd MMMM yyyy' }}  </span>

                <span class="text-12" *ngIf="column === 'email'"> {{element[column]}}  </span>
                <span class="text-12" *ngIf="column === 'note'"> {{element[column]}}  </span>

                <span class="text-12 " *ngIf="column == 'user_sender_avis'">
                    {{ element['user_sender'].civilite }} {{ element['user_sender'].name }}
                </span>
                <span class="text-12 star-etoile" *ngIf="column == 'nbrstar'">
                    {{element[column]}} <span class="material-icons">star</span>
                </span>

                <span class="text-12" *ngIf="column == 'photo_sender' ">
                    <img [src]="element['user_sender'].image64  | safeHtml " class="image-table" />
                </span>

                <span class="text-12" *ngIf="column == 'image' || column == 'photo' ">
                    <img [src]="element[column]  | safeHtml " class="image-table" />
                </span>

                <span class="text-12 text-numtrans" *ngIf="column == 'numtrans'">
                    {{ element[column] }}
                </span>

                <span class="text-12 " *ngIf="column == 'user_sender'">
                    {{ element[column].username }}
                </span>

                <span class="text-12 " *ngIf="column == 'trajet_suggest'">
                    {{ element['infos_suggest'].aerodep }} => {{ element['infos_suggest'].aeroarr }}
                    [<b>{{ element['infos_suggest'].datedepot | date: 'dd/MM/yy' }}</b> -> <b>{{ element['infos_suggest'].daterecup | date: 'dd/MM/yy' }}</b>]
                </span>

                <span class="text-12 " *ngIf="column == 'user_suggest'">
                    {{ element['infos_suggest'].user_suggest.username }}
                </span>

                <span class="text-12 download-file" *ngIf="column == 'justificatif'" (click)="downloadFile( element, column )" >
                    <span class="material-icons">save_alt</span>Télécharger
                </span>

                <span class="text-12" *ngIf="column == 'activate' || column == 'actif'">
                    <span *ngIf="element[column] == 1" class="active"> Actif </span>
                    <span *ngIf="element[column] == 0" class="desactive"> Désactivé </span>
                </span>

                <span class="text-12" *ngIf="column == 'niveau'">
                   {{ element[column] == 1 ? 'Consultant' : element[column] == 2 ? 'Manager' : element[column] == 3 ? 'Administrateur' : 'Super Admin' }}
                </span>

                <span class="text-12 " *ngIf="column == 'nom_prenom'">
                    <span *ngIf="element['nom']"> {{ element['nom'] }} {{ element['prenom'] }} </span>
                    <span *ngIf="element['nom_prenom']"> {{ element['nom_prenom'] }} </span>
                </span>

                <span class="text-12 " *ngIf="column == 'agent_msahilisho'">
                    [{{ element['agent_msahilisho'].id }}] {{ element['agent_msahilisho'].username }}
                </span>

                <span *ngIf="column == 'listItem'" >

                    <ul class="fa-ul">
                        <li class="text-left text-12" *ngFor="let itm of maxItemList; let indItem=index " >
                            <span *ngIf="element[column][indItem]">
                                <span class="fa-li">
                                    <span class="material-icons">done</span>
                                </span>
                                {{element[column][indItem].value_item_ctrl}}
                            </span>
                        </li>

                        <li class="text-left text-12" *ngIf="element[column].length > 3">
                            <span class="fa-li">
                                <span class="material-icons">done</span>
                            </span>
                            ... et plus encore.
                        </li>
                    </ul>

                </span>

                <span *ngIf="column == 'images'" >

                    <ul class="fa-ul">
                        <li class="text-left text-12" *ngFor="let imgl of maxItemList; let indImg=index " >
                            <span *ngIf="element[column][indImg]">
                                <span class="fa-li">
                                    <span class="material-icons">radio_button_unchecked</span>
                                </span>
                                <img [src]="element[column][indImg].url_send  | safeHtml " class="images-table" />
                            </span>
                        </li>

                        <li class="text-left text-12" *ngIf="element[column].length > 3">
                            <span class="fa-li">
                                <span class="material-icons">radio_button_unchecked</span>
                            </span>
                            ... et plus encore.
                        </li>
                    </ul>

                </span>

                <span class="text-12" *ngIf="column === 'action'"> 
                    <button *ngIf="canList" mat-icon-button class="btn_action text-12" (click)="btnEncoursAction($event, element, 'list')" matTooltip="Sélectionner"> <mat-icon>person_search</mat-icon> </button>
                    <button *ngIf="canEdit" mat-icon-button class="btn_action text-12" (click)="btnEncoursAction($event, element, 'edit')" matTooltip="Modifier"> <mat-icon>edit</mat-icon> </button>
                    <button *ngIf="canConfirm" mat-icon-button class="btn_action text-12" (click)="btnEncoursAction($event, element, 'confirm')" matTooltip="Traitement"> <mat-icon>rule_folder</mat-icon> </button>
                    <button *ngIf="canReinit" mat-icon-button class="btn_action text-12" (click)="btnEncoursAction($event, element, 'reinit')" matTooltip="Re-initialiser le mot de passe"> <mat-icon>published_with_changes</mat-icon> </button>
                    <button *ngIf="canDelete" mat-icon-button class="btn_action_delete text-12" (click)="btnEncoursAction($event, element, 'delete')" matTooltip="Supprimer"> <mat-icon>delete_forever</mat-icon> </button>
                </span>
            
            </td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>

    </table>

    <mat-paginator [pageSizeOptions]="[2, 5, 10, 25, 50, 75, 100]"></mat-paginator>

</div>