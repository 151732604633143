
<div class="full-with">

    <app-header></app-header>


    <mat-drawer-container class="example-container" autosize>
        <mat-drawer #drawer class="example-sidenav" mode="side" [opened]="opened" >
            <app-sidebar></app-sidebar>
        </mat-drawer>

        <div class="example-sidenav-content">

            <button type="button" mat-button (click)="drawer.toggle() && eventToggle(drawer)" class="btn-menu-dash" >
                <i class="material-icons">menu</i> <span>{{txtBadge}}</span>
            </button> <br />


            <div class="content-in-div">

                <button type="button" mat-button (click)="getBack()" class="btn-menu-dash" >
                    <i class="material-icons">navigate_before</i> <span> Retourner à la liste</span>
                </button> <br />
                
                <div class="titre-page">
                    Consulter une demande de voyage
                </div>
                

                <div *ngIf="VoyageurSelected" class="inner-div-page" >

                    <div class="row">
                        <div class="col-md-12">
                            <label>Voyageur</label>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6 inter-div">
                            <span> Nom et prénom </span><br />
                            {{VoyageurSelected.nom}} {{VoyageurSelected.prenom}}
                        </div>
                        <div class="col-md-6 inter-div">
                            <span> Téléphone </span><br />
                            {{ VoyageurSelected.numTel | mask: maskNumTel.mask }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 inter-div">
                            <span> E-mail</span><br />
                            {{ VoyageurSelected.email ? VoyageurSelected.email : '-' }}
                        </div>
                        <div class="col-md-6 inter-div">
                            <span> Pièce d'identité </span><br />
                            <div class="download" (click)="downloadPiceIdentite()" *ngIf="VoyageurSelected.image64" > 
                                <span class="material-icons">save_alt</span>
                                Télécharger la pièce d'identité
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 inter-div">
                            <span> Adresse de sésidence </span><br />
                            {{ VoyageurSelected.adresseresid ? VoyageurSelected.adresseresid : '-' }}
                        </div>
                        <div class="col-md-5 inter-div">
                            <span> Date de naissance </span><br />
                            {{!!VoyageurSelected.datenaiss ? (VoyageurSelected.datenaiss | date: 'dd MMMM yyyy') : '-'}}
                        </div>
                        <div class="col-md-1 text-right text-iner-div-btn">
                            <span (click)="editDateNaiss = !editDateNaiss" class="material-icons">{{!editDateNaiss ? 'edit' : 'close'}}</span>
                        </div>
                    </div>

                    <!-- Edit Date de naissance -->
                    <form [formGroup]="naissanceFormGroup">
                        <div class="row padding-inner-div" *ngIf="editDateNaiss" >
                            <div class="col-md-5 inter-div">
                                <div class="form-group" >
                                    <label for="adresseCtrl">Adresse de résidence (complète)<span>(*)</span></label>
                                    <textarea class="form-control" id="adresseCtrl" rows="2" formControlName="adresseCtrl" placeholder="Adresse de résidence" [ngClass]="{ 'is-invalid': submitted && f3.adresseCtrl.errors }"></textarea>
                                </div>
                            </div>
                            <div class="col-md-5 inter-div">
                                <div class="form-group" >
                                    <label for="dateNaissCtrl">Date de naissance  <span>(*)</span></label>
                                    <input [showMaskTyped]="true" mask="00/00/0000" id="dateNaissCtrl" type="text" class="form-control" formControlName="dateNaissCtrl" placeholder="dd/mm/yyyy"  [ngClass]="{ 'is-invalid': submitted && f3.dateNaissCtrl.errors }" >
                                </div>
                            </div>
                            <div class="col-md-2 text-right text-iner-div-btn" (click)="saveDateNaiss()">
                                <span class="material-icons">done</span> Valider
                            </div>
                        </div>
                        <div class="col-md-12 div-error" *ngIf="retourMessage && retourMessage.ko" >
                            {{retourMessage.ko}}
                        </div>
                    </form>


                    <div class="row">
                        <div class="col-md-12">
                            <label>Trajet</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 inter-div">
                            <span> Aéroport de départ </span><br />
                            {{VoyageurSelected.aerodep}}
                        </div>
                        <div class="col-md-6 inter-div">
                            <span> Aéroport d'arrivée </span><br />
                            {{VoyageurSelected.aeroarr}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 inter-div">
                            <span> Date de départ </span><br />
                            {{VoyageurSelected.datedepart | date: 'EE dd MMMM yyyy'}}
                        </div>
                        <div class="col-md-6 inter-div">
                            <span> Date de retour </span><br />
                            {{VoyageurSelected.datearrivee | date: 'EE dd MMMM yyyy'}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 inter-div">
                            <span> Pays de départ </span><br />
                            {{VoyageurSelected.paysdep}}
                        </div>
                        <div class="col-md-6 inter-div">
                            <span> Pays d'arrivé </span><br />
                            {{VoyageurSelected.paysarr}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 inter-div">
                            <span> Information complémentaire </span><br />
                            {{VoyageurSelected.comment}}
                        </div>
                        <div class="col-md-6 inter-div">
                            <span> Statut </span><br />
                            <b> {{VoyageurSelected.statut}} </b>
                        </div>
                    </div>

                    <br />

                    <div class="row" *ngIf="suiviVoyage" >
                        <div class="col-md-6 inter-div">
                            <span> Contrat voyageur  </span>
                        </div>
                        <div class="col-md-6 inter-div">
                            <span> <u> {{!!suiviVoyage && !!suiviVoyage.contratenvoye ? 'Envoyé' : 'Non envoyé'}} </u> </span>
                        </div>
                    </div>

                    <br /><br />

                    <div class="row">
                        <div class="col-md-4">
                            <button class="btn btn-valider" mat-button (click)="editEtat()" >Changer l'état de la demande </button>
                        </div>
                        <div class="col-md-4">
                            <button class="btn btn-bleu" mat-button (click)="editFile('pieceident')" >Mise à jour pièce identité</button>
                        </div>

                        <div class="col-md-4" *ngIf="suiviVoyage">
                            <button class="btn btn-bleu-clair" mat-button (click)="etablirContrat()" >Éditer et envoyer le contrat</button>
                        </div>
                    </div>
                    <br /><br />

                    <div class="titre-page">
                        Suivi de la demande de voyage
                    </div>

                    
                    <div *ngIf="suiviVoyage">

                        <div class="row padding-inner-div">
                            <div class="col-md-6 inter-div">
                                <span> Contrat </span>
                            </div>
                            <div class="col-md-6 text-right text-iner-div-btn">
                                <span *ngIf="!suiviVoyage.contrat" (click)="editFile('contrat')" > <span class="material-icons">difference</span> Ajouter </span>
                                <span *ngIf="suiviVoyage.contrat" (click)="downloadContrat()" > <span class="material-icons">save_alt</span> {{textTelecharger}} </span>
                            </div>
                        </div>
                        <div class="row padding-inner-div">
                            <div class="col-md-6 inter-div">
                                <span> Billet Aller </span> <br />
                            </div>
                            <div class="col-md-6 text-right text-iner-div-btn">
                                <span *ngIf="!suiviVoyage.billetaller" (click)="editFile('billetaller')" > <span class="material-icons">difference</span> Ajouter </span>
                                <span *ngIf="suiviVoyage.billetaller" (click)="downloadBilletAller()" > <span class="material-icons">save_alt</span> Télécharger </span>
                            </div>
                        </div>
                        <div class="row padding-inner-div">
                            <div class="col-md-6 inter-div">
                                <span> Billet Retour </span>
                            </div>
                            <div class="col-md-6 text-right text-iner-div-btn">
                                <span *ngIf="!suiviVoyage.billetretour" (click)="editFile('billetretour')" > <span class="material-icons">difference</span> Ajouter </span>
                                <span *ngIf="suiviVoyage.billetretour" (click)="downloadBilletRetour()" > <span class="material-icons">save_alt</span> Télécharger </span>
                            </div>
                        </div>
    
                        <div class="row padding-inner-div">
                            <div class="col-md-5 inter-div">
                                <span> Date Aller </span><br />
                                {{ suiviVoyage.datedepart ? (suiviVoyage.datedepart | date: 'EE dd MMMM yyyy') : '-'}}
                            </div>
                            <div class="col-md-5 inter-div">
                                <span> Date Retour </span> <br />
                                {{ suiviVoyage.dateretour ? (suiviVoyage.dateretour | date: 'EE dd MMMM yyyy') : '-'}}
                            </div>
                            <div class="col-md-2 text-right text-iner-div-btn">
                                <span (click)="editDate = !editDate" class="material-icons">{{!editDate ? 'edit' : 'close'}}</span>
                            </div>
                        </div>

                        <!-- Edit montant -->
                        <form [formGroup]="dateFormGroup">
                            <div class="row padding-inner-div" *ngIf="editDate" >
                            
                                <div class="col-md-5 inter-div">
                                    <div class="form-group" >
                                        <label for="dateDepartCtrl">Date de départ  <span>(*)</span></label>
                                        <input [showMaskTyped]="true" mask="00/00/0000" id="dateDepartCtrl" type="text" class="form-control" formControlName="dateDepartCtrl" placeholder="dd/mm/yyyy"  [ngClass]="{ 'is-invalid': submitted && f1.dateDepartCtrl.errors }" >
                                    </div>
                                </div>
                                <div class="col-md-5 inter-div">
                                    <div class="form-group" >
                                        <label for="dateArriveeCtrl">Date de retour  <span>(*)</span></label>
                                        <input [showMaskTyped]="true" mask="00/00/0000" id="dateArriveeCtrl" type="text" class="form-control" formControlName="dateArriveeCtrl" placeholder="dd/mm/yyyy"  [ngClass]="{ 'is-invalid': submitted && f1.dateArriveeCtrl.errors }" >
                                    </div>
                                </div>
                                <div class="col-md-2 text-right text-iner-div-btn" (click)="saveDate()">
                                    <span class="material-icons">done</span> Valider
                                </div>
                            
                            </div>
                            <div *ngIf="submitted && dateFormGroup.errors" class="div-error" >
                                La date de départ doit obligatoirement être postérieure à celle du retour
                            </div>
                            <div class="col-md-12 div-error" *ngIf="retourMessage && retourMessage.ko" >
                                {{retourMessage.ko}}
                            </div>
                        </form>

                        <div class="row padding-inner-div">
                            <div class="col-md-2 inter-div-2">
                                <span> Paiement </span>
                            </div>
                            <div class="col-md-3 inter-div">
                                <span> Prix total du billet</span><br />
                                {{ suiviVoyage.prixtotalbillet ? (suiviVoyage.prixtotalbillet + '€') : '-'}}
                            </div>
                            <div class="col-md-3 inter-div">
                                <span> Montant à regler (voyageur)</span><br />
                                {{ suiviVoyage.montantaregler ? (suiviVoyage.montantaregler + '€') : '-'}}
                            </div>
                            <div class="col-md-3 inter-div">
                                <span> Montant payé (voyageur) </span><br />
                                {{ suiviVoyage.montantregle ? (suiviVoyage.montantregle + '€') : '-'}}
                            </div>
                            <div class="col-md-1 text-right text-iner-div-btn">
                                <span (click)="editMontant = !editMontant" class="material-icons">{{!editMontant ? 'edit' : 'close'}}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2 inter-div-2">
                                &nbsp;
                            </div>
                            <!--<div class="col-md-3 inter-div">
                                <span> Dépense du trajet (msahilisho)</span><br />
                                {{ suiviVoyage.depensetrajet ? (suiviVoyage.depensetrajet + '€') : '-'}}
                            </div>-->
                            <div class="col-md-5 inter-div">
                                <span> Recette du trajet (Aller)</span><br />
                                {{ suiviVoyage.rectrajetaller ? (suiviVoyage.rectrajetaller + '€') : '-'}}
                            </div>
                            <div class="col-md-5 inter-div">
                                <span> Recette du trajet (Retour)</span><br />
                                {{ suiviVoyage.rectrajetretour ? (suiviVoyage.rectrajetretour + '€') : '-'}}
                            </div>
                        </div>
                        

                        <form [formGroup]="montantFormGroup">
                            <div class="row padding-inner-div" *ngIf="editMontant" >
                                <div class="col-md-2 inter-div-2">
                                    &nbsp;
                                </div>
                                <div class="col-md-3 inter-div">
                                    <div class="form-group" >
                                        <label for="prixtotalbilletCtrl">Prix total du billet <span>(*)</span></label>
                                        <input step="0.01" id="prixtotalbilletCtrl" type="number" class="form-control" formControlName="prixtotalbilletCtrl" placeholder="Prix total du billet"  [ngClass]="{ 'is-invalid': submitted && f2.prixtotalbilletCtrl.errors }" >
                                    </div>
                                </div>
                                <div class="col-md-3 inter-div">
                                    <div class="form-group" >
                                        <label for="montantareglerCtrl">Montant à regler <span>(*)</span></label>
                                        <input step="0.01" id="montantareglerCtrl" type="number" class="form-control" formControlName="montantareglerCtrl" placeholder="Montant à regler"  [ngClass]="{ 'is-invalid': submitted && f2.montantareglerCtrl.errors }" >
                                    </div>
                                </div>
                                <div class="col-md-3 inter-div">
                                    <div class="form-group" >
                                        <label for="montantregleCtrl">Montant payé <span>(*)</span></label>
                                        <input step="0.01" id="montantregleCtrl" type="number" class="form-control" formControlName="montantregleCtrl" placeholder="Montant payé"  [ngClass]="{ 'is-invalid': submitted && f2.montantregleCtrl.errors }" >
                                    </div>
                                </div>
                                <div class="col-md-1 text-right text-iner-div-btn" (click)="saveMontant()">
                                    <span class="material-icons">done</span> Valider
                                </div>
                            </div>
                            <div class="row padding-inner-div" *ngIf="editMontant" >
                                <div class="col-md-2 inter-div-2">
                                    &nbsp;
                                </div>
                                <!--<div class="col-md-3 inter-div">
                                    <div class="form-group" >
                                        <label for="depensetrajetCtrl"> Dépense du trajet (msahilisho)</label>
                                        <input step="0.01" id="depensetrajetCtrl" type="number" class="form-control" formControlName="depensetrajetCtrl" placeholder="Dépense du trajet"  [ngClass]="{ 'is-invalid': submitted && f2.depensetrajetCtrl.errors }" >
                                    </div>
                                </div>-->
                                <div class="col-md-5 inter-div">
                                    <div class="form-group" >
                                        <label for="rectrajetallerCtrl"> Recette du trajet (Aller) </label>
                                        <input step="0.01" id="rectrajetallerCtrl" type="number" class="form-control" formControlName="rectrajetallerCtrl" placeholder="Recette aller"  [ngClass]="{ 'is-invalid': submitted && f2.rectrajetallerCtrl.errors }" >
                                    </div>
                                </div>
                                <div class="col-md-4 inter-div">
                                    <div class="form-group" >
                                        <label for="rectrajetretourCtrl"> Recette du trajet (Retour) </label>
                                        <input step="0.01" id="rectrajetretourCtrl" type="number" class="form-control" formControlName="rectrajetretourCtrl" placeholder="Recette retour"  [ngClass]="{ 'is-invalid': submitted && f2.rectrajetretourCtrl.errors }" >
                                    </div>
                                </div>
                                <div class="col-md-2 text-right text-iner-div-btn" >
                                    <!-- <span class="material-icons">close</span> Quitter (click)="editMontant = !editMontant"  --> &nbsp;
                                </div>
                            </div>
                        </form>


                        <div class="row">
                            <div class="col">
                                <div class="text-left modification">
                                    <span>Dernière personne à avoir effectué des modifications sur cette demande : </span> {{suiviVoyage.agent_msahilisho.username}}
                                </div>
                            </div>
                        </div>
                        <br /><br />



                        <div class="row">
                            <div class="col-md-6">
                                <div class="titre-page-rubrique">
                                    Les dépenses liées au voyage
                                </div>
                            </div>
                            <div class="col-md-6 text-right btn-add-depenses " >
                                <span (click)="addDepenses()"> <span class="material-icons">add_shopping_cart</span> Ajouter </span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 info-bulles">
                                <u>Dépense du trajet</u> (Achat bagages supplémentaires, Transports, Frais Agent indépendant (frais préparation bagages, frais accompagnement aéroport), autres dépenses (Achat sacs, fourniture)) ... etc
                            </div>
                        </div>
        
                        <app-arraytable *ngIf="lesDepensesVoyage.length > 0"
                            [arrayList]="lesDepensesVoyage"
                            [columnsToDisplay]="columnsToDisplay"
                            [elementsDisplay]="elementsDisplay"
                            [canDelete]="'true'"
                            (tableEmitter)="onTableAction($event)"
                        ></app-arraytable>
        
                        <div class="no-elemnts" *ngIf="lesDepensesVoyage.length <= 0">
                            Aucune dépense pour ce voyage
                        </div>


                    </div>

                    <br /><br />
                </div>
            </div>

            

        </div>
    </mat-drawer-container>
    <app-footer></app-footer>

</div>
