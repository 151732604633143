
<div class="full-with">

    <app-header></app-header>


    <mat-drawer-container class="example-container" autosize>
        <mat-drawer #drawer class="example-sidenav" mode="side" [opened]="opened" >
            <app-sidebar></app-sidebar>
        </mat-drawer>

        <div class="example-sidenav-content">

            <button type="button" mat-button (click)="drawer.toggle() && eventToggle(drawer)" class="btn-menu-dash" >
                <i class="material-icons">menu</i> <span>{{txtBadge}}</span>
            </button> <br />


            <div class="content-in-div">
                

                <div class="form partie-formulaire">

                    <div class="row">
                        <div class="col-md-12 text-h4 colorBleu">
                            L'équipe Msahilisho Group

                            <span class="cursor" (click)="showOrHideFormulaure()">
                                <i class="material-icons text-h3 displayRight">
                                    {{ showFormulaire ? 'expand_less' : 'expand_more' }}
                                </i>
                            </span>
                        </div>
                    </div>

                    <form *ngIf="showFormulaire" class="contactForm" [formGroup]="formulaireGroup" (ngSubmit)="onSubmit()" >

                        <div class="form-group" >
                            <label for="nomPrenomCtrl">Nom et prénom  <span>(*)</span></label>
                            <legend>Le nom et le prénom du membre</legend>
                            <input id="nomPrenomCtrl" type="text" class="form-control" formControlName="nomPrenomCtrl" placeholder="Le nom et le prénom du membre" [ngClass]="{ 'is-invalid': submitted && f.nomPrenomCtrl.errors }">
                            <div class="validation"></div>
                        </div>

                        <div class="form-group" >
                            <label for="emailCtrl">E-mail <span>(*)</span></label>
                            <legend>L'adresse e-mail du membre</legend>
                            <input id="emailCtrl" type="text" class="form-control" formControlName="emailCtrl" placeholder="L'adresse e-mail du membre" [ngClass]="{ 'is-invalid': submitted && f.emailCtrl.errors }">
                            <div class="validation"></div>
                        </div>

                        <div class="form-group" >
                            <label for="presentationCtrl">Présentation  <span>(*)</span></label>
                            <legend> Petite présentation du membre, son status, sa fonction, compétences ...etc. </legend>
       
                            <angular-editor formControlName="presentationCtrl" id="presentationCtrl" [config]="editorConfig" [ngClass]="{ 'is-invalid': submitted && f.presentationCtrl.errors }" ></angular-editor>
                    
                        </div>


                        <div class="form-group" >
                            <label for="fonctionCtrl">Fonction  <span>(*)</span></label>
                            <legend> La fonction du collaborateur </legend>
                            <mat-select id="fonctionCtrl" formControlName="fonctionCtrl" class="form-control" placeholder="Fonction " [ngClass]="{ 'is-invalid': submitted && f.fonctionCtrl.errors }" >
                                <mat-option *ngFor="let ftl of fonctionsList" [value]="ftl.id" class="optionList colorNoirGris text-12">
                                    {{ftl.libelle}}
                                </mat-option>
                            </mat-select>
                            <div class="validation"></div>
                        </div>

                        <div class="form-group" >
                            <label (click)="openAndUploadFile()" for="photoCtrl" #labelImport class="cursor" [ngClass]="{ 'colorError': submitted && urlImageSelect.error }" > 
                                <i class="material-icons">  search </i> {{urlImageSelect.msg}}  
                                <span>Sélectionnez une image (*)</span>
                                <br />
                                <span *ngIf="urlImageSelect.url && urlImageSelect.url !== ''" >
                                    <img [src]="urlImageSelect.url  | safeHtml  " width="100"  />
                                </span>
                            </label>
                            <legend> Sélectionner une image du slide </legend>
<!--
                            <input type="file" accept="image/png, image/jpeg, image/svg+xml" class="custom-file-input colorGris text-12" formControlName="photoCtrl" id="photoCtrl" (change)="onFileChange($event)" [ngClass]="{ 'is-invalid': submitted && f.photoCtrl.errors }">
-->
                            
                            <div class="validation"></div>
                        </div>


                        <div class="row" *ngIf="retourMessage" >

                            <div class="col-md-12 div-error" *ngIf="retourMessage.ko" >
                                {{retourMessage.ko}}
                            </div>
    
                            <div class="col-md-12 div-success" *ngIf="retourMessage.ok" >
                                {{retourMessage.ok}}
                            </div>
    
                        </div>
                
                
                        <div class="row">
                            <div class="col">
                                <div class="text-center">
                                    <button  class="btn-valider" type="submit" title="Enregistrer"> Enregistrer </button>
                                </div>
                            </div>
                            <div class="col" *ngIf="editMode">
                                <div class="text-center">
                                    <button class="btn-annuler" title="Annuler" (click)="cancelEditMode()" > Annuler </button>
                                </div>
                            </div>
                        </div>

                    </form>

                </div>


                <div class="partie-tableau margin-top-40px">
                    <div class="row">
                        <div class="col-md-12 text-h4 colorBleu">
                            Les membres d'équipe
                        </div>
                    </div>

                    <app-arraytable *ngIf="lesEquipesArray.length > 0"
                        [arrayList]="lesEquipesArray"
                        [columnsToDisplay]="columnsToDisplay"
                        [elementsDisplay]="elementsDisplay"
                        [canDelete]="'true'"
                        [canEdit]="'true'"
                        (tableEmitter)="onTableAction($event)"
                    ></app-arraytable>
                </div>


            </div>

            <app-footer></app-footer>

        </div>
    </mat-drawer-container>


</div>
