import { Component, OnInit , HostListener ,  ElementRef, ViewChild , AfterViewInit } from '@angular/core';

import {Location} from '@angular/common'
import { Router , ActivatedRoute } from '@angular/router';
import { MatDrawer } from '@angular/material';
import { EventsService } from 'src/app/services/events/events.service'
import { GlobalService } from 'src/app/services/global/global.service'
import { ValidatorrService } from 'src/app/services/validatorr/validatorr.service'
import { BackendService } from 'src/app/services/backend/backend.service'

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EditEtatService } from 'src/app/modal/edit-etat/edit-etat.service';
import { EditLocationService } from 'src/app/modal/edit-location/edit-location.service';
import { EditSendboxService } from 'src/app/modal/edit-sendbox/edit-sendbox.service';

@Component({
  selector: 'app-consulter-sendbox',
  templateUrl: './consulter-sendbox.component.html',
  styleUrls: ['./consulter-sendbox.component.scss']
})
export class ConsulterSendboxComponent implements OnInit {

  userConnected
  urlImage
  opened = true
  txtBadge = "Afficher le menu"
  @ViewChild("drawer", {static: false}) drawer: MatDrawer;

  retourMessage;
  loadFinished = false;
  sendBoxSelected
  maskNumExped
  maskNumdDep
  maskNumArr
  maskNumTran
  maskNumDest
  lesNotes = []
  paramsPage
  preparationLivraison;

  constructor(
    private location: Location ,
    private router: Router,
    private eventsService: EventsService,
    private backendService: BackendService ,
    private globalService: GlobalService,
    private _formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private editEtatService: EditEtatService,
    private editLocationService: EditLocationService,
    private editSendboxService: EditSendboxService
  ) { 
    this.route.params.subscribe(routeParams => {
      console.log('Param router :: ' , routeParams)
      this.paramsPage = {sendbox: routeParams.sendbox, type: routeParams.type}
      this.displayPage(routeParams.sendbox, routeParams.type)
    });
    
  }

  ngOnInit(): void {
    this.isConnectedUser();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.mediaQuery()
    }, 1000);
  }

  isConnectedUser() {
    this.userConnected = this.globalService.getConnectedUser();
  }

  /** *********************  Sidenav **********************  */
  mediaQuery() {
    this.drawer.opened  = window.innerWidth > 900 ? true : false
    this.txtBadge = window.innerWidth > 900 ? "Cacher le menu" : "Afficher le menu"
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.mediaQuery()
  }

  eventToggle( drawer_ ){
    this.txtBadge = drawer_._opened ?  "Cacher le menu" : "Afficher le menu"
  }


  displayPage(id, type) {

    console.log('Display by type')
    this.loadFinished = false;
    this.backendService.get( `/senbox/sendbox-by-id-${type}/${id}` )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.retourMessage = {ko: resultat.status != 0 ? resultat.error.error : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."}
      } else {
        
        this.sendBoxSelected = resultat[0];
        this.maskNumExped = this.globalService.maskSaisi( this.sendBoxSelected.user_sender.telephone )
        this.maskNumdDep = this.globalService.maskSaisi( this.sendBoxSelected.infos_suggest.teldepot )
        this.maskNumArr = this.globalService.maskSaisi( this.sendBoxSelected.infos_suggest.telrecup )
        this.maskNumTran = this.globalService.maskSaisi( this.sendBoxSelected.infos_suggest.user_suggest.telephone )
        this.maskNumDest = this.globalService.maskSaisi( this.sendBoxSelected.teldest )
        
        this.preparationLivraison = this.sendBoxSelected.prep_remise_exped;

        if ( this.preparationLivraison && this.preparationLivraison.prep_remise_exped.length > 0 ) {

          if( this.preparationLivraison.prep_remise_exped[0].image_recup) {
            this.preparationLivraison.prep_remise_exped[0].image_recup = JSON.parse( this.preparationLivraison.prep_remise_exped[0].image_recup );
          }
          if( this.preparationLivraison.prep_remise_exped[0].image_prep) {
            this.preparationLivraison.prep_remise_exped[0].image_prep = JSON.parse(this.preparationLivraison.prep_remise_exped[0].image_prep);
          }
          if( this.preparationLivraison.prep_remise_exped[0].photo_livr) {
            this.preparationLivraison.prep_remise_exped[0].photo_livr = JSON.parse(this.preparationLivraison.prep_remise_exped[0].photo_livr);
          }
          if( this.preparationLivraison.prep_remise_exped[0].sign_recup) {
            this.preparationLivraison.prep_remise_exped[0].sign_recup = JSON.parse(this.preparationLivraison.prep_remise_exped[0].sign_recup);
          }
          if( this.preparationLivraison.prep_remise_exped[0].sign_livr) {
            this.preparationLivraison.prep_remise_exped[0].sign_livr = JSON.parse(this.preparationLivraison.prep_remise_exped[0].sign_livr);
          }

        }

        if( !!this.sendBoxSelected.notestrans ) {
          this.lesNotes = JSON.parse( this.sendBoxSelected.notestrans );
        } else {
          this.lesNotes = [];
        }
        
      }
      this.loadFinished = true
    })
    .catch( error => {
      this.loadFinished = true
      this.retourMessage = {ko: "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."}
    })
  }

  getBack(){
    this.router.navigate(['/les-expeditions/msahilisho/lister-expeditions/2-2'])
  }


  editEtat(){
    if ( !this.globalService.canEdit() ) {
      this.eventsService.toastrwaring("Vous ne disposez pas des autorisations requises pour cette action.")
      return;
    }
    this.editEtatService.confirm(this.sendBoxSelected, 'Valider', 'Anuler')
    .then( (confirmed : any ) => {
      if( confirmed.status ) {
        this.eventsService.successmsg( "Votre demande a été bien prise en compte" )
        setTimeout( () => {
          this.displayPage(this.paramsPage.sendbox, this.paramsPage.type);
        }, 2000)
      }
    })
    .catch( () => {
    })
  }

  editLocationNote( etat ){
    if ( !this.globalService.canEdit() ) {
      this.eventsService.toastrwaring("Vous ne disposez pas des autorisations requises pour cette action.")
      return;
    }
    const titre = etat === 'location' ? "Mettre à jour la localisation de l'expédition" : "Ajouter une note à l'expédition";
    this.editLocationService.confirmer(this.sendBoxSelected, titre, etat, 'Valider', 'Anuler')
    .then( (confirmed : any ) => {
      if( confirmed.status ) {
        this.eventsService.successmsg( "Votre demande a été bien prise en compte" )
        setTimeout( () => {
          this.displayPage(this.paramsPage.sendbox, this.paramsPage.type);
        }, 2000)
      }
    })
    .catch( () => {
    })
  }

  editExpedition() {
    if ( !this.globalService.canEdit() ) {
      this.eventsService.toastrwaring("Vous ne disposez pas des autorisations requises pour cette action.")
      return;
    }
    this.editSendboxService.confirmer(this.sendBoxSelected, 'Valider', 'Anuler')
    .then( (confirmed : any ) => {
      if( confirmed.status ) {
        this.eventsService.successmsg( "Votre demande a été bien prise en compte" )
        setTimeout( () => {
          this.displayPage(this.paramsPage.sendbox, this.paramsPage.type);
        }, 2000)
      }
    })
    .catch( () => {
    })
  }


  downloadFile( element , name) {
    const extensionFile = this.globalService.getExtentionBase64( element );
    const ladate = new Date();

    this.globalService.downloadFile(
      `${element}` ,
      `${name}-${this.globalService.makeid(10)}.${extensionFile}`
    )
  }

}
