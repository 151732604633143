
<div class="full-with">

    <app-header></app-header>


    <mat-drawer-container class="example-container" autosize>
        <mat-drawer #drawer class="example-sidenav" mode="side" [opened]="opened" >
            <app-sidebar></app-sidebar>
        </mat-drawer>

        <div class="example-sidenav-content">

            <button type="button" mat-button (click)="drawer.toggle() && eventToggle(drawer)" class="btn-menu-dash" >
                <i class="material-icons">menu</i> <span>{{txtBadge}}</span>
            </button> <br />


            <div class="content-in-div">

                <button type="button" mat-button (click)="getBack()" class="btn-menu-dash" >
                    <i class="material-icons">navigate_before</i> <span> Retourner à la liste</span>
                </button> <br />

                <div class="titre-page">
                    Consulter une expédition
                </div>
                <div *ngIf="sendBoxSelected" class="inner-div-page" >

                    <div *ngIf="sendBoxSelected.agent_msahilisho">
                        <div class="row">
                            <div class="col-md-12">
                                <label>Envoi Admin : Agent Msahilisho</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 inter-div">
                                <span> Expédition créee et suivie par </span><br />
                                [{{sendBoxSelected.agent_msahilisho.id}}]
                            </div>
                            <div class="col-md-6 inter-div">
                                <span> Agent </span><br />
                                {{sendBoxSelected.agent_msahilisho.username}}
                            </div>
                        </div>
                        <br />
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <label>Expéditeur</label>
                        </div>
                    </div>
    
                    <div class="row">
                        <div class="col-md-6 inter-div">
                            <span> Nom et prénom </span><br />
                            {{sendBoxSelected.user_sender.username}}
                        </div>
                        <div class="col-md-6 inter-div">
                            <span> Téléphone </span><br />
                            {{ sendBoxSelected.user_sender.telephone | mask: maskNumExped.mask }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 inter-div">
                            <span> E-mail</span><br />
                            {{ sendBoxSelected.user_sender.email ? sendBoxSelected.user_sender.email : '-' }}
                        </div>
                        <div class="col-md-6 inter-div">
                            <span> Adresse </span><br />
                            {{ sendBoxSelected.user_sender.adresse ? sendBoxSelected.user_sender.adresse : '-' }}
                        </div>
                    </div>


                    <div class="row margin-top-20px">
                        <div class="col-md-12">
                            <label>Trajet</label>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6 inter-div">
                            <span> Aéroport de départ </span><br />
                            {{sendBoxSelected.infos_suggest.aerodep}}
                        </div>
                        <div class="col-md-6 inter-div">
                            <span> Aéroport d'arrivée </span><br />
                            {{sendBoxSelected.infos_suggest.aeroarr}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 inter-div">
                            <span> Date de départ </span><br />
                            {{sendBoxSelected.infos_suggest.datedepot | date: 'EE dd MMMM yyyy'}}
                        </div>
                        <div class="col-md-6 inter-div">
                            <span> Date d'arrivée </span><br />
                            {{sendBoxSelected.infos_suggest.daterecup | date: 'EE dd MMMM yyyy'}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 inter-div">
                            <span> Adresse de départ </span><br />
                            {{sendBoxSelected.infos_suggest.adddepot}}
                        </div>
                        <div class="col-md-6 inter-div">
                            <span> Adresse de récupération </span><br />
                            {{sendBoxSelected.infos_suggest.addrecup}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 inter-div">
                            <span> Téléphone pays de départ </span><br />
                            {{sendBoxSelected.infos_suggest.teldepot | mask: maskNumdDep.mask}}
                        </div>
                        <div class="col-md-6 inter-div">
                            <span> Téléphone pays d'arrivée </span><br />
                            {{sendBoxSelected.infos_suggest.telrecup | mask: maskNumArr.mask}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 inter-div">
                            <span> Prix au kg </span><br />
                            {{sendBoxSelected.infos_suggest.prixkg}} {{sendBoxSelected.infos_suggest.devise}}
                        </div>
                        <div class="col-md-6 inter-div">
                            <span> Devise </span><br />
                            {{sendBoxSelected.infos_suggest.devise}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 inter-div">
                            <span> Kg disponible </span><br />
                            {{sendBoxSelected.infos_suggest.kgdispo}} Kg
                        </div>
                        <div class="col-md-6 inter-div">
                            <span> Fréquence du trajet </span><br />
                            {{sendBoxSelected.infos_suggest.freqtrajet}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 inter-div">
                            <span> Pourcentage Msahilisho Group </span><br />
                            {{sendBoxSelected.infos_suggest.pourcentms}} %
                        </div>
                        <div class="col-md-6 inter-div">
                            <span> Moyen de paiement </span><br />
                            {{sendBoxSelected.infos_suggest.moyenpaie}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 inter-div">
                            <span> Iban </span><br />
                            {{!!sendBoxSelected.infos_suggest.iban ? sendBoxSelected.infos_suggest.iban : '-'}}
                        </div>
                        <div class="col-md-6 inter-div">
                            <span> Statut du trajet </span><br />
                            {{ sendBoxSelected.infos_suggest.encours ? 
                                (sendBoxSelected.infos_suggest.etat == 0 ? 'En attente de validation' : sendBoxSelected.infos_suggest.etat == 1 ? 'En cours' : 'Réfusée par Msahilisho Group') 
                            : (sendBoxSelected.infos_suggest.etat == 0 ? 'En attente de validation' : sendBoxSelected.infos_suggest.etat == 1 ? 'Terminé' : 'Réfusée par Msahilisho Group')  }}
                        </div>
                    </div>


                    <div class="row margin-top-20px">
                        <div class="col-md-12">
                            <label>Transporteur</label>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6 inter-div">
                            <span> Nom et prénom </span><br />
                            {{sendBoxSelected.infos_suggest.user_suggest.username}}
                        </div>
                        <div class="col-md-6 inter-div">
                            <span> Téléphone </span><br />
                            {{sendBoxSelected.infos_suggest.user_suggest.telephone | mask: maskNumTran.mask}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 inter-div">
                            <span> E-mail </span><br />
                            {{sendBoxSelected.infos_suggest.user_suggest.email}}
                        </div>
                        <div class="col-md-6 inter-div">
                            <span> Adresse </span><br />
                            {{sendBoxSelected.infos_suggest.user_suggest.address}}
                        </div>
                    </div>


                    <div class="row margin-top-20px">
                        <div class="col-md-12">
                            <label>Expédition</label>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6 inter-div">
                            <span> Numéro de transaction </span><br />
                            {{sendBoxSelected.numtrans}}
                        </div>
                        <div class="col-md-6 inter-div">
                            <span> État de l'expédition </span><br />
                            <b> {{sendBoxSelected.etattrans}} </b>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 inter-div">
                            <span> Localisation du colis </span><br />
                            {{sendBoxSelected.localisation}}
                        </div>
                        <div class="col-md-6 inter-div">
                            <span> Statut </span><br />
                            {{sendBoxSelected.status_end}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 inter-div">
                            <span> Destinataire </span><br />
                            {{sendBoxSelected.nomdest}}
                        </div>
                        <div class="col-md-6 inter-div">
                            <span> Téléphone du destinataire </span><br />
                            {{sendBoxSelected.teldest | mask: maskNumDest.mask }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 inter-div">
                            <span> Adresse du destinataire (livraison) </span><br />
                            {{sendBoxSelected.addrliv}}
                        </div>
                        <div class="col-md-6 inter-div">
                            <span> Taille de l'objet envoyé </span><br />
                            {{sendBoxSelected.tailobj}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 inter-div">
                            <span> Type d'objet envoyé</span><br />
                            {{sendBoxSelected.typeobj}}
                        </div>
                        <div class="col-md-6 inter-div">
                            <span> Poids de l'objet envoyé </span><br />
                            {{sendBoxSelected.poidsobj}} Kg
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 inter-div">
                            <span> Prix au Kg </span><br />
                            {{sendBoxSelected.prixtrans}} €
                        </div>
                        <div class="col-md-6 inter-div">
                            <span> Montant total de lexpédition </span><br />
                            {{sendBoxSelected.prixtotal}} €
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 inter-div">
                            <span> Valeur de l'objet envoyé </span><br />
                            {{ !!sendBoxSelected.valeur ? sendBoxSelected.valeur : '0' }} €
                        </div>
                        <div class="col-md-6 inter-div">
                            <span> Avec ou sans garantie </span><br />
                            {{sendBoxSelected.garantie === 'oui' ? 'Avec' : 'Sans'}}
                        </div>
                    </div>
                    
                    <div class="row">
                        <div class="col-md-6 inter-div">
                            <span> Date de création </span><br />
                            {{sendBoxSelected.datecreate | date: 'EE dd MMMM yyyy'}}
                        </div>
                        <div class="col-md-6 inter-div">
                            <span> Information sur l'objet envoyé </span><br />
                            {{sendBoxSelected.infosobj}}
                        </div>
                    </div>


                    <div class="row margin-top-20px">
                        <div class="col-md-12">
                            <label>Note sur l'expédition</label>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            
                            <div class="body-message note-bubble" *ngFor="let note of lesNotes" >
                                <div class="talk-bubble tri-right round btm-left">
                                    <div class="talktext">
                                        <p>{{note.note}}</p>
                                    </div>
                                </div>
                                <div class="talk-bubble-date">
                                    {{note.create_at}}
                                </div>
                            </div>

                        </div>
                    </div>

                    <div *ngIf="sendBoxSelected.agent_msahilisho_etat">
                        <br />
                        <div class="row">
                            <div class="col-md-6 inter-div">
                                <span>Dernier changement d'état</span>
                            </div>
                            <div class="col-md-6 inter-div">
                                <span> {{sendBoxSelected.agent_msahilisho_etat.id != 0 ? 'Agent' : 'Client transporteur'}} </span><br />
                                <b> ({{sendBoxSelected.etattrans}}) </b>{{sendBoxSelected.agent_msahilisho_etat.username}}
                            </div>
                        </div>
                        <br />
                    </div>


                    <br /><br />
                    <div class="row">
                        <div class="col-md-3">
                            <button class="btn btn-valider" mat-button (click)="editEtat()" >Changer l'état de l'expédition </button>
                        </div>
                        <div class="col-md-3">
                            <button class="btn btn-bleu" mat-button (click)="editLocationNote('note')" >Ajouter une note </button>
                        </div>
                        <div class="col-md-3">
                            <button class="btn btn-bleu-clair" mat-button (click)="editLocationNote('location')" >Changer la localisation </button>
                        </div>
                        <div class="col-md-3">
                            <button class="btn btn-vert" mat-button (click)="editExpedition()" >Modifier l'expédition </button>
                        </div>
                    </div>
                    <br />


                    <div class="row margin-top-20px">
                        <div class="col-md-12">
                            <label>Récupération de colis</label>
                        </div>
                    </div>

                    <div class="row" *ngIf="preparationLivraison?.agent_recup" >
                        <div class="col-md-6 inter-div">
                            <span> Agent de récupération </span><br />
                            [{{preparationLivraison?.agent_recup.id}}]
                        </div>
                        <div class="col-md-6 inter-div">
                            <span> Agent </span><br />
                            {{preparationLivraison?.agent_recup?.username}}
                        </div>
                    </div>
                    <div class="row " *ngIf="preparationLivraison?.prep_remise_exped" >
                        <div class="col-md-12 inter-div">
                            <label>Commentaire sur la récupération de colis</label> <br />
                            {{preparationLivraison?.prep_remise_exped[0]?.comment_recup ? preparationLivraison?.prep_remise_exped[0]?.comment_recup : 'RAS'}}
                        </div>
                    </div>
                    <div class="row" *ngIf="preparationLivraison?.prep_remise_exped" >
                        <div class="col-md-6 inter-div">
                            <span> Date de récupération </span><br />
                            {{
                                preparationLivraison?.prep_remise_exped[0]?.date_recup ? (preparationLivraison?.prep_remise_exped[0]?.date_recup | date: 'EE dd MMMM yyyy') : '-'
                            }}
                        </div>
                        <div class="col-md-6 inter-div">
                            <span> Expédition payé ?  </span><br />
                            {{
                                preparationLivraison?.prep_remise_exped[0]?.exped_paye ? preparationLivraison?.prep_remise_exped[0]?.exped_paye : 'Non'
                            }}
                        </div>
                    </div>
                    <div class="row" *ngIf="preparationLivraison?.prep_remise_exped && preparationLivraison?.prep_remise_exped[0]?.sign_recup" >
                        <div class="col-md-6 inter-div" *ngIf="preparationLivraison?.prep_remise_exped[0]?.sign_recup[0]" >
                            <span> Signature Agent récupération </span><br />
                            <img [src]="preparationLivraison?.prep_remise_exped[0]?.sign_recup[0]?.signature | safeHtml" width="150"  />
                        </div>
                        <div class="col-md-6 inter-div" *ngIf="preparationLivraison?.prep_remise_exped[0]?.sign_recup[1]" >
                            <span> Signature Client récupération </span><br />
                            <img [src]="preparationLivraison?.prep_remise_exped[0]?.sign_recup[1]?.signature | safeHtml" width="150"  />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 inter-div">
                            <label>Image(s) récupération de colis</label>
                        </div>
                    </div>
                    <div class="row" *ngIf="preparationLivraison?.prep_remise_exped && preparationLivraison?.prep_remise_exped[0]?.image_recup" >
                        <div class="col-md-3 inter-div cursor" *ngFor="let imr of preparationLivraison?.prep_remise_exped[0]?.image_recup" >
                            <img [src]="imr.path | safeHtml" width="250" (click)="downloadFile( imr.path , 'recuperation')" />
                        </div>
                    </div>



                    <div class="row margin-top-20px">
                        <div class="col-md-12">
                            <label>Préparation de colis</label>
                        </div>
                    </div>

                    <div class="row" *ngIf="preparationLivraison?.agent_prep" >
                        <div class="col-md-6 inter-div">
                            <span> Agent de préparation </span><br />
                            [{{preparationLivraison?.agent_prep.id}}]
                        </div>
                        <div class="col-md-6 inter-div">
                            <span> Agent </span><br />
                            {{preparationLivraison?.agent_prep?.username}}
                        </div>
                    </div>
                    <div class="row " *ngIf="preparationLivraison?.prep_remise_exped" >
                        <div class="col-md-12 inter-div">
                            <label>Commentaire sur la préparation de colis</label> <br />
                            {{preparationLivraison?.prep_remise_exped[0]?.comment_prep ? preparationLivraison?.prep_remise_exped[0]?.comment_prep : 'RAS'}}
                        </div>
                    </div>
                    <div class="row" *ngIf="preparationLivraison?.prep_remise_exped" >
                        <div class="col-md-6 inter-div">
                            <span> Date de préparation </span><br />
                            {{
                                preparationLivraison?.prep_remise_exped[0]?.date_prep ? (preparationLivraison?.prep_remise_exped[0]?.date_prep | date: 'EE dd MMMM yyyy') : '-'
                            }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 inter-div">
                            <label>Image(s) préparation de colis</label>
                        </div>
                    </div>
                    <div class="row" *ngIf="preparationLivraison?.prep_remise_exped && preparationLivraison?.prep_remise_exped[0]?.image_prep" >
                        <div class="col-md-3 inter-div cursor" *ngFor="let imp of preparationLivraison?.prep_remise_exped[0]?.image_prep" >
                            <img [src]="imp.path | safeHtml" width="250" (click)="downloadFile( imp.path , 'preparation')" />
                        </div>
                    </div>




                    <div class="row margin-top-20px">
                        <div class="col-md-12">
                            <label>Livraison de colis</label>
                        </div>
                    </div>

                    <div class="row" *ngIf="preparationLivraison?.agent_livr" >
                        <div class="col-md-6 inter-div">
                            <span> Agent de livraison </span><br />
                            [{{preparationLivraison?.agent_livr.id}}]
                        </div>
                        <div class="col-md-6 inter-div">
                            <span> Agent </span><br />
                            {{preparationLivraison?.agent_livr?.username}}
                        </div>
                    </div>
                    <div class="row " *ngIf="preparationLivraison?.prep_remise_exped" >
                        <div class="col-md-12 inter-div">
                            <label>Commentaire sur la livraison de colis</label> <br />
                            {{preparationLivraison?.prep_remise_exped[0]?.comment_livr ? preparationLivraison?.prep_remise_exped[0]?.comment_livr : 'RAS'}}
                        </div>
                    </div>
                    <div class="row" *ngIf="preparationLivraison?.prep_remise_exped" >
                        <div class="col-md-6 inter-div">
                            <span> Date de livraison </span><br />
                            {{
                                preparationLivraison?.prep_remise_exped[0]?.date_livr ? (preparationLivraison?.prep_remise_exped[0]?.date_livr | date: 'EE dd MMMM yyyy') : '-'
                            }}
                        </div>
                        <div class="col-md-6 inter-div">
                            <span> E-mail client  </span><br />
                            {{
                                preparationLivraison?.prep_remise_exped[0]?.email_client ? preparationLivraison?.prep_remise_exped[0]?.email_client : '-'
                            }}
                        </div>
                    </div>
                    <div class="row" *ngIf="preparationLivraison?.prep_remise_exped && preparationLivraison?.prep_remise_exped[0]?.sign_livr" >
                        <div class="col-md-6 inter-div" *ngIf="preparationLivraison?.prep_remise_exped[0]?.sign_livr[0]" >
                            <span> Signature Agent livraison </span><br />
                            <img [src]="preparationLivraison?.prep_remise_exped[0]?.sign_livr[0]?.signature | safeHtml" width="150"  />
                        </div>
                        <div class="col-md-6 inter-div" *ngIf="preparationLivraison?.prep_remise_exped[0]?.sign_livr[1]" >
                            <span> Signature Client livraison </span><br />
                            <img [src]="preparationLivraison?.prep_remise_exped[0]?.sign_livr[1]?.signature | safeHtml" width="150"  />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 inter-div">
                            <label>Image(s) livraison de colis</label>
                        </div>
                    </div>
                    <div class="row" *ngIf="preparationLivraison?.prep_remise_exped && preparationLivraison?.prep_remise_exped[0]?.photo_livr" >
                        <div class="col-md-3 inter-div cursor" *ngFor="let iml of preparationLivraison?.prep_remise_exped[0]?.photo_livr" >
                            <img [src]="iml.path | safeHtml" width="250" (click)="downloadFile( iml.path , 'livraison')" />
                        </div>
                    </div>

                    <br /><br />


                </div>
            </div>

            

        </div>
    </mat-drawer-container>
    <app-footer></app-footer>

</div>
