import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditLocationComponent } from './edit-location.component'

@Injectable({
  providedIn: 'root'
})
export class EditLocationService {

  constructor(private modalService: NgbModal) { }

  public confirmer(
    element,
    titre,
    typeOuverture,
    btnOkText: string = 'Yes',
    btnCancelText: string = 'No',
    dialogSize: 'sm'|'lg' = 'lg'): Promise<boolean> {
    const modalRef = this.modalService.open(EditLocationComponent, { size: dialogSize });
    modalRef.componentInstance.element = element;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;
    modalRef.componentInstance.titre = titre;
    modalRef.componentInstance.typeOuverture = typeOuverture;
    return modalRef.result;
  }



}
