<div class="modal-header">
    <h4 class="modal-title"> {{title}} </h4>
    <button type="button" class="close" aria-label="Close" (click)="decline()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<form class="contactForm" [formGroup]="formulaireGroup" (ngSubmit)="onSubmit()" >

    <div class="partie-a-imprimer" id="partie-a-imprimer">
        <div class="row partie-header">
            <div class="col-md-6 ">
                <span class="titrePerson">Msahilisho Group SARL</span><br />
                <span>{{leReglageArray?.adressecomores}}<br />{{leReglageArray?.adresse}}</span><br />
            </div>
            <div class="col-md-6 ">
                <span class="titrePerson">{{leReglageArray?.telephone}}<br/>{{leReglageArray?.telcomores}}</span><br />
                <span class="bonpouraccor"> {{leReglageArray?.email}} </span><br />
                <span>https://msahilishogroup.com </span><br />
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 partie-logo">
                <img src="assets/imgs/msahilisho.png" class="" width="200px" />
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 partie-text" [innerHTML]="textAImprimer" ></div>
        </div>
        <div class="row partie-footer">
            <div class="col-md-6 ">
                <span class="titrePerson">Le Directeur Commercial</span><br />
                <span>M. BADAOUI Abdou</span><br />
                <img src="assets/imgs/signature.png" class="" width="100px" />
            </div>
            <div class="col-md-6 ">
                <span class="titrePerson">Le Voyageur Msahilisho Group</span><br />
                <span class="bonpouraccor"> (Signature électronique, bon pour accord) </span><br />
                <span>{{voyageurSelected.nom.toUpperCase()}} {{this.voyageurSelected.prenom}} </span><br />
            </div>
        </div>
    </div>

    <div class="modal-body">
        
            <div class="example-container-">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group" >
                            <label for="etatSelec">Mettre à jour le contrat <span>(*)</span></label>
                            <legend>Modifier et envoyer le contrat de la demande de voyage n° {{voyageurSelected.idvoyageur}} </legend>
                            <angular-editor formControlName="contenuCtrl" id="contenuCtrl" [config]="editorConfig" [ngClass]="{ 'is-invalid': submitted && f.contenuCtrl.errors }" ></angular-editor>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group" >
                            <label for="emailCtrl">E-mail  <span>(*)</span></label>
                            <legend>Adresse mail du demandeur ( voyageur ).</legend>
                            <input id="emailCtrl" type="text" class="form-control" formControlName="emailCtrl" placeholder="Veuillez renseigner une adresse mail" [ngClass]="{ 'is-invalid': submitted && f.emailCtrl.errors }">
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="messageRetour" >
                    <div class="col-md-12 div-error" *ngIf="messageRetour.ko" >
                        {{messageRetour.ko}}
                    </div>
                    <div class="col-md-12 div-success" *ngIf="messageRetour.ok" >
                        {{messageRetour.ok}}
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    Le bouton Envoyer est désactivé. Vous devez générer le document PDF, vérifier son contenu, puis cliquer sur Envoyer pour envoyer le mail au client.
                </div>
            </div>
        
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="decline()">{{ btnCancelText }}</button>
        <button class="btn btn-valider" type="button" (click)="genererPDF()" >Générer le contrat</button>
        <button class="btn btn_default" type="submit" [disabled]="contratGenerer" >{{ btnOkText }}</button> <!--  -->
    </div>

</form>