import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddDepensesComponent } from './add-depenses.component';

@Injectable({
  providedIn: 'root'
})
export class AddDepensesService {

  constructor(private modalService: NgbModal) { }

  public confirm(
    element,
    btnOkText: string = 'Yes',
    btnCancelText: string = 'No',
    dialogSize: 'sm'|'lg' = 'lg'): Promise<boolean> {
    const modalRef = this.modalService.open(AddDepensesComponent, { size: dialogSize });
    modalRef.componentInstance.element = element;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;
    return modalRef.result;
  }
}
