<div class="modal-header">
    <h4 class="modal-title"> {{title}} </h4>
    <button type="button" class="close" aria-label="Close" (click)="decline()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <form [formGroup]="infosFormGroup" >
        <div class="example-container-">

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group" >
                        <label for="nomPrenomExpediteurCtrl">Expéditeur  <span>(*)</span></label>
                        <legend>Le nom et prénom tel que écrit sur la pièce d'identité</legend>
                        <input id="nomPrenomExpediteurCtrl" type="text" class="form-control" formControlName="nomPrenomExpediteurCtrl" placeholder="Le nom et prénom" required [ngClass]="{ 'is-invalid': submitted && f.nomPrenomExpediteurCtrl.errors }" >
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group" >
                        <label for="telExpediteurCtrl">Téléphone expéditeur  <span>(*)</span></label>
                        <legend>Votre numéro de téléphone tel que défini dans votre compte</legend>
                        <div class="input-group mb-3">

                            <div class="input-group-prepend">
                                <button class="btn btn-outline-secondary dropdown-toggle" type="button"  
                                    aria-haspopup="true" aria-expanded="false" [matMenuTriggerFor]="menuExp" > 
                                    <img src="assets/imgs/{{maskTelExp.value}}" width="20" >
                                </button>
                                <mat-menu #menuExp="matMenu" class="img-select-menu" >
                                  <button *ngFor="let ntl of listTelephone" mat-menu-item (click)="telFormat( ntl , 'exp' )" >
                                      <img src="assets/imgs/{{ntl.image}}" width="30" > &nbsp; {{ntl.text}}
                                  </button>
                                </mat-menu>
                            </div>

                            <input [validation]="true" [showMaskTyped]="true" mask="{{maskTelExp.mask}}" id="telExpediteurCtrl" type="text" 
                            class="form-control" formControlName="telExpediteurCtrl" placeholder="Téléphone expéditeur" 
                            required [ngClass]="{ 'is-invalid': submitted && f.telExpediteurCtrl.errors }" >
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group" >
                        <label for="adresseExpediteurCtrl">Adresse de résidence de l'expéditeur  <span>(*)</span></label>
                        <legend>Adresse précise de l'expéditeur. </legend>
                        <input id="adresseExpediteurCtrl" type="text" class="form-control" formControlName="adresseExpediteurCtrl" placeholder="Adresse de l'expéditeur" required [ngClass]="{ 'is-invalid': submitted && f.adresseExpediteurCtrl.errors }" >
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group" >
                        <label for="emailExpediteurCtrl">E-mail de l'expéditeur  <span>(*)</span></label>
                        <legend>E-mail de l'expéditeur. </legend>
                        <input id="emailExpediteurCtrl" type="text" class="form-control" formControlName="emailExpediteurCtrl" placeholder="E-mail de l'expéditeur" required [ngClass]="{ 'is-invalid': submitted && f.emailExpediteurCtrl.errors }" >
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group" >
                        <label for="nomPrenomDestinataireCtrl">Nom et prénom du destinataire  <span>(*)</span></label>
                        <legend>Attention! Nos agents vérifieront les noms et prénoms avant de remettre le colis</legend>
                        <input id="nomPrenomDestinataireCtrl" type="text" class="form-control" formControlName="nomPrenomDestinataireCtrl" placeholder="Nom et prénom du destinataire" required [ngClass]="{ 'is-invalid': submitted && f.nomPrenomDestinataireCtrl.errors }"  >
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group" >
                        <label for="telephoneDestinataireCtrl">Téléphone destinataire <span>(*)</span></label>
                        <legend>Numéro de téléphone sur lequel le destinataire est joignable. Au format {{masktelArr.mask}}</legend>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <button class="btn btn-outline-secondary dropdown-toggle" type="button"  
                                    aria-haspopup="true" aria-expanded="false" [matMenuTriggerFor]="menuDest" > 
                                    <img src="assets/imgs/{{masktelArr.value}}" width="20" >
                                </button>
                                <mat-menu #menuDest="matMenu" class="img-select-menu" >
                                  <button *ngFor="let ntl of listTelephone" mat-menu-item (click)="telFormat( ntl , 'dest' )" >
                                      <img src="assets/imgs/{{ntl.image}}" width="30" > &nbsp; {{ntl.text}}
                                  </button>
                                </mat-menu>
                            </div>

                            <input [validation]="true" [showMaskTyped]="true" mask="{{masktelArr.mask}}" id="telephoneDestinataireCtrl" 
                            type="text" class="form-control" formControlName="telephoneDestinataireCtrl" 
                            placeholder="Veuillez renseigner le numéro de téléphone du destinataire" 
                            required [ngClass]="{ 'is-invalid': submitted && f.telephoneDestinataireCtrl.errors }" >
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group" >
                        <label for="infoObjetCtrl">Informations sur le colis  <span>(*)</span></label>
                        <legend>Information sur l'expédition</legend>
                        <textarea class="form-control" id="infoObjetCtrl" rows="3" formControlName="infoObjetCtrl" placeholder="Information sur le colis" [ngClass]="{ 'is-invalid': submitted && f.infoObjetCtrl.errors }"></textarea>
                    </div>
                </div>
            </div>


        </div>
    </form>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="decline()">{{ btnCancelText }}</button>
    <button type="button" class="btn btn_default" (click)="valider()">{{ btnOkText }}</button>
</div>
