<nav class="navbar navbar-expand-lg navbar-dark  static-header navbar-fixed-top">


    <div class="navbar-brand cursor " (click)="openPage('/dashboard')"  > 
        <img src="assets/imgs/msahilishoImportExportWhite.png" class="" width="200px" />
    </div>


    <button class="navbar-toggler btn-collapse" type="button" (click)="isCollapsed = !isCollapsed"
        aria-controls="navbarResponsive" [attr.aria-expanded]="!isCollapsed" aria-label="Toggle navigation">
        <i class="material-icons">menu</i>
    </button>


    <div [ngbCollapse]="!isCollapsed" class="collapse navbar-collapse" id="navbarResponsive">

        <ul class="navbar-nav ml-auto" >
            <li>
                <span class="text-12 colorGrisClaire">
                    Msahilisho Group, premier site de transport de colis entre particulier vers l'océan indien
                </span> 
            </li>
        </ul>
        <ul class="navbar-nav ml-auto">

            

            <li class="nav-item admnistration">

                <a class="nav-link waves-effect profile profile-user cursor" aria-label="Administration menu" [matMenuTriggerFor]="menu"  >
                    <img [src]="userConnected.photo" class="profile"  /> 
                    <span>Salut, {{userConnected.username}} <i class="material-icons">arrow_drop_down</i> </span>
                </a>

                <mat-menu #menu="matMenu" >

                    <button mat-menu-item (click)="openPage('/gerer-les-comptes')" >
                        <i class="material-icons">person</i> &nbsp; <span class="text-12 sub-menu">Gérer le(s) compte(s) </span>
                    </button>

                    <button mat-menu-item (click)="logOut()" >
                        <i class="material-icons">logout</i> &nbsp; <span class="text-12 sub-menu">Déconnexion </span>
                    </button>

                    <button mat-menu-item (click)="openWebMail('email')" >
                        <i class="material-icons">forward_to_inbox</i> &nbsp; <span class="text-12 sub-menu">Web E-mail </span>
                    </button>

                    <button mat-menu-item (click)="openWebMail('o2switch')" >
                        <i class="material-icons">dns</i> &nbsp; <span class="text-12 sub-menu">02switch server </span>
                    </button>

                    <button mat-menu-item (click)="openWebMail('sendblue')" >
                        <i class="material-icons">mark_as_unread</i> &nbsp; <span class="text-12 sub-menu">SendInblue</span>
                    </button>
                    
                </mat-menu>

            </li>

        </ul>

        
    </div>

</nav>
  