import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialAngModule } from '../matmodule/materialang.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgxMaskModule } from 'ngx-mask';

import { ConfirmComponent } from './confirm/confirm.component';
import { ConfirmService } from './confirm/confirm.service';
import { EditEtatComponent } from './edit-etat/edit-etat.component'
import { EditEtatService } from './edit-etat/edit-etat.service';
import { EditLocationService } from './edit-location/edit-location.service';
import { EditLocationComponent } from './edit-location/edit-location.component';
import { EditSendboxComponent } from './edit-sendbox/edit-sendbox.component';
import { EditSendboxService } from './edit-sendbox/edit-sendbox.service';
import { EditEtatVoyageService } from './edit-etat-voyage/edit-etat-voyage.service';
import { EditEtatVoyageComponent } from './edit-etat-voyage/edit-etat-voyage.component';
import { EditFileVoyageComponent } from './edit-file-voyage/edit-file-voyage.component';
import { EditFileVoyageService } from './edit-file-voyage/edit-file-voyage.service';
import { AddDepensesComponent } from './add-depenses/add-depenses.component';
import { AddDepensesService } from './add-depenses/add-depenses.service';

import { ConfirmMultipleService } from './confirm-multiple/confirm-multiple.service';
import { ConfirmMultipleComponent } from './confirm-multiple/confirm-multiple.component';

@NgModule({
  declarations: [
    ConfirmComponent,
    EditEtatComponent,
    EditLocationComponent,
    EditSendboxComponent,
    EditEtatVoyageComponent,
    EditFileVoyageComponent,
    AddDepensesComponent,
    ConfirmMultipleComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    MaterialAngModule,
    FormsModule, 
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    SharedModule
  ],
  entryComponents: [
    ConfirmComponent,
    EditEtatComponent,
    EditLocationComponent,
    EditSendboxComponent,
    EditEtatVoyageComponent,
    EditFileVoyageComponent,
    AddDepensesComponent,
    ConfirmMultipleComponent
  ],
  providers: [
    ConfirmService,
    EditEtatService,
    EditLocationService,
    EditSendboxService,
    EditEtatVoyageService,
    EditFileVoyageService,
    AddDepensesService,
    ConfirmMultipleService
  ]
})
export class ModalModule { }
