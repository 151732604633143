import { Component, OnInit , HostListener ,  ElementRef, ViewChild , AfterViewInit } from '@angular/core';

import {Location} from '@angular/common'
import { Router } from '@angular/router';
import { MatDrawer } from '@angular/material';
import { EventsService } from 'src/app/services/events/events.service'
import { GlobalService } from 'src/app/services/global/global.service'
import { ValidatorrService } from 'src/app/services/validatorr/validatorr.service'
import { BackendService } from 'src/app/services/backend/backend.service'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { element } from 'protractor';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';

@Component({
  selector: 'app-nouvelenvoi',
  templateUrl: './nouvelenvoi.component.html',
  styleUrls: ['./nouvelenvoi.component.scss']
})
export class NouvelenvoiComponent implements OnInit {

  userConnected
  urlImage
  opened = true
  txtBadge = "Afficher le menu"
  @ViewChild("drawer", {static: false}) drawer: MatDrawer ;

  columnsToDisplay = ['id', 'nom_prenom', 'email', 'telephone', 'adresse', 'points', 'action'];
  elementsDisplay = ['#', 'Nom et prénom', 'Email', 'Téléphone', 'Adresse', 'Total fidelité', 'Action'];
  lesUsersArray = [];
  isLinear = true;
  submitted = false

  userFormGroup: FormGroup;
  userInfoFormGroup: FormGroup;
  destInfoFormGroup: FormGroup;
  colisInfoFormGroup: FormGroup;

  masktelArr = {mask: "(+00) 0 00 00 00 00" , value: "france.png"}
  maskTelExp = {mask: "(+00) 0 00 00 00 00" , value: "france.png"}


  typeObjet = []
  typeSelected = "colis"
  infoPlaceholder : any = {}
  typeEnveloppe = []
  infosProducts = []
  messageRetour
  produiSelect = null
  listeDesTrajet = []
  envoiEncours = false;

  listTelephone = []

  constructor(
    private _formBuilder: FormBuilder,
    private location: Location ,
    private router: Router,
    private eventsService: EventsService,
    private backendService: BackendService ,
    private globalService: GlobalService,
    public dialog: MatDialog
  ) { 

    this.infosProducts = this.globalService.getInfosProducts();
    this.typeObjet = this.globalService.getTypeObjet();
    this.infoPlaceholder = this.globalService.getInfoPlaceholder();
    this.typeEnveloppe = this.globalService.getTypeEnveloppe();

    this.userFormGroup = this._formBuilder.group({
      nameFirstCtrl: ['', Validators.required],
      nameTableCtrl: [''],
    });
    this.userInfoFormGroup = this._formBuilder.group({
      nomPrenomExpediteurCtrl: ['', Validators.required],
      telExpediteurCtrl: ['33', Validators.required],
      adresseExpediteurCtrl: ['', Validators.required],
      emailExpediteurCtrl: [''],
      idExpediteurCtrl: ['']
    });
    this.destInfoFormGroup = this._formBuilder.group({
      trajetCtrl: ['', Validators.required],
      nomPrenomDestinataireCtrl: ['', Validators.required],
      adresseLivraisonCtrl: ['', Validators.required],
      telephoneDestinataireCtrl: ['33', Validators.required],
    });
    this.colisInfoFormGroup = this._formBuilder.group({
      infoObjetCtrl: ['', Validators.required],//
      poidsCtrl: ['', Validators.required],//
      tailleCtrl: ['normal'],
      typeObjetCtrl: ['colis', Validators.required],//
      garantieColisCtrl: ['non', Validators.required ],//
      valeurCtrl: ['', [Validators.required, Validators.max(3000)] ],//
      confirmationCtrl: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.listTelephone = this.globalService.getListeIndicatifTelephone();
    this.isConnectedUser()
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.mediaQuery();
      this.getAllListeNoUser();
      this.getTrajets();
    }, 1000);
  }

  isConnectedUser() {
    this.userConnected = this.globalService.getConnectedUser();
  }

  /** *********************  Sidenav **********************  */
  mediaQuery() {
    this.drawer.opened  = window.innerWidth > 900 ? true : false
    this.txtBadge = window.innerWidth > 900 ? "Cacher le menu" : "Afficher le menu"
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.mediaQuery()
  }

  eventToggle( drawer_ ){
    this.txtBadge = drawer_._opened ?  "Cacher le menu" : "Afficher le menu"
  }

  /** *********************  Sidenav **********************  */

  get f() { 
    return this.userInfoFormGroup.controls; 
  }
  get f1() { 
    return this.userFormGroup.controls; 
  }
  get f2() { 
    return this.destInfoFormGroup.controls; 
  }
  get f3() { 
    return this.colisInfoFormGroup.controls; 
  }

  getTrajets() {

    let annonce = {
      limite: 0,
      trajet_de: '',
      trajet_a: '',
      limite_all: 20,
      etat: 1
    }
    this.listeDesTrajet = [];
    this.backendService.post( '/suggest/list' , annonce  )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.messageRetour = resultat.status != 0 ? {ko: resultat.error.error} : {ko: "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."}
      } else {
        this.listeDesTrajet = resultat;
      }
      console.log( resultat )
    })
    .catch( error => {
      this.messageRetour = {ko: "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."}
    })
  }


  getAllListeNoUser() {
    this.lesUsersArray = [];
    this.backendService.get(`/senbox/nouser-list` )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.messageRetour = resultat.status != 0 ? {ko: resultat.error.error} : {ko: "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."}
      } else {
        // yes 
        this.lesUsersArray = resultat;
        console.log( 'resultat ', resultat )
      }
    })
    .catch( error => {
      this.messageRetour = {ko: "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."}
    })
  }

  onChangeInput( event ) {
    console.log('Change event ', event);
    this.userFormGroup.patchValue({
      nameTableCtrl: null
    })
    this.userInfoFormGroup.patchValue({
      nomPrenomExpediteurCtrl: event.target.value,
      telExpediteurCtrl: '33',
      adresseExpediteurCtrl: '',
      emailExpediteurCtrl: '',
      idExpediteurCtrl: ''
    });
  }


  onTableAction( event ) {
    console.log('onTableAction Table action ', event)
    if (event.method === 'list') {
      this.userFormGroup.patchValue({
        nameFirstCtrl: event.element.nom_prenom,
        nameTableCtrl: event.element
      })
    }

  }

  changeBTN( action ) {
    this.submitted = false;
    if( action == "user-primo" ) {
      if (this.userFormGroup.invalid) {
        this.submitted = true
        return;
      }

      const { nameTableCtrl: elemnt } = this.userFormGroup.value;
      if ( elemnt ) {
        console.log('Element select ', elemnt)
        this.maskTelExp = this.globalService.maskSaisi( elemnt.telephone );
        this.userInfoFormGroup.patchValue({
          nomPrenomExpediteurCtrl: elemnt.nom_prenom,
          telExpediteurCtrl: elemnt.telephone,
          adresseExpediteurCtrl: elemnt.adresse,
          emailExpediteurCtrl: elemnt.email,
          idExpediteurCtrl: elemnt.id
        })
      }
    }
// +33787654321 +33 7 87 65 43 21   33 60 50 40 40 28  +33 1 23 45 67 89
    if( action == "utilisateur" ) {
      if (this.userInfoFormGroup.invalid) {
        this.submitted = true
        return;
      }
      console.log(this.userInfoFormGroup.getRawValue())
    }

    if( action == "destinataire" ) {
      console.log( this.destInfoFormGroup )
      if (this.destInfoFormGroup.invalid) {
        this.submitted = true
        return;
      }
      console.log(this.destInfoFormGroup.getRawValue())
    }

  }


  telFormat( ntl , type ) {
    console.log('ntel ' , ntl)
    if (type === 'exp') {
      this.userInfoFormGroup.patchValue( {
        telExpediteurCtrl: ntl.text
      } );
      this.maskTelExp.value = ntl.image;
      this.maskTelExp.mask = ntl.mask;
    } else {
      this.destInfoFormGroup.patchValue( {
        telephoneDestinataireCtrl: ntl.text
      } );
      this.masktelArr.value = ntl.image;
      this.masktelArr.mask = ntl.mask;
    }
  }

  onTypeSelection( event ){
    this.typeSelected = this.f3.typeObjetCtrl.value
    if( this.typeSelected === "enveloppe" ) {
      this.colisInfoFormGroup.patchValue({
        tailleCtrl: this.typeEnveloppe[1].value
      })
    }
  }


  calculRemiseFidelite() {
    const { nameTableCtrl: elemnt } = this.userFormGroup.value;
    if (!!elemnt && elemnt.points ) {
      const totalPoints = parseFloat(elemnt.points);
      return totalPoints;
    } else {
      return 0;
    }
  }

  onSubmit(){
    this.messageRetour = null
    this.submitted = true
    let msahilisho = 0

    if ( !this.globalService.canAdd() ) {
      this.eventsService.toastrwaring("Vous ne disposez pas des autorisations requises pour cette action.")
      return;
    }

    if( !this.produiSelect ) {
      this.messageRetour = {ko: "Veuillez sélectionner un produit dans la liste." }
      return;
    }
    if( this.produiSelect && this.produiSelect.id === 'autre' && this.f3.poidsCtrl.value === "" ) {
      this.messageRetour = {ko: "Le poids de votre colis est obligatoire." }
      return;
    }

    if ( this.produiSelect.id !== 'autre' ) {
      this.colisInfoFormGroup.patchValue({
        typeObjetCtrl: "colis",
        poidsCtrl: this.produiSelect.poids,
        tailleCtrl: this.produiSelect.nomproduct
      })
    }

    console.log(this.userFormGroup)
    console.log(this.userInfoFormGroup)
    console.log(this.destInfoFormGroup)
    console.log(this.colisInfoFormGroup)

    if (this.userFormGroup.invalid) {
      this.messageRetour = {ko: "Une erreur a été détectée dans l'onglet [Sélectionner un expéditeur] "}
      return;
    }
    if (this.userInfoFormGroup.invalid) {
      this.messageRetour = {ko: "Une erreur a été détectée dans l'onglet [Infos expéditeur] "}
      return;
    }
    if (this.destInfoFormGroup.invalid) {
      this.messageRetour = {ko: "Une erreur a été détectée dans l'onglet [Infos destinataire] "}
      return;
    }
    if (this.colisInfoFormGroup.invalid) {
      this.messageRetour = {ko: "Une erreur a été détectée dans l'onglet [Infos colis]."}
      return;
    }

   const { trajetCtrl: trajetSelect } = this.destInfoFormGroup.value;

    if ( parseFloat( this.f3.valeurCtrl.value ) < 0  ) {
      this.messageRetour = {ko: "Veuillez vérifier les informations saisies. La valeur de votre colis doit être un nombre positif." }
      return;
    }

    //Calculer le prix
    let prixTotal;
    let lePoidsAukg;
    const valeurColis = parseFloat( this.f3.valeurCtrl.value );
    if( this.produiSelect.id === 'autre' ) {
      lePoidsAukg = parseFloat( this.f3.poidsCtrl.value ) 
      if( parseInt( this.f3.poidsCtrl.value ) < 1 ) {
        lePoidsAukg = 1
      }
      prixTotal = ( lePoidsAukg * parseFloat( trajetSelect.prixkg ) ).toFixed(2)
      msahilisho = 0
    } else {
      // Produit dans la liste des produits non pesés de msahilisho
      prixTotal = (valeurColis * this.globalService.pourcentageGarantie).toFixed(2);
      lePoidsAukg = parseFloat( this.produiSelect.poids ); // produits non pesable donc zero kg
      msahilisho = this.produiSelect.id
    }

    let prixavecgarantie;
    if ( this.f3.garantieColisCtrl.value === 'non' ) {
      prixavecgarantie = prixTotal;
    } else {
      prixavecgarantie = (parseFloat(prixTotal) + (valeurColis * this.globalService.pourcentageGarantie)).toFixed(2);
    }

    if ( lePoidsAukg > parseFloat( trajetSelect.kgdispo ) ) {
      this.messageRetour = {ko: `Le nombre de kilos demandés ${lePoidsAukg} kg est suppérieur au nombre de kilos disponibles (${trajetSelect.kgdispo} kg)` }
      return;
    }
    
 
    let laDate = new Date()
    let note = [ {"note": "Demande de prise en charge", "create_at": `${laDate.getDate()}/${laDate.getMonth() +1}/${laDate.getFullYear()}` } ]
    const totalPoints = this.calculRemiseFidelite();
    const laRemisecent = this.globalService.getRemisecentPoints();

    let prixTotalAEnregistrer = prixavecgarantie;
    if ( totalPoints >= 100 ) {
      const prixavecgarantieremise = prixavecgarantie;
      note.push(
        {
          "note": `Vous avez cumulé ${totalPoints} points de fidélité. Une remise de ${laRemisecent}% est appliquée à cette expédition. Montant sans la remise (${prixavecgarantieremise})€.`,
          "create_at": `${laDate.getDate()}/${laDate.getMonth() +1}/${laDate.getFullYear()}` 
        }
      )
      prixTotalAEnregistrer = ( parseFloat( prixTotalAEnregistrer ) * (laRemisecent / 100) ).toFixed(2)
    }


    // Valeur et garantie
    let box = {
      iduserexp: this.f.idExpediteurCtrl.value !== '' ? this.f.idExpediteurCtrl.value : 0,
      nameerexp: this.f.nomPrenomExpediteurCtrl.value,
      telrecup: this.f.telExpediteurCtrl.value,
      addrexp: this.f.adresseExpediteurCtrl.value,
      mailexp: this.f.emailExpediteurCtrl.value,

      nomdest: this.f2.nomPrenomDestinataireCtrl.value,
      addrliv: this.f2.adresseLivraisonCtrl.value,
      teldest: `${this.f2.telephoneDestinataireCtrl.value}`,

      typeobj: this.f3.typeObjetCtrl.value,
      tailobj: this.f3.tailleCtrl.value,
      poidsobj: lePoidsAukg,
      infosobj: this.f3.infoObjetCtrl.value,
      numtrans: `TR-${(Math.random().toString(36).substring(2, 7) + Math.random().toString(36).substring(2, 7) ).toString().toUpperCase()}-MS${this.userConnected.id}-${(Math.random().toString(36).substring(2, 12) ).toString().toUpperCase()}`,
      etattrans: "Validée par le transporteur",
      prixtrans: prixTotal,
      notestrans: JSON.stringify( note ),
      idtrajet: trajetSelect.id,
      idusertra: trajetSelect.user_suggest.id,
      msahilisho: msahilisho,
      
      valeur: valeurColis,
      garantie: this.f3.garantieColisCtrl.value,
      prixtotal: prixTotalAEnregistrer,
      agent: parseInt( this.userConnected.id ),

      totalPoints: totalPoints
    }
    console.log('La box avant envoi ', box);

    if ( this.f3.garantieColisCtrl.value === 'non' ) {
      const prixenvoisansg = (valeurColis * this.globalService.sansGarantie).toFixed(2)
      let autreMessage3Point = '';
      if ( totalPoints >= 100 ) {
        autreMessage3Point = `L'expédition vous coûtera (sans la remise Msahilisho) ${prixTotal}€ d'envoi + 0€ de garantie, soit ${prixTotal}€.
        Grâce aux systèmes de fidélisation Msahilisho Group, vous avez accumulé ${totalPoints} points de fidélité.
        Vous allez donc avoir une remise de ${laRemisecent}% sur cette expédition pour vous remercier de votre confiance et votre fidélité.`
      } else {
        autreMessage3Point = `L'expédition vous coûtera ${prixTotal}€ d'envoi + 0€ de garantie, soit ${prixTotal}€.`;
      }

      const message = "La garantie Msahilisho Group vous permet de vous faire rembourser ou de remplacer un colis perdu ou détérioré.";
      const autreMessage = "Nous vous conseillons fortement de l'ajouter à votre envoi et de bénéficier la garantie 100% prise en charge.";
      const autreMessage2 = "Sans cette garantie, vous ne toucherez que " + (this.globalService.sansGarantie * 100) + "% de " + valeurColis + "€ soit " + prixenvoisansg + "€ en cas de perte ou détérioration de colis.";
      const autreMessage3 = autreMessage3Point;
      this.openDialog( box, message, autreMessage, autreMessage2, autreMessage3 );
    } else {
      const valeurPourcentage = this.globalService.pourcentageGarantie * 100;
      const prixenvois = (valeurColis * this.globalService.pourcentageGarantie).toFixed(2)
      let autreMessage3Point = '';
      if ( totalPoints >= 100 ) {
        autreMessage3Point = `L'expédition vous coûtera (sans la remise Msahilisho) ${prixTotal}€ d'envoi + ${prixenvois}€ de garantie, soit ${ prixavecgarantie }€..
        Grâce aux systèmes de fidélisation Msahilisho Group, vous avez accumulé ${totalPoints} points de fidélité.
        Vous allez donc avoir une remise de ${laRemisecent}% sur cette expédition pour vous remercier de votre confiance et votre fidélité.`
      } else {
        autreMessage3Point = `L'expédition vous coûtera ${prixTotal}€ d'envoi + ${prixenvois}€ de garantie, soit ${ prixavecgarantie }€.`;
      }
      const message = "La garantie Msahilisho Group vous permet de vous faire rembourser ou de remplacer un colis perdu ou détérioré.";
      const autreMessage = "Vous avez choisi la garantie 100% prise en charge Msahilisho Group. \
      Avec cette garantie, vous toucherez la totalité de la valeur de votre colis soit " + valeurColis + "€ en cas de perte ou détérioration de colis."
      const autreMessage2 = "Cette protection, prise en charge à 100% vous coutera " + prixenvois + "€ soit " + valeurPourcentage + "% de la valeur de votre colis.";
      const autreMessage3 = autreMessage3Point;
      this.openDialog( box, message, autreMessage, autreMessage2, autreMessage3);
    }

  }

  openDialog( box, message, autreMessage, autreMessage2, autreMessage3 ): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '550px',
      data: {
        titre: 'Confirmez votre demande', 
        message: message,
        autreMessage: autreMessage,
        autreMessage2: autreMessage2,
        autreMessage3: autreMessage3
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if( !!result ) {
        this.envoiEncours = true;
        this.backendService.post('/senbox/addnouser', box )
        .then( resultat => {
          this.envoiEncours = false;
          if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
            this.eventsService.errorsmsg( "Erreur lors de la mise en rélation avec le transporteur" )
            this.messageRetour = {ko: resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement." }
          } else {
            this.messageRetour = {ok: resultat.text}
            this.eventsService.successmsg("Votre demande a été transmise au transporteur")
            setTimeout( () => {
              this.router.navigate(["/dashboard"] );
              window.scroll(0,0);
            }, 2000)
          }
        })
        .catch( error => {
          this.envoiEncours = false;
        })
      }
    });
  }

  chooseEnvoieMsahilisho(envoie) {
    this.produiSelect = envoie
    this.messageRetour = null
  }

  openCGU(){
    window.open( `${this.globalService.getUrlFront()}#/conditions-generales-utilisations`  , "_blank")
  }


}
