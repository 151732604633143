import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { version } from '../../../package.json';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  versionApplication = environment.environment === 'production' ? version : `${version}-${environment.environment}`
  constructor() { }

  ngOnInit(): void {
  }

}
