import { Component, OnInit , Input  } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BackendService } from '../../services/backend/backend.service'
import { GlobalService } from '../../services/global/global.service'
import { EventsService } from '../../services/events/events.service'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-edit-file-voyage',
  templateUrl: './edit-file-voyage.component.html',
  styleUrls: ['./edit-file-voyage.component.scss']
})
export class EditFileVoyageComponent implements OnInit {

  @Input() element
  @Input() type; // contrat, billet aller-retour
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() title : string = "Ajouter un fichier à la demande de voyage"

  etatVoyage = []
  messageRetour
  fileFormGroup: FormGroup;
  urlSelectJustif = { url: '', msg: 'Justificatif d\'identité' };
  progressMessage = {progress: null, ok: null, ko: null};
  submitted = false;

  constructor(
    private activeModal: NgbActiveModal ,
    private backendService: BackendService ,
    private globalService: GlobalService,
    private eventsService: EventsService,
    private _formBuilder: FormBuilder,
  ) { 
    this.fileFormGroup = this._formBuilder.group({
      importFile: ['']
    });
  }

  ngOnInit() {

  }

  ngAfterContentInit() {
    if ( this.type === 'contrat' ) {
      this.urlSelectJustif.msg = 'Contrat voyageur'
    } else if ( this.type === 'billetaller' ) {
      this.urlSelectJustif.msg = 'Billet aller voyageur'
    } else if ( this.type === 'billetretour' ) {
      this.urlSelectJustif.msg = 'Billet retour voyageur'
    } else if ( this.type === 'pieceident' ) {
      this.urlSelectJustif.msg = 'Pièce identité du voyageur'
    }
  }

  get f() { 
    return this.fileFormGroup.controls; 
  }

  onFileChange( event ) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      this.urlSelectJustif.url = event.target.files[0]
      reader.readAsDataURL(event.target.files[0]);
      this.urlSelectJustif.msg = event.target.files[0].name
    }
  }

  public decline() {
    this.activeModal.close({status: false});
  }

  public dismiss() {
    this.decline();
  }

  public valider() {
    this.submitted = true
    this.progressMessage.ko = null;
    this.progressMessage.ok = null;
    this.progressMessage.progress = null;

    if ( this.urlSelectJustif.url === '' ) {
      this.progressMessage.ko = " Le fichier est obligatoire."
      return;
    }

    const formData = new FormData();
    if ( this.type === 'contrat' ) {
      formData.append('contrat', this.urlSelectJustif.url);
    } else if ( this.type === 'billetaller' ) {
      formData.append('billetaller', this.urlSelectJustif.url);
    } else if ( this.type === 'billetretour' ) {
      formData.append('billetretour', this.urlSelectJustif.url);
    } else if ( this.type === 'pieceident' ) {
      formData.append('pieceident', this.urlSelectJustif.url);
    }
    formData.append('agent', this.globalService.getConnectedUser().id);

    console.log( formData );

    this.backendService.upload( formData, `/voyageur/upload/${this.element.idvoyageur}`)
    .subscribe (
      (res) => {
        let uploadResponse = res;
        if( uploadResponse.status === 'error' ) {
          this.eventsService.errorsmsg( uploadResponse.message )
        } else {
          if( uploadResponse.status == 'progress' ) {
            this.progressMessage.progress = uploadResponse.message;
          }
          if( uploadResponse.text ) {
            this.progressMessage.ok = "Félicitations ! Votre demande a été enregistrée avec succès.";
            setTimeout( () => {
              this.activeModal.close({status: true});
            }, 1000)
          }
        }
      }, (err) => {
        if( typeof err === "string")
          this.eventsService.errorsmsg( err )
          this.progressMessage.ko = "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
      }
    );

  }

}
