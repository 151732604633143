import { Component, OnInit , HostListener ,  ElementRef, ViewChild , AfterViewInit } from '@angular/core';

import {Location} from '@angular/common'
import { Router , ActivatedRoute } from '@angular/router';
import { MatDrawer } from '@angular/material';
import {FormControl, FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';

import { EventsService } from '../../services/events/events.service'
import { GlobalService } from '../../services/global/global.service'
import { ValidatorrService } from '../../services/validatorr/validatorr.service'
import { BackendService } from '../../services/backend/backend.service';
import { UtilsService } from '../../services/utils/utils.service';

@Component({
  selector: 'app-reglages',
  templateUrl: './reglages.component.html',
  styleUrls: ['./reglages.component.scss']
})
export class ReglagesComponent implements OnInit {

  userConnected

  opened = true
  txtBadge = "Afficher le menu"

  formulaireGroup: FormGroup;
  submitted = false;
  retourMessage = { ok: null, ko: null };
  leReglageArray = [];

  showFormulaire = true;

  @ViewChild("drawer", {static: false}) drawer: MatDrawer ;

  maxListItems = 10;
  editorConfig

  constructor(
    private location: Location ,
    private router: Router,
    private eventsService: EventsService,
    private backendService: BackendService ,
    private globalService: GlobalService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private utilsService: UtilsService
  ) { 
    this.formulaireGroup = this.formBuilder.group({
      nomCtrl: [ '', [Validators.required, Validators.maxLength(140)]],//
      emailCtrl: [ '', [Validators.required, ValidatorrService.emailValidator] ],//
      telephoneFrCtrl: [ '', [Validators.required]],//
      telephoneKmCtrl: [ '', [Validators.required]],//
      adresseFrCtrl: [ '' ],//
      adresseKmCtrl: [ ''],//
      descriptionsCtrl: [ '' , Validators.required ],

      ouverturecompteCtrl: [ '' ],//
      colisenvoyeCtrl: [ '' ],//
      recommandationCtrl: [ '' ],//
      ajouavisCtrl: [ '' ],//
      remisecentpointsCtrl: [ '' ],//
    });
  }

  ngOnInit(): void {
    this.editorConfig = this.globalService.getEditorConfig();
    this.isConnectedUser()
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.mediaQuery()
      this.displayPage()
    }, 1000);
  }

  isConnectedUser() {
    this.userConnected = this.globalService.getConnectedUser();
  }


  displayPage() {

    this.backendService.get( `/contact/footer` )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.retourMessage.ko = resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement.!"
      } else {
        console.log( 'resultat ', resultat );
        this.leReglageArray = resultat;
        this.initFormulaire()
      }
     })
     .catch( error => {
      this.retourMessage.ko = "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement !"
    })
  }

  get f() { 
    return this.formulaireGroup.controls; 
  }

  resetFormulaire() {
    this.retourMessage.ok = null;
    this.retourMessage.ko = null;
    this.submitted = false;
  }

  showOrHideFormulaure() {
    this.showFormulaire = !this.showFormulaire;
  }

  initFormulaire() {
    this.showFormulaire = true;
    this.formulaireGroup.patchValue({
      nomCtrl: this.leReglageArray[0].nom,
      emailCtrl: this.leReglageArray[0].email,
      telephoneFrCtrl: (this.leReglageArray[0].telephone).replace('<br />', ' / '),
      telephoneKmCtrl: (this.leReglageArray[0].telcomores).replace('<br />', ' / '),
      adresseFrCtrl: this.leReglageArray[0].adresse,
      adresseKmCtrl: this.leReglageArray[0].adressecomores,
      descriptionsCtrl: this.leReglageArray[0].nous,
      ouverturecompteCtrl: this.leReglageArray[0].ouverturecompte,
      colisenvoyeCtrl: this.leReglageArray[0].colisenvoye,
      recommandationCtrl: this.leReglageArray[0].recommandation,
      ajouavisCtrl: this.leReglageArray[0].ajouavis,
      remisecentpointsCtrl: this.leReglageArray[0].remisecentpoints
    });
    window.scroll(0,0);
  }


  onSubmit() {

    this.submitted = true;
    this.retourMessage.ok = null;
    this.retourMessage.ko = null;

    if (this.formulaireGroup.invalid) {
      this.retourMessage.ko = "Une erreur a été détectée sur le formulaire. Certains champs sont obligatoires !";
      return;
    }

    const formSend = {
      nom: this.f.nomCtrl.value,
      email: this.f.emailCtrl.value,
      telephone: (this.f.telephoneFrCtrl.value).replace('/', '<br />'),
      adresse: this.f.adresseFrCtrl.value,
      nous: this.f.descriptionsCtrl.value,
      telcomores: (this.f.telephoneKmCtrl.value).replace('/', '<br />'),
      adressecomores: this.f.adresseKmCtrl.value,
      ouverturecompte: this.f.ouverturecompteCtrl.value,
      colisenvoye: this.f.colisenvoyeCtrl.value,
      recommandation: this.f.recommandationCtrl.value,
      ajouavis: this.f.ajouavisCtrl.value,
      remisecentpoints: this.f.remisecentpointsCtrl.value,
      agent: this.globalService.getConnectedUser().id
    }

    console.log('Form Before Send ', formSend)
    this.editReglage( formSend );

  }


  editReglage( formSend ) {

    if ( !this.globalService.canEdit() ) {
      this.eventsService.toastrwaring("Vous ne disposez pas des autorisations requises pour cette action.")
      return;
    }

    this.backendService.post( `/contact/update-parametrage/${this.leReglageArray[0].id}`, formSend )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.retourMessage.ko = resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
      } else {
        this.retourMessage.ok = resultat.text;
        this.globalService.setRemisecentPoints( formSend.remisecentpoints );
        setTimeout(() => {
          this.resetFormulaire();
          this.displayPage();
        }, 2000);
      }
     })
     .catch( error => {
      this.retourMessage.ko = "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
    })
  }

  /** *********************  Sidenav **********************  */
  mediaQuery() {
    this.drawer.opened  = window.innerWidth > 900 ? true : false
    this.txtBadge = window.innerWidth > 900 ? "Cacher le menu" : "Afficher le menu"
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.mediaQuery()
  }

  eventToggle( drawer_ ){
    this.txtBadge = drawer_._opened ?  "Cacher le menu" : "Afficher le menu"
  }

}
