import { Component, OnInit , HostListener ,  ElementRef, ViewChild , AfterViewInit } from '@angular/core';

import {Location} from '@angular/common'
import { Router } from '@angular/router';
import { MatDrawer } from '@angular/material';
import { EventsService } from 'src/app/services/events/events.service'
import { GlobalService } from 'src/app/services/global/global.service'
import { ValidatorrService } from 'src/app/services/validatorr/validatorr.service'
import { BackendService } from 'src/app/services/backend/backend.service'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmService } from 'src/app/modal/confirm/confirm.service';
import { PrintSrvService } from 'src/app/services/print-srv/print-srv.service';

@Component({
  selector: 'app-suivi-voyageurs',
  templateUrl: './suivi-voyageurs.component.html',
  styleUrls: ['./suivi-voyageurs.component.scss']
})
export class SuiviVoyageursComponent implements OnInit {

  userConnected
  urlImage
  opened = true
  txtBadge = "Afficher le menu"
  @ViewChild("drawer", {static: false}) drawer: MatDrawer ;
  listVoyageurs = [];
  searchFormGroup: FormGroup;
  statutVoyage = [];
  loadFinished = false
  retourMessage: any

  columnsToDisplay = ['id', 'nom_prenom', 'numTel', 'email', 'datedepart', 'datearrivee', 'aerodep', 'aeroarr', 'action'];
  elementsDisplay = ['#', 'Nom et prénom', 'Téléphone', 'E-mail', 'Date dep.', 'Date ret.', 'Aéroport dep.', 'Aéroport arr.', 'Action'];

  constructor(
    private location: Location ,
    private router: Router,
    private eventsService: EventsService,
    private backendService: BackendService ,
    private globalService: GlobalService,
    private _formBuilder: FormBuilder,
    private confirmService: ConfirmService,
    private printSrvService: PrintSrvService
  ) { 
    this.searchFormGroup = this._formBuilder.group({
      statutCtrl: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.statutVoyage = this.globalService.getStatutVoyage();
    this.isConnectedUser()
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.searchFormGroup.patchValue({
        statutCtrl: this.statutVoyage[0].statut
      })
      this.mediaQuery()
      this.displayPage()
    }, 1000);
  }

  isConnectedUser() {
    this.userConnected = this.globalService.getConnectedUser();
  }

  /** *********************  Sidenav **********************  */
  mediaQuery() {
    this.drawer.opened  = window.innerWidth > 900 ? true : false
    this.txtBadge = window.innerWidth > 900 ? "Cacher le menu" : "Afficher le menu"
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.mediaQuery()
  }

  eventToggle( drawer_ ){
    this.txtBadge = drawer_._opened ?  "Cacher le menu" : "Afficher le menu"
  }

  get f(){
    return this.searchFormGroup.controls;
  }

  displayPage(){
    let voyageur = {
      statut: this.searchFormGroup.controls.statutCtrl.value
    }
    this.loadFinished = false;
    this.backendService.post(`/voyageur/get-list-for-admin` , voyageur )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.listVoyageurs = [];
      } else {
        console.log( resultat )
        this.listVoyageurs = resultat;
      }
      this.loadFinished = true
    })
    .catch( error => {
        this.listVoyageurs = [];
      this.loadFinished = true
      this.retourMessage = {ko: "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."}
    })
  }

  onStatutSelection(event) {
    console.log(event)
    this.listVoyageurs = [];
    this.displayPage();
  }

  onTableAction( event) {
    console.log('onTableAction Table action ', event)
    const element = event.element
    if (event.method === 'list') {
      this.router.navigate([`/les-voyageurs/msahilisho/lister-les-demandes-voyageur/4-2/${element.idvoyageur}`])
      window.scroll(0,0);
    }

  }


}
