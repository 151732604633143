import { Component, OnInit , HostListener ,  ElementRef, ViewChild , AfterViewInit } from '@angular/core';

import {Location} from '@angular/common'
import { Router } from '@angular/router';
import { MatDrawer } from '@angular/material';
import { EventsService } from 'src/app/services/events/events.service'
import { GlobalService } from 'src/app/services/global/global.service'
import { ValidatorrService } from 'src/app/services/validatorr/validatorr.service'
import { BackendService } from 'src/app/services/backend/backend.service'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-notifier-utilisateur',
  templateUrl: './notifier-utilisateur.component.html',
  styleUrls: ['./notifier-utilisateur.component.scss']
})
export class NotifierUtilisateurComponent implements OnInit {

  userConnected
  urlImage
  opened = true
  txtBadge = "Afficher le menu"
  @ViewChild("drawer", {static: false}) drawer: MatDrawer ;

  searchFormGroup: FormGroup;
  listeDesTrajet = []
  retourMessage : any;
  loadFinished = false;
  submitted = false
  envoieEncours = false;

  constructor(
    private location: Location ,
    private router: Router,
    private eventsService: EventsService,
    private backendService: BackendService ,
    private globalService: GlobalService,
    private _formBuilder: FormBuilder,
  ) {
    this.searchFormGroup = this._formBuilder.group({
      trajetCtrl: ['', Validators.required],
      typeEnvoiCtrl: ['nouveau', Validators.required ],
    });
   }

  ngOnInit(): void {
    this.isConnectedUser()
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.mediaQuery()
      this.getTrajets();
    }, 1000);
  }

  isConnectedUser() {
    this.userConnected = this.globalService.getConnectedUser();
  }

  /** *********************  Sidenav **********************  */
  mediaQuery() {
    this.drawer.opened  = window.innerWidth > 900 ? true : false
    this.txtBadge = window.innerWidth > 900 ? "Cacher le menu" : "Afficher le menu"
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.mediaQuery()
  }

  eventToggle( drawer_ ){
    this.txtBadge = drawer_._opened ?  "Cacher le menu" : "Afficher le menu"
  }



  getTrajets() {

    let annonce = {
      limite: 0,
      trajet_de: '',
      trajet_a: '',
      limite_all: 20,
      etat: 1
    }
    this.listeDesTrajet = [];
    this.backendService.post( '/suggest/list-admin' , annonce  )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.retourMessage = {ko: resultat.status != 0 ? resultat.error.error : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."}
      } else {
        this.listeDesTrajet = resultat;
      }
      console.log( resultat )
      this.loadFinished = true
    })
    .catch( error => {
      this.loadFinished = true
      this.retourMessage = {ko: "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."}
    })
  }

  get f() { 
    return this.searchFormGroup.controls; 
  }


  unFormateDate( la_date ){
    let la_date_ = la_date.split("-");
    return `${la_date_[2]}/${la_date_[1]}/${la_date_[0]}`
  }

  envoyerCampagne() {

    this.submitted = true;
    this.retourMessage = {};

    if ( !this.globalService.canAdd() ) {
      this.eventsService.toastrwaring("Vous ne disposez pas des autorisations requises pour cette action.")
      return;
    }
    
    // stop here if form is invalid
    if (this.searchFormGroup.invalid) {
      return;
    }

    const {trajetCtrl: trajet, typeEnvoiCtrl: type } = this.searchFormGroup.value;
    console.log('Trajet ', trajet )
    console.log('type ', type )

    if ( !trajet.encours ) {
      this.retourMessage = {ko: "Trajet terminé ! Vous ne pouvez plus envoyer un e-mail pour ce trajet."}
      this.eventsService.errorsmsg( this.retourMessage.ko )
      this.envoieEncours = false;
      return;
    }

    const DESTINATION_TRAJET_RAPPELKG = `${trajet.kgdispo}kg disponibles pour le trajet ${trajet.aerodep} => ${trajet.aeroarr}`;
    const DESTINATION_TRAJET_NEWTRAJE = `${trajet.aerodep} => ${trajet.aeroarr}`;
    const SUBJECT_RAPPELKG = `Encore quelques kilos disponibles de ${trajet.aerodep} à ${trajet.aeroarr} pour le ${this.unFormateDate( trajet.datedepot )}`;
    const SUBJECT_NEWTRAJE = `Nouveau trajet ${trajet.aerodep} => ${trajet.aeroarr} pour le ${this.unFormateDate( trajet.datedepot )}`;
    const dateDuJour = new Date()
    let campagne = {
      campaignId: type === 'nouveau' ? environment.campagneIdNouveauTrajet : environment.campagneIdKilosdispo,
      params: {
        ANNEE_ENCOURS: dateDuJour.getFullYear(),
        DATE_NOUVEAU_TRAJET: this.unFormateDate( trajet.datedepot ),
        DESTINATION_TRAJET: type === 'nouveau' ? DESTINATION_TRAJET_NEWTRAJE : DESTINATION_TRAJET_RAPPELKG,
        SUBJECT: type === 'nouveau' ? SUBJECT_NEWTRAJE : SUBJECT_RAPPELKG
      },
      listIds: [
        environment.listeDiffusionGlobale
      ],
      titre: type === 'nouveau' ? DESTINATION_TRAJET_NEWTRAJE : DESTINATION_TRAJET_RAPPELKG,
      message: type === 'nouveau' ? SUBJECT_NEWTRAJE : SUBJECT_RAPPELKG,
      deepLink: '/tabs/sendbox'
    }

    console.log(campagne)
    this.envoieEncours = true;

    this.backendService.post( `/emails/send-campaign` , campagne )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.retourMessage = {ko: resultat.status != 0 ? resultat.error.error : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."}
      } else {
        this.retourMessage = {ok: "La campagne est bien activée et est en cours d'exécution."};
        this.eventsService.successmsg( "La campagne est bien activée et est en cours d'exécution." )
      }
      this.loadFinished = true
      this.envoieEncours = false;
    })
    .catch( error => {
      this.loadFinished = true
      this.envoieEncours = false;
      this.retourMessage = {ko: "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."}
    })

  }




}
