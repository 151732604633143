
<div class="full-with">

    <app-header></app-header>


    <mat-drawer-container class="example-container" autosize>
        <mat-drawer #drawer class="example-sidenav" mode="side" [opened]="opened" >
            <app-sidebar></app-sidebar>
        </mat-drawer>

        <div class="example-sidenav-content">

            <button type="button" mat-button (click)="drawer.toggle() && eventToggle(drawer)" class="btn-menu-dash" >
                <i class="material-icons">menu</i> <span>{{txtBadge}}</span>
            </button> <br />


            <div class="content-in-div">

            <!--
                <div class="row">
                    <div class="col-md-12 text-center colorBleu text-h6 cursor  ">
                        <span class="btn btnBleu" (click)="initFormulaire()" > Mettre à jour mes informartions personnelles</span>
                    </div>
                </div>

            -->

                <div class="form partie-formulaire margin-top-40px ">

                    <div class="row">
                        <div class="col-md-12 text-h4 colorBleu">
                            Mise à jour des administrateurs

                            <span class="cursor" (click)="showOrHideFormulaure()">
                                <i class="material-icons text-h3 displayRight">
                                    {{ showFormulaire ? 'expand_less' : 'expand_more' }}
                                </i>
                            </span>
                        </div>
                    </div>

                    <form *ngIf="showFormulaire" class="contactForm" [formGroup]="formulaireGroup" (ngSubmit)="onSubmit()" >

                        <div class="form-group" >
                            <label for="usernameCtrl">Nom et prénom <span>(*)</span></label>
                            <legend>Le nom et le prénom de l'administrateur</legend>
                            <input id="usernameCtrl" type="text" class="form-control" formControlName="usernameCtrl" placeholder="Le nom de l'administrateur" [ngClass]="{ 'is-invalid': submitted && f.usernameCtrl.errors }">
                            <div class="validation"></div>
                        </div>

                        <div class="form-group" >
                            <label for="emailCtrl">Email  <span>(*)</span></label>
                            <legend>L'adresse e-mail de l'administrateur</legend>
                            <input id="emailCtrl" type="text" class="form-control" formControlName="emailCtrl" placeholder="L'adresse e-mail de l'administrateur" [ngClass]="{ 'is-invalid': submitted && f.emailCtrl.errors }">
                            <div class="validation"></div>
                        </div>


                        <div class="form-group" >
                            <label for="niveauCtrl">Niveau d'accès  <span>(*)</span></label>
                            <legend>
                                Choisir un niveau d'accès de l'administrateur. À savoir : <br />
                                - Super Admin : Gestion totale sur le site Web <br />
                                - Administrateur : Ajoute, Modifie et Consulte <br />
                                - Manager : Ajoute et Consulte <br />
                                - Consultant : Consulte uniquement. Ne peut apporter aucune modification sur le site
                            </legend>
                            <mat-select id="niveauCtrl" formControlName="niveauCtrl" class="form-control" placeholder="Niveau " [ngClass]="{ 'is-invalid': submitted && f.niveauCtrl.errors }" >
                                <mat-option *ngFor="let nvl of niveauList" [value]="nvl.niveau" class="optionList colorNoirGris text-12">
                                    Niveau - {{nvl.libelle}}
                                </mat-option>
                            </mat-select>
                            <div class="validation"></div>
                        </div>


                        <div class="row" *ngIf="retourMessage" >

                            <div class="col-md-12 div-error" *ngIf="retourMessage.ko" >
                                {{retourMessage.ko}}
                            </div>
    
                            <div class="col-md-12 div-success" *ngIf="retourMessage.ok" >
                                {{retourMessage.ok}}
                            </div>
    
                        </div>
                
                
                        <div class="row"  >
                            <div class="col" >
                                <div class="text-center">
                                    <button  class="btn-valider" type="submit" title="Enregistrer"> Enregistrer </button>
                                </div>
                            </div>
                            <div class="col" *ngIf="editMode" >
                                <div class="text-center">
                                    <button class="btn-annuler" title="Annuler" (click)="cancelEditMode()" > Annuler </button>
                                </div>
                            </div>
                        </div>
                
                    </form>

                </div>


               <div class="partie-tableau margin-top-40px">
                    <div class="row">
                        <div class="col-md-12 text-h4 colorBleu">
                            Liste des admins
                        </div>
                    </div>

                    <app-arraytable *ngIf="lesAdminsArray.length > 0"
                        [arrayList]="lesAdminsArray"
                        [columnsToDisplay]="columnsToDisplay"
                        [elementsDisplay]="elementsDisplay"
                        [canDelete]="'true'"
                        [canEdit]="'true'"
                        [canReinit]="'true'"
                        (tableEmitter)="onTableAction($event)"
                    ></app-arraytable>
                </div>
                
            </div>

            <app-footer></app-footer>

        </div>
    </mat-drawer-container>


</div>
