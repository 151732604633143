import { Component, OnInit , Input  } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BackendService } from '../../services/backend/backend.service'
import { GlobalService } from '../../services/global/global.service'
import { EventsService } from '../../services/events/events.service'

@Component({
  selector: 'app-edit-etat-voyage',
  templateUrl: './edit-etat-voyage.component.html',
  styleUrls: ['./edit-etat-voyage.component.scss']
})
export class EditEtatVoyageComponent implements OnInit {

  @Input() element
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  title : string = "Modifier l'état de la demande de voyage"

  etatVoyage = []
  messageRetour
  etatSelected

  constructor(
    private activeModal: NgbActiveModal ,
    private backendService: BackendService ,
    private globalService: GlobalService,
    private eventsService: EventsService,
  ) { }

  ngOnInit() {

  }

  ngAfterContentInit() {
    this.etatSelected = this.element.statut
    if( this.element.statut === "En cours" ){
      this.etatVoyage.push({name: "En cours"}, {name: "Terminée"}, {name: "Annulée"})
    } else if( this.element.statut === "Validée" ){
      this.etatVoyage.push({name: "Validée"}, {name: "En cours"}, {name: "Terminée"}, {name: "Annulée"})
    }else if( this.element.statut === "Terminée" ){
      this.etatVoyage.push({name: "Terminée"})
    } else if( this.element.statut === "En attente de validation" ){
      this.etatVoyage.push({name: "En attente de validation"}, {name: "Validée"}, {name: "Annulée"})
    } else if( this.element.statut === "Annulée" ){
      this.etatVoyage.push({name: "Annulée"})
    }
  }


  public decline() {
    this.activeModal.close({status: false});
  }

  public valider() {

    let voyage = {
      statut: this.etatSelected,
      agent: this.globalService.getConnectedUser().id
    }
    console.log( voyage )
    this.backendService.post(`/voyageur/etat/${this.element.idvoyageur}`, voyage )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.messageRetour = {ko: resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement." }
        this.eventsService.errorsmsg( this.messageRetour.ko )
      } else {
        this.messageRetour = {ok: resultat.text}
        this.eventsService.successmsg( this.messageRetour.ok )
        setTimeout( () => {
          this.activeModal.close({status: true});
        }, 1000)
      }
    })
    .catch( error => {
      this.messageRetour = {ko: "Une erreur lors de l'enregistrement des données"}
      this.eventsService.errorsmsg( this.messageRetour.ko )
    })

  }


  public dismiss() {
    this.decline();
  }


}
