import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login.component';

import { MaterialAngModule } from '../matmodule/materialang.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HeaderModule} from '../header/header.module';
import { SharedModule } from '../shared/shared.module';
import { FooterModule } from '../footer/footer.module';

import { EventsService } from '../services/events/events.service'
import { GlobalService } from '../services/global/global.service'
import { ValidatorrService } from '../services/validatorr/validatorr.service'
import { BackendService } from '../services/backend/backend.service'

@NgModule({
  declarations: [
    LoginComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    MaterialAngModule,
    FormsModule,
    ReactiveFormsModule,

    HeaderModule,
    SharedModule,
    FooterModule
  ],
  providers: [
    EventsService,
    GlobalService,
    ValidatorrService,
    BackendService
  ]
})
export class LoginModule { }
