import { Component, OnInit } from '@angular/core';
import { EventsService } from '../../services/events/events.service'
import { GlobalService } from '../../services/global/global.service'
import { BackendService } from '../../services/backend/backend.service';
import { UtilsService } from '../../services/utils/utils.service';

@Component({
  selector: 'app-statistique',
  templateUrl: './statistique.component.html',
  styleUrls: ['./statistique.component.scss']
})
export class StatistiqueComponent implements OnInit {

  totalStatistique
  constructor(
    private eventsService: EventsService,
    private backendService: BackendService ,
    private globalService: GlobalService,
    private utilsService: UtilsService
  ) { }

  ngOnInit(): void {
    this.displayPage()
  }

  displayPage() {

    this.backendService.get( `/contact/statistique` )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
      } else {
        console.log( 'resultat statistique ', resultat );
        this.totalStatistique = {
          trajets: resultat[6].total_suggestroute,
          expeditions: resultat[5].total_sendbox,
          voyageurs: resultat[4].total_voyageurs,
          utilisateurs: resultat[3].total_users,
          avis: resultat[2].total_noteuser,
          visiteurs: resultat[1].total_stats_visites,
          appli: resultat[0].total_mobilapp,
          expednouser: resultat[7].total_sendboxnouser
        }
        console.log( this.totalStatistique )
      }
     })
     .catch( error => {
    })
  }

}
