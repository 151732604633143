import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor , HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from "rxjs";

import { GlobalService } from '../services/global/global.service'
import { EventsService } from '../services/events/events.service'

import { Router} from '@angular/router';
import { retry, catchError , map } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private globalService: GlobalService ,
        private router: Router ,
        private eventsService: EventsService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
 
        return next.handle(req).pipe(
          catchError((error) => {
     
            // console.log( " ********************** ErrorInterceptor ********************** " , error )

            let handled: boolean = false;
            console.error(error);
            if (error instanceof HttpErrorResponse) {
              if (error.error instanceof ErrorEvent) {
                console.error("Error Event");
              } else {
                // console.log(`error status : ${error.status} ${error.statusText}`);
                switch (error.status) {
                  case 401:      //login
                    console.log( "Le token a expiré")

                    if (this.router.url !== "/dashboard" ) {
                      this.eventsService.onAutoLogout("Votre session a expiré pour cause d'inactivité. ")
                    }
                      
                    this.eventsService.setUserLoggedIn(false);
                    this.globalService.logOut();
                    this.eventsService.onUserDeconnected();
                    this.eventsService.onMajToken();
                    handled = true;
                    break;
                  case 0:
                    if( req.url != "http://api.ipify.org/?format=json" ) {
                        this.eventsService.onAutoLogout( "une erreur inattendue s'est produite lors du chargement de la page. veuillez réessayer." )
                    }
                    handled = true;
                    break;
                }
              }
            }
            else {
              // console.error("Other Errors");
            }
     
            if (handled) {
              // console.log('return back ');
              return of(error);
            } else {
              // console.log('throw error back to to the subscriber');
              return throwError(error);
            }
     
          })
        )
    }
    


}